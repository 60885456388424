import React from 'react'

const Order_data = () => {
  return (
    <div>
      




<div class="">
    <div class="row mt-3 order_box_cont">
        <div class="col-12 col-md-12 ">
            <div class="card border-0 mb-3">
                <div class="card-body">
                    <div class="d-flex ">
                        <div class="border-end pe-3">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" data-lucide="shopping-bag" class="lucide lucide-shopping-bag"><path d="M6 2 3 6v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V6l-3-4Z"></path><path d="M3 6h18"></path><path d="M16 10a4 4 0 0 1-8 0"></path></svg>
                        </div>
                        <div class="ps-3 ">
                            <h6>Order Details</h6>
                        </div>
                    </div>
                    <hr/>
                    <div class="row">
                        <div class="col-12 col-md-6 mb-2">
                            <small class="text-muted">Order created on channel</small>
                            <p>11 Jul 2023 12:00 AM</p>
                        </div>
                        <div class="col-12 col-md-6 mb-2">
                            <small class="text-muted">Order created on system</small>
                            <p>11 Jul 2023 03:41 PM</p>
                        </div>
                        <div class="col-12 col-md-6 mb-2">
                            <small class="text-muted">Channel</small>
                            <p>IndoChina Store - Woocommerce</p>
                        </div>
                        <div class="col-12 col-md-6 mb-2">
                            <small class="text-muted">Shipment value</small>
                            <p>₹ 2000 <span class="badge rounded-pill bg-warning">Prepaid</span></p>
                        </div>
                    </div>
                </div>
            </div>

            <div class="card border-0 mb-3">
                <div class="card-body">
                <div class="d-flex ">
                    <div class="border-end pe-3">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" data-lucide="package" class="lucide lucide-package"><path d="m7.5 4.27 9 5.15"></path><path d="M21 8a2 2 0 0 0-1-1.73l-7-4a2 2 0 0 0-2 0l-7 4A2 2 0 0 0 3 8v8a2 2 0 0 0 1 1.73l7 4a2 2 0 0 0 2 0l7-4A2 2 0 0 0 21 16Z"></path><path d="m3.3 7 8.7 5 8.7-5"></path><path d="M12 22V12"></path></svg>
                    </div>
                    <div class="ps-3 ">
                        <h6>Shipment Details</h6>
                    </div>
                </div>
                <hr/>
                <div class="row">
                    <div class="col-12 col-md">
                        <h6>Ship From:</h6>
                            <small class="text-muted">Sender Name:</small>
                            <p>Rahul Gandi</p>
                            <small class="text-muted">Sender Address:</small>
                            <p>Lodhiya Street, New Delhi, Delhi 110001</p>
                            <small class="text-muted">Sender Email:</small>
                            <p>rahulgandi@gmail.com</p>
                            <small class="text-muted">Sender Mobile:</small>
                            <p>123456789</p>
                    </div>
                    <div class="col-12 col-md">
                        <h6>Ship To:</h6>
                            <small class="text-muted">Receiver Name:</small>
                            <p>Rahul Gandi</p>
                            <small class="text-muted">Receiver Address:</small>
                            <p>Lodhiya Street, New Delhi, Delhi 110001</p>
                            <small class="text-muted">Receiver Email:</small>
                            <p>rahulgandi@gmail.com</p>
                            <small class="text-muted">Receiver Mobile:</small>
                            <p>123456789</p>
                    </div>
                </div>
                </div>
            </div>



            <div class="card border-0  mb-3">
                <div class="card-body">
                <div class="d-flex ">
                    <div class="border-end pe-3">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" data-lucide="package-open" class="lucide lucide-package-open"><path d="M12 22v-9"></path><path d="M15.17 2.21a1.67 1.67 0 0 1 1.63 0L21 4.57a1.93 1.93 0 0 1 0 3.36L8.82 14.79a1.655 1.655 0 0 1-1.64 0L3 12.43a1.93 1.93 0 0 1 0-3.36z"></path><path d="M20 13v3.87a2.06 2.06 0 0 1-1.11 1.83l-6 3.08a1.93 1.93 0 0 1-1.78 0l-6-3.08A2.06 2.06 0 0 1 4 16.87V13"></path><path d="M21 12.43a1.93 1.93 0 0 0 0-3.36L8.83 2.2a1.64 1.64 0 0 0-1.63 0L3 4.57a1.93 1.93 0 0 0 0 3.36l12.18 6.86a1.636 1.636 0 0 0 1.63 0z"></path></svg>
                    </div>
                    <div class="ps-3 ">
                        <h6>Package Details</h6>
                    </div>
                </div>
                <hr/>
                <div class="row">
                    <div class="col-12 col-md-4 mb-2">
                        <small class="text-muted">Applicable Weight (in Kg)</small>
                        <p>1KG</p>
                    </div>
                    <div class="col-12 col-md-4 mb-2">
                        <small class="text-muted">Dead Weight (in Kg)</small>
                        <p>1KG</p>
                    </div>
                    <div class="col-12 col-md-4 mb-2">
                        <small class="text-muted">Volumetric Weight (in kg)</small>
                        <p>1KG</p>
                    </div>
                    <div class="col-12 col-md-4 mb-2">
                        <small class="text-muted">Dimensions (in cm)</small>
                        <p>1.000 x 1.000 x 1.000</p>
                    </div>
                    <div class="col-12 col-md-6 mb-2">
                        <small class="text-muted">Number of Boxes</small>
                        <p>1</p>
                    </div>
                </div>
                </div>
            </div>

            <div class="card border-0 mb-3">
                <div class="card-body">
                <div class="d-flex ">
                    <div class="border-end pe-3">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" data-lucide="shopping-basket" class="lucide lucide-shopping-basket"><path d="m15 11-1 9"></path><path d="m19 11-4-7"></path><path d="M2 11h20"></path><path d="m3.5 11 1.6 7.4a2 2 0 0 0 2 1.6h9.8a2 2 0 0 0 2-1.6l1.7-7.4"></path><path d="M4.5 15.5h15"></path><path d="m5 11 4-7"></path><path d="m9 11 1 9"></path></svg>
                    </div>
                    <div class="ps-3 ">
                        <h6>Product Details</h6>
                    </div>
                </div>
                <hr/>
                <div class="table-responsive ">
                    <table class="table table-light">
                        <thead>
                        <tr>
                            <th>Name</th>
                            <th>Category</th>
                            <th>HSN</th>
                            <th>SKU</th>
                            <th>Qty</th>
                            <th>Unit price</th>
                            <th>Discount</th>
                            <th>Tax</th>
                            <th>Total</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td>Samsung mobile Transparent Covers - Samsung-A50</td>
                            <td>1689070274414</td>
                            <td>samsung-A50</td>
                            <td>1</td>
                            <td>1</td>
                            <td>200.00</td>
                            <td>100.00</td>
                            <td>10.00</td>
                            <td>100.00</td>
                        </tr>
                        </tbody>
                    </table>
                </div>
                </div>
            </div>

            
            
            
        </div>
    </div>
</div>
    </div>
  )
}

export default Order_data
