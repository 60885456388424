import React, { useState, createRef, useEffect } from "react";

import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import Swal from 'sweetalert2'
import config from "../../config"
import { useNetworkState } from 'react-use';


const Register = () => {
  let navigate = useNavigate();
  const isOnline = useNetworkState();
  console.log("isONline  === " ,isOnline )
  let userDetail = localStorage.getItem('ship_rocket_user');
  
  // console.log("userDetail ===>   ",userDetail);
  const [state, setState] = useState({ email: "",password:"" });
  const [regsterState, setRegsterState] = useState({ email: "",password:"",full_name:"", company_name:""  , mobile_number:""});
  const [userState, setUserState] = useState({ full_name: "" });
  const [trackState, setTrackState] = useState({tracking_no:""})

  useEffect(() => {

    if(userDetail!= null) {
      let uDetail = JSON.parse(userDetail)
      setUserState({full_name:uDetail.full_name})
      config.UserId = uDetail.customer_id
    }
  }, [])
  
  const Toast = Swal.mixin({
    toast: true,
    position: 'bottom-end',
    showConfirmButton: false,
    timer: 3000
  })

  
  const inputHandleChangeR = (e) => {
    const value = e.target.value;
    const text = e.target.name;
    setRegsterState({ ...regsterState, [text]: value })
  }

const regitserButton = () =>{
  // console.log("regsterState", regsterState);
  if(regsterState.email != "" && regsterState.password != ""  && regsterState.full_name != "" && regsterState.company_name!="") {
    let full_api = config.apiUrl + `/user/register_api`;
    let sendData = regsterState;
    // console.log("bb", sendData);
    axios.post(full_api, sendData, { headers: config.headers }).then((res) => {
        // console.log("res ", res);
        if (res.data.isValid) {
            Toast.fire({
              background:"#3659cd",
              type: 'success',
              title: res.data.message ,
              color:"white"
            });
            let sendState = {email:sendData.email , mobile_number:sendData.mobile_number};
            // console.log("sendState === " , sendState)
          
             navigate('/verify_email',{state:sendState})
            // navigate('/customer/dashboard')
        }else {
          Toast.fire({
            background:"#a61c09",
            type: 'unsuccess',
            title: res.data.message ,
            color:"white"
          });
           
        }

    }).catch((e) => {


    });
  }else {
    Toast.fire({
      background:"#a61c09",
      type: 'unsuccess',
      title: "Please fill all fields",
      color:"white"
    });
  }

}



  return (
    <div>
   
<section class="register_box_00">
  <div class="row justify-content-center ">
    <div class="col-xl-4 col-lg-5 col-sm-6 col-12 ">
      <form onSubmit={(e) => {
          e.preventDefault(); // Prevent the default form submission behavior
          regitserButton(); // Call your login function
        }} class="my-5">
        <div class="border rounded-2 p-4 mt-5 login_box">
          <div class="login-form">
            <div class="text-center login-logo">
              <a href="/login" class="mb-4 text-center">
                <img src="assets/images/logo_big.png" class="img-fluid " alt=""/>
                <h3 class="fw-bold">SkyShip</h3>
              </a>
            </div>
            <h5 class="fw-bold">Register Your Account</h5>
            <p class="mb-5">Get register your account to access the customer panel.</p>

            <div class="mb-3">
              <label class="form-label">Full Name</label>
              <input type="text" class="form-control" placeholder="Enter your full name" name="full_name" onChange={(e) => inputHandleChangeR(e)}/>
            </div>
            <div class="mb-3">
              <label class="form-label">Company Name</label>
              <input type="text" class="form-control" placeholder="Enter your company name" name="company_name" onChange={(e) => inputHandleChangeR(e)}/>
            </div>

            <div class="mb-3">
              <label class="form-label">Your Email</label>
              <input type="email" class="form-control" placeholder="Enter your email" name="email" onChange={(e) => inputHandleChangeR(e)}/>
            </div>
            <div class="mb-3">
              <label class="form-label">Your Mobile</label>
              <input type="number" class="form-control" placeholder="Enter your mobile" name="mobile_number" onChange={(e) => inputHandleChangeR(e)}/>
            </div>

            <div class="mb-3">
              <label class="form-label">Create Password</label>
              <input type="password" class="form-control" placeholder="Enter password" name="password" onChange={(e) => inputHandleChangeR(e)}/>
            </div>
        
            <div class="d-flex align-items-center justify-content-between">
              <div class="form-check m-0">
                <input class="form-check-input" type="checkbox" value="" id="rememberPassword" checked/>
                <label class="form-check-label" for="rememberPassword">I accept all <a href="" class="text-primary">terms & condition</a></label>
              </div>
            </div>
            <div class="d-grid py-3 mt-4">
              <button type="submit" style={{cursor:"pointer"}} class="btn btn-lg btn-primary">
                Register Account
              </button>
            </div>
            <div class="text-center pt-4">
              <a href="/login" class="text-blue text-decoration-underline ms-2">
              Return to login</a>
            </div>
          </div>
        </div>
      </form>

      <div class="">
        <ul class="nav">
            <li class="nav-item">
              <a class="nav-link" href="terms" target="_blank">Terms &amp; Condition</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="policy" target="_blank">Privacy Policy</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="help" target="_blank">Help</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="contact" target="_blank">Contact</a>
            </li>
        </ul>
      </div>
    </div>
  </div>

</section>

      
    </div>
  )
}

export default Register
