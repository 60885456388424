import React, { useEffect, useState } from 'react'
import axios from 'axios'
import config from "../../config"
import Swal from 'sweetalert2'
import Footer from './Footer'
import Header from './Header'


const Label_size_settings = () => {
    let userData = (localStorage.getItem('ship_rocket_user')) ? JSON.parse(localStorage.getItem('ship_rocket_user')) : {};
    const Toast = Swal.mixin({
        toast: true,
        position: 'bottom-end',
        showConfirmButton: false,
        timer: 3000
    })

    let selectedPage = "label_size_settings";
    const [settingState, setSettingState] = useState({ print_size: "8x11" })
    

    useEffect(() => {
        get_user_label_setting();

    }, [])

    const checkboxChange = (e) => {
        console.log(e.target.value);
        setSettingState({ ...settingState, [e.target.name]: e.target.value })

    }

    const submitLabelSize = () => {
        let dataToSend = {
            customer_id: userData.customer_id, label_size_print: settingState.print_size
        };

        // console.log("datatoSend",dataToSend)
        let url = config.apiUrl + '/user/update_shipment_label';
        axios.post(url, dataToSend, { headers: config.headers })
            .then((res) => {
                console.log("sub responseJson => ", res);
                if (res.data.status) {
                    Toast.fire({
                        background: "#3659cd",
                        type: 'success',
                        title: "Updated Successfuly",
                        color: "white"
                    });
                }
                // setSummarystate(res.data.output)
                //setSettingState(res.data.output)

            })
            .catch((error) => {
                //Hide Loader
                //   setLoadingstate({...loadingstate,loading:false})
                // console.log(error);
            });

    }

    const get_user_label_setting = () => {
        if (userData) {
            console.log("userDetail", userData)
            let dataToSend = {
                customer_id: userData.customer_id
            };

            console.log("datatoSend", dataToSend)
            let url = config.apiUrl + '/user/get_shipment_label_setting';

            // console.log("headers => ", config.headers);


            axios.post(url, dataToSend, { headers: config.headers })
                .then((res) => {
                    console.log("responseJson => ", res);
                    // setSummarystate(res.data.output)
                   if(res.data.output.label_size_print!=""){
                    setSettingState({ ...settingState, print_size: res.data.output.label_size_print })
                   }
                 
                    

                })
                .catch((error) => {
                    //Hide Loader
                    //   setLoadingstate({...loadingstate,loading:false})
                    console.log(error);
                });
        }

    }


  return (
    <div>
       <Header/>
 <div class="page-wrapper pinned">
  <div class="main-container">
   <div className='app-container'>
  <div class="app-body">
  <div class="container-fluid">
  <div class="mob_nav">
  <div class=" d-flex justify-content-start ">
    <a href="javascript:window.history.back();" class=""><i class="bi bi-arrow-left"></i></a>
    <h2>Label Settings</h2>
  </div>
</div>


<div class="row justify-content-between">
    <div class="col-12 col-md-4 desk_nav">
      <div class="d-flex">
        <a href="javascript:window.history.back();" class="back_btn"><i class="bi bi-arrow-90deg-left"></i></a>
        <h2>Label Settings</h2>
      </div>
    </div>
</div>

<ul class="nav nav-pills">
                        <li class="nav-item">
                            <a class={`nav-link ${(selectedPage == "master_label_settings") ? "active" : ""}`} href="/master_label_settings">Global Label Settings</a>
                        </li>
                        <li class="nav-item">
                            <a class={`nav-link ${(selectedPage == "label_size_settings") ? "active" : ""}`} href="/label_size_settings">Label Size Settings</a>
                        </li>
                    </ul>

                    <div class="card mt-3 border-0 shadow-sm label_setting">
                        <div class="card-body">
                            <div class="row">
                                <div class="col-12 col-md border-end">
                                    <div class="form-check form-check-inline">
                                        <input class="form-check-input" type="radio" name="print_size" id="inlineRadio1" value="8x11" checked={settingState.print_size == "8x11"} onChange={(e) => checkboxChange(e)} />
                                        <label class="form-check-label" for="inlineRadio1">Standard Desktop Printers - Size A4 (8"X11")</label>
                                        <br />
                                        <small>(Four Label Printed on one Sheet)</small>
                                    </div>
                                    <hr />
                                    <div class="text-center">
                                        <img src="assets/img/8x11_sample_m.png" class="img-fluid" />
                                    </div>
                                </div>
                                <div class="col-12 col-md">
                                    <div class="form-check form-check-inline">
                                        <input class="form-check-input" type="radio" name="print_size" id="inlineRadio1" value="4x6" checked={settingState.print_size == "4x6"} onChange={(e) => checkboxChange(e)} />
                                        <label class="form-check-label" for="inlineRadio1">Thermal Label Printers - Size (4"X6")</label>
                                        <br />
                                        <small>(Single Label on one Sheet)</small>
                                    </div>
                                    <hr />
                                    <div class="text-center">
                                        <img src="assets/img/4x6_sample.png" class="img-fluid" />
                                    </div>
                                </div>
                                {/* <div class="col-12 col-md">
                                    <div class="form-check form-check-inline">
                                        <input class="form-check-input" type="radio" name="print_size" id="inlineRadio1" value="4x4" checked={settingState.print_size == "4x4"} onChange={(e) => checkboxChange(e)} />
                                        <label class="form-check-label" for="inlineRadio1">Thermal Label Printers - Size (4"X4")</label>
                                        <br />
                                        <small>(Single Label on one Sheet)</small>
                                    </div>
                                    <hr />
                                    <div class="text-center">
                                        <img src="/img/4x4_sample.png" class="img-fluid" />
                                    </div>
                                </div> */}
                            </div>
                            <div class="py-3 pt-5 text-center">
                                <button class="btn btn-primary" onClick={submitLabelSize}>Save Settings</button>
                            </div>
                        </div>
                    </div>
    </div></div>
    <Footer/></div></div></div>
    </div>
  )
}

export default Label_size_settings
