import React from 'react'
import Settings_nav from './Settings_nav'

import Header from './Header'

import axios from 'axios'

import Swal from 'sweetalert2'
import config from "../../config"
import Footer from './Footer'

const Billing_address = () => {
    const [pincode, setPincode] = React.useState('');
  const [newstate, setnewState] = React.useState('');
  const [newcity, setnewCity] = React.useState('');
  const [newstateid, setnewStateid] = React.useState('');
  const [newcityid, setnewCityid] = React.useState('');
    const[country,setCountry] = React.useState({country_id:""})
    const[state , setState] = React.useState({address:""})

  

    React.useEffect(()=>{
      list()
      getBillingAdress()
    },[])

    const handleChange =(e)=>{
      setState({...state,[e.target.name]:e.target.value})
    }
    const list = async()=>{
      let url = config.apiUrl + '/country/country_list';
      let senddata = {}
      const responseJson = await axios.post(url, senddata, { headers: config.headers });
      setCountry({country_id:responseJson.data.defaultCountry})
      }


    const fetchDetailsFromPincode = async (enteredPincode) => {
      try {
        // Replace 'your-api-endpoint' with the actual endpoint of your backend
        const url = config.apiUrl + '/country/automate_pincode';
        
        // Assuming your API expects a POST request with the entered pincode
        const sendData = { enteredPincode , country_id: 244};
        console.log("sendData == " , sendData)
    
        const response = await axios.post(url, sendData);
    
        // Assuming your API returns an object with state and city properties
        const { state, city , state_id , city_id } = response.data;
    
        return { state, city , state_id , city_id };
      } catch (error) {
        console.error('Error fetching details:', error);
        return {
          state: '',
          city: '',
          state_id:'',
          city_id:'',
        };
      }
    };
    const handlePincodeChange = async (e) => {
      const newPincode = e.target.value;
  
      // Update pincode state
      setPincode(newPincode);
  
      // Check if the length is at least three characters
      if (newPincode.length >= 3) {
        // Fetch details based on the pincode
        const { state: newState, city: newCity , state_id: newStateid , city_id:newCityid } = await fetchDetailsFromPincode(newPincode);
  
        // Update state and city inputs
        setnewState(newState);
        setnewCity(newCity);
        setnewStateid(newStateid);
        setnewCityid(newCityid);
      } else {
        // Reset state and city if the pincode is less than three characters
        setnewState('');
        setnewCity('');
      }
    };

    const update =()=>{
      let full_api = config.apiUrl + `/user/update_billing_address`;
      let sendData = { state_id:newstateid  , city_id:newcityid , address:state.address , pincode:pincode , city:newcity , state:newstate };
      // console.log("sendData == " , sendData)

      if (state.address!="" && pincode!="" && newstate!="" && newcity!="") {
       console.log("bb", sendData);
      axios.post(full_api, sendData, { headers: config.headers }).then((res) => {
          if(res.data.status==true){
              // setstate({...state , current_password:"" , new_password:"" , confirm_password:""})
              const Toast = Swal.mixin({
                  toast: true,
                  position: 'bottom-end',
                  showConfirmButton: false,
                  timer: 2000
                })
              Toast.fire({
                  background: "#3659cd",
                  type: 'success',
                  title: res.data.message,
                  color: "white"
              });
          }
          else{
              const Toast = Swal.mixin({
                  toast: true,
                  position: 'bottom-end',
                  showConfirmButton: false,
                  timer: 2000
                })
              Toast.fire({
                  background: "#a61c09",
                  type: 'error',
                  title: res.data.message,
                  color: "white"
              });
          }
         
      }).catch((e) => {
          const Toast = Swal.mixin({
              toast: true,
              position: 'bottom-end',
              showConfirmButton: false,
              timer: 2000
            })
          Toast.fire({
              background: "#a61c09",
              type: 'error',
              title: "Something Went Wrong",
              color: "white"
          });

      });
  }else{
    const Toast = Swal.mixin({
      toast: true,
      position: 'bottom-end',
      showConfirmButton: false,
      timer: 2000
    })
  Toast.fire({
      background: "#a61c09",
      type: 'error',
      title: "Please Fill all details",
      color: "white"
  });

  }
  }

  const getBillingAdress =()=>{
    let full_api = config.apiUrl + `/user/get_billing_address`;
    let sendData = {};
    // console.log("sendData == " , sendData)

    
     console.log("bb", sendData);
    axios.post(full_api, sendData, { headers: config.headers }).then((res) => {
        if(res.data.status==true){
          setState({...state , address:res.data.output.address})
          handlePincodeChange({ target: { value: res.data.output.pincode } });
        
        
        }
        else{
           
        }
       
    }).catch((e) => {
        const Toast = Swal.mixin({
            toast: true,
            position: 'bottom-end',
            showConfirmButton: false,
            timer: 2000
          })
        Toast.fire({
            background: "#a61c09",
            type: 'error',
            title: "Something Went Wrong",
            color: "white"
        });

    });

}

  return (
    <div>
       <Header/>
 <div class="page-wrapper pinned">
  <div class="main-container">
   <div className='app-container'>
  <div class="app-body">
  <div class="container-fluid">
 

<div class="mob_nav">
  <div class=" d-flex justify-content-start ">
    <a href="javascript:window.history.back();" class=""><i class="bi bi-arrow-left"></i></a>
    <h2>Biiling Address</h2>
  </div>
</div>


<div class="row justify-content-between">
    <div class="col-12 col-md-4 desk_nav">
      <div class="d-flex">
        <a href="javascript:window.history.back();" class="back_btn"><i class="bi bi-arrow-90deg-left"></i></a>
        <h2>Biiling Address</h2>
      </div>
    </div>
</div>



<div class="row">
    <div class="col-12 col-md-3 left_sub_menu_bx mb_display_none">
        {/* <?php include 'settings_nav.php';?> */}
        <Settings_nav value={4}/>
    </div>
    <div class="col-12 col-md-9">
        <div class="card border-0 shadow-sm">
            <div class="card-body">
            <div class="row">
                    <div class="col-12 col-md-12 mb-3">
                        <label for="" class="form-label">Full Address <span class="text-danger">*</span></label>
                        <textarea class="form-control" rows="5" id="comment" name="address" placeholder="Enter details" onChange={(e)=>handleChange(e)} value={state.address}></textarea>
                    </div>
                    <div class="col-12 col-md-4 mb-3">
                        <label for="" class="form-label">Pincode <span class="text-danger">*</span></label>
                        <input  type='text'  className='form-control' placeholder='Enter Pincode' value={pincode}
       onChange={handlePincodeChange}></input>
                    </div>
                    <div class="col-12 col-md-4 mb-3">
                        <label for="" class="form-label">State <span class="text-danger">*</span></label>
                        <input className='form-control' type='text' disabled='disabled' value={newstate}></input>
                    </div>
                    <div class="col-12 col-md-4 mb-3">
                        <label for="" class="form-label">City <span class="text-danger">*</span></label>
                        <input className='form-control' type='text' disabled='disabled' value={newcity}></input>
                    </div>
            </div>

            <a onClick={(e)=>update(e)} class="btn btn-primary">Update</a>
            </div>
        </div>
    </div>
</div>



</div></div>
<Footer/></div></div></div>
    </div>
  )
}

export default Billing_address
