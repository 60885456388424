import React, {  useRef } from 'react'
import Header from './Header'

import axios from "axios";

import config from "../../config"

import Footer from './Footer';
import Settings_nav from './Settings_nav';

const Email_notifications = () => {
   
const[state,setState] = React.useState({customer:{}})
const[emailturn , setemailturn] = React.useState(false)
const[status , setstatus] = React.useState({booking_confirmation:false , pickup_completed:false, out_for_delivery:false,delivered:false })
console.log("status statwe == " , status)


  const handleChange =(e)=>{
      console.log("e == " , e.target.checked)
      setemailturn(e.target.checked)
      change_email_notification_status( e.target.checked)

  }

  const axios_get_api = (status) => {

      let full_api = config.apiUrl + `/user/customer_detail`;
      let sendData = {
     
      }
    axios.post(full_api, sendData, { headers: config.headers }).then(res => {
        setState({...state,customer:res.data.output})
        if(res.data.output.email_notification!=undefined && res.data.output.email_notification==true){
          setemailturn(true)
        }
        if(res.data.output.email_array!=undefined  ){
          setstatus({
              booking_confirmation:res.data.output.email_array[0].booking_confirmation , pickup_completed:res.data.output.email_array[0].pickup_completed, out_for_delivery:res.data.output.email_array[0].out_for_delivery,delivered:res.data.output.email_array[0].delivered   
          })
        }
      }).catch((e) => {
      })
    }
    React.useEffect(() => {
      axios_get_api()
    }, [])

    const change_email_notification_status = (status) => {

      let full_api = config.apiUrl + `/user/customer_email_notification_status_change`;
      let sendData = {
        status : status
      }
    axios.post(full_api, sendData, { headers: config.headers }).then(res => {
      //   setState({...state,customer:res.data.output})
      if(res.data.status==true){
          axios_get_api()
      }
      else{
          axios_get_api()
      }
      }).catch((e) => {
      })
    }
    const change_shipping_status = (newStatus) => {
      // console.log("statu == " , status)

      let full_api = config.apiUrl + `/user/customer_email_notification_shipping_status_change`;
      let sendData = {
        status : newStatus
      }
      console.log("data_to_send" , sendData)
    axios.post(full_api, sendData, { headers: config.headers }).then(res => {
      //   setState({...state,customer:res.data.output})
      if(res.data.status==true){
          axios_get_api()
      }
      else{
          axios_get_api()
      }
      }).catch((e) => {
      })
    }

    const handlestatus = (e, statusKey) => {
      const newStatus = { ...status, [statusKey]: e.target.checked };
      setstatus(newStatus);
      change_shipping_status(newStatus);
    };

  return (
    <div>
        <Header/>
 <div class="page-wrapper pinned">
  <div class="main-container">
   <div className='app-container'>
  <div class="app-body">
  <div class="container-fluid"> 
 
<div class="mob_nav">
  <div class=" d-flex justify-content-start ">
    <a href="javascript:window.history.back();" class=""><i class="bi bi-arrow-left"></i></a>
    <h2>Email Notification</h2>
  </div>
</div>


<div class="row justify-content-between">
    <div class="col-12 col-md-4 desk_nav">
      <div class="d-flex">
        <a href="javascript:window.history.back();" class="back_btn"><i class="bi bi-arrow-90deg-left"></i></a>
        <h2>Email Notification</h2>
      </div>
    </div>
</div>



<div class="row">
    <div class="col-12 col-md-3 left_sub_menu_bx mb_display_none">
      <Settings_nav value={6}/>
    </div>
    <div class="col-12 col-md-9">
      <div class="card border-0 shadow-sm">
        <div class="card-body">
          <div class="row">
                  <div class="col-12 col-md-4 mb-3">
                      <label for="" class="form-label">Email Notification <span class="text-danger">*</span></label>
                      <div class="form-check form-switch  mb-2">
                          <input class="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckChecked" onChange={(e)=>handleChange(e)} checked={emailturn}/>
                          <label class="form-check-label" for="flexSwitchCheckChecked" >On/Off</label>
                      </div>
                  </div>

                  {/* <div class="col-12 col-md mb-3">
                    <div class="alert alert-primary">
                      <strong>hello@gamil.com</strong> is your registered email address to receive notifications.
                    </div>
                  </div> */}

   
{ state.customer.email_notification && state.customer.email_notification==true?
                  <div class="col-12 col-md-12 mb-3">
                  <table class="table">
                          <thead>
                            <tr>
                              <th>Notification Type</th>
                              <th>Disable/Enable</th>
                            </tr>
                          </thead>
                          <tbody>
          {[
            { name: "Booking Confirmation", key: "booking_confirmation" },
            { name: "Pickup Completed", key: "pickup_completed" },
            { name: "Out For Delivery", key: "out_for_delivery" },
            { name: "Delivered", key: "delivered" },
           
          ].map((statusItem) => (
            <tr key={statusItem.key}>
              <td>{statusItem.name}</td>
              <td>
                <div className="form-check form-switch">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id={`flexSwitchCheckDefault-${statusItem.key}`}
                    name={statusItem.key}
                    onChange={(e) => handlestatus(e, statusItem.key)}
                    checked={status[statusItem.key]}
                  />
                </div>
              </td>
            </tr>
          ))}
        </tbody>
                        </table>
                  </div>
                  :""}
          </div>

          {/* <a href="#" class="btn btn-primary">Update</a> */}
        </div>
      </div>
    </div>
</div>

    </div>
</div>
<Footer/></div></div></div>
    </div>
  )
}

export default Email_notifications
