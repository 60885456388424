import React from 'react'

const Carrier_list_order = () => {
  return (
    <div>
      <div class="row g-0">
    <div class="col-12 col-md-4 bg-light show_carrier_left mb_display_none">
        <div class="row">
            <div class="col-12 mb-3">
                <small>Pickup From</small>
                <p>421003, Maharashtra</p>
            </div>
            <div class="col-12 mb-3">
                <small>Deliver To</small>
                <p>421003, Maharashtra</p>
            </div>
            <div class="col-12 mb-3">
                <small>Order Value</small>
                <p>₹ 100.00</p>
            </div>
            <div class="col-12 mb-3">
                <small>Payment Mode</small>
                <p>Prepaid</p>
            </div>
            <div class="col-12 mb-3">
                <small>Applicable Weight (in Kg)</small>
                <p>0.5 Kg</p>
            </div>
        </div>
    </div>
    <div class="col-12 col-md show_carrier_right">
        <div class="px-2 mb-1">
            <div class="row justify-content-between">
                <div class="col-12 col-md-4">
                    <h5>Available Couriers</h5>
                </div>
                <div class="col-12 col-md-4">
                    <select class="form-select">
                      <option>Chepest</option>
                      <option>2</option>
                      <option>3</option>
                      <option>4</option>
                    </select>
                </div>
            </div>
        </div>
        <ul class="list-group">
            <li class="list-group-item list-group-item-action">
                <div class="row g-2">
                    <div class="col-2">
                        <figure>
                            <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/2/23/Delhivery_Logo_%282019%29.png/800px-Delhivery_Logo_%282019%29.png?20190506185741" class="img-fluid " />
                        </figure>
                    </div>
                    <div class="col-4">
                        <h4>Delhivery Surface</h4>
                        <small>Min-weight: 0.5 kg</small>
                    </div>
                    <div class="col-2">
                        <h6>Ch. Weight</h6>
                        <small>0.5 Kg</small>
                    </div>
                    <div class="col-2">
                        <h2>₹78.00</h2>
                    </div>
                    <div class="col-12 col-md-2">
                        <div class="d-grid">
                          <a href="" class="btn btn-primary btn-block">Ship Now</a>
                        </div>
                    </div>
                </div>
            </li>
            <li class="list-group-item list-group-item-action">
                <div class="row g-2">
                    <div class="col-2">
                        <figure>
                            <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/2/23/Delhivery_Logo_%282019%29.png/800px-Delhivery_Logo_%282019%29.png?20190506185741" class="img-fluid " />
                        </figure>
                    </div>
                    <div class="col-4">
                        <h4>Delhivery Surface</h4>
                        <small>Min-weight: 0.5 kg</small>
                    </div>
                    <div class="col-2">
                        <h6>Ch. Weight</h6>
                        <small>0.5 Kg</small>
                    </div>
                    <div class="col-2">
                        <h2>₹78.00</h2>
                    </div>
                    <div class="col-12 col-md-2">
                        <div class="d-grid">
                          <a href="" class="btn btn-primary btn-block">Ship Now</a>
                        </div>
                    </div>
                </div>
            </li>
            <li class="list-group-item list-group-item-action">
                <div class="row g-2">
                    <div class="col-2">
                        <figure>
                            <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/2/23/Delhivery_Logo_%282019%29.png/800px-Delhivery_Logo_%282019%29.png?20190506185741" class="img-fluid " />
                        </figure>
                    </div>
                    <div class="col-4">
                        <h4>Delhivery Surface</h4>
                        <small>Min-weight: 0.5 kg</small>
                    </div>
                    <div class="col-2">
                        <h6>Ch. Weight</h6>
                        <small>0.5 Kg</small>
                    </div>
                    <div class="col-2">
                        <h2>₹78.00</h2>
                    </div>
                    <div class="col-12 col-md-2">
                        <div class="d-grid">
                          <a href="" class="btn btn-primary btn-block">Ship Now</a>
                        </div>
                    </div>
                </div>
            </li>
        </ul>
    </div>
</div>

    </div>
  )
}

export default Carrier_list_order
