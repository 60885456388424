import React from 'react'
import Header from './Header'
import Footer from './Footer'
import {  useParams } from 'react-router-dom';

import axios from "axios";

import config from "../../config"


const Bulk_order_log = () => {
    const{log_id} = useParams() 
const[state,setState]= React.useState([])

    const shipment_list=(status)=>{
        let dataToSend = {log_id : log_id
    };
   
      // console.log("datatoSend",dataToSend)
      let url = config.apiUrl + '/shipment/bulk_shipment_log_view';
     
     // console.log("headers => ", config.headers);
     
      
      axios.post(url, dataToSend, { headers: config.headers })
          .then((res) => {
            // console.log("responseJson => ", res);
            setState(res.data.output)
            // setisLoading(false)
         
          })
          .catch((error) => {
              //Hide Loader
            //   setLoadingstate({...loadingstate,loading:false})
              // console.log(error);
          });    

    }

    React.useEffect(()=>{
        shipment_list()
    },[])
  return (
    <div>
      <Header/>
      <div class="page-wrapper pinned">
 <div class="main-container">
  <div className='app-container'>
 <div class="app-body">
 <div class="container-fluid">


<div class="mob_nav">
  <div class=" d-flex justify-content-start ">
    <a href="javascript:window.history.back();" class=""><i class="bi bi-arrow-left"></i></a>
    <h2>Bulk Order Log </h2>
  </div>
</div>


<div class="row justify-content-between">
    <div class="col-12 col-md-4 desk_nav">
      <div class="d-flex">
        <a href="javascript:window.history.back();" class="back_btn"><i class="bi bi-arrow-90deg-left"></i></a>
        <h2>Bulk Order Log</h2>
      </div>
    </div>
    <div class="col-md-8">
    </div>
</div>

{/* <!-- Nav tabs --> */}
{/* <ul class="nav nav-tabs">
  <li class="nav-item">
    <a class="nav-link active" data-bs-toggle="tab" href="#home">Import Order Log</a>
  </li>
  <li class="nav-item">
    <a class="nav-link" data-bs-toggle="tab" href="#menu1">Bulk Shipment Log</a>
  </li>
</ul> */}

{/* <!-- Tab panes --> */}
<div class="tab-content">
  <div class="tab-pane active" id="home">
        <div class="card border-0">
            <div class="card-body">
                <div class="table-data table-responsive">
                    <table class="table table-hover ">
                        <thead>
                        <tr class="table-light">
                            <th>Log ID</th>
                            <th>Date</th>
                            <th>Total Orders</th>
                            <th>Uploaded Orders</th>
                            <th>Status</th>
                            <th>Log</th>
                        </tr>
                        </thead>
                        <tbody>
                        {state.map((sub)=>(
                        <tr class="ship-now-container">
                            <td>
                                <a href={`/bulk_orders_log_detail/${sub.log_id}`} class="text-primary">{sub.log_id}</a>
                            </td>
                            <td>
                            {sub.date} | {sub.time}
                            </td>
                           
                            <td>
                            {sub.total_records}
                            </td>
                            <td>
                            {sub.uploaded_records}
                            </td>
                            <td>
                            {sub.status==0?
                              <span class="inactive_st">Running</span>:
                            <span class="active_st">Completed</span>
}
                            </td>
                            <td>
                                <a href={`/bulk_orders_log_detail/${sub.log_id}`} class="text-primary">Import log <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" data-lucide="move-right" class="lucide lucide-move-right"><path d="M18 8L22 12L18 16"></path><path d="M2 12H22"></path></svg></a>
                            </td>
                        </tr>
                        ))}
                     
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
  </div>
 
</div>




 </div>
 </div>
 </div>
 <Footer/>
 </div>
 </div>

    </div>
  )
}

export default Bulk_order_log
