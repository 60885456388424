import React from 'react'


import axios from "axios";
import Swal from 'sweetalert2'

import config from "../../config"


import { useNetworkState } from 'react-use';

import Footer from './Footer';
import Header from './Header';


const Pickup_request = () => {
    const getFormattedDate = () => {
        const today = new Date();
        const year = today.getFullYear();
        const month = String(today.getMonth() + 1).padStart(2, '0'); // Months are zero-based
        const day = String(today.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
      };
    const[state,setState]=React.useState([])
    const[filters,setfilters]=React.useState({request_id:"",pickup_date:getFormattedDate()})

    let userDetail = localStorage.getItem('ship_rocket_user');
    // console.log("userDetail====", config.UserId);
    let userData = {};
    if (userDetail != null) {
      userData = JSON.parse(userDetail)
    }
    const isOnline = useNetworkState();
    console.log("isONline  === " ,isOnline )
  
   
React.useEffect(()=>{
    pickup_detail()
},[])
   
        const pickup_detail = ()=>{
        let url = config.apiUrl + '/shipment/pickup_request_details';
        let sendData = {  customer_id: userData.customer_id };
        if(filters.pickup_date!=""){
            sendData.pickup_date  = filters.pickup_date
        }
        if(filters.request_id!=""){
            sendData.request_id = filters.request_id
        }
        // console.log("bb", sendData);
        axios.post(url,sendData ,{headers:config.headers}).then((res)=>{
            
                // console.log("sasdfdsa")
                setState(res.data.output)
             
          

        }).catch(()=>{
            const Toast = Swal.mixin({
                toast: true,
                position: 'bottom-end',
                showConfirmButton: false,
                timer: 3000
              })
              
              Toast.fire({
                background:"#a61c09",
                type: 'unsuccess',
                title: "Something Went Wrong",
                color:"white"
              });
       
           
        })
       }

       const handleChange=(e)=>{
        setfilters({...filters,[e.target.name]:e.target.value})
       }
    
  return (

<div>
<Header/>
 <div class="page-wrapper pinned">
  <div class="main-container">
   <div className='app-container'>
  <div class="app-body">
  <div class="container-fluid"> 
  <div class="row mb-2">
        <div class="col-12 col-md">
            <div class="d-flex">
              
                <div class="">
                    <h2>Pickup Request</h2>
                </div>
            </div>
        </div>
    
    </div>
    <div class="row mb-3">
                <div class="col-12 col-md">
                    <div class="row g-1">
                    <div class="col-12 col-md-3">
                            <div class="input-group input-group-sm mb-3">
                            
                                <input type="text" class="form-control w-50" name="request_id" placeholder="Enter Request ID" onChange={(e)=>handleChange(e)} />
                               
                            </div>
                        </div>
                       
                        <div class="col-6 col-md-3 ms-3">
                        <input type="date" class="form-control form-control-sm" id="text" placeholder="Enter details name=" name="pickup_date" value={filters.pickup_date} onChange={(e)=>handleChange(e)} 
                        />
                        </div>
                       
                        <div class="col-6 col-md-2 ms-3">
                        <button class="btn btn-outline-primary btn-sm" onClick={(e)=>pickup_detail()} >Search</button>
                        </div>
                     
                      
                      
                    </div>
                </div>
            </div>
    
    <div class="content-wrapper-data">
        <div class="card border-0">
          <div class="card-body">
                <div class="table-data table-responsive">
                    <table class="table table-hover table-responsive">
                        <thead>
                        <tr class="table-light">
                            <th>Request ID</th>
                            <th>Pickup Point</th>
                            <th>Pickup Date/Time</th>
                            <th>Package Count</th>
                            <th>Delivery Partner</th>
                        </tr>
                        </thead>
                        <tbody>
                        {state.map((sub)=>(
                         <tr class="ship-now-container">
                            <td>
                                <h3><a href="#">{sub.pickup_id}</a></h3>
                             </td>
                            <td>
                                <h4>{sub.warehouse_id?sub.warehouse_id:sub.warehouse_name}</h4>
                            </td>
                            <td>
                            <h4>{sub.pickup_date}<br/>
                               <small>{sub.pickup_time}</small></h4>
                            </td>
                            <td>
                                <h4>{sub.package_count}</h4>
                            </td>
                            <td class="channel_logo">
                                <h4>{sub.service_provider}</h4>
                            </td>
                            
                           
                        </tr>
                        ))} 
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
      
    
          
   </div></div>
   <Footer/></div></div></div>
    

    


    



</div>
  )
}

export default Pickup_request
