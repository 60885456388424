import React from 'react'
import Header_nav from './Header_nav'
import Sidebar from './Sidebar'
import { useLocation } from 'react-router-dom';

const Header = () => {
    const [isPinned, setIsPinned] = React.useState(true); // State to track the pinned class
    const [isToogle, setIsToogle] = React.useState(true); // State to track the pinned class

  const location = useLocation();

  const handlePinToggle = () => {
    setIsPinned(prevState => !prevState); // Toggle the state
  };
  const handleToggle = () => {
   
    setIsToogle(prevState => !prevState); // Toggle the state
    console.log("isToogle===" , isToogle)
  };
  return (
    <div >
     
	{/* <!-- Page wrapper start --> */}
    <div class={`page-wrapper pinned `}>

    {/* <!-- Main container start --> */}
    <div class="main-container">

        {/* <!-- Sidebar wrapper start --> */}
        <nav id="sidebar-wrapper " class={`sidebar-wrapper`}>

            {/* <!-- App brand starts --> */}
            <div class="app-brand px-3 py-2 d-flex align-items-center">
                <a href="/">
                    <div class="d-flex justify-content-between">
                        <div><img src="../../../assets/images/logo_big.png" class="logo" alt="SkyShip" /></div>
                        <div class="logo_text"><h2>SkyShip</h2></div>
                    </div>
                </a>
            </div>
            {/* <!-- App brand ends --> */}

            {/* <!-- Sidebar menu starts --> */}
            {/* <?php include('sidebar.php'); ?> */}
			<Sidebar/>
            {/* <!-- Sidebar menu ends --> */}

        </nav>
        {/* <!-- Sidebar wrapper end --> */}

        {/* <!-- App container starts --> */}
        <div class="app-container">

            {/* <!-- App header starts --> */}
            {/* <?php include('header_nav.php'); ?> */}
			<Header_nav onPinToggle={handlePinToggle} onToggle={handleToggle}/>
            {/* <!-- App header ends --> */}

            {/* <!-- App body starts --> */}
        
            {/* <!-- App body ends --> */}

        
         

        </div>
        {/* <!-- App container ends --> */}

    </div>
   

</div>
{/* <!-- Page wrapper end --> */}

    </div>

   

  )
}

export default Header
