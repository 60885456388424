import React, { useState, useEffect } from 'react'

import Header from './Header'

import axios from 'axios'
import queryString from 'query-string';
import { useNavigate } from 'react-router-dom';

import Swal from 'sweetalert2'
import { useLocation } from 'react-router-dom';

import config from "../../config"
import { useNetworkState } from 'react-use';

import Footer from './Footer'
import Create_order_nav_b2b from './Create_order_nav_b2b'
import Create_order_steps from './Create_order_steps';
import Create_order_steps_b2b from './Create_order_steps_b2b';
const Create_order_b2b_3 = () => {
  let userDetail = localStorage.getItem('ship_rocket_user');
  // console.log("userDetail====", config.UserId);
  let userData = {};
  if (userDetail != null) {
    userData = JSON.parse(userDetail)
  }
  let navigate = useNavigate();
  const isOnline = useNetworkState();
  console.log("isONline  === " ,isOnline )
 const location = useLocation()
//  console.log("location==>122", location)
let parsed = queryString.parse(location.search);
console.log("parsed === ",parsed)
    const [isSidebarOpen, setIsSidebarOpen] = React.useState(false);
    const[state,setState] = React.useState({  parcel_type:"Parcel" , order_date : "" , order_number:"" })
    const [orderNumber, setOrderNumber] = React.useState('');
    const [numberOfBoxes, setNumberOfBoxes] = useState(1);
    const [numberOfInvoices, setNumberOfInvoices] = useState(1);
    const[paymentmode,setpaymentmode]= useState({mode:1})
    const[riskstate,setriskstate]= useState({risk:2})
    const [orderErrors, setOrderErrors] = useState({});
  const [boxErrors, setBoxErrors] = useState([]);
  const [invoiceErrors, setInvoiceErrors] = useState([]);
  const [codvalueerror,setCodValueError] = useState({})

    // console.log("paymentmode",paymentmode)
    const [codvalue,setcodvalue] = useState({cod_amount:0 , err:{}})
    const[boxstate,setBoxState] = React.useState([])
    const [boxDetails, setBoxDetails] = useState([{
      box_name: '',
      box_length: '',
      box_width: '',
      box_height: '',
      box_weight: '',
      quantity: "", // Add quantity field
      unit_price: "",
      product_name:""
    }]);
    // console.log("boxDetails = =" , boxDetails)

    const [invoiceDetails, setinvoiceDetails] = useState([{
      invoice_number: '',
      invoice_date: '',
      invoice_amount: '',
      invoice_ebn: '',
     
    
    }]);
    const handleNumberOfBoxesChange = (event) => {
        const value = parseInt(event.target.value);
        setNumberOfBoxes(value);
        setBoxDetails(Array.from({ length: value }, () => ({
            box_name: '',
            box_length: '',
            box_width: '',
            box_height: '',
            box_weight: '',
            quantity: "", // Add quantity field
            unit_price: "",
            product_name:""
        })));
    };
   
    const handleNumberOfInvoicesChange = (event) => {
        setNumberOfInvoices(parseInt(event.target.value));
        setinvoiceDetails(Array.from({ length: parseInt(event.target.value) }, () => ({
          invoice_number: '',
          invoice_date: '',
          invoice_amount: '',
          invoice_ebn: '',
         
        })));
      };
    const toggleSidebar = () => {
        setIsSidebarOpen(!isSidebarOpen);
        const homeSection = document.querySelector('.home-section');
        if (homeSection) {
            homeSection.classList.toggle('sidebar-closed');
        }
    };

    const generateOrderNumber = () => {
        // Generate the order number logic
        const randomNumber = Math.floor(Math.random() * 1000000);
        const formattedOrderNumber =  + randomNumber;
    
        setOrderNumber(formattedOrderNumber);
        const now = new Date();
            const year = now.getFullYear();
            const month = String(now.getMonth() + 1).padStart(2, '0');
            const day = String(now.getDate()).padStart(2, '0');
         let order_date = `${year}-${month}-${day}`;
        setState({...state , order_number:formattedOrderNumber ,order_date:order_date })
      }
      const handleChange=(e)=>{
        // console.log("namess",e.target.name)
        // console.log("value",e.target.value)
       
        let names = e.target.name
        let value= e.target.value
       
        if(e.target.type=="text"){
        setState({...state,[e.target.name]:value})
        }
        // else if(e.target.type=="radio"){
        //     if(e.target.checked==true){
              
        //          if(e.target.name=="document"){
        //           setparceltypeState({...parceltype,parcel:false})
        //           setdocumenttypeState({...documenttype,document:true})
        //           setState({...state,parcel_type:"Document"})
        //         }
        //         else{
        //           setparceltypeState({...parceltype,parcel:true})
        //           setdocumenttypeState({...documenttype,document:false})
        //           setState({...state,parcel_type:"Parcel"})
        //         }
        //     }
        // }
        else if(e.target.type=="number"){
          setState({...state,[e.target.name]:value})
        }
        else if(e.target.type=="date"){
            setState({...state,[e.target.name]:value})
           
        }
    }

    React.useEffect(()=>{
        generateOrderNumber()
        customer_boxes()
    },[])

    const customer_boxes = () => {
       
       
        let full_api = config.apiUrl  + `/user/get_customer_boxes`;
       let sendData = {}
        axios.post(full_api, sendData, {
             headers: config.headers 
        }).then((res) => {
            console.log("res ", res);
           
            if(res.data.status==true){
              setBoxState(res.data.output)
               
             }
             else{
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Something went wrong!',
                    
                  })
                }
            
        }).catch((e) => {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Something went wrong!',
                
              })
         
          console.log("----error:   ", e);

        });
    }

    const handleBoxChange = (event, index) => {
      const selectedBoxName = event.target.value;
      const selectedBox = boxstate.find(box => box.box_name === selectedBoxName);
    
      if (selectedBox) {
        setBoxDetails(prevState => {
          const newState = [...prevState];
          newState[index].box_name = selectedBoxName;
          newState[index].box_length = selectedBox.box_length;
          newState[index].box_width = selectedBox.box_width;
          newState[index].box_height = selectedBox.box_height;
          newState[index].box_weight = selectedBox.box_weight;
          return newState;
        });
      }
    };
      const handleBoxDimensionChange = (event, key, index) => {

        const value = key === 'quantity' || key === 'unit_price' ? parseFloat(event.target.value) : event.target.value;
        setBoxDetails(prevState => {
          const newState = [...prevState];
          newState[index][key] = value;
          return newState;
        });
      };

      const handleInvoiceDimensionChange = (event, key, index) => {

        const value = key === 'invoice_amount' ? parseFloat(event.target.value) : event.target.value;
        setinvoiceDetails(prevState => {
          const newState = [...prevState];
          newState[index][key] = value;
          return newState;
        });
      };

      
      const payment_mode =(e)=>{
        if(e.target.name=="prepaid"){
          setpaymentmode({mode:1})
        }
        else{
          setpaymentmode({mode:2})
        }
      }
      
      const codvalueChange=(e)=>{
          
        setcodvalue({...codvalue,cod_amount:e.target.value , err:{}})
      }
      const risk_mode =(e)=>{
        if(e.target.name=="owner_risk"){
          setriskstate({risk:1})
        }
        else{
          setriskstate({risk:2})
        }
      }
      const next_step = () => {
        if (!state.order_number || !state.order_date) {
          setOrderErrors({
            order_number: !state.order_number ? 'Order number is required' : '',
            order_date: !state.order_date ? 'Order date is required' : '',
          });
          return;
        } else {
          setOrderErrors({});
        }
        const newBoxErrors = [];
        boxDetails.forEach((box, index) => {
          const errors = {};
          // if (!box.box_name) errors.box_name = 'Box name is required';
          if (!box.box_length) errors.box_length = 'Length is required';
          if (!box.box_width) errors.box_width = 'Width is required';
          if (!box.box_height) errors.box_height = 'Height is required';
          if (!box.box_weight) errors.box_weight = 'Weight is required';
          if (!box.quantity) errors.quantity = 'Quantity is required';
          if (!box.product_name) errors.product_name = 'Product Name is required';
          if (!box.unit_price) errors.unit_price = 'Unit price is required';


          // Add validations for other box fields
          newBoxErrors[index] = errors;
        });
        setBoxErrors(newBoxErrors);
    
        const newInvoiceErrors = [];
        invoiceDetails.forEach((invoice, index) => {
          const errors = {};
          if (!invoice.invoice_number) errors.invoice_number = 'Invoice number is required';
          if (!invoice.invoice_date) errors.invoice_date = 'Invoice Date is required';
          if (!invoice.invoice_amount) errors.invoice_amount = 'Invoice Amount is required';

          // Validate EBN number if invoice amount is greater than 50000
          if (invoice.invoice_amount > 50000 && !invoice.invoice_ebn) {
            errors.invoice_ebn = 'EBN number is required for invoice amount greater than 50000';
          }
          // Add validations for other invoice fields
          newInvoiceErrors[index] = errors;
        });
        setInvoiceErrors(newInvoiceErrors);
        if (newBoxErrors.some(errors => Object.keys(errors).length > 0) || 
      newInvoiceErrors.some(errors => Object.keys(errors).length > 0)) {
    return; // Return early if there are errors in boxes or invoices
  }

        if (paymentmode.mode === 2 && codvalue.cod_amount <= 0) {
          setCodValueError('COD amount must be greater than 0');
          return;
        } else {
          setCodValueError('');
        }
      
           let dataToSend = {  sender_name: parsed.sender_name,
                                sender_mobile: parsed.sender_mobile,
                                sender_email: parsed.sender_email,
                                sender_address: parsed.sender_address,
                                sender_city: parsed.sender_city,
                                sender_city_id: parsed.sender_city_id,
                                sender_country_name: parsed.sender_country,
                                sender_country_code: parsed.sender_country_code,
                                sender_postal_code: parsed.sender_postal_code,
                                receiver_name: parsed.receiver_name,
                                receiver_mobile: parsed.receiver_mobile,
                                receiver_email: parsed.receiver_email,
                                receiver_address: parsed.receiver_address,
                                receiver_city: parsed.receiver_city,
                                receiver_country_name: parsed.receiver_country,
                                receiver_country_code: parsed.receiver_country_code,
                                receiver_postal_code: parsed.receiver_postal_code,
                                receiver_city_id:parsed.receiver_city_id ,
                                receiver_state_id:parsed.receiver_state_id,
                                sender_state_name:parsed.sender_state_name,
                                receiver_state_name:parsed.receiver_state_name,
                                return_name: parsed.return_name,
                                return_mobile: parsed.return_mobile,
                                return_email: parsed.return_email,
                                return_address: parsed.return_address,
                                return_city: parsed.return_city,
                                return_state_name: parsed.return_state_name,
                                return_country_name: parsed.return_country,
                                return_country_code: parsed.return_country_code,
                                return_postal_code: parsed.return_postal_code,
                                payment_mode:paymentmode.mode==1?"prepaid":"cod",
                                shipment_type:"Parcel",
                                cod_amount : codvalue.cod_amount,
                                address_id : parsed.sender_address_id,
                                order_id:state.order_number,
                                order_date:state.order_date,
                                mode:parsed.mode,
                                boxes:boxDetails,
                                invoices:invoiceDetails,
                                rv_insurance :riskstate.mode==2?true:false,
                                carrier_type:2,
                                
                                }
                  
                             
                               dataToSend.customer_id = userData.customer_id;
                                console.log("datatoSend", dataToSend)
    
                                let url = config.apiUrl + '/shipment/order_create';
    
                              
                                axios.post(url, dataToSend, { headers: config.headers })
                                .then((responseJson) => {
                              
                                if (responseJson.data.status == true) {
                                //     navigate({pathname:`/customer/b2b_orders`,
                                   
                                //   })  
                                  window.location.replace(`/b2b_orders`) 
                                  const Toast = Swal.mixin({
                                    toast: true,
                                    position: 'bottom-end',
                                    showConfirmButton: false,
                                    timer: 3000
                                  })       
                                Toast.fire({
                                background:"#001b72",
                                type: 'unsuccess',
                                title: "Order created successfully.",
                                color:"white"
                                });
                                  }
                                else{
                                    const Toast = Swal.mixin({
                                        toast: true,
                                        position: 'bottom-end',
                                        showConfirmButton: false,
                                        timer: 3000
                                      })       
                                    Toast.fire({
                                    background:"#c2200a",
                                    type: 'unsuccess',
                                    title: "Something Went Wrong.",
                                    color:"white"
                                    }); 
      
    
                                }
                                })
             
           }
  return (
    <div>
    <Header/>
 <div class="page-wrapper pinned">
  <div class="main-container">
   <div className='app-container'>
  <div class="app-body">
  <div class="container-fluid"> 
 
 <div class="mob_nav">
   <div class=" d-flex justify-content-start ">
     <a href="javascript:window.history.back();" class=""><i class="bi bi-arrow-left"></i></a>
     <h2>Create Order</h2>
   </div>
 </div>
 
 <div class="row justify-content-between">
     <div class="col-12 col-md-4 desk_nav">
       <div class="d-flex">
         <a href="javascript:window.history.back();" class="back_btn"><i class="bi bi-arrow-90deg-left"></i></a>
         <h2>Create Order</h2>
       </div>
     </div>
     
 </div>
 <Create_order_nav_b2b/>
 <hr/>
 <div class="row">
     <div class="col-12 col-md-2">
   <Create_order_steps_b2b value={3}/>
     </div>
    
 
 <div class="col-12 col-md">
 
   <div class="card border-0 order_box">
     <div class="card-body">
       <section class="pb-3">
           <div class="row">
            
               <div class="col-12 col-md-4 mb-3">
                   <label for="" class="form-label">Order ID: <small class="text-muted">(Leave Blank for Auto Generate)</small></label>
                   <input type="text" class="form-control" id="" placeholder="Order ID"  value ={state.order_number} name="order_number" onChange={(e)=>handleChange(e)}/>
                    <span>{orderErrors.order_number}</span>
               </div>
               <div class="col-12 col-md-4 mb-3">
                   <label for="" class="form-label">Order Date:</label>
                   <input type="date" class="form-control" id="" placeholder="Enter Full Name" value={state.order_date} name="order_date" onChange={(e)=>handleChange(e)}/>
                   <span>{orderErrors.order_date}</span>
               </div>
            
           </div>
       </section>
       <hr/>
       <div class="row mb-3">
           <div class="col-12 col-md-4">
           <label for="" class="form-label">Number of Boxes:</label>
           <input
      type="number"
      className="form-control"
      id="numberOfBoxes"
      placeholder="Enter number of boxes"
      value={numberOfBoxes}
      onChange={handleNumberOfBoxesChange}
  />
           </div>
       </div>
      {boxDetails.map((box, index) => (
       <div class="card mb-2" key={index} >
         <div class="card-body">
           <section class="">
             <h6 class="mb-4">Box {index+1} Detail</h6>
             <div class="row">
               <div class="col-12 col-md-3 mb-3">
                   <label for="" class="form-label">Box Name:</label>
                   <select className="form-select form-select-sm" onChange={(e) => handleBoxChange(e, index)} defaultValue={box.box_name}>
         <option value="">Select Box</option>
         {boxstate.map((sub) => (
           <option key={sub.box_name} value={sub.box_name}>{sub.box_name}</option>
         ))}
       </select>
       <span>{boxErrors[index]?.box_name}</span>
               </div>
               <div class="col-12 col-md">
                     <label for="" class="form-label">Box Dimensions (CM):</label>
                     <div class="row g-1">
                       <div class="col-12 col-md mb-3">
                       <input type="number" className="form-control" id="" placeholder="Length"  value={box.box_length} onChange={(e) => handleBoxDimensionChange(e, 'box_length', index)} />
                       <span>{boxErrors[index]?.box_length}</span>
                       </div>
                       <div class="col-12 col-md mb-3">
                         <input type="number" class="form-control" id="" placeholder="Width" name=""  value={box.box_width} onChange={(e) => handleBoxDimensionChange(e, 'box_width', index)}/>
                         <span>{boxErrors[index]?.box_width}</span>
                       </div>
                       <div class="col-12 col-md mb-3">
                         <input type="number" class="form-control" id="" placeholder="Height" name=""  value={box.box_height} onChange={(e) => handleBoxDimensionChange(e, 'box_height', index)}/>
                         <span>{boxErrors[index]?.box_height}</span>
                       </div>
                     </div>
               </div>
               <div class="col-12 col-md-2">
                 <label for="" class="form-label">Box Weight(KG):</label>
                 <input type="number" class="form-control" id="" placeholder="Enter Weight" name=""  value={box.box_weight} onChange={(e) => handleBoxDimensionChange(e, 'box_weight', index)}/>
                 <span>{boxErrors[index]?.box_weight}</span>
               </div>
               <div class="col-12 col-md-2">
                 <label for="" class="form-label">Box Quantity:</label>
                 <input type="number" class="form-control" id="" placeholder="Enter quantity" name="" value={box.quantity} onChange={(e) => handleBoxDimensionChange(e, 'quantity', index)}/>
                 <span>{boxErrors[index]?.quantity}</span>
               </div>
             </div>
           </section>
           <section class="pb-3">
             <div class="row">
                 <div class="col-12 col-md-4 mb-3">
                     <label for="" class="form-label">Product Name:</label>
                     <input type="text" class="form-control" id="" placeholder="Enter your product name" value={box.product_name} onChange={(e) => handleBoxDimensionChange(e, 'product_name', index)}/>
                 <span>{boxErrors[index]?.product_name}</span>

                 </div>
                 <div class="col-12 col-md-2 mb-3">
                   <label for="" class="form-label">Unit Price:</label>
                     <div class="input-group">
                         <span class="input-group-text">&#8377;</span>
                         <input type="number" class="form-control" placeholder="Enter Price" value={box.unit_price} onChange={(e) => handleBoxDimensionChange(e, 'unit_price', index)}/>
                     </div>
                     <span>{boxErrors[index]?.unit_price}</span>
                 </div>
               
                
             </div>
           </section>
         </div>
       </div>
         ))}
       
       <hr/>
       <div class="row mb-3">
           <div class="col-12 col-md-4">
           <label for="" class="form-label">Number of Invoics:</label>
             <select class="form-select form-select-sm"onChange={handleNumberOfInvoicesChange} value={numberOfInvoices}>
             {[...Array(10).keys()].map((num) => (
<option key={num + 1} value={num + 1}>{num + 1}</option>
))} 
             
             </select>
           </div>
       </div>
       <div class="card">
       {invoiceDetails.map((invoice, index) => (
         <div class="card-body" key={index}>
         <h6>Invoice {index+1}</h6>
       <div class="row">
           <div class="col-12 col-md">
             <label for="" class="form-label">Invoice Number:</label>
             <input type="text" class="form-control" id="text" placeholder="Invoice Number" onChange={(e) => handleInvoiceDimensionChange(e, 'invoice_number', index)}/>
             <span>{invoiceErrors[index]?.invoice_number}</span>
           </div>
           <div class="col-12 col-md">
             <label for="" class="form-label">Invoice Date:</label>
             <input type="date" class="form-control" id="text" placeholder="Invoice Date" onChange={(e) => handleInvoiceDimensionChange(e, 'invoice_date', index)}/>
             <span>{invoiceErrors[index]?.invoice_date}</span>
           </div>
           <div class="col-12 col-md">
             <label for="" class="form-label">Invoice Amount:</label>
             <input type="text" class="form-control" id="text" placeholder="Invoice Amount" onChange={(e) => handleInvoiceDimensionChange(e, 'invoice_amount', index)}/>
             <span>{invoiceErrors[index]?.invoice_amount}</span>
           </div>
           <div class="col-12 col-md">
             <label for="" class="form-label">EBN Number:</label>
             <input type="text" class="form-control" id="text" placeholder="Invoice EBN Number" onChange={(e) => handleInvoiceDimensionChange(e, 'invoice_ebn', index)}/>
             <span>{invoiceErrors[index]?.invoice_ebn}</span>
           </div>
         
       </div>
       <hr/>
         </div>
       ))}
       </div>
       <hr/>
       <section class="pb-3">
           <h6 class="mb-1">Payment Details</h6>
           <small class="">Select mode of payment that your buyer has chosen for the order</small>
           <div class="row mt-4">
               <div class="col-12 col-md-2 mb-3">
                   <div class="form-check form-check-inline">
                     <input class="form-check-input" type="radio" name="prepaid" id="inlineRadio1" value="option1" onChange={(e) => payment_mode(e)} checked={paymentmode.mode==1} />
                     <label class="form-check-label" for="inlineRadio1">Prepaid <a href="#" data-bs-toggle="popover"  data-bs-placement="top" data-bs-trigger="hover" data-bs-content="Payment already received from the Buyer" class="text-muted"><i class="fa fa-question-circle-o" aria-hidden="true"></i></a></label>
                   </div>
               </div>
               <div class="col-12 col-md mb-3">
               <div class="form-check form-check-inline">
                    <input class="form-check-input"  type="radio" name="cod" id="inlineRadio1" value="option1" onChange={(e) => payment_mode(e)}  checked={paymentmode.mode==2} />
                      <label class="form-check-label" for="inlineRadio1">COD (Cash On Delivery) <a href="#" data-bs-toggle="popover" data-bs-placement="top" data-bs-trigger="hover" data-bs-content="COD payment will be credited to your account" class="text-muted"><i class="bi bi-question-circle"></i></a></label>
                    </div>
               </div>
           </div>
           {paymentmode.mode==2?
              <div class="row">
                <div class="col-12 col-md-5 mb-3">
                  <input type="text" class="form-control" id="" placeholder="Enter COD amount" name="cod_amount" onChange={(e)=>codvalueChange(e)} value={codvalue.cod_amount}/>
                </div>
            </div>
             :""}
       </section>
       <hr/>
           <h6 class="mb-1">Insurance</h6>
           <small class="">Select insurance for the order</small>
           <div class="row mt-4">
               <div class="col-12 col-md-3 mb-3">
                   <div class="form-check form-check-inline">
                     <input class="form-check-input" type="radio" name="owner_risk" id="inlineRadio1" value="option1" onChange={(e) => risk_mode(e)} checked={riskstate.risk==1}/>
                     <label class="form-check-label" for="inlineRadio1">OR (Owner Risk) <a href="#" data-bs-toggle="popover"  data-bs-placement="top" data-bs-trigger="hover" data-bs-content="Insurance charges will not apply to this order" class="text-muted"><i class="fa fa-question-circle-o" aria-hidden="true"></i></a></label>
                   </div>
               </div>
               <div class="col-12 col-md mb-3">
                 <div class="form-check form-check-inline">
                   <input class="form-check-input" type="radio" name="carrier_risk" id="inlineRadio1" value="option1" onChange={(e) => risk_mode(e)} checked={riskstate.risk==2} />
                   <label class="form-check-label" for="inlineRadio1">CR (Carrier Risk) <a href="#" data-bs-toggle="popover"  data-bs-placement="top" data-bs-trigger="hover" data-bs-content="Insurance charges will be applied to this order." class="text-muted"><i class="fa fa-question-circle-o" aria-hidden="true"></i></a></label>
                 </div>
               </div>
           </div>
       <hr/>
       
     </div>
   </div>
   <div class="py-4 text-end">
   <a onClick={()=> navigate({pathname:`/customer/create_order_2`,
                   search: `sender_name=${parsed.sender_name}&sender_address=${parsed.sender_address}&sender_country=${parsed.sender_country}&sender_country_code=${parsed.sender_country_code}&sender_postal_code=${parsed.sender_postal_code}&sender_state_name=${parsed.sender_state_name}&sender_city=${parsed.sender_city}&sender_address_id=${parsed.sender_address_id}&sender_city_id=${parsed.sender_city_id}&sender_mobile=${parsed.sender_mobile}&sender_email=${parsed.sender_email}&receiver_name=${parsed.receiver_name}&receiver_email=${parsed.receiver_email}&receiver_mobile=${parsed.receiver_mobile}&receiver_address=${parsed.receiver_address}&receiver_country=${parsed.receiver_country}&receiver_country_code=${parsed.receiver_country_code}&receiver_postal_code=${parsed.receiver_postal_code}&receiver_city=${parsed.receiver_city}&receiver_state_name=${parsed.receiver_state_name}&return_name=${parsed.return_name}&return_email=${parsed.return_email}&return_mobile=${parsed.return_mobile}&return_address=${parsed.return_address}&return_country=${parsed.return_country}&return_country_code=${parsed.return_country_code}&return_postal_code=${parsed.return_postal_code}&return_city=${parsed.return_city}&return_state_name=${parsed.return_state_name}&mode=${parsed.mode}&items=${parsed.items}&shipment_type=${state.parcel_type}&order_id=${state.order_number}&order_date=${state.order_date}&cod_amount=${codvalue.cod_amount}&payment_mode=${paymentmode.mode==1?"prepaid":"cod"}&weight=${parsed.weight?parsed.weight:""}&height=${parsed.height?parsed.height:""}&width=${parsed.width?parsed.width:""}&length=${parsed.length?parsed.length:""}&store_id=${parsed.store_id?parsed.store_id:""}&channel_name=${parsed.channel_name?parsed.channel_name:""}&volumetric_weight=${parsed.volumetric_weight?parsed.volumetric_weight:""}&dead_weight=${parsed.dead_weight?parsed.dead_weight:""}&receiver_state_id=${parsed.receiver_state_id}&receiver_city_id=${parsed.receiver_city_id}&carrier_type=${parsed.carrier_type}`
                 })   } class="btn btn-secondary me-2">Back</a>
     <a  class="btn btn-primary" onClick={(e)=>next_step()}>Next</a>
 </div>
</div>      

 </div>
 
 
 
 
 
 
     </div>
     </div></div>
     <Footer/>
     </div></div>
     </div>
  )
}

export default Create_order_b2b_3

        

 