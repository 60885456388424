import React from 'react'
import Header from './Header'
import Footer from './Footer'

const Settings = () => {
  return (
    <div>
 <Header/>
 <div class="page-wrapper pinned">
  <div class="main-container">
   <div className='app-container'>
  <div class="app-body">
  <div class="container-fluid"> 

<div class="mob_nav">
  <div class=" d-flex justify-content-start ">
    <a href="javascript:window.history.back();" class=""><i class="bi bi-arrow-left"></i></a>
    <h2>Settings</h2>
  </div>
</div>


<div class="row justify-content-between">
    <div class="col-12 col-md-4 desk_nav">
      <div class="d-flex">
        <a href="javascript:window.history.back();" class="back_btn"><i class="bi bi-arrow-90deg-left"></i></a>
        <h2>Settings</h2>
      </div>
    </div>
    <div class="col-12 col-md-4 mb-3">
      
    </div>
</div>



<div class="row">
    <div class="col-12 col-md-4 mb-3">
      <h5>Settings</h5>
      <div class="list-group w-auto settings_list">
        <a href="/profile_update" class="list-group-item list-group-item-action d-flex gap-3" aria-current="true">
          <span><i class="bi bi-person"></i></span>
          <div class="d-flex gap-2 w-100 justify-content-between">
            <div>
              <h6 class="mb-0">Profile</h6>
            </div>
            <small class="opacity-50 text-nowrap"><i class="bi bi-chevron-right"></i></small>
          </div>
        </a>
        <a href="/bank_details" class="list-group-item list-group-item-action d-flex gap-3" aria-current="true">
          <span><i class="bi bi-bank"></i></span>
          <div class="d-flex gap-2 w-100 justify-content-between">
            <div>
              <h6 class="mb-0">Bank Details</h6>
            </div>
            <small class="opacity-50 text-nowrap"><i class="bi bi-chevron-right"></i></small>
          </div>
        </a>
        <a href="/user_kyc" class="list-group-item list-group-item-action d-flex gap-3" aria-current="true">
          <span><i class="bi bi-person-vcard"></i></span>
          <div class="d-flex gap-2 w-100 justify-content-between">
            <div>
              <h6 class="mb-0">KYC Detail</h6>
            </div>
            <small class="opacity-50 text-nowrap"><i class="bi bi-chevron-right"></i></small>
          </div>
        </a>
        <a href="/billing_address" class="list-group-item list-group-item-action d-flex gap-3" aria-current="true">
          <span><i class="bi bi-cash"></i></span>
          <div class="d-flex gap-2 w-100 justify-content-between">
            <div>
              <h6 class="mb-0">Billing Detail</h6>
            </div>
            <small class="opacity-50 text-nowrap"><i class="bi bi-chevron-right"></i></small>
          </div>
        </a>
        <a href="/change_password" class="list-group-item list-group-item-action d-flex gap-3" aria-current="true">
          <span><i class="bi bi-person-lock"></i></span>
          <div class="d-flex gap-2 w-100 justify-content-between">
            <div>
              <h6 class="mb-0">Change Password</h6>
            </div>
            <small class="opacity-50 text-nowrap"><i class="bi bi-chevron-right"></i></small>
          </div>
        </a>
        <a href="/email_notification" class="list-group-item list-group-item-action d-flex gap-3" aria-current="true">
          <span><i class="bi bi-envelope-at"></i></span>
          <div class="d-flex gap-2 w-100 justify-content-between">
            <div>
              <h6 class="mb-0">Email Notification</h6>
            </div>
            <small class="opacity-50 text-nowrap"><i class="bi bi-chevron-right"></i></small>
          </div>
        </a>
        {/* <a href="#" class="list-group-item list-group-item-action d-flex gap-3" aria-current="true">
          <span><i class="bi bi-phone"></i></span>
          <div class="d-flex gap-2 w-100 justify-content-between">
            <div>
              <h6 class="mb-0">SMS Notification</h6>
            </div>
            <small class="opacity-50 text-nowrap"><i class="bi bi-chevron-right"></i></small>
          </div>
        </a> */}
        <a href="/whatsapp_notification" class="list-group-item list-group-item-action d-flex gap-3" aria-current="true">
          <span><i class="bi bi-whatsapp"></i></span>
          <div class="d-flex gap-2 w-100 justify-content-between">
            <div>
              <h6 class="mb-0">Whatsapp Notification</h6>
            </div>
            <small class="opacity-50 text-nowrap"><i class="bi bi-chevron-right"></i></small>
          </div>
        </a>
      </div>
    </div>
    {/* <div class="col-12 col-md-4 mb-3">
      <h5>Settings</h5>
      <div class="list-group w-auto settings_list">
        <a href="#" class="list-group-item list-group-item-action d-flex gap-3" aria-current="true">
          <span><i class="bi bi-person"></i></span>
          <div class="d-flex gap-2 w-100 justify-content-between">
            <div>
              <h6 class="mb-0">Profile</h6>
            </div>
            <small class="opacity-50 text-nowrap"><i class="bi bi-chevron-right"></i></small>
          </div>
        </a>
        <a href="#" class="list-group-item list-group-item-action d-flex gap-3" aria-current="true">
          <span><i class="bi bi-bank"></i></span>
          <div class="d-flex gap-2 w-100 justify-content-between">
            <div>
              <h6 class="mb-0">Bank Details</h6>
            </div>
            <small class="opacity-50 text-nowrap"><i class="bi bi-chevron-right"></i></small>
          </div>
        </a>
        <a href="#" class="list-group-item list-group-item-action d-flex gap-3" aria-current="true">
          <span><i class="bi bi-person-vcard"></i></span>
          <div class="d-flex gap-2 w-100 justify-content-between">
            <div>
              <h6 class="mb-0">KYC Detail</h6>
            </div>
            <small class="opacity-50 text-nowrap"><i class="bi bi-chevron-right"></i></small>
          </div>
        </a>
        <a href="#" class="list-group-item list-group-item-action d-flex gap-3" aria-current="true">
          <span><i class="bi bi-cash"></i></span>
          <div class="d-flex gap-2 w-100 justify-content-between">
            <div>
              <h6 class="mb-0">Billing Detail</h6>
            </div>
            <small class="opacity-50 text-nowrap"><i class="bi bi-chevron-right"></i></small>
          </div>
        </a>
        <a href="#" class="list-group-item list-group-item-action d-flex gap-3" aria-current="true">
          <span><i class="bi bi-person-lock"></i></span>
          <div class="d-flex gap-2 w-100 justify-content-between">
            <div>
              <h6 class="mb-0">Change Password</h6>
            </div>
            <small class="opacity-50 text-nowrap"><i class="bi bi-chevron-right"></i></small>
          </div>
        </a>
        <a href="#" class="list-group-item list-group-item-action d-flex gap-3" aria-current="true">
          <span><i class="bi bi-envelope-at"></i></span>
          <div class="d-flex gap-2 w-100 justify-content-between">
            <div>
              <h6 class="mb-0">Email Notification</h6>
            </div>
            <small class="opacity-50 text-nowrap"><i class="bi bi-chevron-right"></i></small>
          </div>
        </a>
        <a href="#" class="list-group-item list-group-item-action d-flex gap-3" aria-current="true">
          <span><i class="bi bi-phone"></i></span>
          <div class="d-flex gap-2 w-100 justify-content-between">
            <div>
              <h6 class="mb-0">SMS Notification</h6>
            </div>
            <small class="opacity-50 text-nowrap"><i class="bi bi-chevron-right"></i></small>
          </div>
        </a>
        <a href="#" class="list-group-item list-group-item-action d-flex gap-3" aria-current="true">
          <span><i class="bi bi-whatsapp"></i></span>
          <div class="d-flex gap-2 w-100 justify-content-between">
            <div>
              <h6 class="mb-0">Whatsapp Notification</h6>
            </div>
            <small class="opacity-50 text-nowrap"><i class="bi bi-chevron-right"></i></small>
          </div>
        </a>
      </div>
    </div>
    <div class="col-12 col-md-4 mb-3">
      <h5>Settings</h5>
      <div class="list-group w-auto settings_list">
        <a href="#" class="list-group-item list-group-item-action d-flex gap-3" aria-current="true">
          <span><i class="bi bi-person"></i></span>
          <div class="d-flex gap-2 w-100 justify-content-between">
            <div>
              <h6 class="mb-0">Profile</h6>
            </div>
            <small class="opacity-50 text-nowrap"><i class="bi bi-chevron-right"></i></small>
          </div>
        </a>
        <a href="#" class="list-group-item list-group-item-action d-flex gap-3" aria-current="true">
          <span><i class="bi bi-bank"></i></span>
          <div class="d-flex gap-2 w-100 justify-content-between">
            <div>
              <h6 class="mb-0">Bank Details</h6>
            </div>
            <small class="opacity-50 text-nowrap"><i class="bi bi-chevron-right"></i></small>
          </div>
        </a>
        <a href="#" class="list-group-item list-group-item-action d-flex gap-3" aria-current="true">
          <span><i class="bi bi-person-vcard"></i></span>
          <div class="d-flex gap-2 w-100 justify-content-between">
            <div>
              <h6 class="mb-0">KYC Detail</h6>
            </div>
            <small class="opacity-50 text-nowrap"><i class="bi bi-chevron-right"></i></small>
          </div>
        </a>
        <a href="#" class="list-group-item list-group-item-action d-flex gap-3" aria-current="true">
          <span><i class="bi bi-cash"></i></span>
          <div class="d-flex gap-2 w-100 justify-content-between">
            <div>
              <h6 class="mb-0">Billing Detail</h6>
            </div>
            <small class="opacity-50 text-nowrap"><i class="bi bi-chevron-right"></i></small>
          </div>
        </a>
        <a href="#" class="list-group-item list-group-item-action d-flex gap-3" aria-current="true">
          <span><i class="bi bi-person-lock"></i></span>
          <div class="d-flex gap-2 w-100 justify-content-between">
            <div>
              <h6 class="mb-0">Change Password</h6>
            </div>
            <small class="opacity-50 text-nowrap"><i class="bi bi-chevron-right"></i></small>
          </div>
        </a>
        <a href="#" class="list-group-item list-group-item-action d-flex gap-3" aria-current="true">
          <span><i class="bi bi-envelope-at"></i></span>
          <div class="d-flex gap-2 w-100 justify-content-between">
            <div>
              <h6 class="mb-0">Email Notification</h6>
            </div>
            <small class="opacity-50 text-nowrap"><i class="bi bi-chevron-right"></i></small>
          </div>
        </a>
        <a href="#" class="list-group-item list-group-item-action d-flex gap-3" aria-current="true">
          <span><i class="bi bi-phone"></i></span>
          <div class="d-flex gap-2 w-100 justify-content-between">
            <div>
              <h6 class="mb-0">SMS Notification</h6>
            </div>
            <small class="opacity-50 text-nowrap"><i class="bi bi-chevron-right"></i></small>
          </div>
        </a>
        <a href="#" class="list-group-item list-group-item-action d-flex gap-3" aria-current="true">
          <span><i class="bi bi-whatsapp"></i></span>
          <div class="d-flex gap-2 w-100 justify-content-between">
            <div>
              <h6 class="mb-0">Whatsapp Notification</h6>
            </div>
            <small class="opacity-50 text-nowrap"><i class="bi bi-chevron-right"></i></small>
          </div>
        </a>
      </div>
    </div> */}
</div>

</div></div>
<Footer/></div></div></div>



    </div>
  )
}

export default Settings
