import React, { useState } from 'react'
import Footer from './Footer'
import Header from './Header'
import Settings_nav from './Settings_nav'
import config from "../../config"
import axios from "axios";
import Swal from 'sweetalert2'
import Moment from 'react-moment';

const System_prefrences = () => {
    const [orderDateRange, setOrderDateRange] = useState('Today');
  const [orderItemsPerPage, setOrderItemsPerPage] = useState(10);
  const [shipmentDateRange, setShipmentDateRange] = useState('Today');
  const [shipmentItemsPerPage, setShipmentItemsPerPage] = useState(10);

  React.useEffect(() => {
    fetchPreferences();
  }, []);

  const fetchPreferences = async () => {
    try {
        let dataToSend ={}
        let url = config.apiUrl + '/user/system_preferences_fetch';
     axios.post(url, dataToSend, { headers: config.headers}) .then((response) => {
      const data = response.data;
      setOrderDateRange(data.orderDateRange);
      setOrderItemsPerPage(data.orderItemsPerPage);
      setShipmentDateRange(data.shipmentDateRange);
      setShipmentItemsPerPage(data.shipmentItemsPerPage);
      })
    } catch (error) {
      console.error('Failed to fetch preferences:', error);
    }
  };

  const updatePreferences = async () => {
    try {
        let url = config.apiUrl + '/user/system_preferences_update';
      await axios.post(url, {
        orderDateRange,
        orderItemsPerPage,
        shipmentDateRange,
        shipmentItemsPerPage,
      },{headers:config.headers});
      const Toast = Swal.mixin({
        toast: true,
        position: 'bottom-end',
        showConfirmButton: false,
        timer: 3000
        })
      Toast.fire({
        background: "#3659cd",
        type: 'success',
        title: 'Preferences updated successfully',
        color: "white"
    });
      
    } catch (error) {
      console.error('Failed to update preferences:', error);
    }
  };
  return (
    <div>
     <Header/>
    <div class="page-wrapper pinned">
  <div class="main-container">
   <div className='app-container'>
  <div class="app-body">
  <div class="container-fluid"> 
<div class="mob_nav">
  <div class=" d-flex justify-content-start ">
    <a href="javascript:window.history.back();" class=""><i class="bi bi-arrow-left"></i></a>
    <h2>System Preferences</h2>
  </div>
</div>


<div class="row justify-content-between">
    <div class="col-12 col-md-4 desk_nav">
      <div class="d-flex">
        <a href="javascript:window.history.back();" class="back_btn"><i class="bi bi-arrow-90deg-left"></i></a>
        <h2>System Preferences</h2>
      </div>
    </div>
</div>



<div class="row">
    <div class="col-12 col-md-3 left_sub_menu_bx mb_display_none">
    <Settings_nav value={8}/>
    </div>
    <div class="col-12 col-md-9">
      <div class="card border-0 shadow-sm">
        <div class="card-body">
          <section class="mb-1">
            <h5 class="mb-3 border-start border-3 border-primary ps-2">Order Page Settings</h5>
            <div class="row mb-2">
              <div class="col-12 col-md-4">
                <label for="email" class="form-label">Default date range:</label>
              </div>
              <div class="col-12 col-md-4"> 
              <select value={orderDateRange} onChange={(e) => setOrderDateRange(e.target.value)} className='form-control'>
            <option value="Today">Today</option>
            <option value="7 Days">7 Days</option>
            <option value="30 Days">30 Days</option>
          </select>
              </div>
            </div>
            <div class="row mb-2">
              <div class="col-12 col-md-4 mb-3">
                <label for="email" class="form-label">Number of order per page:</label>
              </div>
              <div class="col-12 col-md-4 mb-3"> 
              <select value={orderItemsPerPage} onChange={(e) => setOrderItemsPerPage(Number(e.target.value))} className='form-control'>
            <option value={10}>10</option>
            <option value={30}>30</option>
            <option value={50}>50</option>
            <option value={100}>100</option>
          </select>
              </div>
            </div>
          </section>
          <hr/>
          <section class="mb-1">
            <h5 class="mb-3 border-start border-3 border-primary ps-2 ">Shipment Page Settings</h5>
            <div class="row mb-2">
              <div class="col-12 col-md-4">
                <label for="email" class="form-label">Default date range:</label>
              </div>
              <div class="col-12 col-md-4"> 
              <select value={shipmentDateRange} onChange={(e) => setShipmentDateRange(e.target.value)} className='form-control'>
            <option value="Today">Today</option>
            <option value="7 Days">7 Days</option>
            <option value="30 Days">30 Days</option>
          </select>
              </div>
            </div>
            <div class="row mb-2">
              <div class="col-12 col-md-4 mb-3">
                <label for="email" class="form-label">Number of order per page:</label>
              </div>
              <div class="col-12 col-md-4 mb-3"> 
              <select value={shipmentItemsPerPage} onChange={(e) => setShipmentItemsPerPage(Number(e.target.value))} className='form-control'>
            <option value={10}>10</option>
            <option value={30}>30</option>
            <option value={50}>50</option>
            <option value={100}>100</option>
          </select>
              </div>
            </div>
          </section>
          <a onClick={updatePreferences} class="btn btn-primary">Update</a>
        </div>
      </div>
    </div>
</div>
</div></div>
<Footer/>
</div></div></div>
    </div>
  )
}

export default System_prefrences
