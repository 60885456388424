import React from 'react'

import Header from './Header'


import * as XLSX from 'xlsx'
import axios from "axios";
import Swal from 'sweetalert2';
import Moment from 'react-moment';
import config from "../../config"

import Footer from './Footer'
import moment from 'moment'
import $ from 'jquery'; 
import 'daterangepicker/daterangepicker.css'; // Import daterangepicker CSS
import 'daterangepicker';

const Billing_wallet = () => {
    let userDetail = localStorage.getItem('ship_rocket_user');
    // console.log("userDetail====", config.UserId);
    let userData = {};
    if (userDetail != null) {
      userData = JSON.parse(userDetail)
    }
    const[balancestate, setbalanceState ]= React.useState({add_balance:[], total_balance:[] , total_credit:0 , total_debit:0})
    const[dimensionState,setdimensionState] = React.useState({companyDetail:[]})
    const [otherStates,setOtherState] = React.useState({dstatus:"",activePage:1,rowsPerPage:20,page:0,total_count:0,onload:true});
    
  
   
    const[date, setDate] = React.useState({from_date:moment().subtract(30, 'days').format('YYYY-MM-DD'),to_date:moment().format('YYYY-MM-DD')})
    
    const [filters, setfilters] = React.useState('all');


  
   React.useEffect(()=>{
    get_wallet_balance(0,true)
    dimension_detail()
   },[otherStates.rowsPerPage])

    const get_wallet_balance=(index=0,onLoad)=>{
     let dataToSend = {customer_id : userData.customer_id ,  limit:otherStates.rowsPerPage,indexValue:index , filters:filters};
     if(date.from_date!=""){
      dataToSend.from_date = date.from_date
     }
     if(date.to_date!=''){
      dataToSend.to_date = date.to_date
     }

     
     
      // console.log("datatoSend",dataToSend)
      let url = config.apiUrl + '/user/get_wallet_balance';
      axios.post(url, dataToSend, { headers: config.headers })
          .then((res) => {
            // console.log("responseJson => ", res);
            if(res.data.status==true){
              if( onLoad) {
                setOtherState({...otherStates,total_count:res.data.count, page:index})         
  
    }
        setbalanceState({add_balance:res.data.added_wallet_transactions, total_balance:res.data.find_customer_wallet , total_credit:res.data.credit_value , total_debit:res.data.debit_value})
     }
    else{
        const Toast = Swal.mixin({
            toast: true,
            position: 'bottom-end',
            showConfirmButton: false,
            timer: 3000
          })
          Toast.fire({
            background:"#a61c09",
            type: 'Unsuccess',
            title: "Something Went Wrong !",
            color:"white"
          });
    } })
        
          .catch((error) => {
            const Toast = Swal.mixin({
                toast: true,
                position: 'bottom-end',
                showConfirmButton: false,
                timer: 3000
              })
              Toast.fire({
                background:"#a61c09",
                type: 'Unsuccess',
                title: "Something Went Wrong !",
                color:"white"
              });
              // console.log(error);
          });    
    
    }
  
   
    const dimension_detail = () =>{
        let full_api = config.apiUrl + `/user/get_company_info_api`;
        let sendData = {};
      
        axios.post(full_api, sendData, { headers: config.headers }).then(res => {
          setdimensionState({...dimensionState,companyDetail:res.data.companyDetail});
          // console.log("rnmmmmm", res);
        }).catch((e) => {
          // toast.configure()
           //toast.error("Some thing went wrong")
          // console.log("----error:   ", e);
        })
      }

   

  
     
    const handlefilters =(e)=>{
      setfilters(e.target.value)
    }

    const fetchReportData = async () => {
      const requestBody = {
        from_date: date.from_date,
        to_date: date.to_date,
        filters: filters,
      };
  
      try {
        const response = await axios.post(config.apiUrl +'/user/get_sheet_wallet_balance', requestBody, {
          headers:config.headers
        });
  
        return response.data;
      } catch (error) {
        console.error('Error fetching report data:', error);
        return null;
      }
    };

    const formatDataForSheet = (data) => {
      return data.map(item => ({
        'Date/Time': new Date(item.created * 1000).toLocaleString(),
        'Particulars': item.payment_method,
        'Transaction': item.transaction_id,
        'Credit': item.payment_type === 1 ? item.amount : '',
        'Debit': item.payment_type === 2 ? item.amount : ''
      }));
    };
  
    const handleDownload = async () => {
      
      const data = await fetchReportData();
      if (!data) return;
  
      const workbook = XLSX.utils.book_new();
  
      if (filters === 'all' || filters === 'deduction') {
        const deductionData = data.added_wallet_transactions.filter(item => item.payment_type === 2);
        const formattedDeductionData = formatDataForSheet(deductionData);
        const deductionSheet = XLSX.utils.json_to_sheet(formattedDeductionData);
        XLSX.utils.book_append_sheet(workbook, deductionSheet, 'Deduction');
      }
  
      if (filters === 'all' || filters === 'refund') {
        const refundData = data.added_wallet_transactions.filter(item => item.payment_type === 1 && item.shipment_id);
        const formattedRefundData = formatDataForSheet(refundData);
        const refundSheet = XLSX.utils.json_to_sheet(formattedRefundData);
        XLSX.utils.book_append_sheet(workbook, refundSheet, 'Refund');
      }
  
      if (filters === 'all' || filters === 'recharge') {
        const rechargeData = data.added_wallet_transactions.filter(item =>
          item.payment_type === 1 &&
          ['RazorPay', 'Easebuzz', 'Cashfree', 'Phonepay'].includes(item.payment_method)
        );
        const formattedRechargeData = formatDataForSheet(rechargeData);
        const rechargeSheet = XLSX.utils.json_to_sheet(formattedRechargeData);
        XLSX.utils.book_append_sheet(workbook, rechargeSheet, 'Recharge');
      }
  
      if (filters === 'all' || filters=== 'weight') {
        const weightData = data.added_wallet_transactions.filter(item => item.payment_method.startsWith('Weight Discrepancies for'));
        const formattedWeightData = formatDataForSheet(weightData);
        const weightSheet = XLSX.utils.json_to_sheet(formattedWeightData);
        XLSX.utils.book_append_sheet(workbook, weightSheet, 'Weight Reconciliation');
      }
  
      XLSX.writeFile(workbook, 'wallet_report.xlsx');
    };

    const handleDateChange =(start , end)=>{
        setDate({...date, from_date:moment(start).format('YYYY-MM-DD'), to_date:moment(end).format("YYYY-MM-DD")})
     }
     React.useEffect(() => {
      // Initialize date range picker with today's date as the default range
      $('.custom-daterange').daterangepicker({
          opens: 'right',
          startDate: moment().subtract(30, 'days').format('YYYY-MM-DD'),  // Set start date to today's date
          endDate: moment().startOf('day'),    // Set end date to today's date
          autoUpdateInput: true,
          locale: {
              format: 'YYYY-MM-DD'             // Display format
          }
      }, (start, end) => {
        handleDateChange(start, end); 
       
                // Update selected range when a new range is chosen
      });
    
      // Set initial value in the input to show today's date
      $('.custom-daterange').val(`${moment().subtract(30, 'days').format('YYYY-MM-DD')} to ${moment().format('YYYY-MM-DD')}`);
    }, []);
    
  return (
    <div>
   <Header/>
 <div class="page-wrapper pinned">
  <div class="main-container">
   <div className='app-container'>
  <div class="app-body">
  <div class="container-fluid"> 


<div class="mob_nav">
  <div class="row">
      <div class="col-7">
        <div class=" d-flex justify-content-start ">
          <a href="javascript:window.history.back();" class=""><i class="bi bi-arrow-left"></i></a>
          <h2>Passbook</h2>
        </div>
      </div>
      <div class="col">
      </div>
  </div>
</div>


<div class="row justify-content-between desk_nav">
    <div class="col-12 col-md-4 ">
      <div class="d-flex">
        <a href="javascript:window.history.back();" class="back_btn"><i class="bi bi-arrow-90deg-left"></i></a>
        <h2>Passbook</h2>
      </div>
    </div>
    <div class="col-12 col-md-4 mb-3">
    </div>
</div>

<div class="card mb-3">
  <div class="card-body">
    <div class="row justify-content-between">
        <div class="col-12 col-md-6 mb-1">
          <div class="row g-1">
              <div class="col-12 col-md-6">
                <div class="input-group">
                  <input type="text" class="form-control custom-daterange"/>
                  <span class="input-group-text">
                    <i class="bi bi-search"></i>
                  </span>
                </div>
              </div>
              <div class="col-12 col-md-6">
                {/* <div class="search-box">
                    <div class="search-icon">
                    <i class="bi bi-search"></i>
                    </div>
                    <input type="text" class="search-input" placeholder="Search ..."/>
                </div> */}
              </div>
          </div>
        </div>
        <div class="col-12 col-md-6 mb-1">
          <div class="row justify-content-between">
              <div class="col-6 col-md">
                <div class="row">
                    <div class="col-12 col-md">
                    <select className='form-select' onChange={(e)=>handlefilters(e)}>
                          <option value="all">All</option>
                          <option value="deduction">Deduction</option>
                          <option value="recharge">Recharge</option>
                          <option value="refund">Refund</option>
                          <option value="weight">Weight Reconciliation</option>
                          </select>
                    </div>
                    <div class="col-12 col-md">
                      <a href="" class="btn btn-outline-secondary btn-block" onClick={(e)=>get_wallet_balance(0,true)}>View Report</a>
                    </div>
                </div>
              </div>
              <div class="col-6 col-md-4 text-end">
                <a  class="btn btn-outline-secondary btn-block" onClick={(e)=>handleDownload(e)}>Download Report</a>
              </div>
          </div>
        </div>
    </div>
  </div>
</div>



<div class="card data_table mb-3 ">
  <div class="card-body">
    <div class="table-responsive" >
      <div class="shipm_tablew rounded-3">
        <table class="table align-top table-striped table-hover m-0 table-xl ">
          <thead>
            <tr>
              <th>Date</th>
              <th>Particluars</th>
              <th>Transaction ID</th>
              <th>Credit</th>
              <th>Debit</th>
            </tr>					
          </thead>
          <tbody>
          {balancestate.add_balance.map((sub)=>(
            <tr class="">
              <td>
                <small><Moment format="MMM DD YYYY">
        {new Date(sub.created*1000)}
</Moment>  <Moment format="hh:mm:ss a ">
        {new Date(sub.created*1000)}
</Moment></small>
              </td>
              <td>                
              {sub.payment_type==1?`Credited into the wallet (${sub.payment_method})`:`Debited from the wallet (${sub.payment_method})`}
              </td>
              <td>
                <h6><a href="#" class="text-primary">{sub.transaction_id}</a></h6>
              </td>
              <td>
              <span class="text-success">{sub.payment_type==1 ?<>₹ {sub.amount}</> :""}</span>
              </td>
              <td>
              <span class="text-danger">{sub.payment_type!=1 ?<>- ₹ {sub.amount}</> :""}</span>
              </td>
            </tr>
          ))}
          
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>


    </div>
    </div>
    <Footer/>
    </div></div></div>
    </div>
  )
}

export default Billing_wallet
