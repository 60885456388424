import React from 'react'
import Order_data from './Order_data'
import Shipment_data from './Shipment_data'
import Carrier_list_order from './Carrier_list_order'

const Common = () => {
  return (
    <div>
      {/* <!-- Offcanvas Order_Filter --> */}
{/* <div class="offcanvas offcanvas-end" id="Order_Filter">
  <div class="offcanvas-header">
    <h2 class="offcanvas-title">Order Filters</h2>
    <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas"></button>
  </div>
  <div class="offcanvas-body">
    <div class="card mb-3">
      <div class="card-body">
        <h6>Payment Mode</h6>
        <hr/>
        <div class="form-check form-switch  mb-2">
           <input class="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckChecked" checked/>
           <label class="form-check-label" for="flexSwitchCheckChecked">Prepaid</label>
        </div>
        <div class="form-check form-switch  mb-2">
           <input class="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckChecked" checked/>
           <label class="form-check-label" for="flexSwitchCheckChecked">COD (Cash On Delivery)</label>
        </div>
      </div>
    </div>
    <div class="card mb-3">
      <div class="card-body">
        <h6>Warehouse Pickup Locations</h6>
        <hr/>
        <div class="form-check form-switch  mb-2">
           <input class="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckChecked" checked/>
           <label class="form-check-label" for="flexSwitchCheckChecked">Navi Mumbai</label>
        </div>
        <div class="form-check form-switch  mb-2">
           <input class="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckChecked" checked/>
           <label class="form-check-label" for="flexSwitchCheckChecked">Karol Bagh Delhi</label>
        </div>
      </div>
    </div>
    <div class="d-grid">
      <a href="" class="btn btn-primary btn-block">Update Filter</a>
    </div>
  </div>
</div> */}

{/* <!-- Offcanvas Shipment_Filter --> */}
<div class="offcanvas offcanvas-end" id="Shipment_Filter">
  <div class="offcanvas-header">
    <h2 class="offcanvas-title">Shipment Filters</h2>
    <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas"></button>
  </div>
  <div class="offcanvas-body">
    <div class="card mb-3">
      <div class="card-body">
        <h6>Shipment Status</h6>
        <hr/>
        <div class="form-check form-switch  mb-2">
           <input class="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckChecked" checked/>
           <label class="form-check-label" for="flexSwitchCheckChecked">Delivered</label>
        </div>
        <div class="form-check form-switch  mb-2">
           <input class="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckChecked" checked/>
           <label class="form-check-label" for="flexSwitchCheckChecked">In Transit</label>
        </div>
        <div class="form-check form-switch  mb-2">
           <input class="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckChecked" checked/>
           <label class="form-check-label" for="flexSwitchCheckChecked">RTO</label>
        </div>
      </div>
    </div>
    <div class="card mb-3">
      <div class="card-body">
        <h6>Payment Mode</h6>
        <hr/>
        <div class="form-check form-switch  mb-2">
           <input class="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckChecked" checked/>
           <label class="form-check-label" for="flexSwitchCheckChecked">Prepaid</label>
        </div>
        <div class="form-check form-switch  mb-2">
           <input class="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckChecked" checked/>
           <label class="form-check-label" for="flexSwitchCheckChecked">COD (Cash On Delivery)</label>
        </div>
      </div>
    </div>
    <div class="card mb-3">
      <div class="card-body">
        <h6>Warehouse Pickup Locations</h6>
        <hr/>
        <div class="form-check form-switch  mb-2">
           <input class="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckChecked" checked/>
           <label class="form-check-label" for="flexSwitchCheckChecked">Navi Mumbai</label>
        </div>
        <div class="form-check form-switch  mb-2">
           <input class="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckChecked" checked/>
           <label class="form-check-label" for="flexSwitchCheckChecked">Karol Bagh Delhi</label>
        </div>
      </div>
    </div>
    <div class="d-grid">
      <a href="" class="btn btn-primary btn-block">Update Filter</a>
    </div>
  </div>
</div>


{/* <!-- Order Data View --> */}
 <section class="order_data">
    <div class="offcanvas offcanvas-end " id="Order_data">
    <div class="offcanvas-header shadow-sm">
        <h4 class="offcanvas-title">Orders #298239842348</h4>
        <div class="dropdown text-end">
            <button type="button" class="btn btn-outline-primary btn-block dropdown-toggle" data-bs-toggle="dropdown">
            Actions 
            </button>
            <ul class="dropdown-menu">
                <li><a class="dropdown-item" href="#"> Edit Order</a></li>
                <li><a class="dropdown-item" href="#"> Clone Order</a></li>
                <li><a class="dropdown-item text-danger" href="#"> Cancel Order</a></li>
            </ul>
        </div>
        <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas"></button>
    </div>
    <div class="offcanvas-body">
       <Order_data/>
    </div>
    </div>
 </section>




{/* <!-- Order Data View --> */}
<section class="order_data">
    <div class="offcanvas offcanvas-end " id="Shipment_data">
    <div class="offcanvas-header shadow-sm">
        <h4 class="offcanvas-title">Shipment #298239842348</h4>
        <div class="shipm_tablew">
            <span class="status pickedup"> <i class="fa fa-check" aria-hidden="true"></i> Pickedup</span>
        </div>
        <div class="dropdown text-end">
            <button type="button" class="btn btn-outline-primary btn-block dropdown-toggle" data-bs-toggle="dropdown">
            Actions 
            </button>
            <ul class="dropdown-menu">
                <li><a class="dropdown-item" href="#"> Edit Order</a></li>
                <li><a class="dropdown-item" href="#"> Clone Order</a></li>
                <li><a class="dropdown-item text-danger" href="#"> Cancel Order</a></li>
            </ul>
        </div>
        <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas"></button>
    </div>
    <div class="offcanvas-body">
      <Shipment_data/>
    </div>
    </div>
 </section>






 {/* <!-- Carrier show list Offcanvas --> */}
 <section class="order_data">
    <div class="offcanvas offcanvas-end" id="Carrier_lists">
    <div class="offcanvas-header">
        <h4 class="offcanvas-title">Select Courier Partner</h4>
        <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas"></button>
    </div>
    <div class="offcanvas-body">
       <Carrier_list_order/>
    </div>
    </div>
</section>




{/* <!-- Add/Edit Warehouse Modal --> */}
<div class="modal" id="Warehouse_up">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title">Warehouse</h4>
        <button type="button" class="btn-close" data-bs-dismiss="modal"></button>
      </div>
      <div class="modal-body">
      <div class="row">
            <div class="col-12 col-md-6 mb-3">
              <label for="email" class="form-label">Full Name:</label>
              <input type="text" class="form-control" id="text" placeholder="Enter Your full name"/>
            </div>
            <div class="col-12 col-md-6 mb-3">
              <label for="email" class="form-label">Warehouse Name:</label>
              <input type="text" class="form-control" id="text" placeholder="Enter Your Warehouse Name"/>
            </div>
            <div class="col-12 col-md-3">
              <label for="email" class="form-label">Select Country:</label>
              <select class="form-select form-select-sm">
                <option>Select Country</option>
                <option>2</option>
                <option>3</option>
                <option>4</option>
              </select>
            </div>
            <div class="col-12 col-md-3">
              <label for="email" class="form-label">Select State:</label>
              <select class="form-select form-select-sm">
                <option>Select State</option>
                <option>2</option>
                <option>3</option>
                <option>4</option>
              </select>
            </div>
            <div class="col-12 col-md-3">
              <label for="email" class="form-label">Select City:</label>
              <select class="form-select form-select-sm">
                <option>Select City</option>
                <option>2</option>
                <option>3</option>
                <option>4</option>
              </select>
            </div>
            <div class="col-12 col-md-3">
              <label for="email" class="form-label">Select Pincode:</label>
              <select class="form-select form-select-sm">
                <option>Select Pincode</option>
                <option>2</option>
                <option>3</option>
                <option>4</option>
              </select>
            </div>
            <div class="col-12 col-md-12 mb-3 mt-3">
              <label for="email" class="form-label">Address:</label>
              <input type="text" class="form-control" id="text" placeholder="Enter Your Warehouse Address"/>
            </div>
            <div class="col-12 col-md-6 mb-3">
              <label for="email" class="form-label">Email:</label>
              <input type="text" class="form-control" id="text" placeholder="Enter Email"/>
            </div>
            <div class="col-12 col-md-6 mb-3">
              <label for="email" class="form-label">Mobile Number:</label>
              <input type="text" class="form-control" id="text" placeholder="Enter Your Mobile Number"/>
            </div>
        </div>
      </div>
      <div class="modal-footer">
        <a href="#" class="btn btn-primary">Submit</a>
      </div>

    </div>
  </div>
</div>


{/* <!-- The Modal Weight Dispute Raise--> */}
<div class="modal" id="WeightDis">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title">Raise Weight Dispute </h4>
        <button type="button" class="btn-close" data-bs-dismiss="modal"></button>
      </div>
      <div class="modal-body">
        <section class="chatting_lists">
          <div class="chat_list left_hand">
            <img src="https://avatar.iran.liara.run/username?username=Michel+Wilson" class="img-fluid chat_list_img" />
            <div>
              <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Magnam totam aperiam corrupti, voluptates repellat laboriosam, culpa deleniti delectus maxime voluptatibus est in nemo possimus minus soluta provident fugiat id odit.</p>
              <strong>- Michel Wilson</strong> <small>just now</small>
              <h6>Attachments</h6>
                <div class="d-flex gap-2 mt-2">
                  <div class="text-center">
                    <a href=""><img src="https://picsum.photos/60" alt="" class="rounded-3 img-3x"/></a>
                  <h6>Picture</h6>
                  </div>
                  <div class="text-center">
                    <a href=""><img src="https://picsum.photos/60" alt="" class="rounded-3 img-3x"/></a>
                  <h6>Picture</h6>
                  </div>
                  <div class="text-center">
                    <a href=""><img src="https://picsum.photos/60" alt="" class="rounded-3 img-3x"/></a>
                  <h6>Video</h6>
                  </div>
                </div>
            </div>
          </div>
          <hr/>
          <div class="chat_list right_hand">
            <img src="https://avatar.iran.liara.run/username?username=James+Kanday" class="img-fluid chat_list_img" />
            <div>
            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Magnam totam aperiam corrupti, voluptates repellat laboriosam, culpa deleniti delectus maxime voluptatibus est in nemo possimus minus soluta provident fugiat id odit.</p>
              <strong>- Michel Wilson</strong> <small>just now</small>
              <h6>Attachments</h6>
                <div class="d-flex gap-2 mt-2">
                  <div class="text-center">
                    <a href=""><img src="https://picsum.photos/600" alt="" class="rounded-3 img-3x"/></a>
                  <h6>Picture</h6>
                  </div>
                  <div class="text-center">
                    <a href=""><img src="https://picsum.photos/1000" alt="" class="rounded-3 img-3x"/></a>
                  <h6>Picture</h6>
                  </div>
                  <div class="text-center">
                    <a href=""><img src="https://picsum.photos/60" alt="" class="rounded-3 img-3x"/></a>
                  <h6>Video</h6>
                  </div>
                </div>
            </div>
          </div>
        </section>
        <hr/>
            <div class="text-center">
              <a href="#" class="btn btn-danger"  data-bs-toggle="collapse" data-bs-target="#reaise_disp">Reject</a>
              <a href="#" class="btn btn-success">Accept</a>
              <a href="#" class="btn btn-secondary">Close</a>
            </div>
        <hr/>
        <div class="row collapse" id="reaise_disp" >
            <div class="col-12 col-md-12 mb-3">
              <label for="email" class="form-label">Enter Dispute Details:</label>
              <textarea class="form-control" rows="5" id="comment" name="text" placeholder="Enter details"></textarea>
            </div>
            <div class="col-12 col-md-12 mb-3">
              <label for="email" class="form-label">Enter Dispute Details: (Upto 3 images/video max 100mb)</label>
              <input type="file" class="form-control" id="text" placeholder="Enter details name="/>
            </div>
            <div class="col-12 col-md-12 mb-3">
              <a href="#" class="btn btn-primary">Raise Weight Dispute</a>
            </div>
        </div>
      </div>
    </div>
  </div>
</div>







    </div>
  )
}

export default Common
