import React from 'react'
import Header from './Header'
import Footer from './Footer'
import { useNavigate } from 'react-router-dom'
import config from "../../config"
import axios from "axios";
import Swal from 'sweetalert2'
const Manifest_add = () => {
    let navigate = useNavigate();
    const [orderNumbers, setOrderNumbers] = React.useState('');
    const[isLoading ,setisLoading ] = React.useState(false)

    const generateManifest=()=>{
        setisLoading(true)
          let dataToSend = {};
     
    if(orderNumbers!=""){
      const orders = orderNumbers.split(/[\s,]+/).filter(Boolean); // Filter empty values
      const orderString = orders.join(',');
      dataToSend.find_bulk = orderString
    }
        let url = config.apiUrl + '/shipment/generate_manifest';
          
        axios.post(url, dataToSend, { headers: config.headers })
            .then((res) => {
            if(res.data.status==true){
                setisLoading(false)
                window.location.replace('/manifest_list')
            } 
            else{
                const Toast = Swal.mixin({
                    toast: true,
                    position: 'bottom-end',
                    showConfirmButton: false,
                    timer: 3000
                    })
                    
                    Toast.fire({
                    background:"#a61c09",
                    type: 'unsuccess',
                    title: "Something Went Wrong ",
                    color:"white"
                    });
                setisLoading(false)

            }
              })
            .catch((error) => {
                const Toast = Swal.mixin({
                    toast: true,
                    position: 'bottom-end',
                    showConfirmButton: false,
                    timer: 3000
                    })
                    
                    Toast.fire({
                    background:"#a61c09",
                    type: 'unsuccess',
                    title: "Something Went Wrong ",
                    color:"white"
                    });
                setisLoading(false)

            });    
  
      }
  return (
    <div>
         <Header/>
 <div class="page-wrapper pinned">
  <div class="main-container">
   <div className='app-container'>
  <div class="app-body">
  <div class="container-fluid"> 
<div class="mob_nav">
  <div class=" d-flex justify-content-start ">
    <a href="javascript:window.history.back();" class=""><i class="bi bi-arrow-left"></i></a>
    <h2>Generate Manifest</h2>
  </div>
</div>


<div class="row justify-content-between">
    <div class="col-12 col-md-4 desk_nav">
      <div class="d-flex">
        <a href="javascript:window.history.back();" class="back_btn"><i class="bi bi-arrow-90deg-left"></i></a>
        <h2>Generate Manifest</h2>
      </div>
    </div>
</div>

<div class="row justify-content-center">
    <div class="col-12 col-md-6">
      <div class="card">
        <div class="card-body">
          <div class="my-3">
            <textarea class="form-control" rows="5" id="comment" name="text" placeholder="Enter AWB Number seprated by commas or in new line."  value={orderNumbers}
          onChange={(e) => setOrderNumbers(e.target.value)}></textarea>
          </div>
          <div class="d-flex justify-content-between">
            <div class="pe-3">
        {isLoading==true?"":      <a onClick={(e)=>generateManifest()} class="btn btn-primary">Generate Manifest</a>}
            </div>
            <div>
              {/* <small>(Max 500 AWB/Ids Allowed)</small> */}
            </div>
          </div>
          
        </div>
      </div>
    </div>
</div>

    </div></div>
    <Footer/></div></div></div>
      
    </div>
  )
}

export default Manifest_add
