import React, {  useRef } from 'react'

import { useLocation, useNavigate } from 'react-router-dom';
import axios from "axios";
import Swal from 'sweetalert2'
import { Container, Row, Col, Form, Button, ProgressBar } from "react-bootstrap";
// import './customer_style.css';
import Barcode from 'react-barcode';
import Modal from 'react-bootstrap/Modal';
// import { useLocation } from 'react-router-dom';
import ReactLoading from 'react-loading';
import config from "../../config"
import download from 'downloadjs'
import Moment from 'react-moment';
import TablePagination from '@mui/material/TablePagination';
import htmlToPdfmake from 'html-to-pdfmake';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';

import ReactDOM from 'react-dom';
import ReactDOMServer from 'react-dom/server';
import moment from 'moment';
import html2canvas from 'html2canvas';
// import BarcodeComponent from './BacodeComponent'
import { renderToString } from 'react-dom/server';
import createRoot from 'react-dom';
import { useNetworkState } from 'react-use';
import { BarcodeIcon, CornerUpLeft, DollarSign, FileDown, Package, PackageCheck, PackageIcon, PackageSearch, ShoppingBag, ShoppingBasket, Truck, TruckIcon } from 'lucide-react';
import Header from './Header';
import Footer from './Footer';
import $ from 'jquery'; 
import 'daterangepicker/daterangepicker.css'; // Import daterangepicker CSS
import 'daterangepicker';



const Shipments = () => {
  pdfMake.vfs = pdfFonts.pdfMake.vfs; 
    let navigate = useNavigate()
    const isOnline = useNetworkState();
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const pendingOrder = searchParams.get('pending');
    // console.log("isONline  === " ,isOnline )
    // const barcodeRef = useRef(null);
    let userDetail = localStorage.getItem('ship_rocket_user');
    // console.log("userDetail====shipments====", config.UserId);
    let userData = {};
    if (userDetail != null) {
      userData = JSON.parse(userDetail)
    }
const [openOffcanvasfilter, setOpenOffcanvasfilter] = React.useState(false);
    
    const[ndrmodal , setndrmodal] = React.useState(false)
    const[loaderpickup , setloaderpickup] = React.useState(false)
    const[dimensionState,setdimensionState] = React.useState({companyDetail:[]})
    const[state,setState]= React.useState({shipment_list:[],isLoading:true})
    const[labelstate,setlabel]= React.useState({shipment_list:[],isLoading:true})
    const[searchstate,setsearchstate]= React.useState({search_shipment:""})

    const [shipmentstate, setshipStatus] = React.useState({ shipment_status: null });
    const [shipSelect, setShipSelect] = React.useState({ shipment_id: "",cancel_reason:"" , total_amount:0 });
    const [modalState, setModalState] = React.useState({ show: false });
    const [modalpickupState, setModalpickupState] = React.useState({ show: false });
    const[isLoading , setisLoading] =React.useState(true)
    const[pickupstate , setpickupstate] = React.useState({  warehouse_name:""});
    const[checkstatus , setcheckstatus] = React.useState({ship:{},alert:''})
    // console.log("pickupstate ==== " , pickupstate)
    const [selectedShipments, setSelectedShipments] = React.useState([]);
    // console.log("selectedShipments === " ,selectedShipments)
    const [selectCount ,setSelectCount] = React.useState({select_count:0 })
    const[addressState, setaddressState] = React.useState([]);
    
    const [otherStates,setOtherState] = React.useState({dstatus:"",activePage:1,rowsPerPage:20,page:0,total_count:0,onload:true});
    // console.log("otherState ==== " , otherStates)
    const[isloading , setisloading] = React.useState(true)

    const [selectedDateTime, setSelectedDateTime] = React.useState(null);
    const [selectedTime, setSelectedTime] = React.useState('9:00');
    const[cancelshipment , setcancelshipment] = React.useState(false)
    const [openOffdetailcanvas, setOpenOffdetailcanvas] = React.useState(false);
    const[bookorder , setbookorder] = React.useState([])
    console.log("bookorder == " , bookorder)
    const [isSidebarOpen, setIsSidebarOpen] = React.useState(false);
    const[date, setdate] = React.useState({from_date:moment().subtract(30, 'days').format('YYYY-MM-DD'),to_date:moment().format('YYYY-MM-DD')})
    const[paymentmode, setpaymentmode] = React.useState({cod:false,prepaid:false})
    const[warehouse , setwarehouse] = React.useState([])
    const [selectedWarehouses, setSelectedWarehouses] = React.useState([]);
    const [searchfilters, setsearchFilters] = React.useState({ searchtitle: "",filter:[{name:"booked",value:1,status:true},{name:"assigned",value:2,status:true},{name:"intransit",value:3,status:true},{name:"delivered",value:4,status:true}],filterStatus:[]})
    const[failedstate , setfailed] = React.useState({failed:""})
    const [trackingstate,setTrackingState] = React.useState({track:{},isLoading:true})
    const [stateship, setStateship] = React.useState({ shipmentDetail: {sender:{},receiver:{},items:[] }, isLoading: true,detailFound:false,parcel_count:0  })
    const [showComponent, setShowComponent] = React.useState(true);
    const [stateshipment, setShipments] = React.useState({shipment:{}});
    const[ndrstate , setndrstate] = React.useState({ndr_date:"" , ndr_address:"" , ndr_phone:"" , ndr_remarks:""})

    const [typestate, setType] = React.useState({type:5})
    const [dateRange, setDateRange] = React.useState({
      startDate: moment().startOf("day"), // Default start date
      endDate: moment().startOf("day"),   // Default end date
    });
    React.useEffect(() => {
      // Set the date range dynamically based on fetchedDays
      let dataToSend ={}
        let url = config.apiUrl + '/user/system_preferences_fetch';
     axios.post(url, dataToSend, { headers: config.headers}) .then((response) => {
    
      const data = response.data;
      console.log("response.data ==" , response.data)
      if(response.data!=null){
     
      if (data.shipmentDateRange === "7 Days") {
        setDateRange({
          startDate: moment().subtract(7, "days").startOf("day"),
          endDate: moment().startOf("day"),
        });
      } else if (data.shipmentDateRange === "30 Days") {
        setDateRange({
          startDate: moment().subtract(30, "days").startOf("day"),
          endDate: moment().startOf("day"),
        });
      } else if (data.shipmentDateRange === "Today") {
        setDateRange({
          startDate: moment().startOf("day"),
          endDate: moment().startOf("day"),
        });
      }
     
    }
    else{
      setDateRange({
        startDate: moment().subtract(30, "days").startOf("day"),
        endDate: moment().startOf("day"),
      });
    }
      })
     
    }, []);
    React.useEffect(() => {
      const urlParams = new URLSearchParams(window.location.search);
      const statusParam = urlParams.get('status');
      if (statusParam && parseInt(statusParam) === 8) {
          // If status is 8, don't show the component
          setShowComponent(false);
          setshipStatus({shipment_status:statusParam})
      } else if (statusParam && parseInt(statusParam) === 1) {
          setShowComponent(true);
           setshipStatus({shipment_status:statusParam})
      }
      else if (statusParam && parseInt(statusParam) === 5) {
        setShowComponent(true);
         setshipStatus({shipment_status:statusParam})
    }
    else if (statusParam && parseInt(statusParam) === 10) {
      setShowComponent(true);
       setshipStatus({shipment_status:statusParam})
  } else if (statusParam && parseInt(statusParam) === 9) {
    setShowComponent(true);
     setshipStatus({shipment_status:statusParam})
}
else{
  setShowComponent(true);
}

  }, []);
    React.useEffect(() => {
      const urlSearchParams = new URLSearchParams(window.location.search);
      console.log("urlSearchParams == " , urlSearchParams)
      // Get and set the customer_id from the URL
   
      const urlStatus = urlSearchParams.get('status');
      console.log("urlStatus == " , urlStatus)
      const decodedStatus = decodeURIComponent(urlStatus);
      const statusArray = decodedStatus.split(',');
      const statusNumbers = statusArray.map(Number);
      console.log("statusNumbers == " , statusNumbers.length)
      // if(statusNumbers.length>0){
      //   setshipStatus({shipment_status:statusNumbers})
      // }
      const order_number = urlSearchParams.get('number');

      if(order_number && !urlStatus){
        setsearchstate({search_shipment:order_number})
        let onLoad = true
        let index = 0 
        if(isOnline.online==true){
          let dataToSend = {customer_id : userData.customer_id ,find:order_number,  limit:otherStates.rowsPerPage,indexValue:index ,   
      };
      if(date.from_date!="" && date.to_date!=""){
        dataToSend.from_date = date.from_date
        dataToSend.to_date = date.to_date
  
      }
     if(selectedWarehouses.length>0){
      dataToSend.warehouse = selectedWarehouses
     }
      if (paymentmode.cod && !paymentmode.prepaid) {
        dataToSend.payment_mode = 'cod';
    } else if (!paymentmode.cod && paymentmode.prepaid) {
        dataToSend.payment_mode = 'prepaid';
    } else if (paymentmode.cod && paymentmode.prepaid) {
        dataToSend.payment_mode = 'both';
    }
  
  
    
  
        // console.log("datatoSend",dataToSend)
        let url = config.apiUrl + '/shipment/customer_shipments';
       
       // console.log("headers =========> ", config.headers);
       
        
        axios.post(url, dataToSend, { headers: config.headers })
        // // console.log("headers ====== " , config.headers)
            .then((res) => {
              // console.log("responseJson => ", res);
              if( onLoad) {
                setOtherState({...otherStates,total_count:res.data.count , page:index})         
  
    }
              setState({shipment_list:res.data.output,isLoading:false})
              setisLoading(false)
           
            })
            .catch((error) => {
                //Hide Loader
              //   setLoadingstate({...loadingstate,loading:false})
                // console.log(error);
            });    
          }
          else{
            Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: "Network Connection Error",
              
            })
          }
      }

      else if(!order_number&&urlStatus && statusNumbers.length>0){
        let onLoad = true
        let index = 0 
        if(isOnline.online==true){
          let dataToSend = {customer_id : userData.customer_id ,find:searchstate.search_shipment,  limit:otherStates.rowsPerPage,indexValue:index ,   status:statusNumbers
      };
      if(date.from_date!="" && date.to_date!=""){
        dataToSend.from_date = date.from_date
        dataToSend.to_date = date.to_date
  
      }
     if(selectedWarehouses.length>0){
      dataToSend.warehouse = selectedWarehouses
     }
      if (paymentmode.cod && !paymentmode.prepaid) {
        dataToSend.payment_mode = 'cod';
    } else if (!paymentmode.cod && paymentmode.prepaid) {
        dataToSend.payment_mode = 'prepaid';
    } else if (paymentmode.cod && paymentmode.prepaid) {
        dataToSend.payment_mode = 'both';
    }
  
  
    
  
        // console.log("datatoSend",dataToSend)
        let url = config.apiUrl + '/shipment/customer_shipments';
       
       // console.log("headers =========> ", config.headers);
       
        
        axios.post(url, dataToSend, { headers: config.headers })
        // // console.log("headers ====== " , config.headers)
            .then((res) => {
              // console.log("responseJson => ", res);
              if( onLoad) {
                setOtherState({...otherStates,total_count:res.data.count , page:index})         
  
    }
              setState({shipment_list:res.data.output,isLoading:false})
              setisLoading(false)
           
            })
            .catch((error) => {
                //Hide Loader
              //   setLoadingstate({...loadingstate,loading:false})
                // console.log(error);
            });    
          }
          else{
            Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: "Network Connection Error",
              
            })
          }
      }
      else if(order_number&&urlStatus && statusNumbers.length>0){
        let onLoad = true
        let index = 0 
        if(isOnline.online==true){
          let dataToSend = {customer_id : userData.customer_id ,find:order_number,  limit:otherStates.rowsPerPage,indexValue:index ,   status:statusNumbers
      };
      if(date.from_date!="" && date.to_date!=""){
        dataToSend.from_date = date.from_date
        dataToSend.to_date = date.to_date
  
      }
     if(selectedWarehouses.length>0){
      dataToSend.warehouse = selectedWarehouses
     }
      if (paymentmode.cod && !paymentmode.prepaid) {
        dataToSend.payment_mode = 'cod';
    } else if (!paymentmode.cod && paymentmode.prepaid) {
        dataToSend.payment_mode = 'prepaid';
    } else if (paymentmode.cod && paymentmode.prepaid) {
        dataToSend.payment_mode = 'both';
    }
  
  
    
  
        // console.log("datatoSend",dataToSend)
        let url = config.apiUrl + '/shipment/customer_shipments';
       
       // console.log("headers =========> ", config.headers);
       
        
        axios.post(url, dataToSend, { headers: config.headers })
        // // console.log("headers ====== " , config.headers)
            .then((res) => {
              // console.log("responseJson => ", res);
              if( onLoad) {
                setOtherState({...otherStates,total_count:res.data.count , page:index})         
  
    }
              setState({shipment_list:res.data.output,isLoading:false})
              setisLoading(false)
           
            })
            .catch((error) => {
                //Hide Loader
              //   setLoadingstate({...loadingstate,loading:false})
                // console.log(error);
            });    
          }
          else{
            Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: "Network Connection Error",
              
            })
          } 
      }
      else{
       
        shipment_list(null,0,true)
        
      }
    }, [ paymentmode , selectedWarehouses , searchfilters.filterStatus , otherStates.rowsPerPage ]);

    
    const Toast = Swal.mixin({
      toast: true,
      position: 'bottom-end',
      showConfirmButton: false,
      timer: 5000
  })

    // console.log("shipmentState",shipmentstate)
    React.useEffect(() => {
  
        // shipment_list(null,0,true)
        dimension_detail()
    address_book_detail()
    // get_warehouse()
    
      }, [])

      // React.useEffect(()=>{
      //   shipment_list(null,0,true)
      // },[date.date , paymentmode , selectedWarehouses , searchfilters.filterStatus,location.search])
    

    const shipment_list=(status,index=0,onLoad)=>{
      console.log("status   ===" , status)
      setisLoading(true)
      if(isOnline.online==true){
        let dataToSend = {customer_id : userData.customer_id ,find:searchstate.search_shipment,  status:status , limit:otherStates.rowsPerPage,indexValue:index
    };
    if(date.from_date!="" && date.to_date!=""){
      dataToSend.from_date = date.from_date
      dataToSend.to_date = date.to_date

    }
   if(selectedWarehouses.length>0){
    dataToSend.warehouse = selectedWarehouses
   }
    if (paymentmode.cod && !paymentmode.prepaid) {
      dataToSend.payment_mode = 'cod';
  } else if (!paymentmode.cod && paymentmode.prepaid) {
      dataToSend.payment_mode = 'prepaid';
  } else if (paymentmode.cod && paymentmode.prepaid) {
      dataToSend.payment_mode = 'both';
  }

  if(searchfilters.filterStatus.length > 0) {
    dataToSend.status= searchfilters.filterStatus
}
      // console.log("datatoSend",dataToSend)
      let url = config.apiUrl + '/shipment/customer_shipments';
     
     // console.log("headers =========> ", config.headers);
     
      
      axios.post(url, dataToSend, { headers: config.headers })
      // // console.log("headers ====== " , config.headers)
          .then((res) => {
            // console.log("responseJson => ", res);
            
            if( onLoad) {
              setOtherState({...otherStates,total_count:res.data.count , page:index})         

  }
            setState({shipment_list:res.data.output,isLoading:false})
            setisLoading(false)
         
          })
          .catch((error) => {
              //Hide Loader
            //   setLoadingstate({...loadingstate,loading:false})
              // console.log(error);
          });    
        }
        else{
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: "Network Connection Error",
            
          })
        }

    }

    const label_shipments=()=>{
      if(isOnline.online==true){
      let dataToSend = {customer_id : userData.customer_id,shipment_id:selectedShipments
  };
    let url = config.apiUrl + '/shipment/download_label_shipments';
     axios.post(url, dataToSend, { headers: config.headers })
        .then((res) => {
          console.log("responseJson => ", res);
          setlabel({shipment_list:res.data.output,total : res.data.total , company_info:res.data.company_info})
          setisloading(false)
         
          
        })
        .catch((error) => {
        
        });    
      }else{
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: "Network Connection Error",
          
        })
      }

  }
  
  React.useEffect(() => {
    // This effect will run every time labelstate is updated
    if (labelstate.shipment_list.length > 0) {
      onDownloadPDF();
    }
  }, [labelstate]); 
 
  const onDownloadPDF = async() => {
    const content = [];
    for (const sub of labelstate.shipment_list) {
      console.log("sub -- " , sub)
        try {
          const pdfContent = htmlToPdfmake(`
          <table width="100%" border="0" style="border-collapse: collapse; border: 2px solid #000; font-size: 11px; line-height:1;">
          <tr class="" style="margin:0; padding:0px">
            <td colSpan='2' style='margin:0; padding:0px; font-weight:bold;'>
              Ship To:
            </td>
            <td align="right" style='margin:0; padding:0px;'>
            ${moment(new Date(sub.created * 1000)).format("MMM DD YYYY")}
            </td>
          </tr>
          <tr class="">
            <td colspan="3">
              <strong style="font-size:16px;">${sub.receiver[0].name}</strong></br>
               ${(sub.receiver[0].address)}</br>
                ${sub.receiver[0].city},${sub.receiver[0].state} ${sub.receiver[0].pincode} &nbsp;
                Mob: ${sub.receiver[0].mobile}
              
            </td>
          </tr>
          <tr class="">
            <td class="" style="width: 60%;">
             <strong style='font-size:10px;'>Shipment Mode</strong>:</br>
              ${sub.product_type_name?sub.product_type_name:"------"}</br>
              <strong style='font-size:10px;'>Payment Type</strong>:</br>
              ${sub.payment_mode}
            </td>
            <td colspan="2" style="width: 40%;">
            <strong style='font-size:16px;'>${sub.payment_mode=="cod"?`COD Amount: INR ${sub.cod_amount}`:"DO NOT COLLECT CASH"}</strong>
            </td>
          </tr>
          <tr style="border-bottom:0;">
            <td colspan="2" style="border-bottom:0; text-align:right; border-right:0;">
            <strong style='font-size:14px;'>${sub.service_provider?sub.service_provider:sub.carrier_name}</strong> 
            </td>
            <td colspan="0" style="border-bottom:0;  border-left:0;">
            <strong style='font-size:10px;'>ROUTE ----</strong> 
            </td> 
           </tr>
           <tr style="border-top:0;">
           <td colspan="3" style="margin: 0; padding: 0; line-height: 0; font-size: 0; border-top:0;">
           <img src="${sub.barcode_url_carrier}" style="height: 55px; width: 250px; margin-left:8px; display: block; padding: 0;">
           </img>
           </td>
          </tr>
          <tr class="border-bottom">
          <td>
          Product
      </td>
      <td>
          Qty
      </td>
      <td>
          Price
      </td>
  </tr> 
  ${sub.items.map((item)=>(`
    <tr class="border-bottom">
    <td>
    ${item.item_name}
    </td>
    <td colspan="">
    ${item.item_quantity}
    </td>
    <td colspan="">
        ${item.item_value}
    </td>
</tr>`
  ))}
  <tr class="border-bottom">
    <td>
      Total
    </td>
    <td colspan="">
      
    </td>
    <td colspan="">
    ${sub.totalValue}
    </td>
  </tr>
  <tr>
  <td colspan="3" class="" style="margin: 0; padding: 0; line-height: 0; font-size: 0;">
  <img src="${sub.barcode_url}" style="height: 55px; width: 250px; margin-left:8px; display: block; padding: 0;">
</td>
  </tr>
  <tr style="margin: 0px;">
    <td colspan="3" class="" style="margin: 0px;">
    <strong style='font-weight:bold;'> Return Address</strong>: ${(sub.return[0].address)}, ${sub.return[0].city}-${sub.return[0].pincode}, ${sub.return[0].state}, ${sub.return[0].country}
    </td>
  </tr>
</table>

           `);
  
  
          content.push(pdfContent);
          content.push({ text: '', pageBreak: 'after' });
          setlabel({shipment_list:[]})
        } catch (error) {
          console.error(`Error converting HTML to pdfmake format for shipment :`, error);
        }
    };
    content.pop();
   const pdfDoc = {
      content,
      pageSize: { width: 4 * 72, height: 6 * 72 },
      pageOrientation: 'portrait',
     
    };
    pdfMake.createPdf(pdfDoc).download('labels.pdf');
  };
    

    const dimension_detail = () =>{
      let full_api = config.apiUrl + `/user/get_company_info_api`;
      let sendData = {};
    
      axios.post(full_api, sendData, { headers: config.headers }).then(res => {
        setdimensionState({...dimensionState,companyDetail:res.data.companyDetail});
        // console.log("rnmmmmm", res);
      }).catch((e) => {
        // toast.configure()
         //toast.error("Some thing went wrong")
        // console.log("----error:   ", e);
      })
    }
    const handlePageChange = (event,newPage) =>{
      setOtherState({...otherStates,page:newPage})
      // console.log("newPage ", newPage);
      // getCountryList(newPage);
      shipment_list(shipmentstate.shipment_status,newPage,true)
  }
  const handleChangeRowsPerPage = (event , newPage) => {
      // console.log("event ", event.target.value);
      setOtherState({ ...otherStates, rowsPerPage: event.target.value })
     
       
  }
   

    const cancelModal = (ship) =>{
      // console.log("----ship:   ", ship.shipment_id);
      setShipSelect({cancel_reason:"",shipment_id:ship.shipment_id , total_amount:ship.total_amount});
      setModalState({ show: true })
     
    }

    const cancelShipment = () =>{
      setcancelshipment(true)
      if(isOnline.online==true){
      if(shipSelect.cancel_reason != ""){
      let full_api = config.apiUrl + `/shipment/shipment_cancel`;
      let sendData = {customer_id:userData.customer_id,shipment_id:shipSelect.shipment_id,cancel_reason:shipSelect.cancel_reason , total_amount:shipSelect.total_amount};
    
      axios.post(full_api, sendData, { headers: config.headers }).then(res => {
        Toast.fire({
          background: "#3659cd",
          type: 'success',
          title: res.data.message,
          color: "white"
      });
        if(res.data.status == "success"){
          setcancelshipment(false)
                setShipSelect({cancel_reason:"",shipment_id:""});
                setModalState({ show: false })
                shipment_list()
        }
        else{
          setcancelshipment(false)
        }
        // console.log("rnmmmmm", res);
      }).catch((e) => {
        setcancelshipment(false)
        // toast.configure()
         //toast.error("Some thing went wrong")
        // console.log("----error:   ", e);
      })
    }else {
      Toast.fire({
        background: "#a61c09",
        type: 'success',
        title: "Enter Reason to Cancel.",
        color: "white"
    });
    }
  }else{
    Swal.fire({
      icon: 'error',
      title: 'Oops...',
      text: "Network Connection Error",
      
    })
  }
    }

    const inputHandleChange = (e) => {
      const value = e.target.value;
      const text = e.target.name;
      setShipSelect({ ...shipSelect, [text]: value })
    }
    
   const shipping_label =(sub)=>{
    // console.log("sub ==== " , sub)
    if(sub.carrier_id=="1656377059"){
    let config = {
      method: 'get',
      maxBodyLength: Infinity,
      url: 'https://dtdcapi.shipsy.io/api/customer/integration/consignment/shippinglabel/stream?reference_number='+sub.carrier_tracking_number,
      responseType: 'blob',
      headers: { 
        'Content-Type': 'application/pdf', 
        'api-key': 'e96fb203eef06314303c1aa7ef8c0c'
      }
    };
    
    axios.request(config)
    .then((response) => {
      // console.log((response.data));
      download(response.data, 'dtdc.pdf')
      
    })
    .catch((error) => {
      // console.log(error);
    });
  
  }
  else{
    let data = new FormData();
data.append('username', 'SHIPORTTECHNOLOGIES-EXSPLUS915066');
data.append('password', '6e3P9Xwy19');
data.append('awbs', sub.carrier_tracking_number);

let config = {
  method: 'post',
  maxBodyLength: Infinity,
  url: 'https://shipment.ecomexpress.in/services/expp/shipping_label',
  
  data : data
};

axios.request(config)
.then((response) => {
  // console.log("ecom ===== ",JSON.stringify(response.data));
  // download(response.data, 'ecom.pdf')
})
.catch((error) => {
  // console.log(error);
});

  }
   }

   const openPickupModal=(sub)=>{
    // console.log("sub===== " , sub)
    if(isOnline.online==true){
    setModalpickupState({...modalpickupState,show:true})
    }
    else{
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: "Network Connection Error",
        
      })
    }
    // setpickupstate({...pickupstate , awb_number:sub.carrier_tracking_number , service_name:sub.carrier_name , carrier_id:sub.carrier_id , address:sub.sender[0].address , state:sub.sender[0].state , city:sub.sender[0].city , postal_code:sub.sender[0].pincode , country:sub.sender[0].country })
   }
  //  const getNext5Dates = () => {
  //   const today = new Date();
  //   const nextDates = [];

  //   for (let i = 0; i < 5; i++) {
  //     const nextDate = new Date(today);
  //     nextDate.setDate(today.getDate() + i);
  //     nextDate.setHours(today.getHours());
  //     nextDate.setMinutes(today.getMinutes());
  //     nextDates.push(formatDate(nextDate));
  //   }

  //   return nextDates;
  // };
  const getNext5Dates = () => {
    const today = new Date();
    const now = new Date(); // Get the current time
  
    const nextDates = [];
  
    // Check if the current time is before 1:00 PM
    if (now.getHours() < 14) {
      // Show today's date
      nextDates.push(formatDate(today));
    }
  
    // Calculate and show the dates for the next 4 days
    for (let i = 1; i < 5; i++) {
      const nextDate = new Date(today);
      nextDate.setDate(today.getDate() + i);
      nextDate.setHours(today.getHours());
      nextDate.setMinutes(today.getMinutes());
      nextDates.push(formatDate(nextDate));
    }
  
    return nextDates;
  };
  const formatDate = (date) => {
    const options = { day: 'numeric', month: 'long', year: 'numeric'  };
    return date.toLocaleDateString(undefined, options);
  };
  const nextDates = getNext5Dates();
  const handleDateSelect = (date) => {
    setSelectedDateTime(date);
    // You can also close the modal or perform other actions here
  };

  // const get_warehouse = () =>{
  //   let full_api = config.apiUrl + `/shipment/getAllWarehouse`;
  //   let sendData = {};
  
  //   axios.post(full_api, sendData, { headers: config.headers }).then(res => {
  //     if(res.data.status==true){
  //       setwarehouse(res.data.output)
  //     }
  //  console.log("rnmmmmm", res);
  //   }).catch((e) => {
  //     // toast.configure()
  //     //  toast.error("Some thing went wrong")
  //     console.log("----error:   ", e);
  //   })
  // }

   
  const handleSelectAll = (event) => {
    if (event.target.checked) {
      setSelectedShipments(state.shipment_list.map((shipment) => shipment.shipment_id));
      setSelectCount({select_count : state.shipment_list.length})
    } else {
      setSelectedShipments([]);
      setSelectCount({select_count : 0})

    }
  };

  const handleSelectAllmobile = () => {
    const isAllSelected = selectedShipments.length === state.shipment_list.length;
    const selectedIds = isAllSelected ? [] : state.shipment_list.map(order => order.shipment_id);
  
    setSelectedShipments({
      
      selectedIds,
    });
    setSelectCount({select_count : selectedIds.length})

  };
  const handleSelectIndividual = (event, shipmentId) => {
    if (event.target.checked) {
      setSelectedShipments([...selectedShipments, shipmentId]);
      setSelectCount({...selectCount , select_count :selectCount.select_count +1})
    } else {
      setSelectedShipments(selectedShipments.filter((id) => id !== shipmentId));
      setSelectCount({...selectCount , select_count :selectCount.select_count -1})

    }
   
  };

  const address_book_detail = ()=>{
    let url = config.apiUrl + '/country/address_book';
    let sendData = {  customer_id: userData.customer_id };
    // console.log("bb", sendData);
    axios.post(url,sendData ,{headers:config.headers}).then((res)=>{
        if(res.data.status==true){
            // console.log("sasdfdsa")
            setaddressState(res.data.output)
            // setState([])
            // setCityList([])
            // setPincodeList([])
            // setaddState({...addstate, full_name:"" ,  state_name:"" , city_name:"" , pincode:"" , address:"" , email:"" , mobile:"" , warehouse_name:"", err:{} })
            // setOptions([])

             
        }

    }).catch(()=>{
        const Toast = Swal.mixin({
            toast: true,
            position: 'bottom-end',
            showConfirmButton: false,
            timer: 3000
          })
          
          Toast.fire({
            background:"#a61c09",
            type: 'unsuccess',
            title: "Something Went Wrong",
            color:"white"
          });
   
       
    })
   }

   const inputHandleChangemodal=(e)=>{
    setpickupstate({...pickupstate , warehouse_name:e.target.value})
   }
   const pickup_request = ()=>{
   if(isOnline.online==true){
    let url = config.apiUrl + '/shipment/create_pickup_request_api';
    if(pickupstate.warehouse_name!=""){
      if( selectedDateTime!=null ){
      if(selectedTime!=null){
      setloaderpickup(true)
    let sendData = {  customer_id: userData.customer_id , warehouse_name : pickupstate.warehouse_name , date:selectedDateTime , time:selectedTime};
    // console.log("pickup_request === ", sendData);
    axios.post(url,sendData ,{headers:config.headers}).then((res)=>{
        if(res.data.status==true){
          setModalpickupState({ show: false })
          setloaderpickup(false)
          const Toast = Swal.mixin({
            toast: true,
            position: 'bottom-end',
            showConfirmButton: false,
            timer: 3000
          })
          
          Toast.fire({
            background:"#3659cd",
            type: 'success',
            title: res.data.message,
            color:"white"
          });
          shipment_list()
            // // console.log("sasdfdsa")
          
        }
        else{
          setModalpickupState({ show: false })
          setloaderpickup(false)
          const Toast = Swal.mixin({
            toast: true,
            position: 'bottom-end',
            showConfirmButton: false,
            timer: 3000
          })
          
          Toast.fire({
            background:"#a61c09",
            type: 'unsuccess',
            title: res.data.message,
            color:"white"
          });
        }

    }).catch(()=>{
      setModalpickupState({ show: false })
      setloaderpickup(false)
        const Toast = Swal.mixin({
            toast: true,
            position: 'bottom-end',
            showConfirmButton: false,
            timer: 3000
          })
          
          Toast.fire({
            background:"#a61c09",
            type: 'unsuccess',
            title: "Something Went Wrong",
            color:"white"
          });
   
       
    })
      }else{
        const Toast = Swal.mixin({
          toast: true,
          position: 'bottom-end',
          showConfirmButton: false,
          timer: 3000
        })
        
        Toast.fire({
          background:"#a61c09",
          type: 'unsuccess',
          title: "Please Select Time",
          color:"white"
        });
      }
    }else{

    const Toast = Swal.mixin({
      toast: true,
      position: 'bottom-end',
      showConfirmButton: false,
      timer: 3000
    })
    
    Toast.fire({
      background:"#a61c09",
      type: 'unsuccess',
      title: "Please Select Date",
      color:"white"
    });
    }
  }else{
  
    const Toast = Swal.mixin({
      toast: true,
      position: 'bottom-end',
      showConfirmButton: false,
      timer: 3000
    })
    
    Toast.fire({
      background:"#a61c09",
      type: 'unsuccess',
      title: "Please Select All",
      color:"white"
    });
  }
}else{
  Swal.fire({
    icon: 'error',
    title: 'Oops...',
    text: "Network Connection Error",
    
  })
}
   }
   const selectTime=(e)=>{
    setSelectedTime(e.target.value)
   }

   const handlesearchShipment=(e)=>{
    setsearchstate({search_shipment:e.target.value})
   }
   const openOffdetailcanvasHandler = (sub) => {
    setOpenOffdetailcanvas(true);
setbookorder(sub)
shipment_tracking(sub.shipment_id)
shipment_failed(sub.shipment_id)
   
  };
   
  const closeOffdetailcanvasHandler = () => {
    setOpenOffdetailcanvas(false);
    setbookorder([])
    setTrackingState({track:{},isLoading:true})
    setStateship({ shipmentDetail: {sender:{},receiver:{},items:[] }, isLoading: true,detailFound:false,parcel_count:0  })
    setfailed({failed:""})
    // Call your get_list function here if needed
    // get_list(sub);
  };  
 
 const dateselect =(start , end)=>{
  setdate({...date, from_date:moment(start).format('YYYY-MM-DD'), to_date:moment(end).format("YYYY-MM-DD")})
}
// React.useEffect(() => {
// // Initialize date range picker with today's date as the default range
// $('.custom-daterange').daterangepicker({
//     opens: 'right',
//     startDate: moment().subtract(30, 'days').format('YYYY-MM-DD'),  // Set start date to today's date
//     endDate: moment().startOf('day'),    // Set end date to today's date
//     autoUpdateInput: true,
//     locale: {
//         format: 'YYYY-MM-DD'             // Display format
//     }
// }, (start, end) => {
//   dateselect(start, end); 
 
//           // Update selected range when a new range is chosen
// });

// // Set initial value in the input to show today's date
// $('.custom-daterange').val(`${moment().subtract(30, 'days').format('YYYY-MM-DD')} to ${moment().format('YYYY-MM-DD')}`);
// }, []);

React.useEffect(() => {
  // Initialize daterangepicker after the date range is set
  $(".custom-daterange").daterangepicker({
    opens: "right",
    startDate: dateRange.startDate.format("YYYY-MM-DD"),
    endDate: dateRange.endDate.format("YYYY-MM-DD"),
    autoUpdateInput: true,
    locale: {
      format: "YYYY-MM-DD", // Display format
    },
  }, (start, end) => {
    console.log("Selected range:", start.format("YYYY-MM-DD"), "to", end.format("YYYY-MM-DD"));
    dateselect(start, end); 
  });

  // Set initial value in the input
  $(".custom-daterange").val(
    `${dateRange.startDate.format("YYYY-MM-DD")} to ${dateRange.endDate.format("YYYY-MM-DD")}`
  );
}, [dateRange]);

 const modeChange =(e , value)=>{
 if(e.target.checked){
setpaymentmode({...paymentmode , [e.target.name]:true})
 }
 else{
   setpaymentmode({...paymentmode , [e.target.name]:false})
 }

 }

 const handleWarehouseChange = (e, warehouseId) => {
   const isChecked = e.target.checked;
   if (isChecked) {
       setSelectedWarehouses([...selectedWarehouses, warehouseId]);
   } else {
       setSelectedWarehouses(selectedWarehouses.filter(id => id !== warehouseId));
   }
};

const filter=(e,values)=>{
  const name = e.target.name;
  const value = values;
  const isChecked = e.target.checked;

  // Create a new array by spreading the existing filterStatus array
  const updatedFilterStatus = [...searchfilters.filterStatus];

  if (isChecked) {
    updatedFilterStatus.push(value);
  } else {
    // Remove the value from the array if unchecked
    const index = updatedFilterStatus.indexOf(value);
    if (index !== -1) {
      updatedFilterStatus.splice(index, 1);
    }
  }

  // Update the searchfilters state with the updated filterStatus array
  setsearchFilters((prevFilters) => ({
    ...prevFilters,
    filterStatus: updatedFilterStatus,
  }));
  
  const selectedStatus = updatedFilterStatus;
  const statusQueryString = `status=${selectedStatus.join(',')}`;
  
  // Get the existing query parameters
  const url = new URL(window.location);
  const searchParams = new URLSearchParams(url.search);
  
  // Set the new status parameter
  searchParams.set('status', selectedStatus.join(','));
  
  // Update the URL with the modified query parameters
  url.search = searchParams.toString();
  window.history.pushState({}, '', url.toString());
}

const shipment_tracking = (shipment_id) =>{
      
  let full_api = config.apiUrl + `/shipment/shipment_tracking`;
 let sendData = {customer_id:userData.customer_id , trackingNumber:shipment_id};
 // console.log("sendData",sendData)
  
 axios.post(full_api, sendData, { headers: config.headers }).then(res => {
  if(res.data.status==200){
     setTrackingState({...trackingstate,track:res.data.output,isLoading:false})
     // console.log("res",trackingstate.track)
     setStateship({ ...stateship, shipmentDetail: res.data.ship_detail, isLoading:false})
   
  }
  else{
   setTrackingState({...trackingstate,track:res.data.output,isLoading:false})
   // console.log("res",trackingstate.track)
   setStateship({ ...stateship, shipmentDetail: res.data.ship_detail, isLoading:false})
  }
 
  
 }).catch((e) => {
   // toast.configure()
    //toast.error("Some thing went wrong")
   // console.log("----error:   ", e);
 })
}


const shipment_failed = (shipment_id) =>{
      
  let full_api = config.apiUrl + `/shipment/check_failed_shipments`;
 let sendData = {shipment_id:shipment_id};
 // console.log("sendData",sendData)
  
 axios.post(full_api, sendData, { headers: config.headers }).then(res => {
  setfailed({...failedstate , failed:res.data.output})
 
  
 }).catch((e) => {
   // toast.configure()
    //toast.error("Some thing went wrong")
   // console.log("----error:   ", e);
 })
}
const openModal=(sub)=>{
  setndrmodal(true)
  setShipments({shipment:sub})
}
const closemodal =(e)=>{
  setndrmodal(false)
  setType({...typestate,type:5})
  setndrstate({...ndrstate ,ndr_date:"" , ndr_address:"" , ndr_phone:"" , ndr_remarks:"" })
  setShipments({shipment:{}})
}

const handleNDRchange  =(e)=>{
setType({...typestate,type:e.target.value})
}

const handleNDRchnage =(e)=>{
  setndrstate({...ndrstate , [e.target.name]:e.target.value})
}

const ndrReAttempt = () =>{
  if(typestate.type==1 && ndrstate.ndr_date!="" && ndrstate.ndr_phone!="" && ndrstate.remarks!="" || typestate.type==2 && ndrstate.ndr_date!="" && ndrstate.ndr_address!="" && ndrstate.remarks!=""  || typestate.type==0 && ndrstate.ndr_date!="" && ndrstate.remarks!="" || typestate.type==3  && ndrstate.remarks!="" ){
let full_api = config.apiUrl + `/shipment/ndr_re_attempt`;
let sendData = {type:typestate.type ,shipment: stateshipment.shipment , ndrstate};
console.log("sendData",sendData)

axios.post(full_api, sendData, { headers: config.headers }).then(res => {
if(res.data.status == true){
setndrmodal(false)
setType({...typestate,type:5})
setndrstate({...ndrstate ,ndr_date:"" , ndr_address:"" , ndr_phone:"" , ndr_remarks:"" })
setShipments({shipment:{}})
const Toast = Swal.mixin({
 toast: true,
 position: 'bottom-end',
 showConfirmButton: false,
 timer: 3000
})

Toast.fire({
 background:"#3659cd",
 type: 'success',
 title: res.data.message,
 color:"white"
});
}
else{
setndrmodal(false)
setType({...typestate,type:5})
setndrstate({...ndrstate ,ndr_date:"" , ndr_address:"" , ndr_phone:"" , ndr_remarks:"" })
setShipments({shipment:{}})
const Toast = Swal.mixin({
 toast: true,
 position: 'bottom-end',
 showConfirmButton: false,
 timer: 3000
})

Toast.fire({
 background:"#a61c09",
 type: 'unsuccess',
 title: res.data.message,
 color:"white"
});
}


}).catch((e) => {
const Toast = Swal.mixin({
toast: true,
position: 'bottom-end',
showConfirmButton: false,
timer: 3000
})

Toast.fire({
background:"#a61c09",
type: 'unsuccess',
title: "Something Went Wrong ",
color:"white"
});
// toast.configure()
//toast.error("Some thing went wrong")
// console.log("----error:   ", e);
})
}else{
const Toast = Swal.mixin({
toast: true,
position: 'bottom-end',
showConfirmButton: false,
timer: 3000
})

Toast.fire({
background:"#a61c09",
type: 'unsuccess',
title: "Please fill all the details ",
color:"white"
});
}
}

React.useEffect(()=>{
  if (pendingOrder) {
    trackShipmentStatus(pendingOrder);
  }
},[])
const trackShipmentStatus = (orderNumber) => {
  const interval = setInterval(async () => {
    try {
      let sendData = {orderNumber:orderNumber}
      const response = await axios.post(config.apiUrl +`/shipment/check_shipment_status`,sendData,{headers:config.headers}); 
      const status = response.data.status;
      const shipment = response.data.shipment
      if (status === 1 || status === 9 ) {
        // Shipment status is finalized (successful or failed)
        clearInterval(interval);  // Stop polling
        const url = new URL(window.location);
        url.searchParams.delete('pending');  // Remove the 'pending' query parameter
        window.history.replaceState({}, '', url);  // Update the URL without reloading the page
      
        window.location.reload();  
      }
      else if(status === 7){
        clearInterval(interval);
         setcheckstatus({alert:`Order Number ${shipment.order_number} failed and move to Orders Section` , shipment:shipment})
         setState(prevState => ({
          shipment_list: [
            shipment,  // Add the failed shipment to the top
            ...prevState.shipment_list.filter(sub => sub.status !== 0)  // Remove processing shipment
          ]
        }));
      }
    } catch (error) {
      console.error('Error tracking shipment status:', error);
      clearInterval(interval); // Stop polling in case of error
    }
  }, 2000); // Poll every 2 seconds
};

const copyAWBToClipboard = (item) => {
  navigator.clipboard.writeText(item).then(() => {
   
    const Toast = Swal.mixin({
      toast: true,
      position: 'bottom-end',
      showConfirmButton: false,
      timer: 3000
    })
      Toast.fire({
      background:"#3659cd",
      type: 'success',
      title: "AWB number copied to clipboard!",
      color:"white"
    });
  }).catch((err) => {
    console.error("Failed to copy text: ", err);
  });
};
const copyToClipboard = (item) => {
  navigator.clipboard.writeText(item).then(() => {
   
    const Toast = Swal.mixin({
      toast: true,
      position: 'bottom-end',
      showConfirmButton: false,
      timer: 3000
    })
      Toast.fire({
      background:"#3659cd",
      type: 'success',
      title: "Order number copied to clipboard!",
      color:"white"
    });
  }).catch((err) => {
    console.error("Failed to copy text: ", err);
  });
};

const generateManifest=()=>{
  // setisLoading(true)
    let dataToSend = {};

if(selectedShipments.length>0){
// const orders = selectedShipments.split(/[\s,]+/).filter(Boolean); // Filter empty values
const orderString = selectedShipments.join(',');
dataToSend.find_bulk = orderString
}
else{

}
  let url = config.apiUrl + '/shipment/generate_manifest';
    
  axios.post(url, dataToSend, { headers: config.headers })
      .then((res) => {
      if(res.data.status==true){
          // setisLoading(false)
          window.location.replace('/manifest_list')
      } 
      else{
          const Toast = Swal.mixin({
              toast: true,
              position: 'bottom-end',
              showConfirmButton: false,
              timer: 3000
              })
              
              Toast.fire({
              background:"#a61c09",
              type: 'unsuccess',
              title: "Something Went Wrong ",
              color:"white"
              });
          // setisLoading(false)

      }
        })
      .catch((error) => {
          const Toast = Swal.mixin({
              toast: true,
              position: 'bottom-end',
              showConfirmButton: false,
              timer: 3000
              })
              
              Toast.fire({
              background:"#a61c09",
              type: 'unsuccess',
              title: "Something Went Wrong ",
              color:"white"
              });
          // setisLoading(false)

      });    

}
  return (
    <div>
       <Header/>
       <Modal show={modalState.show} onHide={() => setModalState({ show: false })}>
 <Modal.Header closeButton>
                     <Modal.Title>Do you really want to Cancel ?</Modal.Title>
                 </Modal.Header>
                 <Modal.Body >
                 <div className="row">
                       <div className="col-2 mb-3 mt-5" style={{marginLeft:"25px"}}>
                       <i class="fa fa-envelope " style={{fontSize:"20px",marginTop:"10px"}} aria-hidden="true"></i>
                       </div>
                         <div className="col-md-10 mb-3 mt-5 " style={{marginLeft:"-50px"}}>
                               <input className="form-control" id="first_name" type="text" placeholder="Enter Reason" name="cancel_reason" onChange={(e) => inputHandleChange(e)} required   style={{ background: "transparent", border: "none", borderBottom: "1px solid #000000",  outline:"none", boxShadow:"none"}}/>
                          </div>
                         
                        
                        <div>
                       
                        {cancelshipment==false?
                     <button className="btn mt-3" style={{color: "#fff", backgroundColor: "#D65142", display:"inline-block",textAlign:"center",verticalAlign:"middle",position:"relative",boxSizing:"border-box",marginLeft:"190px",padding: "0.84rem 2.14rem",
     fontSize: "18px",fontWeight:"bold"}} onClick={() =>
       cancelShipment()
                         }>Yes</button>
                         :""}
                     </div>
                     </div>
                 </Modal.Body>
                 <Modal.Footer>
               
                   <Button variant="secondary" onClick={() => setModalState({ show: false })}>
                         Close
                     </Button>
                                               </Modal.Footer>
            </Modal>

                         <Modal show={modalpickupState.show} onHide={() => setModalpickupState({ show: false })} size='lg'>
 <Modal.Header closeButton>
                     <Modal.Title> <h4 class="modal-title">Schedule Your Pickup</h4></Modal.Title>
                 </Modal.Header>
                 <Modal.Body >
              
       <div class="modal-body">
         <div class="row booking_info_bx">
          
             <div class="col-12 col-md-12 mb-3">
               <div class="card bg-light border-0 shadow-sm">
                 <div class="card-body">
                   <div class="d-flex">
                       <div class="p-2 text-primary">
                         <i class="bi bi-truck"></i>
                       </div>
                       <div class="ps-4 ">
                         <h6>Pickup Address</h6>
                         <select class="form-select" name="warehouse_name" onChange={(e)=>inputHandleChangemodal(e)}>
                           <option>Select</option>
                           {addressState.map((sub)=>{
                             return(
                               <option value={sub.address_id}>{sub.warehouse_id} - {sub.address}</option>
                            )
                        
                             })}
                        
                         </select>
                       </div>
                   </div>
                 </div>
               </div>
             </div>
             <div class="col-12 col-md-12 mb-3">
               <div class="card bg-light border-0 shadow-sm">
                 <div class="card-body">
                   <div class="d-flex">
                       <div class="p-2 text-primary">
                         <i class="bi bi-calendar"></i>
                       </div>
                       <div class="ps-4 shcelu_pil">
                         <h6>Schedule Your Pickup</h6>
                         <small>Please select a suitable date and time for the pickup.</small>
                         <div class=" mt-3">
                         <div className="d-flex mt-3">
  
   {nextDates.map((date, index) => (
                           <>
                           <a   class="me-2 btn-time mb-2 text-decoration-none"  style={{
              color: selectedDateTime === date ? "dark" : "black",
              borderColor: selectedDateTime === date ? "red" : "initial",
            }} key={index} onClick={() => handleDateSelect(date)}>{(date)}
                            <select name="time" onChange={(e)=>selectTime(e)}>
                            <option value="9:00">9:00</option>
                            <option value="10:00">10:00</option>
                             <option value="11:00">11:00</option>
                             <option value="12:00">12:00</option>
                             <option value="13:00">13:00</option>
                             <option value="14:00">14:00</option>
                             <option value="15:00">15:00</option>
                             <option value="16:00">16:00</option>
                             <option value="17:00">17:00</option>
                             <option value="18:00">18:00</option>
                           </select>
                           </a>
                     </>
           ))}
  </div>
                         {selectedDateTime && <p>Selected Date: {(selectedDateTime)} {selectedTime}</p>}      
                         </div>
                       </div>
                   </div>
                 </div>
               </div>
             </div>
         </div>
       </div>
       <div class="modal-footer text-start">
       {loaderpickup==true? <ReactLoading type={"spin"} color={"#0d6efd"} height={40} width={60} />:  <button type="button" class="btn btn-primary" data-bs-dismiss="modal" onClick={()=>pickup_request()}>Schedule Pickup</button>}
         <button type="button" class="btn btn-outline-danger" data-bs-dismiss="modal" onClick={()=>setModalpickupState({show:false})}>I'll Do this later</button>
       </div>
    
  
                 </Modal.Body>
               
             </Modal>
 <div class="page-wrapper pinned">
  <div class="main-container">
   <div className='app-container'>
  <div class="app-body">
  <div class="container-fluid"> 


<div class="mob_nav">
  <div class="row">
      <div class="col-6">
        <div class=" d-flex justify-content-start ">
          <a href="javascript:window.history.back();" class=""><i class="bi bi-arrow-left"></i></a>
          <h2>Shipments</h2>
        </div>
      </div>
      <div class="col text-end">
      <a onClick={()=>openPickupModal()} class="btn btn-primary btn-block">Schedule Pickups</a>
      </div>
  </div>
</div>


<div class="row justify-content-between desk_nav">
    <div class="col-12 col-md-4 ">
      <div class="d-flex">
        <a href="javascript:window.history.back();" class="back_btn"><i class="bi bi-arrow-90deg-left"></i></a>
        <h2>Shipments</h2>
      </div>
    </div>
    <div class="col-12 col-md-7 mb-3">
      <div class="row justify-content-end">
      {selectCount.select_count==0?
    "":
    <div class="col-6 col-md-3">
            <div class="btn-group">
              <button type="button" class="btn btn-outline-secondary" onClick={(e)=>generateManifest()}><i class="bi bi-stack"></i>Generate Manifest List</button>
              {/* <div class="btn-group" role="group">
                <button type="button" class="btn btn-outline-secondary dropdown-toggle" data-bs-toggle="dropdown"></button>
                <ul class="dropdown-menu dropdown-menu-end">
                  <li><a class="dropdown-item" href="/manifest_add"><i class="bi bi-file-text"></i> Generate Custom Manifest</a></li>
                </ul>
              </div> */}
            </div>
          </div>
          }
          <div class="col-6 col-md-4">
            <div class="btn-group">
              <button type="button" class="btn btn-outline-secondary" onClick={()=>openPickupModal()}><i class="bi bi-clock"></i> Schedule Pickups</button>
              <div class="btn-group" role="group">
                <button type="button" class="btn btn-outline-secondary dropdown-toggle" data-bs-toggle="dropdown"></button>
                <ul class="dropdown-menu dropdown-menu-end">
                  <li><a class="dropdown-item" href="/pickup_request"><i class="bi bi-file-text"></i> Pickup List</a></li> 
                </ul>
              </div>
            </div>
          </div>
          <div class="col-6 col-md-2 text-end">
            <div class="btn-group">
              <button type="button" class="btn btn-outline-primary">Actions</button>
              <div class="btn-group" role="group">
                <button type="button" class="btn btn-outline-primary dropdown-toggle" data-bs-toggle="dropdown"></button>
                <ul class="dropdown-menu dropdown-menu-end">
                  <li><a class="dropdown-item" href="/bulk_lb_print"><i class="bi bi-file-text"></i> Bulk Label Print</a></li> 
                </ul>
              </div>
            </div>
          </div>
      </div>
    </div>
</div>



<div class="card mb-3">
  <div class="card-body">
    <div class="row justify-content-between">
        <div class="col-12 col-md-6 mb-1">
          <div class="row g-1">
              <div class="col-12 col-md-6">
                <div class="input-group">
                  <input type="text" class="form-control custom-daterange" />
                  <span class="input-group-text">
                    <i class="bi bi-search" onClick={(e)=>shipment_list(null,0,true)}></i>
                  </span>
                </div>
              </div>
              <div class="col-12 col-md-6">
                <div class="search-box">
                    <div class="search-icon">
                    <i class="bi bi-search" onClick={(e)=>shipment_list(null,0,true)}></i>
                    </div>
                    <input type="text" class="search-input" placeholder="Enter Tracking Number" onChange={(e)=>handlesearchShipment(e)} value={searchstate.search_shipment}/>
                </div>
              </div>
          </div>
        </div>
        <div class="col-12 col-md-6 mb-1">
          <div class="row justify-content-between">
          {selectCount.select_count==0?
           ""
            : 
              <div class="col-6 col-md-6">
                <div class="dropdown">
                  <button type="button" class="btn btn-outline-secondary dropdown-toggle" data-bs-toggle="dropdown">
                    Bulk Actions
                  </button>
                  <ul class="dropdown-menu">
                    <li><a class="dropdown-item" href={`/print_label/${selectedShipments.join(',')}`} target="_blank">Print Labels</a></li>
                    <li><a class="dropdown-item" onClick={()=>label_shipments()} style={{cursor:"pointer"}}>Download Labels</a></li>
                    {/* <li><a class="dropdown-item" href="#">Manifest Shipments</a></li>
                    <li><a class="dropdown-item" href="#">Print Invoices</a></li>
                    <li><a class="dropdown-item text-danger" href="#">Cancel Shipments</a></li> */}
                  </ul>
                </div>
              </div>
}
              <div class="col-6 col-md-6 text-end">
                <a onClick={()=>setOpenOffcanvasfilter(true)} class="btn btn-outline-secondary btn-block"  ><i class="bi bi-filter"></i> Filter</a>
              </div>
              {openOffcanvasfilter && (
              <section>
              <div class="offcanvas offcanvas-end show" id="Shipment_Filter">
  <div class="offcanvas-header">
    <h2 class="offcanvas-title">Shipment Filters</h2>
    <button type="button" class="btn-close text-reset" onClick={()=>setOpenOffcanvasfilter(false)}></button>
  </div>
  <div class="offcanvas-body">
    <div class="card mb-3">
      <div class="card-body">
        <h6>Shipment Status</h6>
        <hr/>
        <div class="form-check form-switch  mb-2">
        <input class="form-check-input" type="checkbox" id="check1" name="pickup_awaited" value="something"  onChange={(e)=>filter(e,1)}/>
        <label class="form-check-label">Pickup Awaited</label>
        </div>
        <div class="form-check form-switch  mb-2">
        <input class="form-check-input" type="checkbox" id="check1" name="pickup_scheduled" value="something" onChange={(e)=>filter(e,9)}/>
        <label class="form-check-label">Pickup Scheduled</label>
        </div>
        <div class="form-check form-switch  mb-2">
        <input class="form-check-input" type="checkbox" id="check1" name="picked_up" value="something"  onChange={(e)=>filter(e,2)}/>
        <label class="form-check-label">Picked Up</label>
        </div>

        <div class="form-check form-switch  mb-2">
        <input class="form-check-input" type="checkbox" id="check1" name="in_transit" value="something"  onChange={(e)=>filter(e,3)}/>
                               <label class="form-check-label">In Transit</label>
        </div>

        <div class="form-check form-switch  mb-2">
        <input class="form-check-input" type="checkbox" id="check1" name="out_for_delivery" value="something"  onChange={(e)=>filter(e,4)}/>
        <label class="form-check-label">Out for Delivery</label>
        </div>

        <div class="form-check form-switch  mb-2">
        <input class="form-check-input" type="checkbox" id="check1" name="delivered" value="something"  onChange={(e)=>filter(e,5)}/>
        <label class="form-check-label">Delivered</label>
        </div>

        <div class="form-check form-switch  mb-2">
        <input class="form-check-input" type="checkbox" id="check1" name="cancelled" value="something"  onChange={(e)=>filter(e,6)}/>
        <label class="form-check-label">Cancelled</label>
        </div>
      </div>
    </div>
    <div class="card mb-3">
      <div class="card-body">
        <h6>Payment Mode</h6>
        <hr/>
        <div class="form-check form-switch  mb-2">
        <input class="form-check-input" type="checkbox" id="inlineCheckbox1" value="option1" name="prepaid" onChange={(e)=>modeChange(e)}  checked={paymentmode.prepaid}/>
           <label class="form-check-label" for="flexSwitchCheckChecked">Prepaid</label>
        </div>
        <div class="form-check form-switch  mb-2">
        <input class="form-check-input" type="checkbox" id="inlineCheckbox1" value="option1" name="cod" onChange={(e)=>modeChange(e)} checked={paymentmode.cod}/>
           <label class="form-check-label" for="flexSwitchCheckChecked">COD (Cash On Delivery)</label>
        </div>
      </div>
    </div>
    <div class="card mb-3">
      <div class="card-body">
        <h6>Warehouse Pickup Locations</h6>
        <hr/>
        {addressState.map((sub) => (
        <div class="form-check form-switch  mb-2">
          <input 
                    class="form-check-input" 
                    type="checkbox" 
                    id={`warehouseCheckbox${sub.address_id}`} 
                    value={sub.address_id} 
                    onChange={(e) => handleWarehouseChange(e, sub.address_id)} 
                    checked={selectedWarehouses.includes(sub.address_id)}
                />
           <label class="form-check-label" for={`warehouseCheckbox${sub.warehouse_id}`}>
           {sub.warehouse_id}</label>
        </div>
        ))}
       
      </div>
    </div>
    {/* <div class="d-grid">
      <a href="" class="btn btn-primary btn-block">Update Filter</a>
    </div> */}
  </div>
</div>

<div className='offcanvas-backdrop fade show'></div>

              </section>
              )}
          </div>
        </div>
    </div>
  </div>
</div>

<div class="card data_table mb-3 mb_display_none">
{isLoading? <div style={{ display: 'flex', justifyContent: 'center', marginLeft:"60px", alignItems: 'center',marginTop:"-200px", height: '100vh' }}>
      <ReactLoading type={"spin"} color={"#0d6efd"} height={40} width={60} />
    </div>:
  <div class="card-body">
    <div class="table-responsive" >
      <div class="shipm_tablew rounded-3">
        <table class="table align-top table-striped table-hover m-0 table-xl nowrap">
          <thead>
            <tr>
              <th><input class="form-check-input form-check-input-lg" type="checkbox" onChange={handleSelectAll}/></th>
              <th width="250px">Shipment Details</th>
              <th width="200px">Shipping Carrier</th>
              <th>Shipping Fee</th>
              <th width="200px">Status</th>
              <th>Customer Details</th>
              <th>Value</th>
              <th>Sender Address</th>
              <th>Action</th>
              {showComponent==true? <th>Package Details</th>:<th>Reason</th>}
              {/* <th>Channel</th> */}
              <th>Product Details</th>
            </tr>				
          </thead>
          <tbody>
          {state.shipment_list.map((sub)=>(
                                  <>
            <tr class={sub.status==0 ?"disabled table-info": sub.status==7?"disabled_falied table-danger":""}>
              <td><input class="form-check-input form-check-input-lg" type="checkbox" onChange={(event) => handleSelectIndividual(event, sub.shipment_id)}
               checked={selectedShipments.includes(sub.shipment_id)}/></td>
              <td>
                <h6><a onClick={()=>openOffdetailcanvasHandler(sub)} style={{cursor:"pointer"}} class="text-primary" >AWB {sub.carrier_tracking_number!=undefined && sub.carrier_tracking_number!=""?sub.carrier_tracking_number:""}</a> <a onClick={(e)=>copyAWBToClipboard(sub.carrier_tracking_number)} data-bs-toggle="tooltip" title="Copy AWB Number"><i class="bi bi-c-square"></i></a> </h6>
                <div><small>Order ID: {sub.order_number} <a onClick={(e)=>copyToClipboard(sub.order_number)} data-bs-toggle="tooltip" title="Copy Order Number"><i class="bi bi-c-square"></i></a></small></div>
                <div><small><Moment format="MMM DD YYYY">
                        {new Date(sub.created*1000)}
            </Moment> - <Moment format="hh:mm:ss a">
                        {new Date(sub.created*1000)}
            </Moment></small></div>
                {/* <div><small><span class="badge border border-primary text-primary" data-bs-toggle="tooltip" title="Last: 23 jun 2034 04:49pm, 23 jun 2034 05:12pm">Label Printed</span></small> <small><span class="badge border border-info text-info" data-bs-toggle="tooltip" title="Last: 23 jun 2034 04:49pm, 23 jun 2034 05:12pm">Invoice Printed</span></small> <small><span class="badge border border-secondary text-secondary"  data-bs-toggle="tooltip" title="Manifested ID #345678908">Manifested</span></small></div> */}
              </td>
              <td class="shipment_carrier">
              <h6>{sub.service_provider}</h6>
                {/* <img src="assets/images/carriers_logo/dhl.svg" class="img-fluid" /> */}
              </td>
              <td>
              ₹ {sub.total_amount}
              </td>
              <td>
              
                {sub.status==0?<span class="status under_process"><img src="assets/images/spinner.gif" class="img-fluid" /> Under Processing</span>:sub.status==10? <span class="status rtrn"> <i class="bi bi-arrow-clockwise"></i> RTO</span>:sub.status==1?<span class="status pickup_awit"> <i class="bi bi-clock"></i> Pickup Awaited</span>:sub.status==2?  <span class="status pickedup"> <i class="bi bi-building-fill-up"></i> Pickedup</span>:sub.status==3?  <span class="status in_transit"> <i class="bi bi-truck"></i> In Transit</span>:sub.status==4? <span class="status out_f_deli"> <i class="bi bi-rocket-takeoff"></i> Out for Delivery</span>:sub.status==5? <span class="status delivered"> <i class="bi bi-check2-all"></i> Delivered</span>:sub.status==6? <span class="status cancelled"> <i class="bi bi-x-square"></i> Cancelled</span>:sub.status==7? <span class="status failed"> <i class="bi bi-x-octagon"></i> Failed</span>:sub.status==8?<span class="status failed"> <i class="bi bi-x-octagon"></i> NDR</span>:sub.status==15?<span class="status failed"> <i class="bi bi-x-octagon"></i> Booked</span>:sub.status==16?<span class="status delivered"> <i class="bi bi-check2-all"></i>RTO Delivered</span>:sub.status==17?<span class="status failed"> <i class="bi bi-x-octagon"></i>Pickup Failed</span>:sub.status==9?<>  <span class="status pickup_sechdls"> <i class="bi bi-calendar"></i> Pickup Scheduled</span><br/><br/>
                  {sub.pickup_date!=undefined && sub.pickup_date!=""?  <small>on {sub.pickup_date!=undefined?sub.pickup_date:""} at {sub.pickup_time!=undefined?sub.pickup_time:""}</small>:""} </>:<span class="badge rounded-pill bg-danger fw-400">Not Available</span>}
                {sub.status==7 && (
     <div>
     <strong class="text-danger">Shipment was not created due to some error. <br/>The order has been moved to Order section.</strong>
   </div>
    )}
              </td>
              {sub.receiver.map((item)=>(
              <td>
                {item.name}
                <div><small>{item.city} - {item.pincode}</small></div>
                <div><small>{item.mobile}</small></div>
              </td>
               ))}
              <td>
                <div><span class="badge rounded-pill bg-warning">{sub.payment_mode}</span></div>
              </td>
              {sub.sender.map((item)=>(
              <td>
                {item.name}
                <div><small>{item.city} - {item.pincode}</small></div>
                <div><small>{item.mobile}</small></div>
              </td>
               ))}
              <td>
              {showComponent==true?
                <div class="btn-group">
                  <a type="button" class="btn btn-primary" href={"/tracking/"+ sub.shipment_id} >Track Shipment</a>
                  <div class="btn-group">
                    <button type="button" class="btn btn-primary dropdown-toggle" data-bs-toggle="dropdown"></button>
                    <div class="dropdown-menu">
                      {/* <a class="dropdown-item" href="#">Download Label</a> */}
                      
                      {sub.label_status==0?   <a class="dropdown-item" href={"/print_label/"+sub.shipment_id}  target="_blank">Print Label</a>:sub.status==7?"":sub.shipping_label!=undefined && sub.shipping_label!=""? <a class="dropdown-item" href={sub.shipping_label} target="_blank" ></a>:<a  class="dropdown-item" onClick={()=>{shipping_label(sub)}} title="Print Label" ></a>}
                      {(sub.status==0 || sub.status==1 || sub.status==9)?   <a class="dropdown-item text-danger" onClick={()=>cancelModal(sub)}>Cancel Shipment</a>
                      :""} 
                    </div>
                  </div>
                </div>
                :
                sub.carrier_id=="1343170006" ||
                sub.carrier_id == "1343170996"?
                <div class="btn-group">
                <a type="button" class="btn btn-primary" onClick={(e)=>openModal(sub)}  >NDR Action</a>
               
              </div>:""}
              </td>
              {showComponent==true?
              <td>
                Dead wt. : {sub.total_weight} kg
                <div><small>{sub.height} x {sub.width} x {sub.length} (cm)</small></div>
                Volumetric wt.: {sub.volumetric_weight} Kg  
              </td>
              : <td>
              {sub.ndr_reason.length>0?
              sub.ndr_reason.map((ndr)=>(
               <p>{ndr.reason} </p>
              )):""}</td>}
              {/* <td>
              <a href="#" data-bs-toggle="tooltip" title="Direct Sales">Direct Sales</a>
              </td> */}
              <td>{sub.carrier_type === "B2C"
    ? sub.items[0].item_name.substring(0, 20) + '...' + (sub.items.length > 1 ? " +1 items" : "")
    : ""}
              </td>
            </tr>
            </>))}

          
          </tbody>
        </table>
      </div>
    </div>
  </div>
}
</div>
<Modal show={ndrmodal} onHide={() => closemodal()} size='lg'>
                  <Modal.Header closeButton>
                                     <Modal.Title> <h4 class="modal-title">NDR Submit Form</h4></Modal.Title>
                                  </Modal.Header>
                                  <Modal.Body >
                               
                        <div class="modal-body">
                          <div class="row booking_info_bx">
                           
                            <div className='row'>
                              <div className='col-12'>
                               <select className='form-select' onChange={(e)=>handleNDRchange(e)}>
                                <option>Choose Action</option>
                                <option value="0">Re-Attempt</option>
                                {/* <option value="0">RTO</option> */}
                                <option value="2">Update Address</option>
                                <option value="1">Update Phone</option>
                               </select>
                              </div>

                              <div className='row mt-2'>
                                {typestate.type==1?<>
                                 <div className='col-6 '>
                                 <input className='form-control' placeholder='Enter phone' type='date' name="ndr_date" onChange={(e)=>handleNDRchnage(e)}></input>
                               </div>
                                <div className='col-6 '>
                                  <input className='form-control' placeholder='Enter phone' type='number' name="ndr_phone" onChange={(e)=>handleNDRchnage(e)}></input>
                                </div>
                                <div className='col-12 m-2'>
                                 <input className='form-control' placeholder='Enter Remarks' type='text' name="ndr_remarks" onChange={(e)=>handleNDRchnage(e)}></input>
                               </div>
                                </>
                                :typestate.type==2?<>
                                <div className='col-6 '>
                                <input className='form-control' placeholder='Enter phone' type='date' name="ndr_date" onChange={(e)=>handleNDRchnage(e)}></input>
                              </div>
                               <div className='col-6 '>
                                 <input className='form-control' placeholder='Enter address' type='text' name="ndr_address" onChange={(e)=>handleNDRchnage(e)}></input>
                               </div>
                               <div className='col-12 mt-2'>
                                 <input className='form-control' placeholder='Enter Remarks' type='text' name="ndr_remarks" onChange={(e)=>handleNDRchnage(e)}></input>
                               </div>
                               </>

                               :typestate.type==0?<>
                               <div className='col-12 '>
                               <input className='form-control' placeholder='Enter phone' type='date' name="ndr_date" onChange={(e)=>handleNDRchnage(e)}></input>
                             </div>
                              {/* <div className='col-6 '>
                                <input className='form-control' placeholder='Enter address' type='text'></input>
                              </div> */}
                              <div className='col-12 mt-2'>
                                <input className='form-control' placeholder='Enter Remarks' type='text' name="ndr_remarks" onChange={(e)=>handleNDRchnage(e)}></input>
                              </div>
                              </>
                              :  typestate.type==3?<>
                              <div className='col-12 mt-2'>
                                <input className='form-control' placeholder='Enter Remarks' type='text' name="ndr_remarks" onChange={(e)=>handleNDRchnage(e)}></input>
                              </div>
                              </>
                              :
                               ""}
                              </div>



                            </div>
                            
                         </div>
                       </div>
                       <div class="modal-footer text-start">
                         <button type="button" class="btn btn-primary"  onClick={()=>ndrReAttempt()}>Submit</button>
                         <button type="button" class="btn btn-outline-danger"  onClick={()=>closemodal()}>I'll Do this later</button>
                       </div>
                     
                   
                                 </Modal.Body>
                                
                             </Modal>
            

{/* <!-- Mobile View Order Data --> */}
<section class="desk_display_none">
<div>
  <button type="button" class="btn btn-outline-secondary" onClick={handleSelectAllmobile} >
    <i class="bi bi-check2-square"></i>  Select All
  </button>
</div>
{state.shipment_list.map((sub)=>(
  <div class="card  mb-3 shipm_tablew">
    <div class="card-body">
      <div class="row">
          <div class="col mb-2">
            <div class="row">
                <div class="col-2">
                  <input class="form-check-input form-check-input-lg" type="checkbox" onChange={(event) => handleSelectIndividual(event, sub.shipment_id)}
               checked={selectedShipments.includes(sub.shipment_id)}/>
                </div>
                <div class="col-10">
                  <h4>AWB {sub.carrier_tracking_number}</h4>
                </div>
            </div>
          </div>
          <div class="col-2 mb-2 text-end">
            <a href="#" class="btn btn-outline-secondary btn-block btn-sm"  data-bs-toggle="collapse" data-bs-target="#shipment_full"><i class="bi bi-caret-down"></i></a>
          </div>
          <div class="col-12 mb-2 text-center">
          {sub.status==0?<span class="status under_process"><img src="assets/images/spinner.gif" class="img-fluid" /> Under Processing</span>:sub.status==10? <span class="status rtrn"> <i class="bi bi-arrow-clockwise"></i> RTO</span>:sub.status==1?<span class="status pickup_awit"> <i class="bi bi-clock"></i> Pickup Awaited</span>:sub.status==2?  <span class="status pickedup"> <i class="bi bi-building-fill-up"></i> Pickedup</span>:sub.status==3?  <span class="status in_transit"> <i class="bi bi-truck"></i> In Transit</span>:sub.status==4? <span class="status out_f_deli"> <i class="bi bi-rocket-takeoff"></i> Out for Delivery</span>:sub.status==5? <span class="status delivered"> <i class="bi bi-check2-all"></i> Delivered</span>:sub.status==6? <span class="status cancelled"> <i class="bi bi-x-square"></i> Cancelled</span>:sub.status==7? <span class="status failed"> <i class="bi bi-x-octagon"></i> Failed</span>:sub.status==8?<span class="status failed"> <i class="bi bi-x-octagon"></i> NDR</span>:sub.status==15?<span class="status failed"> <i class="bi bi-x-octagon"></i> Booked</span>:sub.status==16?<span class="status delivered"> <i class="bi bi-check2-all"></i>RTO Delivered</span>:sub.status==17?<span class="status failed"> <i class="bi bi-x-octagon"></i>Pickup Failed</span>:sub.status==9?<>  <span class="status pickup_sechdls"> <i class="bi bi-calendar"></i> Pickup Scheduled</span><br/><br/>
                  {sub.pickup_date!=undefined && sub.pickup_date!=""?  <small>on {sub.pickup_date!=undefined?sub.pickup_date:""} at {sub.pickup_time!=undefined?sub.pickup_time:""}</small>:""} </>:<span class="badge rounded-pill bg-danger fw-400">Not Available</span>}
                {sub.status==7 && (
     <div>
     <strong class="text-danger">Shipment was not created due to some error. <br/>The order has been moved to Order section.</strong>
   </div>
    )}
          </div>
          <div class="row">
              <div class="col-6">Order {sub.order_number}</div>
              <div class="col-6 text-end"><Moment format="MMM DD YYYY">
                         {new Date(sub.created*1000)}
             </Moment> - <Moment format="hh:mm:ss a">
                         {new Date(sub.created*1000)}
             </Moment></div>
          </div>

          <div class="collapse" id="shipment_full">
            <hr/>
            <div class="row">
              <div class="col-9 mb-2">
                <h5>Shipping Carrier:</h5>
                <h6>{sub.service_provider?sub.service_provider:sub.carrier_name}</h6>
              </div>
              <div class="col-3 mb-2 shipment_carrier text-end">
                {/* <img src="assets/images/carriers_logo/dhl.svg" class="img-fluid"/> */}
              </div>
              <div class="col-12 mb-2">
              <h5>Customer Details:</h5>
              {sub.receiver.map((item)=>(
                       <>
                     
                       {item.name}, {item.city} - {item.pincode} <br/>Mob: {item.mobile}</>
                
              ))}
              </div>
              <div class="col-6 mb-2">
                <h5>Pickup Address</h5>
                {sub.sender.map((item)=>(
                       <>
                    
                       {item.name}, {item.city} - {item.pincode} <br/>Mob: {item.mobile}</>
                
              ))}
              </div>
              <div class="col-6 mb-2">
                <h5>Shipping Fee:</h5>
                ₹ {sub.total_amount}
              </div>
              <div class="col-6 mb-2">
                <h5>Product Details</h5>
                {sub.carrier_type === "B2C"
    ? sub.items[0].item_name.substring(0, 20) + '...' + (sub.items.length > 1 ? " +1 items" : "")
    : ""}
              </div>
              <div class="col-6 mb-2">
                <h5>Package Details</h5>
                  Dead wt. :  {sub.total_weight}  kg
                  <div><small>{sub.height} x {sub.width} x {sub.length} (cm)</small></div>
                  Volumetric wt.: {sub.volumetric_weight} Kg  
              </div>
              {/* <div class="col-6 mb-2">
                <h5>Channel</h5>
                <a href="" data-bs-toggle="tooltip" data-bs-original-title="Direct Sales">Direct Sales</a>
              </div> */}
              <div class="col-6 mb-2">
               
               
                <span class="badge rounded-pill bg-warning">{sub.payment_mode}</span>
              </div>
              <div class="col-12 mb-2 text-end">
                <div class="d-grid">
                  <a onClick={()=>openOffdetailcanvasHandler(sub)} style={{cursor:"pointer"}} class="btn btn-outline-secondary btn-block">View Shipment</a>
                </div>
              </div>
            </div>
          </div>
      </div>
    </div>
  </div>
))}
</section>

{openOffdetailcanvas && (
  <section class="order_data">
    <div class="offcanvas offcanvas-end show" id="Shipment_data">
    <div class="offcanvas-header shadow-sm">
        <h4 class="offcanvas-title">Shipment #{bookorder.shipment_id}</h4>
        <div class="shipm_tablew">
        {bookorder.status==0?<span class="status under_process"><img src="assets/images/spinner.gif" class="img-fluid" /> Under Processing</span>:bookorder.status==10? <span class="status rtrn"> <i class="bi bi-arrow-clockwise"></i> RTO</span>:bookorder.status==1?<span class="status pickup_awit"> <i class="bi bi-clock"></i> Pickup Awaited</span>:bookorder.status==2?  <span class="status pickedup"> <i class="bi bi-building-fill-up"></i> Pickedup</span>:bookorder.status==3?  <span class="status in_transit"> <i class="bi bi-truck"></i> In Transit</span>:bookorder.status==4? <span class="status out_f_deli"> <i class="bi bi-rocket-takeoff"></i> Out for Delivery</span>:bookorder.status==5? <span class="status delivered"> <i class="bi bi-check2-all"></i> Delivered</span>:bookorder.status==6? <span class="status cancelled"> <i class="bi bi-x-square"></i> Cancelled</span>:bookorder.status==7? <span class="status failed"> <i class="bi bi-x-octagon"></i> Failed</span>:bookorder.status==8?<span class="status failed"> <i class="bi bi-x-octagon"></i> NDR</span>:bookorder.status==15?<span class="status failed"> <i class="bi bi-x-octagon"></i> Booked</span>:bookorder.status==16?<span class="status delivered"> <i class="bi bi-check2-all"></i>RTO Delivered</span>:bookorder.status==17?<span class="status failed"> <i class="bi bi-x-octagon"></i>Pickup Failed</span>:bookorder.status==9?<>  <span class="status pickup_sechdls"> <i class="bi bi-calendar"></i> Pickup Scheduled</span><br/><br/>
          {bookorder.pickup_date!=undefined && bookorder.pickup_date!=""?  <small>on {bookorder.pickup_date!=undefined?bookorder.pickup_date:""} at {bookorder.pickup_time!=undefined?bookorder.pickup_time:""}</small>:""} </>:<span class="badge rounded-pill bg-danger fw-400">Not Available</span>}
        </div>
        <div class="dropdown text-end">
            <button type="button" class="btn btn-outline-primary btn-block dropdown-toggle" data-bs-toggle="dropdown">
            Actions 
            </button>
            <ul class="dropdown-menu">
            {bookorder.carrier_tracking_number?
                                        
            
                <li>
                  <a href={`/create_ticket?order_number=${bookorder.carrier_tracking_number}`} class="dropdown-item"> Raise Ticket</a>
                </li>
                :""}
            </ul>
        </div>
        <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" onClick={(e)=>closeOffdetailcanvasHandler(e)}></button>
    </div>
    {failedstate.failed!=""?   
            <div class="alert alert-danger text-center" role="alert">
              Shipment Failed Due to "{failedstate.failed}"
              </div>:""}
    <div class="offcanvas-body">
    <section>
            


            <div class="">
                <div class="row mt-3 order_box_cont">
                    <div class="col-12 col-md-8 ">
                        
        
                       
        
                        <div class="card border-0 mb-3">
                            <div class="card-body">
                                <div class="d-flex ">
                                    <div class="border-end pe-3">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" data-lucide="truck" class="lucide lucide-truck"><path d="M5 18H3c-.6 0-1-.4-1-1V7c0-.6.4-1 1-1h10c.6 0 1 .4 1 1v11"></path><path d="M14 9h4l4 4v4c0 .6-.4 1-1 1h-2"></path><circle cx="7" cy="18" r="2"></circle><path d="M15 18H9"></path><circle cx="17" cy="18" r="2"></circle></svg>
                                    </div>
                                    <div class="ps-3 ">
                                        <h6>Shipping Details</h6>
                                    </div>
                                </div>
                                <hr/>
                                <div class="row">
                                    <div class="col-12 col-md-4 mb-2 shipping_carrier_im">
                                        <img src={`https://cdn.fastboxlogistics.in/images/${bookorder.carrier_image}`} alt="" class="img-fluid" title="Fedex Inc."/>
                                    </div>
                                    <div class="col-6 col-md-4 mb-2">
                                        <small class="text-muted">Shipment No.</small>
                                        <p><strong><a href="">{bookorder.shipment_id}</a></strong></p>
                                    </div>
                                    <div class="col-6 col-md-4 mb-2">
                                        <small class="text-muted">Order on</small>
                                        <p><a href="">{bookorder.shipment_created_date}</a></p>
                                    </div>
                                    <div class="col-6 col-md-4 mb-2">
                                        <small class="text-muted">Shipping Courier</small>
                                        <p>{bookorder.service_provider?bookorder.service_provider:bookorder.carrier_name}</p>
                                    </div>
                                    <div class="col-6 col-md-4 mb-2">
                                        <small class="text-muted">Shipping Courier AWB No.</small>
                                        <p><a href="">{bookorder.carrier_tracking_number?bookorder.carrier_tracking_number:""}</a></p>
                                    </div>
                                    <div class="col-6 col-md-4 mb-2">
                                        <small class="text-muted">Shipping Courier Service</small>
                                        <p>{bookorder.service_provider}</p>
                                    </div>
                                    <div class="col-6 col-md-4 mb-2">
                                        <small class="text-muted">Order Type</small>
                                        <p>{bookorder.mode}</p>
                                    </div>
                                    <div class="col-6 col-md-4 mb-2">
                                        <small class="text-muted">Shipment Type</small>
                                        <p>Forward</p>
                                    </div>
                                    <div class="col-6 col-md-4 mb-2">
                                        <small class="text-muted">Zone</small>
                                        <p>{bookorder.zone_name}</p>
                                    </div>
                                    <div class="col-6 col-md-4 mb-2">
                                        <small class="text-muted">Courier Rule Applied</small>
                                        <p>N/A</p>
                                    </div>
                                    <div class="col-12 col-md-4 mb-2 desk_display_none">
                                        <div class="d-grid">
                                          <a href="#track_shipment" class="btn btn-outline-secondary btn-block">Track Shipment</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
        
                        <div class="card border-0  mb-3">
                            <div class="card-body">
                            <div class="d-flex ">
                                <div class="border-end pe-3">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" data-lucide="package-open" class="lucide lucide-package-open"><path d="M20.91 8.84 8.56 2.23a1.93 1.93 0 0 0-1.81 0L3.1 4.13a2.12 2.12 0 0 0-.05 3.69l12.22 6.93a2 2 0 0 0 1.94 0L21 12.51a2.12 2.12 0 0 0-.09-3.67Z"></path><path d="m3.09 8.84 12.35-6.61a1.93 1.93 0 0 1 1.81 0l3.65 1.9a2.12 2.12 0 0 1 .1 3.69L8.73 14.75a2 2 0 0 1-1.94 0L3 12.51a2.12 2.12 0 0 1 .09-3.67Z"></path><line x1="12" x2="12" y1="22" y2="13"></line><path d="M20 13.5v3.37a2.06 2.06 0 0 1-1.11 1.83l-6 3.08a1.93 1.93 0 0 1-1.78 0l-6-3.08A2.06 2.06 0 0 1 4 16.87V13.5"></path></svg>
                                </div>
                                <div class="ps-3 ">
                                    <h6>Package Details</h6>
                                </div>
                            </div>
                            <hr/>
                            <div class="row">
                                                <div class="col-12 col-md-4 mb-2">
                                                    <small class="text-muted">Applicable Weight (in Kg)</small>
                                                    <p>{bookorder.total_weight}KG</p>
                                                </div>
                                                <div class="col-12 col-md-4 mb-2">
                                                    <small class="text-muted">Dead Weight (in Kg)</small>
                                                    <p>{bookorder.dead_weight}KG</p>
                                                </div>
                                                <div class="col-12 col-md-4 mb-2">
                                                    <small class="text-muted">Volumetric Weight (in kg)</small>
                                                    <p>{bookorder.volumetric_weight}KG</p>
                                                </div>
                                                <div class="col-12 col-md-4 mb-2">
                                                    <small class="text-muted">Dimensions (in cm)</small>
                                                    <p>{bookorder.height} x {bookorder.width} x {bookorder.length}</p>
                                                </div>
                                                <div class="col-12 col-md-6 mb-2">
                                                    <small class="text-muted">Number of Boxes</small>
                                                    <p>1</p>
                                                </div>
                                            </div>
                            </div>
                        </div>
        
        
                        <div class="card border-0 mb-3">
                            <div class="card-body">
                                <div class="d-flex ">
                                    <div class="border-end pe-3">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" data-lucide="package" class="lucide lucide-package"><path d="m7.5 4.27 9 5.15"></path><path d="M21 8a2 2 0 0 0-1-1.73l-7-4a2 2 0 0 0-2 0l-7 4A2 2 0 0 0 3 8v8a2 2 0 0 0 1 1.73l7 4a2 2 0 0 0 2 0l7-4A2 2 0 0 0 21 16Z"></path><path d="m3.3 7 8.7 5 8.7-5"></path><path d="M12 22V12"></path></svg>
                                    </div>
                                    <div class="ps-3 ">
                                        <h6>Shipment Details</h6>
                                    </div>
                                </div>
                                <hr/>
                                <div class="row">
                                            <div class="col-12 col-md">
                                                    <h6>Ship From:</h6>
                                                        <small class="text-muted">Sender Name:</small>
                                                        <p>{bookorder.sender[0].name}</p>
                                                        <small class="text-muted">Sender Address:</small>
                                                        <p>{bookorder.sender[0].address},{bookorder.sender[0].city}, {bookorder.sender[0].state} {bookorder.sender[0].pincode}</p>
                                                        <small class="text-muted">Sender Email:</small>
                                                        <p>{bookorder.sender[0].email}</p>
                                                        <small class="text-muted">Sender Mobile:</small>
                                                        <p>{bookorder.sender[0].mobile}</p>
                                                </div>
                                                <div class="col-12 col-md">
                                                    <h6>Ship To:</h6>
                                                        <small class="text-muted">Receiver Name:</small>
                                                        <p>{bookorder.receiver[0].name}</p>
                                                        <small class="text-muted">Receiver Address:</small>
                                                        <p>{bookorder.receiver[0].address},{bookorder.receiver[0].city}, {bookorder.receiver[0].state} {bookorder.receiver[0].pincode}</p>
                                                        <small class="text-muted">Receiver Email:</small>
                                                        <p>{bookorder.receiver[0].email}</p>
                                                        <small class="text-muted">Receiver Mobile:</small>
                                                        <p>{bookorder.receiver[0].mobile}</p>
                                                </div>
                                            </div>
                            </div>
                        </div>
        
        
                        <div class="card border-0  mb-3">
                            <div class="card-body">
                            <div class="d-flex ">
                                <div class="border-end pe-3">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" data-lucide="dollar-sign" class="lucide lucide-dollar-sign"><line x1="12" x2="12" y1="2" y2="22"></line><path d="M17 5H9.5a3.5 3.5 0 0 0 0 7h5a3.5 3.5 0 0 1 0 7H6"></path></svg>
                                </div>
                                <div class="ps-3 ">
                                    <h6>Payment Details</h6>
                                </div>
                            </div>
                            <hr/>
                            <div class="row">
                                                <div class="col-12 col-md-4 mb-2">
                                                    <small class="text-muted">Total Paid Amount</small>
                                                    <p>₹ {bookorder.total_amount}</p>
                                                </div>
                                                <div class="col-12 col-md-4 mb-2">
                                                    <small class="text-muted">Payment Mode</small>
                                                    <p>Wallet</p>
                                                </div>
                                                {/* <div class="col-12 col-md-4 mb-2">
                                                    <small class="text-muted">Transaction ID</small>
                                                    <p><a href="">3456789876</a></p>
                                                </div> */}
                                            </div>
                            </div>
                        </div>
        
                        <div class="card border-0 mb-3">
                            <div class="card-body">
                            <div class="d-flex ">
                                <div class="border-end pe-3">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" data-lucide="shopping-basket" class="lucide lucide-shopping-basket"><path d="m5 11 4-7"></path><path d="m19 11-4-7"></path><path d="M2 11h20"></path><path d="m3.5 11 1.6 7.4a2 2 0 0 0 2 1.6h9.8c.9 0 1.8-.7 2-1.6l1.7-7.4"></path><path d="m9 11 1 9"></path><path d="M4.5 15.5h15"></path><path d="m15 11-1 9"></path></svg>
                                </div>
                                <div class="ps-3 ">
                                    <h6>Product Details</h6>
                                </div>
                            </div>
                            <hr/>
                            <div class="table-responsive ">
                                                <table class="table table-light">
                                                    <thead>
                                                    <tr>
                                                        <th>Name</th>
                                                        {/* <th>Category</th> */}
                                                        {/* <th>HSN</th> */}
                                                        {/* <th>SKU</th> */}
                                                        <th>Qty</th>
                                                        <th>Unit price</th>
                                                        {/* <th>Discount</th> */}
                                                        {/* <th>Tax</th> */}
                                                        <th>Total</th>
                                                    </tr>
                                                    </thead>
                                                    <tbody>
                                                      {bookorder.items.map((sub)=>(
                                                    <tr>
                                                        <td>{sub.item_name}</td>
                                                        {/* <td>1689070274414</td> */}
                                                        {/* <td>samsung-A50</td> */}
                                                        {/* <td>1</td> */}
                                                        <td>{sub.item_quantity}</td>
                                                        <td>{sub.item_value}</td>
                                                        {/* <td>100.00</td> */}
                                                        {/* <td>10.00</td> */}
                                                        <td> {bookorder.items.reduce((total, sub) => total + parseFloat(sub.item_value), 0).toFixed(2)}</td>
                                                    </tr>
                                                    ))}
                                                    </tbody>
                                                </table>
                                            </div>
                            </div>
                        </div>
        
                        
                        
                        
                    </div>
                    <div class="col-12 col-md-4">
                    {/* <section class="bg-white p-3 mb-3">
                        <p>
                            Jaipur <i class="bi bi-arrow-right"></i> Delhi
                            <span class="text-end"><small class="text-muted">Location last updated on 3 May 2022 09:38</small></span>
                        </p>
        
                        
                        <p>Delivery ETA - <span class="text-muted">03-02-2022 @ 23:29</span></p>
                    </section> */}
                    <div class="card border-0 shadow-sm tracking_xb">
                        <div class="card-body">
                            <div class="d-flex ">
                                <div class="border-end pe-3">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" data-lucide="package-search" class="lucide lucide-package-search"><path d="M21 10V8a2 2 0 0 0-1-1.73l-7-4a2 2 0 0 0-2 0l-7 4A2 2 0 0 0 3 8v8a2 2 0 0 0 1 1.73l7 4a2 2 0 0 0 2 0l2-1.14"></path><path d="m7.5 4.27 9 5.15"></path><polyline points="3.29 7 12 12 20.71 7"></polyline><line x1="12" x2="12" y1="22" y2="12"></line><circle cx="18.5" cy="15.5" r="2.5"></circle><path d="M20.27 17.27 22 19"></path></svg>
                                </div>
                                <div class="ps-3 ">
                                    <h6>Tracking Info</h6>
                                </div>
                            </div>
                            <hr/>
                            {trackingstate.track!='' && stateship.shipmentDetail!='' ?
                                            <div class="progress-container  position-relative">
                                              {trackingstate.isLoading==false?
                                               trackingstate.track.history.length>0?
                                               trackingstate.track.history.map((sub)=>(
                                                <div class="step-container">
                                                <div class="step active">
                                                <i class="bi bi-check-circle-fill"></i>
                                                </div>
                                                <div class="progress"></div>
                                                <div class="step-info">
                <h4>{sub.message} </h4>
                <p>{sub.location}</p>
                <p><Moment format="MMM DD YYYY hh:mm:ss a">
                 {(sub.time)}
     </Moment></p>
            </div>
                                                </div>
                                             )) :"No Data Available"  :<ReactLoading type="bubbles" color="#0d6efd" style={{ position: 'absolute', right: '45%', height: '20px', width: '10%' }} />}
                                              
                                            </div>:"No Data Available"}
                        </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
    </div>
    <div className='offcanvas-backdrop fade show'></div>
 </section>
)}




    </div>
    <TablePagination
                component="div"
                 rowsPerPageOptions={[20,25,30,35,40,45,50]}
                 count={otherStates.total_count}
                 page={otherStates.page}
                 onPageChange={handlePageChange}
                 rowsPerPage={otherStates.rowsPerPage}
                 onRowsPerPageChange={handleChangeRowsPerPage}
               
                         />
    </div>
    <Footer/>
    </div>
    </div>
    </div>
    </div>
  )
}

export default Shipments
