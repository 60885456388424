import React from 'react'

import { useNavigate, useParams } from 'react-router-dom';

import axios from "axios";
import Swal from 'sweetalert2'

import config from "../../config"

import ExcelJS from 'exceljs';
import Footer from './Footer';
import Header from './Header';


const Detail_summary = () => {
   
    const { record_id } = useParams()
    let userDetail = localStorage.getItem('ship_rocket_user');
    // console.log("userDetail====", config.UserId);
    let userData = {};
    if (userDetail != null) {
      userData = JSON.parse(userDetail)
    }
   
    const [state, setState] = React.useState({ log: [] })
    const [successlog, setsuccesslog] = React.useState([])
    const [failedlog, setfailedlog] = React.useState([])
    const[failedcount , setfailedcount] = React.useState(0)
  
    const[successcount , setsuccescount] = React.useState(0)
  
    const [stateRecord, setStateRecord] = React.useState({ total_record: 0, uploaded_record: 0, status: "" })
    
   
    const[shipmentstate,setshipmentstate] = React.useState({status:1})
  
   
  
    // console.log("shipmentState",shipmentstate)
    React.useEffect(() => {
  
      shipment_list()
  
      // dimension_detail()
  
    }, [])
  
    // React.useEffect(() => {
  
    //   shipment_list()
  
     
  
    // }, [shipmentstate.status])
  
    const shipment_list = (status) => {
      let dataToSend = {
        customer_id: userData.customer_id, record_id: record_id , status:null
      };
  
      // console.log("datatoSend",dataToSend)
      let url = config.apiUrl + '/shipment/view_summary';
  
      // // console.log("headers => ", config.headers);
  
  
      axios.post(url, dataToSend, { headers: config.headers })
        .then((res) => {
          console.log("responseJson11 => ", res.data.output.length);
          if (res.data.output.length > 0) {
            // let status = stateRecord.status;
            setState(res.data.output[0])
            setStateRecord({ uploaded_record: res.data.output[0].uploaded_record, total_record: res.data.output[0].total_record, status: "" })
            let logs = res.data.output[0].log.filter(item => item.status === 1);
        setsuccesslog(logs)
        setsuccescount(logs.length)
        let failed_logs = res.data.output[0].log.filter(item => item.status === 2);
        setfailedlog(failed_logs)
        setfailedcount(failed_logs.length)
            // console.log(status)
            if (res.data.output[0].uploaded_record != res.data.output[0].total_record) {
              summary_record()
            }
          }
          else{
            setState({log:[]})
          }
  
        })
        .catch((error) => {
          //Hide Loader
          //   setLoadingstate({...loadingstate,loading:false})
          // console.log(error);
        });
  
    }
  
    const summary_record = () => {
      console.log("summary_record called")
      let dataToSend = {
        customer_id: userData.customer_id, record_id: record_id
      };
  
      // console.log("datatoSend",dataToSend)
      let url = config.apiUrl + '/shipment/view_summary_upload_record?customer_id=' + userData.customer_id + '&record_id=' + record_id + '';
  
      // // console.log("headers => ", config.headers);
      axios({
        method: "get",
        url: url,
        headers: config.headers,
        responseType: "stream",
        onDownloadProgress: progressEvent => {
          // console.log("progressEvent ", progressEvent.currentTarget.response);
          let parseData = progressEvent.currentTarget.response;
          parseData = parseData.split("Z");
          parseData = JSON.parse(parseData[parseData.length - 1]);
  
          // console.log(parseData)
          // console.log(state);
          setStateRecord({ uploaded_record: parseData.uploaded_record, total_record: parseData.total_record, status: parseData.status })
  
  
        }
      }).then((res) => {
        //  console.log("responseJson11 => ", res);
        // setState(res.data.output)
  
      })
        .catch((error) => {
          //Hide Loader
          //   setLoadingstate({...loadingstate,loading:false})
          // console.log(error);
        });
  
    }
  
    const handleRefreshButtonClick = () => {
      window.location.reload(); // Reloads the page
    };
  
    const shipmentFilter =(status)=>{
      console.log("statu == " , status)
      setshipmentstate({status:status})
      if(status==1){
        let logs = state.log.filter(item => item.status === status);
        setsuccesslog(logs)
        setsuccescount(logs.length)
      }
      else{
        let logs = state.log.filter(item => item.status === status);
        setfailedlog(logs)
        setfailedcount(logs.length)
      }
    }
    
    // const downloadReport=()=>{
    //   let dataToSend = {
    //     customer_id: userData.customer_id, record_id: record_id  , 
    //   };
    //     let url = config.apiUrl + '/shipment/view_summary';
    //   axios.post(url, dataToSend, { headers: config.headers })
    //     .then((res) => {
    //       console.log("responseJson11 => ", res.data.output.length);
         
    //     })
    //     .catch((error) => {
          
    //     });
    // }
    const downloadReport = async () => {
      const response = await fetch(config.apiUrl +'/shipment/get_order_details', {
          method: 'POST',
          headers: config.headers,
          body: JSON.stringify({ failedlog , record_id:record_id})
      });
  
      const orderDetails = await response.json();
      generateExcel(orderDetails);
  };
  const generateExcel = (orderDetails) => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Failed Orders');
  
    worksheet.columns = [
        { header: 'Order_ID', key: 'Order_ID' },
        { header: 'Order_Date', key: 'Order_Date' },
        { header: 'Warehouse_Name', key: 'Warehouse_Name' },
        { header: 'Receiver_Name', key: 'Receiver_Name' },
        { header: 'Receiver_Address', key: 'Receiver_Address' },
        { header: 'Receiver_Pincode', key: 'Receiver_Pincode' },
        { header: 'Receiver_Email', key: 'Receiver_Email' },
        { header: 'Receiver_Mobile', key: 'Receiver_Mobile' },
        { header: 'Return_Name', key: 'Return_Name' },
        { header: 'Return_Address', key: 'Return_Address' },
        { header: 'Return_Pincode', key: 'Return_Pincode' },
        { header: 'Return_Email', key: 'Return_Email' },
        { header: 'Return_Mobile', key: 'Return_Mobile' },
        { header: 'Item_Name', key: 'Item_Name' },
        { header: 'Height', key: 'Height' },
        { header: 'Width', key: 'Width' },
        { header: 'Length', key: 'Length' },
        { header: 'Weight', key: 'Weight' },
        { header: 'Item_Value', key: 'Item_Value' },
        { header: 'Item_Quantity', key: 'Item_Quantity' },
        { header: 'Payment_Mode', key: 'Payment_Mode' },
        { header: 'COD_Value', key: 'COD_Value' },
        { header: 'Reason', key: 'Reason' }
    ];
  
    worksheet.addRows(orderDetails);
  
    workbook.xlsx.writeBuffer().then(buffer => {
        const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = 'FailedOrdersReport.xlsx';
        document.body.appendChild(a);
        a.click();
        a.remove();
    });
  };
  
  const successdownloadReport = async () => {
    const response = await fetch(config.apiUrl +'/shipment/get_success_order_details', {
        method: 'POST',
        headers: config.headers,
        body: JSON.stringify({ successlog , record_id:record_id})
    });
  
    const orderDetails = await response.json();
    successgenerateExcel(orderDetails);
  };
  const successgenerateExcel = (orderDetails) => {
  const workbook = new ExcelJS.Workbook();
  const worksheet = workbook.addWorksheet('Success Orders');
  
  worksheet.columns = [
      { header: 'Order_ID', key: 'Order_ID' },
      { header: 'Order_Date', key: 'Order_Date' },
      { header: 'Warehouse_Name', key: 'Warehouse_Name' },
      { header: 'Receiver_Name', key: 'Receiver_Name' },
      { header: 'Receiver_Address', key: 'Receiver_Address' },
      { header: 'Receiver_Pincode', key: 'Receiver_Pincode' },
      { header: 'Receiver_Email', key: 'Receiver_Email' },
      { header: 'Receiver_Mobile', key: 'Receiver_Mobile' },
      { header: 'Return_Name', key: 'Return_Name' },
      { header: 'Return_Address', key: 'Return_Address' },
      { header: 'Return_Pincode', key: 'Return_Pincode' },
      { header: 'Return_Email', key: 'Return_Email' },
      { header: 'Return_Mobile', key: 'Return_Mobile' },
      { header: 'Item_Name', key: 'Item_Name' },
      { header: 'Height', key: 'Height' },
      { header: 'Width', key: 'Width' },
      { header: 'Length', key: 'Length' },
      { header: 'Weight', key: 'Weight' },
      { header: 'Item_Value', key: 'Item_Value' },
      { header: 'Item_Quantity', key: 'Item_Quantity' },
      { header: 'Payment_Mode', key: 'Payment_Mode' },
      { header: 'COD_Value', key: 'COD_Value' },
      { header: 'Reason', key: 'Reason' }
  ];
  
  worksheet.addRows(orderDetails);
  
  workbook.xlsx.writeBuffer().then(buffer => {
      const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = 'SuccessOrdersReport.xlsx';
      document.body.appendChild(a);
      a.click();
      a.remove();
  });
  };
  return (
    <div>
          <Header/>
 <div class="page-wrapper pinned">
  <div class="main-container">
   <div className='app-container'>
  <div class="app-body">
  <div class="container-fluid"> 

<div class="mob_nav">
  <div class=" d-flex justify-content-start ">
    <a href="javascript:window.history.back();" class=""><i class="bi bi-arrow-left"></i></a>
    <h2>Bulk Orders Log ID {record_id}</h2>
  </div>
</div>

<div class="row justify-content-between">
    <div class="col-12 col-md-6 desk_nav">
      <div class="d-flex">
        <a href="javascript:window.history.back();" class="back_btn"><i class="bi bi-arrow-90deg-left"></i></a>
        <h2>Bulk Orders Log ID {record_id}</h2>
      </div>
    </div>
    <div class="col-12 col-md-4">
      
    </div>
</div>



<div class="row justify-content-center">
  <div class="col-12 col-md-8">
  <section class="mb-3">
    <div class="container">
      <div class="row justify-content-center">
          <div class="col-12 col-md-12">
              
              <div class="order_progres_box">
              {state.total_record !== state.uploaded_record?    <img src="../../assets/images/spinner.gif" class="img-fluid" style={{maxHeight: "40px"}} />:""}
                
              <h6>{stateRecord.uploaded_record}/{stateRecord.total_record} Orders has been created</h6>
                             
                             {(stateRecord.status == "pending") ? <p>This is going to take a few minutes. Kindly request your patience.</p>: (stateRecord.status == "finished") ? <small>Uploading Finished</small> : ""}
              </div>
          </div>
      </div>      
    </div>
  </section>
  {state.total_record !== state.uploaded_record?
  <section class="mb-3">
    <div class="container alerts_box">
      <div class="alert alert-secondary">
          <div class="row">
              <div class="col-12 col-md">
                  <div class="d-flex ">
                      <div class="">
                      <i class="bi bi-check-circle"></i>
                      </div>
                      <div class="ps-2 ">
                      <h5>This task is running in the background</h5>
                      <p>You can do other activities while we are creating your order.</p> 
                      </div>
                  </div>
              </div>
              <div class="col-12 col-md-4 text-end">
                  <a onClick={() => handleRefreshButtonClick()} class="btn btn-outline-dark btn-sm">Reload Page</a>
              </div>
          </div>
      </div>    
    </div>
  </section>
  :
<>
  <section class="mb-3">
      <div class="container">
          <div class="row justify-content-center">
              
              
          </div>      
      </div>
  </section>
  <section class="mb-3">
    <div class="container">
      <div class="card order_import_stat">
        <div class="card-body">
          <h4>UPLOAD ID : {record_id}</h4>
          <div class="row mb-3">
              <div class="col-12 col-md-8">
              {/* <p><small><i class="bi bi-clock"></i> 12:20 12/12/12</small></p> */}
              </div>
              <div class="col-12 col-md-4 text-end">
              {state.total_record == state.uploaded_record?    <p><span class="badge rounded-pill bg-success fw-400">Completed</span></p>: <p><span class="badge rounded-pill bg-danger fw-400">Running</span></p>}
              </div>
          </div>
          <hr/>
          {/* <!-- Nav tabs --> */}
          <ul class="nav nav-tabs justify-content-center nav-justified" role="tablist">
              <li class="nav-item" role="presentation">
                  <a  class={shipmentstate.status==1?"nav-link active":"nav-link"} style={{cursor:"pointer"}} onClick={(e)=>shipmentFilter(1)}>Successful Uploads <span class="badge rounded-pill bg-success">{successcount}</span></a>
              </li>
              <li class="nav-item " role="presentation">
                  <a class={shipmentstate.status==2?"nav-link active":"nav-link"} style={{cursor:"pointer"}} onClick={(e)=>shipmentFilter(2)}>Failed Uploads <span class="badge rounded-pill bg-danger">{failedcount}</span></a>
              </li>
          </ul>

          <div class="tab-content mt-3">
                    {shipmentstate.status==1?
                       <div class={shipmentstate.status==1?"tab-pane container active ":"tab-pane container fade "} id="success">
                           <div class="row mb-3">
                               <div class="col-12 col-md-12 alrt_success_task alrt_task mb-3">
                                {successlog.length>0?
                                
                                   <div class="alert alert-success">
                                       <div class="row">
                                           <div class="col-12 col-md">
                                               <div class="d-flex ">
                                                   <div class="">
                                                   <i class="bi bi-check-circle"></i>
                                                   </div>
                                                   <div class="ps-2 ">
                                                   <h5>{successcount}/{state.total_record} Order created successfully</h5>
                                                   <p>You can now check and view the orders</p>
                                                   </div>
                                               </div>
                                           </div>
                                           <div class="col-12 col-md-4 text-end">
                                               <a onClick={(e)=>successdownloadReport(e)} class="btn btn-outline-success btn-sm">Download Report</a>
                                           </div>
                                       </div>
                                   </div>
                                   :""}
                               </div>
                           </div>
                           {successlog.length>0?
                           successlog.map((sub)=>(
                           <div class="import_success import_sta_boxs">
                               <div class="d-flex ">
                                   <div class="">
                                       <i class="bi bi-check-circle"></i>
                                   </div>
                                   <div class="ps-2 ">
                                       <h5>Order {sub.order_id}</h5>
                                       <p>Order created successfully</p>
                                   </div>
                               </div>
                           </div>
                          )) :<div class="">
                          <h5>No Orders to show here</h5>
                          {/* <p>You will get remarks in the report</p> */}
                          </div>}
                       </div>
                       :
                       <div class={shipmentstate.status==2?"tab-pane container active ":"tab-pane container fade "}  id="failed">
                           <div class="row mb-3">
                            {failedlog.length>0?
                               <div class="col-12 col-md-12 alrt_failed_task alrt_task">
                                   <div class="alert alert-danger">
                                       <div class="row">
                                           <div class="col-12 col-md">
                                               <div class="d-flex ">
                                                   <div class="">
                                                   <i class="bi bi-exclamation-triangle"></i>
                                                   </div>
                                                   <div class="ps-2 ">
                                                   <h5>{failedcount} Orders could not be created</h5>
                                                   <p>You will get remarks in the report</p>
                                                   </div>
                                               </div>
                                           </div>
                                           <div class="col-12 col-md-4 text-end">
                                               <a onClick={(e)=>downloadReport(e)} class="btn btn-outline-danger btn-sm">Download Report</a>
                                           </div>
                                       </div>
                                   </div>
                               </div>
                               : <div class="">
                               <h5>No Orders to show here</h5>
                               {/* <p>You will get remarks in the report</p> */}
                               </div>}
                           </div>
                           {failedlog.length>0?
                           failedlog.map((sub)=>(
                           <div class="import_failed import_sta_boxs">
                               <div class="d-flex ">
                                   <div class="">
                                       <i class="bi bi-exclamation-triangle"></i>
                                   </div>
                                   <div class="ps-2 ">
                                       <h5>Order {sub.order_id}</h5>
                                       <p>Order could not be created {sub.order_id}  <strong>Log Error:</strong> <small>{sub.reason}</small></p>
                                   </div>
                               </div>
                           </div>
                          )) :""}
                       </div>
}
                   </div>
        </div>
      </div>      
    </div>
  </section>
  </>
}


  
  </div>
</div>




    </div></div>
    <Footer/>
    </div></div></div>
    </div>
  )
}

export default Detail_summary
