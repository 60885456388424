import React from 'react'

import Header from './Header'

import {  useNavigate, useParams } from 'react-router-dom'

import axios from "axios";
import Swal from 'sweetalert2';

import Moment from 'react-moment';

import ReactLoading from 'react-loading';
import config from "../../config"
import { useNetworkState } from 'react-use';
import Footer from './Footer'

const Transaction_detail = () => {
    let payout_id = useParams();
    console.log("payout_id " , payout_id)
    let navigate = useNavigate()
    const isOnline = useNetworkState();
    console.log("isONline  === " ,isOnline )
  
    const[payoutdetail , setpayoutdetail]= React.useState([])
    const[shipmentdetail , setshipmentdetail]= React.useState([])

    const [isSidebarOpen, setIsSidebarOpen] = React.useState(false);
   

    const toggleSidebar = () => {
        setIsSidebarOpen(!isSidebarOpen);
        const homeSection = document.querySelector('.home-section');
        if (homeSection) {
            homeSection.classList.toggle('sidebar-closed');
        }
    };

    React.useEffect(()=>{
        get_payout_detail()
    },[])
    const get_payout_detail=()=>{
        let dataToSend = { payout_id:payout_id.payout_id};
       
         // console.log("datatoSend",dataToSend)
         let url = config.apiUrl + '/user/payout_detail_api';
         axios.post(url, dataToSend, { headers: config.headers })
             .then((res) => {
          setpayoutdetail(res.data.payout_detail)
          setshipmentdetail(res.data.shipments)


     })
           
             .catch((error) => {
               const Toast = Swal.mixin({
                   toast: true,
                   position: 'bottom-end',
                   showConfirmButton: false,
                   timer: 3000
                 })
                 Toast.fire({
                   background:"#c2200a",
                   type: 'Unsuccess',
                   title: "Something Went Wrong !",
                   color:"white"
                 });
                 // console.log(error);
             });    
       
       }
  return (
    <div>
       <Header/>
 <div class="page-wrapper pinned">
  <div class="main-container">
   <div className='app-container'>
  <div class="app-body">
  <div class="container-fluid"> 
 

<div class="mob_nav">
  <div class="row">
      <div class="col-7">
        <div class=" d-flex justify-content-start ">
          <a href="javascript:window.history.back();" class=""><i class="bi bi-arrow-left"></i></a>
          <h2>Payout Detail</h2>
        </div>
      </div>
      <div class="col">
      </div>
  </div>
</div>
<div class="row justify-content-between desk_nav">
    <div class="col-12 col-md-4 ">
      <div class="d-flex">
        <a href="javascript:window.history.back();" class="back_btn"><i class="bi bi-arrow-90deg-left"></i></a>
        <h2>Payout Detail</h2>
      </div>
    </div>
    <div class="col-12 col-md-4 mb-3">
    </div>
</div> 
<section class="mb-3">
            <div class="col-12 col-md">
            <div class="d-flex">
                <div class="">
                    <h2><small>Payout #</small>{payout_id.payout_id}</h2>
                </div>
            </div>
        </div>
            
            <div class=" table-responsive">
                <table class="table table-bordered table_box billing_table">
                    <thead>
                    <tr>
                    <th class="border-gray-200">Transaction #</th>	
                    <th class="border-gray-200">Total COD <a data-bs-toggle="tooltip" title="Total COD Customer Wallet Amount"></a></th>	
                    <th class="border-gray-200">WALLET ADJ. <a data-bs-toggle="tooltip" title="Prepaid Wallet Adjusted from COD Amount"></a></th>	
                    <th class="border-gray-200">Payout <a data-bs-toggle="tooltip" title="Total Releasable Payout after adjustment if any"></a></th>
                    <th class="border-gray-200">Bank Details</th>
                   
                    <th class="border-gray-200">Status</th>
                   
                </tr>
                    </thead>
                    {payoutdetail.length>0?
                    payoutdetail.map((item)=>(
               
            <tbody>
                <tr>
                    <td>
                        {item.transaction_id}
                    </td>
                    <td>
                    ₹{item.total_cod_balance}
                    </td>    
                       
                    <td class="fw-700 text-danger">
                        (-)₹{item.wallet_adjusted_amount}<br/>
                       
                    </td>    
                    <td class="fw-700 text-success">
                        ₹{item.payout_amount}
                    </td>                    
                    <td class="">
                   
                    <small>
                    Acc no: {item.acc_no}<br/>
                    IFSC Code: {item.ifsc_code}<br/>
                   
                    </small>
                    </td>
                  
                    <td>
                   {item.status==0?     <span class="badge bg-warning"> <i class="fa fa-clock-o" aria-hidden="true"></i> Under Process</span>: <span class="badge bg-success"> <i class="fa fa-check" aria-hidden="true"></i> Completed</span>}
                    </td>
                  
                </tr>
              
            </tbody>
          )):""}
                </table>  
            </div>
            <div class="col-12 col-md">
            <div class="d-flex">
                <div class="">
                    <h2><small>Total Orders #</small>{shipmentdetail.length}</h2>
                </div>
            </div>
        </div>
            <div class=" table-responsive">
                <table class="table table-bordered table_box billing_table">
                    <thead>
                    <tr>
                    <th class="border-gray-200">Shipment Delivered Date #</th>	
                    <th class="border-gray-200">Awb Number</th>
                    <th class="border-gray-200">Order Number </th>	
                    <th class="border-gray-200">COD Amount</th>	

                   
                  
                </tr>
                    </thead>
                    {shipmentdetail.length>0?
                    shipmentdetail.map((item)=>(
             <tbody>
                <tr>
                    <td>
                        {item.delivery_date}
                    </td>    
                       
                    <td class="fw-700">
                    {item.carrier_tracking_number}
                       
                       
                    </td>    
                    <td class="fw-700 ">
                    {item.order_number}
                    </td>  
                    <td class="fw-700 ">
                    ₹{item.cod_amount}
                    </td>                    
                   
                  
                   
                </tr>
              
            </tbody>
          )):""}
                </table>  
            </div>
     </section>    
     </div></div>
     <Footer/></div></div></div>  


    
 </div>
  )
}

export default Transaction_detail
