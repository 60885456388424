import React from 'react'
import {  useNavigate } from 'react-router-dom'

import axios from "axios";
import Swal from 'sweetalert2';

import config from "../../config"
import { useNetworkState } from 'react-use';
import Moment from 'react-moment';


const Header_nav = ({onPinToggle , onToggle}) => {
  let navigate = useNavigate()
     const isOnline = useNetworkState();
    console.log("isONline  === " ,isOnline )
    const[balancestate,setbalanceState]= React.useState({total_balance:[]})
  const[dimensionState,setdimensionState] = React.useState({companyDetail:[]})
  const [userState, setUserState] = React.useState({ full_name: "" });
  const [trackstate, settrackState] = React.useState({ awb: "" });
  
  const[searchstate,setsearch]= React.useState({search:""})
  const[log,setlog]= React.useState({order_log:{},shipment_log:{}})
  const [searchQuery, setSearchQuery] = React.useState('');
  const [searchHistory, setSearchHistory] = React.useState(
    JSON.parse(localStorage.getItem("searchHistory")) || [] // default to empty array if no history found
  );
  const [isSearchBoxVisible, setIsSearchBoxVisible] = React.useState(false);

  let userDetail = localStorage.getItem('ship_rocket_user');
    // console.log("userDetail====", config.UserId);
    let userData = {};
    if (userDetail != null) {
      userData = JSON.parse(userDetail)
    }
  React.useEffect(()=>{
    get_wallet_balance()
    dimension_detail()
    // user()

  },[])


    const get_wallet_balance=()=>{
        let dataToSend = {customer_id : userData.customer_id};
       
         // console.log("datatoSend",dataToSend)
         let url = config.apiUrl + '/user/get_customer_wallet';
         axios.post(url, dataToSend, { headers: config.headers })
             .then((res) => {
               // console.log("responseJson => ", res);
               if(res.data.status==true){
           setbalanceState({total_balance:res.data.find_customer_wallet})
        }
       else{
           const Toast = Swal.mixin({
               toast: true,
               position: 'bottom-end',
               showConfirmButton: false,
               timer: 3000
             })
             Toast.fire({
               background:"#a61c09",
               type: 'Unsuccess',
               title: "Something Went Wrong !",
               color:"white"
             });
       } })
           
             .catch((error) => {
               const Toast = Swal.mixin({
                   toast: true,
                   position: 'bottom-end',
                   showConfirmButton: false,
                   timer: 3000
                 })
                 Toast.fire({
                   background:"#a61c09",
                   type: 'Unsuccess',
                   title: "Something Went Wrong !",
                   color:"white"
                 });
                 // console.log(error);
             });    
       
       }
       const dimension_detail = () =>{
        let full_api = config.apiUrl + `/user/get_company_info_api`;
        let sendData = {};
      
        axios.post(full_api, sendData, { headers: config.headers }).then(res => {
          setdimensionState({...dimensionState,companyDetail:res.data.companyDetail});
          // console.log("rnmmmmm", res);
        }).catch((e) => {
          // toast.configure()
           //toast.error("Some thing went wrong")
          // console.log("----error:   ", e);
        })
      }

      React.useEffect(()=>{
        log_detail()
      },[])
      const log_detail = () =>{
        let full_api = config.apiUrl + `/shipment/logs_detail`;
        let sendData = {};
      
        axios.post(full_api, sendData, { headers: config.headers }).then(res => {
          setlog({order_log:res.data.order_log , shipment_log:res.data.shipment_log})
        
          
        }).catch((e) => {
          // toast.configure()
           //toast.error("Some thing went wrong")
          // console.log("----error:   ", e);
        })
      }
      const logOutButton = () =>{
        // console.log("userDetail ===>   ");
      
        let full_api = config.apiUrl + `/user/user_logout`;
          let sendData = {customer_id:userData.customer_id};
          // console.log("bb", sendData);
          axios.post(full_api, sendData, { headers: config.headers }).then((res) => {
              // console.log("res ", res);
              if (res.data.status==true) {
                localStorage.removeItem('ship_rocket_user');
                localStorage.removeItem('searchHistory');
                userDetail = null;
                setUserState({full_name:""})
                window.location.replace('/login')
              }else {
                const Toast = Swal.mixin({
                    toast: true,
                    position: 'bottom-end',
                    showConfirmButton: false,
                    timer: 3000
                  })
                Toast.fire({
                  background:"#a61c09",
                  type: 'unsuccess',
                  title: res.data.message ,
                  color:"white"
                });
                 
              }
      
          }).catch((e) => {
      
      
          });
        
      
      }

      // const handlechnage=(e)=>{
      //  settrackState({...trackstate , awb:e.target.value})
      // }

      const searchchange =(e)=>{
        setsearch({search:e.target.value})
      }

      const shipmentpage = ()=>{
        window.location.href = `/shipments?number=${searchstate.search}`;
      }
  
      React.useEffect(() => {
        const handleKeyDown = (e) => {
          // Check for Cmd/Ctrl + K
          if ((e.metaKey || e.ctrlKey) && e.key === 'k') {
            e.preventDefault(); // Prevent the default browser action
            setIsSearchBoxVisible((prevState) => !prevState); // Toggle search box visibility
          }
        };
    
        // Attach the event listener
        document.addEventListener('keydown', handleKeyDown);
    
        // Cleanup the event listener on unmount
        return () => {
          document.removeEventListener('keydown', handleKeyDown);
        };
      }, []);
    
      // Handle Search Input Change
      const handleSearchChange = (e) => {
        setSearchQuery(e.target.value);
      };
    
      // Handle Search (Enter key)
      const handleSearchSubmit = (e) => {
        if (e.key === 'Enter' && searchQuery.trim()) {
          const updatedHistory = [searchQuery, ...searchHistory].slice(0, 5);
          setSearchHistory(updatedHistory);
      
          // Save to localStorage
          localStorage.setItem('searchHistory', JSON.stringify(updatedHistory));
      
          // Navigate to the shipments page
          window.location.href = `/shipments?number=${encodeURIComponent(searchQuery)}`;
          
          // Clear the search query
          setSearchQuery('');
        }
      };
      // Handle Search from History Item Click
      const handleHistoryClick = (searchTerm) => {
        setSearchQuery(searchTerm);
        setIsSearchBoxVisible(false); // Close search box when a history item is clicked
      };
  return (
    <div>
      <div class="app-header d-flex align-items-center">

{/* <!-- Toggle buttons start --> */}
<div class="d-flex">
  <button class="btn btn-outline-primary me-2 toggle-sidebar" id="toggle-sidebar" onClick={onToggle}>
    <i class="bi bi-text-indent-left fs-5"></i>
  </button>
  <button class="btn btn-outline-primary me-2 pin-sidebar" id="pin-sidebar" onClick={onPinToggle}>
    <i class="bi bi-text-indent-left fs-5"></i>
  </button>
</div>
{/* <!-- Toggle buttons end --> */}

{/* <!-- App brand sm start --> */}
<div class="app-brand-sm d-md-none d-sm-block">
  <a href="/">
    <img src="../../../../assets/images/logo_big.png" class="logo" alt=""/>
  </a>
</div>
{/* <!-- App brand sm end --> */}

<div className={`search_box position-relative ${isSearchBoxVisible ? 'show-dropdown' : ''}`}>
		<div class="input-group">
			<input 
				type="text"
        id="searchBox"
        className="form-control"
        placeholder="Search Tracking Number (⌘ + K)"
        autocomplete="off"
        value={searchQuery}
        onChange={handleSearchChange}
        onKeyDown={handleSearchSubmit}
			/>
		</div>
		<ul id="searchDropdown" className="dropdown-menu show">
  {searchHistory.length > 0 ? (
    searchHistory.map((historyItem, index) => (
      <li key={index} onClick={() => handleHistoryClick(historyItem)}>
        <div className="d-flex justify-content-between">
          <h5>{historyItem}</h5>
          {/* <h6>Delhivery Surface</h6> Or dynamic carrier */}
        </div>
      </li>
    ))
  ) : (
    <li>No History Found</li>
  )}
</ul>
	</div>

{/* <!-- App header actions start --> */}
<div class="header-actions">
  <div class="dropdown border-start">
    <a class="dropdown-toggle d-flex px-3 py-4 position-relative" href="#!" role="button"
      data-bs-toggle="dropdown" aria-expanded="false">
      <i class="bi bi-search fs-4 lh-1 text-secondary"></i>
    </a>
    <div class="dropdown-menu dropdown-menu-end shadow-sm dropdown-menu-md">
      <div class="input-group mb-3 px-2">
        <input type="text" class="form-control" placeholder="Search AWB Number..."  onChange={(e)=>searchchange(e)}/>
        <button class="btn btn-primary"  onClick={shipmentpage}><i class="bi bi-search"></i></button>
      </div>
      {/* <div class="d-grid">
        <a href="/activity_logs" class="btn btn-primary m-2">View All Shipments</a>
      </div> */}
    </div>
  </div>
  <div class="dropdown border-start">
    <a class="dropdown-toggle d-flex px-3 py-4 position-relative" href="#!" role="button"
      data-bs-toggle="dropdown" aria-expanded="false">
      <i class="bi bi-wallet2 fs-4 lh-1 text-secondary"></i>
      <span class="count-label info"></span>
    </a>
    <div class="dropdown-menu dropdown-menu-end dropdown-menu-md shadow-sm">
      <div class="d-flex flex-column p-3 mx-3 my-2 border">
        <a href="javascript:void(0)" class="text-decoration-none">
          <h3 class="mb-2">₹{balancestate.total_balance.map((sub)=>(
                        parseFloat(sub.wallet_balance).toFixed(2)))}</h3>
          <div class="mb-1 d-flex justify-content-between">
            <span class="text-secondary">Wallet Balance</span>
          </div>
        </a>
      </div>
      <div class="d-flex flex-column p-3 mx-3 my-2 border">
        <a href="javascript:void(0)" class="text-decoration-none">
          <h3 class="mb-2">₹{balancestate.total_balance.map((sub)=>(
                        parseFloat(sub.cod_wallet_balance).toFixed(2)))}</h3>
          <div class="mb-1 d-flex justify-content-between">
            <span class="text-secondary">COD Balance</span>
          </div>
        </a>
      </div>
      <div class="d-flex flex-column p-3 mx-3 my-2 border">
        <a href="javascript:void(0)" class="text-decoration-none">
          <h3 class="mb-2">₹{balancestate.total_balance.map((sub)=>(
                        sub.wallet_balance>0 && sub.credit_limit?  parseFloat(sub.credit_limit).toFixed(2):!sub.credit_limit?0:parseFloat(sub.wallet_balance+sub.credit_limit).toFixed(2)))}</h3>
          <div class="mb-1 d-flex justify-content-between">
            <span class="text-secondary">Credit Limit</span>
            {/* <span class="text-danger">Used: $900</span> */}
          </div>
        </a>
      </div>
      <div class="d-grid mx-3 my-3">
        <a  href='/wallet_recharge' style={{cursor:"pointer"}} class="btn btn-primary"> <i class="bi bi-plus"></i>Recharge Wallet</a>
      </div>
    </div>
  </div>
  <div class="dropdown border-start">
    <a class="dropdown-toggle d-flex px-3 py-4 position-relative" href="#!" role="button"
      data-bs-toggle="dropdown" aria-expanded="false">
      <i class="bi bi-envelope-open fs-4 lh-1 text-secondary"></i>
      <span class="count-label"></span>
    </a>
    <div class="dropdown-menu dropdown-menu-end dropdown-menu-md shadow-sm">
      <a href="/activity_logs" class="dropdown-item">
        <div class="d-flex py-2">
          <div class="p-3 not_icons">
            <i class="bi bi-bag fs-4 lh-1 text-secondary"></i>
          </div>
          <div class="m-0">
            <h5 class="mb-1">Order Imported</h5>
            <p class="mb-1 text-secondary">
              {log.order_log.record_id}
              |  {log.order_log.uploaded_record}/{log.order_log.total_record} Orders
            </p>
            <p class="small m-0 text-secondary"><Moment format="hh:mm:ss a">
                         {new Date(log.order_log.end_time*1000)}
             </Moment></p>
          </div>
        </div>
      </a>
      <a href="/activity_logs" class="dropdown-item">
        <div class="d-flex py-2">
          <div class="p-3 not_icons">
            <i class="bi bi-box fs-4 lh-1 text-secondary"></i>
          </div>
          <div class="m-0">
            <h5 class="mb-1">Shipment Created</h5>
            <p class="mb-1 text-secondary">
            {log.shipment_log.log_id}
            |  {log.shipment_log.uploaded_records}/{log.shipment_log.total_records} Shipments
            </p>
            <p class="small m-0 text-secondary">{log.shipment_log.date} - {log.shipment_log.time}</p>
          </div>
        </div>
      </a>
      <div class="d-grid mx-3 my-3">
        <a href="/activity_logs" class="btn btn-primary">View all logs</a>
      </div>
    </div>
  </div>
  <div class="dropdown ms-2">
    <a id="userSettings" class="dropdown-toggle d-flex py-2 align-items-center text-decoration-none"
      href="#!" role="button" data-bs-toggle="dropdown" aria-expanded="false">
      <span class="head_user_icon"><i class="bi bi-person-circle fs-4 lh-1 text-secondary"></i></span>
      {/* <!-- <img src="assets/images/user.png" class="rounded-2 img-3x" alt="" /> --> */}
    </a>
    <div class="dropdown-menu dropdown-menu-end shadow-sm">
      <div class="p-3 border-bottom mb-2">
        <h6 class="mb-1">{userData.full_name}</h6>
        <p class="m-0 small opacity-50">{userData.email}</p>
      </div>
      <a class="dropdown-item d-flex align-items-center" href="/profile_update"><i
          class="bi bi-person fs-4 me-2"></i>Profile</a>
      <a class="dropdown-item d-flex align-items-center" href="/bank_details"><i
          class="bi bi-bank fs-4 me-2"></i>Bank Details</a>
      <a class="dropdown-item d-flex align-items-center" href="/user_kyc"><i
          class="bi bi-person-vcard fs-4 me-2"></i>KYC Detail</a>
      <a class="dropdown-item d-flex align-items-center" href="/billing_address"><i
          class="bi bi-cash fs-4 me-2"></i>Billing Detail</a>
      <a class="dropdown-item d-flex align-items-center" href="/change_password"><i
          class="bi bi-person-lock fs-4 me-2"></i>Change Password</a>
          <li><hr class="dropdown-divider"></hr></li>
      <a class="dropdown-item d-flex align-items-center" href="/email_notification"><i
          class="bi bi-envelope-at fs-4 me-2"></i>Email Notification</a>
      {/* <a class="dropdown-item d-flex align-items-center" href="sms_notification.php"><i
          class="bi bi-phone fs-4 me-2"></i>SMS Notification</a> */}
      <a class="dropdown-item d-flex align-items-center" href="/whatsapp_notification"><i
          class="bi bi-whatsapp fs-4 me-2"></i>Whatsapp Notification</a>
            <a class="dropdown-item d-flex align-items-center" href="/system_prefrences"><i
          class="bi bi-gear fs-4 me-2"></i>System Prefrences</a>
      <div class="d-grid p-3 py-2">
        <a class="btn btn-danger" onClick={(e)=>logOutButton(e)}>Logout</a>
      </div>
    </div>
  </div>
</div>
</div>
    </div>
  )
}

export default Header_nav
