import React, { useEffect, useRef } from 'react'
import Header from './Header'

import { useNavigate } from 'react-router-dom';
import axios from "axios";
import Swal from 'sweetalert2'
import { Container, Row, Col, Form, Button, ProgressBar } from "react-bootstrap";
// import './customer_style.css';
import Modal from 'react-bootstrap/Modal';
// import { useLocation } from 'react-router-dom';
import ReactLoading from 'react-loading';
import config from "../../config"
import download from 'downloadjs'
import Moment from 'react-moment';
import TablePagination from '@mui/material/TablePagination';
import { DateRangePicker } from 'react-date-range';
import { addDays } from "date-fns";
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css';

import { useDownloadExcel } from 'react-export-table-to-excel';

import { useNetworkState } from 'react-use';
import * as XLSX from 'xlsx'
import Footer from './Footer';
import moment from 'moment'

import $ from 'jquery'; 
import 'daterangepicker/daterangepicker.css'; // Import daterangepicker CSS
import 'daterangepicker';

const Reports = () => {
  function todayDateString() {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, '0');
    const day = String(today.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  }
  
  // State for storing the selected dates
 
    const tableRef = useRef(null);
    const isOnline = useNetworkState();
    console.log("isONline  === " ,isOnline )
    let navigate = useNavigate();
    let userDetail = localStorage.getItem('ship_rocket_user');
    // console.log("userDetail====shipments====", config.UserId);
    let userData = {};
    if (userDetail != null) {
      userData = JSON.parse(userDetail)
    }
   
    const [date, setDate] = React.useState({
      start_date: moment().subtract(30, 'days').format('YYYY-MM-DD'),
      end_date: moment().format('YYYY-MM-DD')
    });
    console.log("date == " , date)
   
    // Function to get today's date in the format required by the date input (YYYY-MM-DD)
   
  
  
    const [state, setState] = React.useState([
        {
          startDate: new Date(),
          endDate: addDays(new Date(), 0),
          key: "selection",
          isOpen: false,
        },
      ]);
      // console.log("state === " ,  state)
      var months = [
        "01",
        "02",
        "03",
        "04",
        "05",
        "06",
        "07",
        "08",
        "09",
        "10",
        "11",
        "12",
      ];
      var days = [
        "00",
        "01",
        "02",
        "03",
        "04",
        "05",
        "06",
        "07",
        "08",
        "09",
        "10",
        "11",
        "12",
        "13",
        "14",
        "15",
        "16",
        "17",
        "18",
        "19",
        "20",
        "21",
        "22",
        "23",
        "24",
        "25",
        "26",
        "27",
        "28",
        "29",
        "30",
        "31",
      ];
    
      const xcc =
        state[0].startDate.getFullYear() +
        "-" +
        months[state[0].startDate.getMonth()] +
        "-" +
        days[state[0].startDate.getDate()];
      const ycc =
        state[0].endDate.getFullYear() +
        "-" +
        months[state[0].endDate.getMonth()] +
        "-" +
        days[state[0].endDate.getDate()];
  
  
  const [searchfilters, setsearchFilters] = React.useState({ filterStatus:[]})
  // console.log("searchfilters == " , searchfilters)
 
  // console.log("dimensionState= " , dimensionState)
  const [isLoading, setIsLoading] = React.useState(false);
  const[shipstate, setShipstate]= React.useState([])
  const[shipcount, setShipcount]= React.useState({count:0 , amount:0})
  
  const [isSidebarOpen, setIsSidebarOpen] = React.useState(false);

  const toggleSidebar = () => {
      setIsSidebarOpen(!isSidebarOpen);
      const homeSection = document.querySelector('.home-section');
      if (homeSection) {
          homeSection.classList.toggle('sidebar-closed');
      }
  };
  // console.log("rstate= " , rstate)
  
  const filter=(e,values)=>{
    const name = e.target.name;
    const value = values;
    const isChecked = e.target.checked;
  
    // Create a new array by spreading the existing filterStatus array
    const updatedFilterStatus = [...searchfilters.filterStatus];
  
    if (isChecked) {
      updatedFilterStatus.push(value);
    } else {
      // Remove the value from the array if unchecked
      const index = updatedFilterStatus.indexOf(value);
      if (index !== -1) {
        updatedFilterStatus.splice(index, 1);
      }
    }
  
    // Update the searchfilters state with the updated filterStatus array
    setsearchFilters((prevFilters) => ({
      ...prevFilters,
      filterStatus: updatedFilterStatus,
    }));
  }
  React.useEffect(()=>{
    getData()
  },[])
        const getData = () => {
            setIsLoading(true)
            const currentDate = new Date();
            const startDate = date.start_date !== "" ? date.start_date : currentDate.toISOString().slice(0,10);
            const endDate = date.end_date !== "" ? date.end_date : currentDate.toISOString().slice(0,10);
            let sendData = {
            //   indexValue: index,
            //   limit: perPage,
              from_start_date: startDate,
              to_start_date: endDate,
              customer_id:userData.customer_id,
              status:searchfilters.filterStatus,
             
            };
            let full_api =  config.apiUrl + '/shipment/shipment_reports';
           
            
        // console.log("sendData",sendData)
        axios.post(full_api, sendData, { headers: config.headers }).then(res => {
            // setdimensionState(res.data.output);
            if(res.data.status==true){
                setIsLoading(false)
                setShipstate(res.data.output)
                setShipcount({count:res.data.count , amount:res.data.amount})
            }
            
            // console.log("rnmmmmm", res);
          }).catch((e) => {
            // toast.configure()
             //toast.error("Some thing went wrong")
            // console.log("----error:   ", e);
          })
          };
          const { onDownload } = useDownloadExcel({
            currentTableRef: tableRef.current,
            filename: 'Reports Table',
            sheet: 'Shipment Reports'
        })
        const transformDataForExcel = () => {
          return shipstate.map(item => {
            console.log("created == ", item.created)
            const formattedDate = item.created
              ? new Date(item.created * 1000).toLocaleDateString('en-US', {
                year: 'numeric',
                month: 'short',
                day: 'numeric',
              })
              : '';
      
            const formattedTime = item.created
              ? new Date(item.created * 1000).toLocaleTimeString('en-US', {
                hour: 'numeric',
                minute: 'numeric',
                second: 'numeric',
                hour12: true,
              })
              : '';
            return {
              'Tracking Id': item?.carrier_tracking_number || '',
              'Reference Number': item?.order_number || '',
              'Pickup Warehouse': item?.warehouse_id || item.warehouse_name ||"",
              'Order Mode': item?.channel_name || "Custom-Single",
              'Receiver Name': item?.receiver[0]?.name || '',
              'Address': item?.receiver[0]?.address || '',
              'Mobile Number': item?.receiver[0]?.mobile || '',
              'City': item?.receiver[0]?.city || '',
              'State': item?.receiver[0]?.state || '',
              'Pincode': item?.receiver[0]?.pincode || '',
              // 'Carrier': item?.carrier_name || '',
              'Mode':  item?.product_type_name || '',
              'Carrier Product': item?.service_provider || '',
              'Current Status': getStatusText(item?.status) || '',
              'Weight (kg)': item?.total_weight || '',
              'L': item?.length || '',
              'B':item?.width || '',
              'H':item?.height || '',
              'Volumetric Weight (kg)': item?.volumetric_weight || '',
              'Chargeable Weight': item?.dead_weight || '',
              'Shipping Fee (₹)': item?.total_amount || '',
              'Shipment type': item?.type || '',
              'Payment Mode': item?.payment_mode === 'cod' || item?.payment_mode === 'cash' ? 'COD' : item?.payment_mode || '',
              'COD Amount': item?.cod_amount || '',
              'Zone Name':item?.zone_name || '',
              'Date': formattedDate,
              'Time': formattedTime,
            }
          });
        };
      
        const getStatusText = (status) => {
          // Define your status mappings here
          const statusMappings = {
            0: 'Pending',
            10: 'RTO',
            1: 'Pick Up Awaited',
            2: 'Picked Up',
            3: 'In Transit',
            4: 'Out For Delivery',
            5: 'Delivered',
            6: 'Cancelled',
            7: 'Failed',
            8: 'NDR',
            9: 'Pickup Scheduled',
      
          };
      
      
          return statusMappings[status] || '';
        }
        // const { onDownload } = useDownloadExcel({
        //   data: transformDataForExcel(),
        //   filename: 'Reports Table',
        //   sheet: 'Shipment Reports'
        // });
        const downloadExcel = () => {
          const data = transformDataForExcel();
          const ws = XLSX.utils.json_to_sheet(data);
          const wb = XLSX.utils.book_new();
          XLSX.utils.book_append_sheet(wb, ws, 'Shipment Reports');
          XLSX.writeFile(wb, 'Reports Table.xlsx');
        };

        const handleDate =(start, end)=>{
            setDate({...date, from_date:moment(start).format('YYYY-MM-DD'), to_date:moment(end).format("YYYY-MM-DD")})
        }
        React.useEffect(() => {
            // Initialize date range picker with today's date as the default range
            $('.custom-daterange').daterangepicker({
                opens: 'right',
                startDate: moment().subtract(30, 'days').format('YYYY-MM-DD'),  // Set start date to today's date
                endDate: moment().startOf('day'),    // Set end date to today's date
                autoUpdateInput: true,
                locale: {
                    format: 'YYYY-MM-DD'             // Display format
                }
            }, (start, end) => {
                handleDate(start, end); 
             
                      // Update selected range when a new range is chosen
            });
          
            // Set initial value in the input to show today's date
            $('.custom-daterange').val(`${moment().subtract(30, 'days').format('YYYY-MM-DD')} to ${moment().format('YYYY-MM-DD')}`);
          }, []);

          const copyAWBToClipboard = (item) => {
            navigator.clipboard.writeText(item).then(() => {
             
              const Toast = Swal.mixin({
                toast: true,
                position: 'bottom-end',
                showConfirmButton: false,
                timer: 3000
              })
                Toast.fire({
                background:"#3659cd",
                type: 'success',
                title: "AWB number copied to clipboard!",
                color:"white"
              });
            }).catch((err) => {
              console.error("Failed to copy text: ", err);
            });
          };
          const copyToClipboard = (item) => {
            navigator.clipboard.writeText(item).then(() => {
             
              const Toast = Swal.mixin({
                toast: true,
                position: 'bottom-end',
                showConfirmButton: false,
                timer: 3000
              })
                Toast.fire({
                background:"#3659cd",
                type: 'success',
                title: "Order number copied to clipboard!",
                color:"white"
              });
            }).catch((err) => {
              console.error("Failed to copy text: ", err);
            });
          };
        
  return (
    <div>
    
  
    <Header/>
 <div class="page-wrapper pinned">
  <div class="main-container">
   <div className='app-container'>
  <div class="app-body">
  <div class="container-fluid"> 

<div class="mob_nav">
  <div class="row">
      <div class="col-6">
        <div class=" d-flex justify-content-start ">
          <a href="javascript:window.history.back();" class=""><i class="bi bi-arrow-left"></i></a>
          <h2>Shipment Report</h2>
        </div>
      </div>
      <div class="col text-end">
      </div>
  </div>
</div>


<div class="row justify-content-between desk_nav">
    <div class="col-12 col-md-4 ">
      <div class="d-flex">
        <a href="javascript:window.history.back();" class="back_btn"><i class="bi bi-arrow-90deg-left"></i></a>
        <h2>Shipment Report</h2>
      </div>
    </div>
    <div class="col-12 col-md-4 mb-3">
      <div class="row justify-content-end">
          <div class="col-6 col-md-6">
            
          </div>
          <div class="col-6 col-md-4">
            
          </div>
      </div>
    </div>
</div>



<div class="card mb-3">
  <div class="card-body">
    <div class="row justify-content-between">
        <div class="col-12 col-md-6 mb-1">
          <div class="row g-1">
              <div class="col-12 col-md-6">
                <div class="input-group">
                  <input type="text" class="form-control custom-daterange" />
                  <span class="input-group-text">
                    <i class="bi bi-calendar2-range"></i>
                  </span>
                </div>
              </div>
              <div class="col-12 col-md-2">
                <div class="dropdown">
                  <button type="button" class="btn btn-outline-secondary dropdown-toggle" data-bs-toggle="dropdown">
                    All Status
                  </button>
                  <ul class="dropdown-menu">
                    <li class="px-3">
                      <div class="form-check">
                      <input class="form-check-input" type="checkbox" id="check1" name="pickup_awaited" value="something"  onChange={(e)=>filter(e,1)}/>
                      <label class="form-check-label">Pickup Awaited</label>
                      </div>
                    </li>
                    <li class="px-3">
                    <div class="form-check">
                               <input class="form-check-input" type="checkbox" id="check1" name="pickup_scheduled" value="something" onChange={(e)=>filter(e,9)}/>
                               <label class="form-check-label">Pickup Scheduled</label>
                           </div>
                    </li>
                    <li class="px-3">
                    <div class="form-check">
                               <input class="form-check-input" type="checkbox" id="check1" name="picked_up" value="something"  onChange={(e)=>filter(e,2)}/>
                               <label class="form-check-label">Picked Up</label>
                           </div>
                    </li>
                    <li class="px-3">
                    <div class="form-check">
                               <input class="form-check-input" type="checkbox" id="check1" name="in_transit" value="something"  onChange={(e)=>filter(e,3)}/>
                               <label class="form-check-label">In Transit</label>
                           </div>
                    </li>
                    <li class="px-3">
                    <div class="form-check">
                               <input class="form-check-input" type="checkbox" id="check1" name="out_for_delivery" value="something"  onChange={(e)=>filter(e,4)}/>
                               <label class="form-check-label">Out for Delivery</label>
                           </div>
                    </li>
                    <li class="px-3">
                    <div class="form-check">
                               <input class="form-check-input" type="checkbox" id="check1" name="delivered" value="something"  onChange={(e)=>filter(e,5)}/>
                               <label class="form-check-label">Delivered</label>
                           </div>
                    </li>
                      <li class="px-3">
                      <div class="form-check">
                               <input class="form-check-input" type="checkbox" id="check1" name="cancelled" value="something"  onChange={(e)=>filter(e,6)}/>
                               <label class="form-check-label">Cancelled</label>
                           </div>
                    </li> 
                     <li class="px-3">
                     <div class="form-check">
                               <input class="form-check-input" type="checkbox" id="check1" name="failed" value="something"  onChange={(e)=>filter(e,7)}/>
                               <label class="form-check-label">Failed</label>
                           </div>
                    </li> 
                  
                  </ul>
                </div> 
              </div>
              <div class="col-12 col-md">
                <a onClick={(e)=>getData()} class="btn btn-outline-secondary">Update Results</a>
              </div>
          </div>
        </div>
        <div class="col-12 col-md-6 mb-1">
          <div class="row justify-content-between">
              <div class="col-6 col-md-6">
              
              </div>
              <div class="col-6 col-md-6 text-end">
              <a onClick={downloadExcel} class="btn btn-primary">Download Report</a> 
              </div>
          </div>
        </div>
    </div>
  </div>
</div>

{(isLoading) ?
            <div style={{ backgroundColor: "#f1f5f9", zIndex: 2, height: "100%", width: "100%", position: "absolute", opacity: "0.4" }}>
                <div style={{ left: "47%", top: "45%", position: "absolute" }}>
                    <ReactLoading type={"spin"} color={"#000000"} height={300} width={89} />
                </div>
            </div> : 
<div class="card data_table mb-3 mb_display_none">
  <div class="card-body">
    <div class="table-responsive" >
      <div class="shipm_tablew rounded-3">
        <table class="table align-top table-striped table-hover m-0 table-xl nowrap" ref={tableRef}>
          <thead>
            <tr>
              <th width="250px">Shipment Details</th>
              <th width="200px">Shipping Carrier</th>
              <th>Shipping Fee</th>
              {/* <th>Shipping Zone</th> */}
              <th width="200px">Status</th>
              <th>Customer Details</th>
              <th>Value</th>
              <th>Package Details</th>
              {/* <th>Channel</th> */}
              <th>Warehouse Address</th>
              <th>Product Details</th>
            </tr>				
          </thead>
          { shipstate.map((item)=>
          <tbody>
            <tr class="">
              
              <td>
                <h6><a href="#" class="text-primary" data-bs-toggle="offcanvas" data-bs-target="#Shipment_data">AWB {item.carrier_tracking_number!=undefined && item.carrier_tracking_number!=""?item.carrier_tracking_number:""}</a> <a onClick={(e)=>copyAWBToClipboard(item.carrier_tracking_number)} data-bs-toggle="tooltip" title="Copy AWB Number"><i class="bi bi-c-square"></i></a> </h6>
                <div><small>Order ID: {item.order_number} <a onClick={(e)=>copyToClipboard(item.order_number)} data-bs-toggle="tooltip" title="Copy Order Number"><i class="bi bi-c-square"></i></a></small></div>
                <div><small><Moment format="MMM DD YYYY">
                        {new Date(item.created*1000)}
            </Moment> - <Moment format="hh:mm:ss a">
                        {new Date(item.created*1000)}
            </Moment></small></div>
                
              </td>
              <td class="shipment_carrier">
                <h6>{item.service_provider}</h6>
                {/* <img src="assets/images/carriers_logo/dhl.svg" class="img-fluid" /> */}
              </td>
              <td>
                ₹ {item.total_amount}
              </td>
              {/* <td>
                Local Zone
              </td> */}
              <td>
              {item.status==0?<span class="status pickup_awit"> <i class="bi bi-clock"></i> Pending</span>:item.status==10? <span class="status rtrn"> <i class="bi bi-arrow-clockwise"></i> RTO</span>:item.status==1?<span class="status pickup_awit"> <i class="bi bi-clock"></i> Pickup Awaited</span>:item.status==2?  <span class="status pickedup"> <i class="bi bi-building-fill-up"></i> Pickedup</span>:item.status==3?  <span class="status in_transit"> <i class="bi bi-truck"></i> In Transit</span>:item.status==4? <span class="status out_f_deli"> <i class="bi bi-rocket-takeoff"></i> Out for Delivery</span>:item.status==5? <span class="status delivered"> <i class="bi bi-check2-all"></i> Delivered</span>:item.status==6? <span class="status cancelled"> <i class="bi bi-x-square"></i> Cancelled</span>:item.status==7? <span class="status failed"> <i class="bi bi-x-octagon"></i> Failed</span>:item.status==8?<span class="status failed"> <i class="bi bi-x-octagon"></i> NDR</span>:item.status==15?<span class="status failed"> <i class="bi bi-x-octagon"></i> Booked</span>:item.status==16?<span class="status delivered"> <i class="bi bi-check2-all"></i>RTO Delivered</span>:item.status==17?<span class="status failed"> <i class="bi bi-x-octagon"></i>Pickup Failed</span>:item.status==9?<>  <span class="status pickup_sechdls"> <i class="bi bi-calendar"></i> Pickup Scheduled</span><br/><br/>
                {item.pickup_date!=undefined && item.pickup_date!=""?  <small>on {item.pickup_date!=undefined?item.pickup_date:""} at {item.pickup_time!=undefined?item.pickup_time:""}</small>:""} </>:<span class="badge rounded-pill bg-danger fw-400">Not Available</span>}
              
              </td>
            
                       
                          {item.receiver.map((sub)=>(
              <td>
                {sub.name}
                <div><small>{sub.city_name} - {sub.pincode}</small></div>
                <div><small>{sub.mobile}</small></div>
              </td>
               ))}
              <td>
                <div><span class="badge rounded-pill bg-warning">{item.payment_mode}</span></div>
              </td>
              <td>
                Dead wt. : {item.total_weight} kg
                <div><small>{item.height} x {item.width} x {item.length} (cm)</small></div>
                Volumetric wt.:  {item.volumetric_weight} Kg  
              </td>
              {/* <td>
              <a href="#" data-bs-toggle="tooltip" title="Direct Sales">Direct Sales</a>
              </td> */}
              <td>
              <a href="#"  data-bs-toggle="popover" data-bs-trigger="hover" data-bs-content="Mumbai WI, Warli, 400001">{item.warehouse_id}</a>
              </td>
              <td>{item.items[0].item_name.substring(0,20) + '...'} {item.items.length>1?"+1 items":""}
              </td>
            </tr>
          
          
        
           
           
          
       
          </tbody>
            )}
        </table>
      </div>
    </div>
  </div>
</div>
}


    </div></div>
    <Footer/></div></div></div>
    </div>
  )
}

export default Reports
