import React from 'react'

const Sidebar = () => {
  const [isActive, setIsActive] = React.useState(false);
  const [isActiveshipment, setIsActiveshipment] = React.useState(false);
  const [isActivetools, setIsActivetools] = React.useState(false);
  const [isActivebilling, setIsActivebilling] = React.useState(false);
  const [isActivereport, setIsActivereport] = React.useState(false);
  const [isActivesupport, setIsActivesupport] = React.useState(false);
  const [isActivesetting, setIsActivesetting] = React.useState(false);

  const [b2bisActive, setIsb2bActive] = React.useState(false);

 

  // Toggle function
  const toggleActiveClass = () => {
    setIsActive(!isActive);
  };
  const toggleActiveClassshipment = () => {
    setIsActiveshipment(!isActiveshipment);
  };
  const toggleActiveClasstools = () => {
    setIsActivetools(!isActivetools);
  };
  const toggleActiveClassbilling = () => {
    setIsActivebilling(!isActivebilling);
  };

  const toggleActiveClassreport = () => {
    setIsActivereport(!isActivereport);
  };

  const toggleActiveClasssupport = () => {
    setIsActivesupport(!isActivesupport);
  };
  const toggleActiveClasssetting = () => {
    setIsActivesetting(!isActivesetting);
  };
  const toggleActiveb2bClass = () => {
    setIsb2bActive(!b2bisActive);
  };
  const redirectToShipmentsPage = (statuses) => {
	const statusParam = statuses.join('%2C'); // Join status array with comma and encode
	window.location.href = `/shipments?status=${statusParam}`;
};
  const handleDeliveredShipmentsClick = () => {
	// Redirect to Shipments page with only status 5 included
	redirectToShipmentsPage([5]);
};
const handleBookedShipmentsClick = () => {
 
  redirectToShipmentsPage([15]);
};
const handleNdrShipmentsClick = () => {

redirectToShipmentsPage([8]);
};
const handleRtoShipmentsClick = () => {

redirectToShipmentsPage([10,19]);
};
const handleRtodeliveredShipmentsClick = () => {

	redirectToShipmentsPage([16]);
	};
const handleScheduledShipmentsClick = () => {

redirectToShipmentsPage([9]);
};
  return (
    <div>
      <div id="sidebar" class="sidebarMenuScroll">
						<ul class="sidebar-menu">
							<li class="active current-page">
								<a href="/dashboard">
									<i class="bi bi-pie-chart"></i>
									<span class="menu-text">Dashboard</span>
								</a>
							</li>
							<li className={`treeview ${isActive ? 'active' : ''}`}>
								<a href="#"onClick={toggleActiveClass}>
									<i class="bi bi-bag"></i>
									<span class="menu-text">Orders</span>
								</a>
								<ul class="treeview-menu">
									<li>
										<a href="/orders">Domestic Orders</a>
									</li>
									<li ><a href="/international_orders" >International Orders</a></li>

									<li>
										<a href="/create_order_1?value=0">Create New Order</a>
									</li>
									<li>
										<a href="/bulk_order_upload">Import Bulk Orders</a>
									</li>
								</ul>
							</li>
							<li className={`treeview ${b2bisActive ? 'active' : ''}`}>
								<a href="#"onClick={toggleActiveb2bClass}>
									<i class="bi bi-bag"></i>
									<span class="menu-text">B2B Orders</span>
								</a>
								<ul class="treeview-menu">
									<li>
										<a href="/b2b_orders">Domestic Orders</a>
									</li>
									{/* <li ><a href="/international_orders" >International Orders</a></li> */}

									<li>
										<a href="/create_order_b2b_1?value=0">Create New Order</a>
									</li>
									{/* <li>
										<a href="/bulk_order_upload">Import Bulk Orders</a>
									</li> */}
								</ul>
							</li>
							<li className={`treeview ${isActiveshipment ? 'active' : ''}`}>
								<a href="#!" onClick={toggleActiveClassshipment}>
									<i class="bi bi-box"></i>
									<span class="menu-text">Shipments</span>
								</a>
								<ul class="treeview-menu">
									<li>
										<a href="/shipments">All Shipments</a>
									</li>
									<li>
										<a onClick={handleBookedShipmentsClick} style={{cursor:"pointer"}}>Booked</a>
									</li>
									<li>
										<a onClick={handleScheduledShipmentsClick} style={{cursor:"pointer"}}>Pick-up Scheduled</a>
									</li>
									<li>
										<a onClick={handleDeliveredShipmentsClick} style={{cursor:"pointer"}}>Delivered</a>
									</li>
									{/* <li>
										<a onClick={handleNdrShipmentsClick}>NDR Shipments</a>
									</li> */}
									<li>
										<a onClick={handleRtoShipmentsClick} style={{cursor:"pointer"}}>RTO INTRANSIT</a>
									</li>
									<li>
										<a onClick={handleRtodeliveredShipmentsClick} style={{cursor:"pointer"}}>RTO DELIVERED</a>
									</li>
                                </ul>
							</li>
							<li>
								<a href="/pickup_request">
									<i class="bi bi-arrow-up-square"></i>
									<span class="menu-text">Pickup Requests</span>
								</a>
							</li>
							<li>
								<a href="/ndr">
									<i class="bi bi-exclamation-square"></i>
									<span class="menu-text">NDR</span>
								</a>
							</li>
							<li>
								<a href="/manifest_list">
									<i class="bi bi-stack"></i>
									<span class="menu-text">Manifest List</span>
								</a>
							</li>
							<li>
								<a href="/warehouse">
									<i class="bi bi-buildings"></i>
									<span class="menu-text">Warehouse</span>
								</a>
							</li>
							<li>
								<a href="/weight_discrepancies_list">
									<i class="bi bi-box2"></i>
									<span class="menu-text">Weight Discrepancy</span>
								</a>
							</li>
                            <li className={`treeview ${isActivetools ? 'active' : ''}`}>
								<a href="#!" onClick={toggleActiveClasstools}>
									<i class="bi bi-wrench"></i>
									<span class="menu-text">Tools</span>
								</a>
								<ul class="treeview-menu">
									<li>
										<a href="/rate_calculator">Rate Calculator</a>
									</li>
									<li>
										<a href="/order_allocation_engine">Order Allocation Engine</a>
									</li>
									<li>
										<a href="/aftership">AfterShip</a>
									</li>
									{/* <li>
										<a href="#">Boxes</a>
									</li> */}
								</ul>
							</li>
                            <li className={`treeview ${isActivebilling ? 'active' : ''}`}>
								<a href="#!" onClick={toggleActiveClassbilling}>
									<i class="bi bi-wallet"></i>
									<span class="menu-text">Billing</span>
								</a>
								<ul class="treeview-menu">
									<li>
										<a href="/billing_shipping_charges">Shipping Charges</a>
									</li>
									<li>
										<a href="/billing_cod_remittance">COD Remittance</a>
									</li>
									<li>
										<a href="/billing_passbook">Passbook</a>
									</li>
									<li>
										<a href="/billing_deduction">Deduction</a>
									</li>
									<li>
										<a href="billing_recharges">Recharge</a>
									</li>
									{/* <li>
										<a href="billing_refunded.php">Refund</a>
									</li> */}
									<li>
										<a href="/billing_weight_reconciliation">Weight Reconciliation</a>
									</li>
								</ul>
							</li>
              <li className={`treeview ${isActivereport ? 'active' : ''}`}>
              <a href="#!" onClick={toggleActiveClassreport}>
									<i class="bi bi-bar-chart-line"></i>
									<span class="menu-text">Reports</span>
								</a>
								<ul class="treeview-menu">
									<li>
										<a href="/shipment_report">Shipment Reports</a>
									</li>
									<li>
										<a href="/invoices_list">Invoices</a>
									</li>
								</ul>
							</li>
							{/* <li>
								<a href="team.php">
									<i class="bi bi-people"></i>
									<span class="menu-text">Team</span>
								</a>
							</li> */}
              <li className={`treeview ${isActivesupport ? 'active' : ''}`}>
              <a href="#!" onClick={toggleActiveClasssupport}>
									<i class="bi bi-headset"></i>
									<span class="menu-text">Support</span>
								</a>
								<ul class="treeview-menu">
									<li>
										<a href="/tickets">Support Tickets</a>
									</li>
									{/* <li>
										<a href="support-faq.php">Support FAQ's</a>
									</li> */}
								</ul>
							</li>
              <li className={`treeview ${isActivesetting ? 'active' : ''}`}>
              <a href="#!" onClick={toggleActiveClasssetting}>
									<i class="bi bi-gear"></i>
									<span class="menu-text">Settings</span>
								</a>
								<ul class="treeview-menu">
									<li>
										<a href="/channel_list">Channel Integration</a>
									</li>
									<li>
										<a href="/master_label_settings">Lable Settings</a>
									</li>
									{/* <li>
										<a href="#">Developer APIs</a>
									</li> */}
								</ul>
							</li>
						</ul>
					</div>
    </div>
  )
}

export default Sidebar
