import React from 'react'
import Footer from './Footer'
import Header from './Header'

const Bulk_lb_print = () => {
    const [orderNumbers, setOrderNumbers] = React.useState('');
    const[isLoading ,setisLoading ] = React.useState(false)
    const handleSubmit = () => {
        const selectedShipments = orderNumbers.split(/[\s,]+/).filter(Boolean);  // Split by commas or newlines, remove empty values
      
        // navigate(`/print_label/${selectedShipments.join(',')}`);
        window.open(`/print_label/${selectedShipments.join(',')}`, '_blank');
    }      
  return (
    <div>
         <Header/>
 <div class="page-wrapper pinned">
  <div class="main-container">
   <div className='app-container'>
  <div class="app-body">
  <div class="container-fluid"> 
<div class="mob_nav">
  <div class=" d-flex justify-content-start ">
    <a href="javascript:window.history.back();" class=""><i class="bi bi-arrow-left"></i></a>
    <h2>Bulk Invoice/Label Print</h2>
  </div>
</div>


<div class="row justify-content-between">
    <div class="col-12 col-md-4 desk_nav">
      <div class="d-flex">
        <a href="javascript:window.history.back();" class="back_btn"><i class="bi bi-arrow-90deg-left"></i></a>
        <h2>Bulk Invoice/Label Print</h2>
      </div>
    </div>
</div>

<div class="row justify-content-center">
    <div class="col-12 col-md-6">
      <div class="card">
        <div class="card-body">
          {/* <div class="mb-3">
            <div class="form-check form-check-inline">
              <input class="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio1" value="option1"/>
              <label class="form-check-label" for="inlineRadio1" checked >By AWB Number</label>
            </div>
            <div class="form-check form-check-inline">
              <input class="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio2" value="option2"/>
              <label class="form-check-label" for="inlineRadio2">By Order Number</label>
            </div>
          </div> */}
          {/* <div class="mb-3">
            <div class="form-check form-check-inline">
              <input class="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio1" value="option1"/>
              <label class="form-check-label" for="inlineRadio1" checked >Label</label>
            </div>
            <div class="form-check form-check-inline">
              <input class="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio2" value="option2"/>
              <label class="form-check-label" for="inlineRadio2">Invoice</label>
            </div>
          </div> */}
          <div class="my-3">
            <textarea class="form-control" rows="5" id="comment" name="text" placeholder="Enter values seprated by commas or in new line."  value={orderNumbers}
          onChange={(e) => setOrderNumbers(e.target.value)}></textarea>
          </div>
          <div class="d-flex justify-content-between">
            <div class="pe-3">
              <a onClick={handleSubmit} class="btn btn-primary">Submit</a>
            </div>
            <div>
              <small>Print orders using order ids or tracking number to get shipping labels and invoices
              </small>
            </div>
          </div>
          
        </div>
      </div>
    </div>
</div>

    </div></div>
    <Footer/></div></div></div>
    </div>
  )
}

export default Bulk_lb_print
