import React from 'react'

import { useNavigate, useParams } from 'react-router-dom'
import config from "../../config"
import axios from "axios";
import Swal from 'sweetalert2'
import Moment from 'react-moment';
import Barcode from 'react-barcode';
const Manifest_print = () => {
    const{manifest_id} = useParams();
    const[state,setState]= React.useState({manifest_list:{}})
    const[shipmentstate,setshipmentState]= React.useState([])

    let userDetail = localStorage.getItem('ship_rocket_user');
    // console.log("userDetail====shipments====", config.UserId);
    let userData = {};
    if (userDetail != null) {
      userData = JSON.parse(userDetail)
    }
    React.useEffect(()=>{
        ManifestList()
        ShipmentList()
    },[])
    const ManifestList=()=>{
       
          let dataToSend = {manifest_id:manifest_id};
        let url = config.apiUrl + '/shipment/manifest_list';
          
        axios.post(url, dataToSend, { headers: config.headers })
            .then((res) => {
            if(res.data.status==true){
                setState({manifest_list:res.data.output[0]})
            } 
            else{
                const Toast = Swal.mixin({
                    toast: true,
                    position: 'bottom-end',
                    showConfirmButton: false,
                    timer: 3000
                    })
                    
                    Toast.fire({
                    background:"#a61c09",
                    type: 'unsuccess',
                    title: "Something Went Wrong ",
                    color:"white"
                    });
            }
              })
            .catch((error) => {
                const Toast = Swal.mixin({
                    toast: true,
                    position: 'bottom-end',
                    showConfirmButton: false,
                    timer: 3000
                    })
                    
                    Toast.fire({
                    background:"#a61c09",
                    type: 'unsuccess',
                    title: "Something Went Wrong ",
                    color:"white"
                    });
            });    
  
      }
      const ShipmentList=()=>{
       
        let dataToSend = {manifest_id:manifest_id};
      let url = config.apiUrl + '/shipment/manifest_shipment_list';
        
      axios.post(url, dataToSend, { headers: config.headers })
          .then((res) => {
          if(res.data.status==true){
            setshipmentState(res.data.output)
          } 
          else{
              const Toast = Swal.mixin({
                  toast: true,
                  position: 'bottom-end',
                  showConfirmButton: false,
                  timer: 3000
                  })
                  
                  Toast.fire({
                  background:"#a61c09",
                  type: 'unsuccess',
                  title: "Something Went Wrong ",
                  color:"white"
                  });
          }
            })
          .catch((error) => {
              const Toast = Swal.mixin({
                  toast: true,
                  position: 'bottom-end',
                  showConfirmButton: false,
                  timer: 3000
                  })
                  
                  Toast.fire({
                  background:"#a61c09",
                  type: 'unsuccess',
                  title: "Something Went Wrong ",
                  color:"white"
                  });
          });    

    }
  return (
    <div>
      <div class="container mt-3">
        <div class="row justify-content-between">
            <div class="col col-md-4">
                <h2>{userData.company_name}</h2>
            </div>
            <div class="col col-md text-center">
                <h2   class="m-0 p-0">Manifest</h2>
                <div class="d-flex justify-content-center">
                    <div class="p-1">Generated on  <Moment format="MMM DD YYYY">
                        {new Date(state.manifest_list.date*1000)}
            </Moment> - <Moment format="hh:mm:ss a">
                        {new Date(state.manifest_list.date*1000)}
            </Moment></div>
                </div>
            </div>
            <div class="col-3 col-md-3 text-end">
                {/* <img src="https://i.imgur.com/Sx6F4aC.png" class="img-fluid" style={{maxHeight:"40px"}} /> */}
                 <Barcode value={state.manifest_list.manifest_id} height={40} />
                {/* <h5>MID: {state.manifest_list.manifest_id}</h5> */}
            </div>
        </div>

        <table class="table table-bordered border-dark bordered table-sm">
            <thead>
              <tr>
                <th>Sr No</th>
                <th>Order #</th>
                <th>AWB #</th>
                <th>Carrier</th>
                <th>Content</th>
                <th>Signature/Company Seal</th>
              </tr>
            </thead>
            <tbody>
                {shipmentstate.map((item, index)=>(
              <tr>
                <td>{index+1}</td>
                <td>
                    <h6>{item.order_number?item.order_number:""}</h6>
                </td>
                <td class="text-center">
                <Barcode value={item.carrier_tracking_number} height={40} />
                    {/* <img src="https://i.imgur.com/Sx6F4aC.png" class="img-fluid" style={{maxHeight:"40px"}} />
                    <h6 class="m-0 p-0">1234567890</h6> */}
                </td>
                <td>{item.carrier_name?item.carrier_name:""}</td>
                <td>
                    {item.items.map((sub)=>(<>
                        {sub.item_name}</>
                ))}
                <h6>{item.payment_mode} - {item.payment_mode=="cod"?`₹ ${item.cod_amount}`:""}</h6>
                </td>
                <td>
                    
                </td>
              </tr>
              ))}
            </tbody>
        </table>


        <div class="row mt-0">
            <div class="col-6 col-md-3">
                Receiver Name:
                <h4 class="py-5">____________</h4>
            </div>
            <div class="col-6 col-md-3">
                Receiver Date/Time:
                <h4 class="py-5">____________</h4>
            </div>
            <div class="col-6 col-md-3">
                Receiver Signature:
                <h4 class="py-5">____________</h4>
            </div>
            <div class="col-6 col-md-3">
                Total Shipments: {shipmentstate.length}
            </div>
        </div>

    </div>
    </div>
  )
}

export default Manifest_print
