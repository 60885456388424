import React from 'react'

import axios from "axios";
import Swal from 'sweetalert2';

import Moment from 'react-moment';

import config from "../../config"
import TablePagination from '@mui/material/TablePagination';
import { useNetworkState } from 'react-use';
import Footer from './Footer';
import Header from './Header';


const Billing_shipping_charges = () => {
    let userDetail = localStorage.getItem('ship_rocket_user');
    // console.log("userDetail====", config.UserId);
    let userData = {};
    if (userDetail != null) {
      userData = JSON.parse(userDetail)
    }
    const isOnline = useNetworkState();
    console.log("isONline  === " ,isOnline )
    const [state,setState] = React.useState([])
    const[loading, setloading] = React.useState(true)
  const [otherStates,setOtherState] = React.useState({dstatus:"",activePage:1,rowsPerPage:20,page:0,total_count:0,onload:true});
  
    React.useEffect(()=>{
        list(0,true)
       
    },[otherStates.rowsPerPage])

    const list = (index=0,onLoad)=>{

        let dataToSend = {customer_id : userData.customer_id , limit:otherStates.rowsPerPage,indexValue:index};
    
        // console.log("datatoSend",dataToSend)
        let url = config.apiUrl + '/user/shipping_charges_transaction';
        axios.post(url, dataToSend, { headers: config.headers })
            .then((res) => {
              // console.log("responseJson => ", res);
              if(res.data.status==true){
                if( onLoad) {
                  setOtherState({...otherStates,total_count:res.data.count ,  page:index})         
    
      }
       setState(res.data.output)
       setloading(false)
       }
      else{
          const Toast = Swal.mixin({
              toast: true,
              position: 'bottom-end',
              showConfirmButton: false,
              timer: 3000
            })
            Toast.fire({
              background:"#a61c09",
              type: 'Unsuccess',
              title: "Something Went Wrong !",
              color:"white"
            });
      } })
          
            .catch((error) => {
              const Toast = Swal.mixin({
                  toast: true,
                  position: 'bottom-end',
                  showConfirmButton: false,
                  timer: 3000
                })
                Toast.fire({
                  background:"#a61c09",
                  type: 'Unsuccess',
                  title: "Something Went Wrong !",
                  color:"white"
                });
                // console.log(error);
            });    
    }
    
    const handlePageChange = (event,newPage) =>{
      setOtherState({...otherStates,page:newPage})
      // console.log("newPage ", newPage);
      // getCountryList(newPage);
      list(newPage,true)
  }
  const handleChangeRowsPerPage = (event , newPage) => {
      // console.log("event ", event.target.value);
      setOtherState({ ...otherStates, rowsPerPage: event.target.value })
       
  }
     
  
  return (
    <div>
          <Header/>
 <div class="page-wrapper pinned">
  <div class="main-container">
   <div className='app-container'>
  <div class="app-body">
  <div class="container-fluid"> 
<div class="mob_nav">
  <div class="row">
      <div class="col">
        <div class=" d-flex justify-content-start ">
          <a href="javascript:window.history.back();" class=""><i class="bi bi-arrow-left"></i></a>
          <h2>Shipping Charges</h2>
        </div>
      </div>
  </div>
</div>


<div class="row justify-content-between desk_nav">
    <div class="col-12 col-md-4 ">
      <div class="d-flex">
        <a href="javascript:window.history.back();" class="back_btn"><i class="bi bi-arrow-90deg-left"></i></a>
        <h2>Shipping Charges</h2>
      </div>
    </div>
    <div class="col-12 col-md-4 mb-3">
    </div>
</div>


{/* <div class="card mb-3">
  <div class="card-body">
    <div class="row justify-content-between">
        <div class="col-12 col-md-6 mb-1">
          <div class="row g-1">
              <div class="col-12 col-md-6">
                <div class="input-group">
                  <input type="text" class="form-control custom-daterange"/>
                  <span class="input-group-text">
                    <i class="bi bi-calendar2-range"></i>
                  </span>
                </div>
              </div>
              <div class="col-12 col-md-6">
                <div class="search-box">
                    <div class="search-icon">
                    <i class="bi bi-search"></i>
                    </div>
                    <input type="text" class="search-input" placeholder="Search ..."/>
                </div>
              </div>
          </div>
        </div>
        <div class="col-12 col-md-6 mb-1">
          <div class="row justify-content-between">
              <div class="col-6 col-md">
                <div class="row">
                    <div class="col-12 col-md">
                      <a href="" class="btn btn-outline-secondary btn-block">View Report</a>
                    </div>
                </div>
              </div>
              <div class="col-6 col-md-4 text-end">
                <a href="" class="btn btn-outline-secondary btn-block">Download Report</a>
              </div>
          </div>
        </div>
    </div>
  </div>
</div> */}

<div class="card data_table mb-3 ">
  <div class="card-body">
    <div class="table-responsive" >
      <div class="shipm_tablew rounded-3">
        <table class="table align-top table-striped table-hover m-0 table-xl ">
          <thead>
            <tr>
              <th width="170px">Date</th>
              <th width="140px">Transaction ID</th>
              {/* <th width="">Order Number</th> */}
              <th width="200px">AWB Number</th>
              <th>Weight & Zone</th>
              <th>Carrier</th>
              <th>Particluars</th>
              <th>Credit</th>
              <th>Debit</th>
            </tr>					
          </thead>
          <tbody>
          {state.length>0?
                        state.map((sub)=>(
            <tr class="">
              <td>
                <small><Moment format="MMM DD YYYY">
                        {new Date(sub.created*1000)}
            </Moment> - <Moment format="hh:mm:ss a">
                        {new Date(sub.created*1000)}
            </Moment></small>
              </td>
              <td>                
                <a href="#" class="text-primary">{sub.transaction_id}</a>
              </td>
              {/* <td>
                <h6><a href="#" class="text-primary">1724228987370</a></h6>
              </td> */}
              <td>
                <h6><a href="#" class="text-primary"> {sub.carrier_tracking_number}</a></h6>
              </td>
              <td>
              {sub.total_weight} KG
              <small> {sub.zone_name?sub.zone_name:""}</small>
              </td>
              <td>
              {sub.service_provider} 
              </td>
              <td>
              {sub.status==6? "Refunded":"Shipping Charges"}
              </td>
              <td>
              <span class="text-success">{sub.status==6 ?<>₹ {sub.total_amount}</> :""}</span>
              </td>
              <td>
              <span class="text-danger">{sub.status!=6 && sub.status!=0?<>- ₹ {sub.total_amount}</> :""}</span>
              </td>
            </tr>
                        )):  <tr>
                        <td colspan="12" class="text-center">
                            Sorry! There is no data available at the moment.
                        </td>
                      </tr>
                  }
           
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>


  
      
       </div>
       <TablePagination
                component="div"
                rowsPerPageOptions={[20,25,30,35,40,45,50]}
                count={otherStates.total_count}
                page={otherStates.page}
                onPageChange={handlePageChange}
                rowsPerPage={otherStates.rowsPerPage}
                onRowsPerPageChange={handleChangeRowsPerPage}
               
                        /></div>
       <Footer/></div></div></div>
  
    </div>
  )
}

export default Billing_shipping_charges
