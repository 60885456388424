import React from 'react'
import { useNavigate } from 'react-router-dom';
import { Container, Row, Col, Form, Button, ProgressBar, ModalHeader, ModalTitle } from "react-bootstrap";
// import './customer_style.css';
import Modal from 'react-bootstrap/Modal';

import { Tooltip as ReactTooltip } from "react-tooltip";
import axios from "axios";
import Swal from 'sweetalert2'
import { useLocation } from 'react-router-dom';
import ReactLoading from 'react-loading';
import config from "../../config"
import Moment from 'react-moment';

import download from 'downloadjs'

import Header from './Header';
import Create_order_nav from './Create_order_nav';
import Papa from 'papaparse';
import { useNetworkState } from 'react-use';
import { CSVLink, CSVDownload } from "react-csv";
import Footer from './Footer';


const Order_import = () => {
   
  let navigate = useNavigate()
  const isOnline = useNetworkState();
  // console.log("isONline  === ", isOnline)
  let userDetail = localStorage.getItem('ship_rocket_user');
  // console.log("userDetail====", config.UserId);
  let userData = {};
  if (userDetail != null) {
    userData = JSON.parse(userDetail)
  }
  const [recordid, setRecordid] = React.useState({ record_id: "" })
  const [uploadModalState, setUploadModalState] = React.useState({ show: false, excel_fle: "" });
  const [dimensionState, setdimensionState] = React.useState({ companyDetail: [] })
  const [state, setState] = React.useState({ shipment_list: [], isLoading: true })
  const [shipmentstate, setshipStatus] = React.useState({ shipment_status: null });
  const [shipSelect, setShipSelect] = React.useState({ shipment_id: "", cancel_reason: "" });
  const [modalState, setModalState] = React.useState({ show: false });
  const [modaldtdtcState, setModaldtdcState] = React.useState({ show: false });
  const [uploadState, setUploadState] = React.useState({ status: "" });
  const [csvFileError, setCsvFileError] = React.useState([])
  const [dtdc, setdtdc] = React.useState();
  const [loadingstate, setLoadingstate] = React.useState(false)
  const [summarystate, setSummarystate] = React.useState(false)
  const [statesummary, setsummaryState] = React.useState([])
  const [summaryloader, setSummaryLoader] = React.useState(false)
  const [isSidebarOpen, setIsSidebarOpen] = React.useState(false);
  const [loader, setloader] = React.useState(false);
  const [dataloader, setdataloader] = React.useState(true);


  
  const toggleSidebar = () => {
      setIsSidebarOpen(!isSidebarOpen);
      const homeSection = document.querySelector('.home-section');
      if (homeSection) {
          homeSection.classList.toggle('sidebar-closed');
      }
  };


  const Toast = Swal.mixin({
    toast: true,
    position: 'bottom-end',
    showConfirmButton: false,
    timer: 5000
  })

  // console.log("shipmentState",shipmentstate)
  React.useEffect(() => {

    shipment_list()
    dimension_detail()

  }, [])

  const shipment_list = (status) => {
    setdataloader(true)
    let dataToSend = {
      customer_id: userData.customer_id, status: status
    };

    // console.log("datatoSend",dataToSend)
    let url = config.apiUrl + '/shipment/view_order_file_summary';

    // // console.log("headers => ", config.headers);


    axios.post(url, dataToSend, { headers: config.headers })
      .then((res) => {
        // console.log("responseJson => ", res);
        // setSummarystate(res.data.output)
        setsummaryState(res.data.output)
        setdataloader(false)

      })
      .catch((error) => {
        setdataloader(false)
        //Hide Loader
        //   setLoadingstate({...loadingstate,loading:false})
        // console.log(error);
      });

  }

  const dimension_detail = () => {
    let full_api = config.apiUrl + `/user/get_company_info_api`;
    let sendData = {};

    axios.post(full_api, sendData, { headers: config.headers }).then(res => {
      setdimensionState({ ...dimensionState, companyDetail: res.data.companyDetail });
      // console.log("rnmmmmm", res);
    }).catch((e) => {
      // toast.configure()
      //toast.error("Some thing went wrong")
      // console.log("----error:   ", e);
    })
  }


  const csvinputEditHandleChange = (e) => {
    // console.log(e.target.files[0].type);
    if (e.target.type == "file") {

      setCsvFileError([])
      if (e.target.files.length > 0) {
        // setUploadModalState({ ...uploadModalState, [e.target.name]: e.target.files });
        if (e.target.files[0].type == "text/csv") {
          setloader(true)
          Papa.parse(e.target.files[0], {
            header: true,
            skipEmptyLines: true,
            complete: function (results) {
              // console.log("re => ", results.data)
             
              checkCsvFile(results.data, e.target.files)
            },
          });
        } else {
          const Toast = Swal.mixin({
            toast: true,
            position: 'bottom-end',
            showConfirmButton: false,
            timer: 3000
          })
          Toast.fire({
            background: "#ff0000",
            type: 'error',
            title: "Please select CSV file only",
            color: "white"
          });
        }
      }
    } else {
      setUploadModalState({ ...uploadModalState, [e.target.name]: e.target.value });
    }
  }

 
const checkCsvFile = (data, file) => {
 
  const pincodeLength = 6;
  const mobileLength = 10;
  const keyFields = [
      "Order_ID", "Warehouse_Name", "Receiver_Name", "Receiver_Address", 
         "Receiver_Pincode", "Receiver_Email", "Receiver_Mobile", "Item_Name", 
         "Height", "Width", "Length", "Weight (KG)", "Item_Value", 
         "Item_Quantity", "Payment_Mode", "COD_Value"
  ];

  const csvError = [];
  let validFile = true;

  data.forEach((item, index) => {
      const rowError = Array(keyFields.length).fill(""); // Initialize row error array
      let isValid = true;

      // Check for missing fields
      const missingFields = keyFields.filter(field => !(field in item));
      if (missingFields.length > 0) {
          missingFields.forEach(field => {
              const colIndex = keyFields.indexOf(field);
              rowError[colIndex] = "MISSING FIELD";
          });
          isValid = false;
      }

      // Validate fields only if no missing fields
      if (isValid) {
          keyFields.forEach((field, i) => {
              const value = item[field];

              // Blank value check (skip for COD_Value and Receiver_Email)
              if (!value && field !== "COD_Value" && field !== "Receiver_Email") {
                  rowError[i] = "BLANK VALUE";
                  isValid = false;
              }

              // Validate specific fields
              if (field === "Receiver_Pincode" && value && value.length !== pincodeLength) {
                  rowError[i] = "INVALID PINCODE";
                  isValid = false;
              }
              if (field === "Receiver_Mobile") {
                  if (value && value.length !== mobileLength) {
                      rowError[i] = "INVALID MOBILE LENGTH";
                      isValid = false;
                  } else if (value && isNaN(value)) {
                      rowError[i] = "INVALID MOBILE FORMAT";
                      isValid = false;
                  }
              }
              if (field === "Receiver_Email" && value && !value.match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/)) {
                  rowError[i] = "INVALID EMAIL";
                  isValid = false;
              }
              if (field === "COD_Value" && (!value || isNaN(value)) &&
                  (item["Payment_Mode"]?.toLowerCase() === "cod")) {
                  rowError[i] = "COD VALUE REQUIRED";
                  isValid = false;
              }
          });
      }

      // Add row errors if invalid
      if (!isValid) {
          csvError.push({
              rowIndex: index + 1,
              errors: rowError
          });
          validFile = false;
      }
  });

  if (validFile) {
      setUploadModalState({ excel_fle: file, show: false });
      setloader(false)
     
  } else {
    setloader(false)
      // Format errors for toast display
      const errorMessages = csvError.map(({ rowIndex, errors }) => 
          errors.map((error, colIndex) => 
              error ? `Row ${rowIndex} Column ${keyFields[colIndex]}: ${error}` : ""
          ).filter(Boolean)
      ).flat();

      const Toast = Swal.mixin({
          toast: true,
          position: 'bottom-end',
          showConfirmButton: false,
          timer: 3000
      });
      errorMessages.forEach(message => {
          Toast.fire({
              background: "#ff0000",
              icon: 'error',
              title: message,
              color: "white"
          });
      });
  // 

  }
};
  const uploadOrdersFile = () => {
    setLoadingstate(true)
    if (uploadModalState.excel_fle != undefined && uploadModalState.excel_fle != '') {
      // var patt1 = /\.([0-9a-z]+)(?:[\?#]|$)/i;
      let fileName = uploadModalState.excel_fle[0].name;
      // // console.log("fileName =======>                 ",fileName);

      // // console.log("file =======>                 ",fileName.match(/\.([0-9a-z]+)(?:[\?#]|$)/i)[0]);
      // return 
      if (fileName.match(/\.([0-9a-z]+)(?:[\?#]|$)/i)[0] == ".csv") {
        Papa.parse(uploadModalState.excel_fle[0], {
          complete: (result) => {
            const dataLength = result.data.length;
            // console.log("dataLength == " , dataLength)

            if (dataLength <= 1001) {
              let full_api = config.apiUrl + `/shipment/upload_bulk_orders`;
              let bodyFormData = new FormData()
              // // console.log("bb", editState);

              bodyFormData.append("customer_id", userData.customer_id)



              bodyFormData.append("excel_fle", uploadModalState.excel_fle[0]);

              // let bodyData = {country_id:country_id,csvFile:uploadModalState.csv_fle}
              // console.log("bb", bodyFormData);
              let header = config.headers;
              header.Accept = "text/event-stream";
              console.log(header);
              axios({
                method: "post",
                url: full_api,
                headers: header,
                data: bodyFormData,
                // responseType: "stream",
                // onDownloadProgress: progressEvent => {
                //    console.log("progressEvent ", progressEvent.currentTarget.response);
                //   let json = progressEvent.currentTarget.response.split("Z");
                //   json = JSON.parse(json[json.length - 1]);
                //   // console.log(json)
                //   if (json.status != "pending") {

                //     // console.log(v)

                //     Toast.fire({
                //       background: "#206bc4",
                //       type: 'success',
                //       title: "Bulk Import Finished",
                //       color: "white"
                //     });
                //     shipment_list()
                //     setLoadingstate(false)
                //     setSummarystate(true)
                //     setRecordid({ record_id: json.data })
                //     setUploadState({ ...uploadState, status: "" })

                //   } else {
                //     // console.log(json.data);
                //     let v = json.data.split("S");
                //     v = v[v.length - 1];
                //     setUploadState({ ...uploadState, status: v })
                //     setRecordid({ record_id: json.record_id })
                //   }

                // }
              }).then((res) => {
                console.log("res ", res);

                // setUploadModalState({...uploadModalState, show: false });
                //  setEditState({ ...editState, state_name: "", state_id: "" })


                if (res.status == 200) {
                  if (res.data.status == "success") {
                    setLoadingstate(false)
                    setSummarystate(true)
                    setRecordid({ record_id: res.data.record_id })
                    
                    Toast.fire({
                      background: "#206bc4",
                      type: 'success',
                      title: "Bulk Order Import Process Started",
                      color: "white"
                    });
                    shipment_list()
                    // setTimeout(getStateList(0,true), 5000);
                  } else {
                    //setUploadState(res.data)
                  }


                  // shipment_list()
                  // setTimeout(()=>{
                  //   navigate(`/import_process/${product_id}/${carrier_id}/${source_city_id}`)
                  // },1000)

                }

                else {
                  console.log("res unsuccess");
                  Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Something went wrong!',

                  })
                }

                // navigate('/categories')
                // let newCategory = res.data.category;
                //setState(state.concat([newCategory]))
              }).catch((e) => {
                console.log("error  ", e);
                // Swal.fire({
                //   icon: 'error',
                //   title: 'Oops...',
                //   text: 'catch Something went wrong!',

                // })
              });
            }
            else {
              setLoadingstate(false)
              setSummarystate(false)
              Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Not Allowed to Import more than 1000 orders in one file ',

              })
            }
          }
        })

      } else {
        const Toast = Swal.mixin({
          toast: true,
          position: 'bottom-end',
          showConfirmButton: false,
          timer: 3000
        })
        Toast.fire({
          background: "#206bc4",
          type: 'error',
          title: "Please choose CSV file!",
          color: "white"
        });
        setLoadingstate(false)
      }
    } else {
      // const Toast = Swal.mixin({
      //   toast: true,
      //   position: 'bottom-end',
      //   showConfirmButton: false,
      //   timer: 3000
      // })
      // Toast.fire({
      //   background: "#206bc4",
      //   type: 'error',
      //   title: "Please choose a file!",
      //   color: "white"
      // });
      setLoadingstate(false)
    }
}
  React.useEffect(()=>{
    uploadOrdersFile()
  },[uploadModalState.excel_fle])
  return (
    <div>
    <Header/>
 <div class="page-wrapper pinned">
  <div class="main-container">
   <div className='app-container'>
  <div class="app-body">
  <div class="container-fluid"> 


<div class="mob_nav">
  <div class=" d-flex justify-content-start ">
    <a href="javascript:window.history.back();" class=""><i class="bi bi-arrow-left"></i></a>
    <h2>Create Order</h2>
  </div>
</div>

<div class="row justify-content-between">
    <div class="col-12 col-md-4 desk_nav">
      <div class="d-flex">
        <a href="javascript:window.history.back();" class="back_btn"><i class="bi bi-arrow-90deg-left"></i></a>
        <h2>Create Order</h2>
      </div>
    </div>
    
</div>
<Create_order_nav/>
<hr/>

<div class="row">
  <div class="col-12 col-md">
    <div class="card border-0 order_box mb-4">
      <div class="card-body">
          <section class="pb-5">
            <div class="row mb-3">
                <div class="col-12 col-md-8 mb-3 fileinput">
                    <div class="card text-center border-primary border-3 rounded-3">
                    {loader==false?
                            recordid.record_id != "" ? <div class="text-center">
                            <a href={`/detail_summary/${recordid.record_id}`} class="btn btn-primary btn-block">View summary</a>
                          </div>
                            :
                      <div class="card-body">
                        <label class="custom-file-upload">
                          <input type="file" name="excel_fle" onChange={(e) => csvinputEditHandleChange(e)}/>
                          <i class="bi bi-cloud-arrow-up"></i> Browse and Upload
                        </label>
                        <p><small>Please download and use sample template file </small><br/>
                        <small>Only csv file format is acceptable</small></p>


                      <h6>Creating Orders</h6>
                      <p>This is going to take a few minutes. Kindly request your patience.</p>
                      </div>
                      :  <div className='' style={{alignItems:"center" , marginLeft:"50%"}}> <ReactLoading type={"spin"} color={"#0d6efd"} height={40} width={40} /></div>
                           
                            
                            }
                    </div>
                </div>
                <div class="col-12 col-md-4">
                    <div class="card border-0 shadow-lg p-3">
                      <div class="card-body">
                      <h3><i class="bi bi-lightbulb"></i></h3>
                        <ul>
                          <li>Download the sample file and replace its data with your order data.</li>
                          <li>Make sure all mandatory fields are filled. Save the file and upload it back.</li>
                        </ul>

                        <div class="d-grid">
                          <a href="../../../assets/doc/DOMESTIC_BULK_ORDER_UPLOAD.csv"  target="_blank" class="btn btn-primary btn-block">Download Sample File</a>
                        </div>
                      </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-12 col-md-8 alerts_box ">
                  {/* <div class="alert alert-danger mb-3">
                    <div class="row">
                        <div class="col-12 col-md">
                          <div class="d-flex ">
                              <div class="">
                              <i class="bi bi-exclamation-circle"></i>
                              </div>
                              <div class="ps-2 ">
                                <h5>File could not be processed</h5>
                                <p>Please fix the errors and re-upload</p>
                              </div>
                          </div>
                        </div>
                        <div class="col-12 col-md-4">
                          <a href="#" class="btn btn-outline-danger btn-sm">Download Error Report</a>
                        </div>
                    </div>
                  </div> */}
                  {/* <div class="alert alert-success">
                    <div class="row">
                        <div class="col-12 col-md">
                          <div class="d-flex ">
                              <div class="">
                              <i class="bi bi-check-circle"></i>
                              </div>
                              <div class="ps-2 ">
                                <h5>This task will run in the background</h5>
                                <p>We'll notify you once it done</p>
                              </div>
                          </div>
                        </div>
                        <div class="col-12 col-md-4">
                      
                          <a href="bulk_order_log_detail.php" class="btn btn-outline-dark btn-sm">Go to summery</a>
                        </div>
                    </div>
                  </div> */}
                    {/* <div style={{ float: "right" }}>
                        {loadingstate == false ? (recordid.record_id != "") ? <div class="col-12 col-md-4">
                          <a href={'/detail_summary/' + recordid.record_id} class="btn btn-primary btn-block">View summary</a>
                        </div> : <a class="btn btn-primary btn-block" onClick={() => uploadOrdersFile()} >Submit</a> : loadingstate == true && summarystate == false ? <ReactLoading type={"spin"} color={"#0d6efd"} height={40} width={40} /> : ""}

                      </div> */}
                </div>
                <div class="col-12 col-md-4"></div>
            </div>
          </section>
      </div>
    </div>
    <div class="card border-0 order_box mb-4">
      <div class="card-body">
        <section class="pb-5">
          <div class="alert alert-light">
            <small>The successful orders will be directed to Process Orders. You can download your error files from below. If you cannot find the file you’re looking for, Please go to <a href="bulk_order_log.php">Activity Log</a> for the same.</small>
          </div>
            <div class="row pb-4">
                <div class="col-12 col-md">
                  <h6 class="mb-3">Recent Uploads</h6>
                  {dataloader ?<div style={{marginLeft:"50%" , alignItems:"center"}}><ReactLoading type={"spin"} color={"#0d6efd"} height={40} width={40} /></div>  :
                    <div class="table-responsive upload_table">
                      <table class="table">
                          <thead>
                            <tr>
                              <th>File Name</th>
                              <th>Date</th>
                              <th>No. Of Orders</th>
                              <th>Uploaded Orders</th>
                            
                              <th>Log</th>
                            </tr>
                          </thead>
                          {summaryloader ? <ReactLoading type={"spin"} color={"#0d6efd"} height={40} width={40} /> :
                                <tbody>
                                  {statesummary.map((sub) => (
                                    <tr>
                                      <td><a href="">{sub.file_name}</a></td>
                                      <td><Moment format="MMM DD YYYY">
                                        {new Date(sub.start_time * 1000)}
                                      </Moment> - <Moment format="hh:mm:ss a">
                                          {new Date(sub.start_time * 1000)}
                                        </Moment> </td>
                                      <td>{sub.total_record}</td>
                                      <td>{sub.uploaded_record}</td>
                                      {/* <td>09</td> */}
                                      <td><a href={`/detail_summary/${recordid.record_id ? recordid.record_id : sub.record_id}`}>Import log <i class="fa fa-long-arrow-right" aria-hidden="true"></i></a></td>
                                    </tr>
                                  ))}
                                </tbody>
                              }
                        </table>
                    </div>
}
                </div>
            </div>
        </section>
      </div>
    </div>
  </div>
</div>




      </div>
    </div></div>
    <Footer/>
    </div></div>
    </div>
  )
}

export default Order_import
