import React from 'react'
import Common from './Common'


const Footer = () => {
  return (
    <div>
       <div className="app-footer">
      <div className="mb_display_none">
        <span>© SkyShip @ {new Date().getFullYear()}</span>
      </div>
      <div className="d-flex justify-content-center mobile_foo_nav text-center desk_display_none">
        <a href="/home" className="active"><i className="bi bi-house"></i></a>
        <a href="/orders"><i className="bi bi-bag"></i></a>
        <a href="/shipments"><i className="bi bi-box"></i></a>
        <a href="/wallet_recharge"><i className="bi bi-wallet"></i></a>
        <a href="/settings"><i className="bi bi-gear"></i></a>
      </div>
    </div>
    <Common/>
    </div>
   
  )
}

export default Footer
