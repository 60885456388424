import React, {  useRef } from 'react'

import { useLocation, useNavigate } from 'react-router-dom';
import axios from "axios";
import Swal from 'sweetalert2'
import { Container, Row, Col, Form, Button, ProgressBar } from "react-bootstrap";
// import './customer_style.css';
import Barcode from 'react-barcode';
import Modal from 'react-bootstrap/Modal';
// import { useLocation } from 'react-router-dom';
import ReactLoading from 'react-loading';
import config from "../../config"
import download from 'downloadjs'
import Moment from 'react-moment';
import TablePagination from '@mui/material/TablePagination';
import htmlToPdfmake from 'html-to-pdfmake';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';

import ReactDOM from 'react-dom';
import ReactDOMServer from 'react-dom/server';
import moment from 'moment';
import html2canvas from 'html2canvas';
// import BarcodeComponent from './BacodeComponent'
import { renderToString } from 'react-dom/server';
import createRoot from 'react-dom';
import { useNetworkState } from 'react-use';
import { BarcodeIcon, CornerUpLeft, DollarSign, FileDown, Package, PackageCheck, PackageIcon, PackageSearch, ShoppingBag, ShoppingBasket, Truck, TruckIcon } from 'lucide-react';
import Header from './Header';
import Footer from './Footer';
import $ from 'jquery'; 
import 'daterangepicker/daterangepicker.css'; // Import daterangepicker CSS
import 'daterangepicker';


const Ndr = () => {
  pdfMake.vfs = pdfFonts.pdfMake.vfs; 
  let navigate = useNavigate()
  const isOnline = useNetworkState();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const pendingOrder = searchParams.get('pending');
  // console.log("isONline  === " ,isOnline )
  // const barcodeRef = useRef(null);
  let userDetail = localStorage.getItem('ship_rocket_user');
  // console.log("userDetail====shipments====", config.UserId);
  let userData = {};
  if (userDetail != null) {
    userData = JSON.parse(userDetail)
  }
const [openOffcanvasfilter, setOpenOffcanvasfilter] = React.useState(false);
  const[history , sethistory] = React.useState({ndr_history:[],awb:""})
  console.log("history == " , history)
  const[ndrmodal , setndrmodal] = React.useState(false)
  const[bulkndrmodal , setbulkndrmodal] = React.useState(false)
  const[bulkndrloader , setbulkndrloader] = React.useState(false)

  const[ndrloader , setndrloader] = React.useState(false)

  const[loaderpickup , setloaderpickup] = React.useState(false)
  const[dimensionState,setdimensionState] = React.useState({companyDetail:[]})
  const[state,setState]= React.useState({shipment_list:[],isLoading:true , count_all:0 , count_action_required:0,count_action_requested:0, count_delivered:0 , count_rto:0})
  const[labelstate,setlabel]= React.useState({shipment_list:[],isLoading:true})
  const[searchstate,setsearchstate]= React.useState({search_shipment:""})
  const [isSidebarOpen, setIsSidebarOpen] = React.useState(false);
  const [shipmentstate, setshipStatus] = React.useState({ shipment_status: null });

  const[isLoading , setisLoading] = React.useState(true)
  
  // console.log("pickupstate ==== " , pickupstate)
  const [selectedShipments, setSelectedShipments] = React.useState([]);
  // console.log("selectedShipments === " ,selectedShipments)
  const [selectCount ,setSelectCount] = React.useState({select_count:0 })
  const[addressState, setaddressState] = React.useState([]);
  
  const [otherStates,setOtherState] = React.useState({dstatus:"",activePage:1,rowsPerPage:20,page:0,total_count:0,onload:true});
  // console.log("otherState ==== " , otherStates)


  const [selectedDateTime, setSelectedDateTime] = React.useState(null);
  const [selectedTime, setSelectedTime] = React.useState('9:00');
  const[cancelshipment , setcancelshipment] = React.useState(false)
  const [openOffdetailcanvas, setOpenOffdetailcanvas] = React.useState(false);
  const[bookorder , setbookorder] = React.useState([])
  console.log("bookorder == " , bookorder)
  
  const[date, setdate] = React.useState({from_date:moment().subtract(30, 'days').format('YYYY-MM-DD'),to_date:moment().format('YYYY-MM-DD')})
  const[paymentmode, setpaymentmode] = React.useState({cod:false,prepaid:false})
  const[warehouse , setwarehouse] = React.useState([])
  const [selectedWarehouses, setSelectedWarehouses] = React.useState([]);
  const [searchfilters, setsearchFilters] = React.useState({ searchtitle: "",filter:[{name:"booked",value:1,status:true},{name:"assigned",value:2,status:true},{name:"intransit",value:3,status:true},{name:"delivered",value:4,status:true}],filterStatus:[]})
  const[failedstate , setfailed] = React.useState({failed:""})
  const [trackingstate,setTrackingState] = React.useState({track:{},isLoading:true})
  const [stateship, setStateship] = React.useState({ shipmentDetail: {sender:{},receiver:{},items:[] }, isLoading: true,detailFound:false,parcel_count:0  })
  const [showComponent, setShowComponent] = React.useState(true);
  const [stateshipment, setShipments] = React.useState({shipment:{}});
  const[ndrstate , setndrstate] = React.useState({ndr_date:"" , ndr_address:"" , ndr_phone:"" , ndr_remarks:""})

  const [typestate, setType] = React.useState({type:5})


 

  const Toast = Swal.mixin({
    toast: true,
    position: 'bottom-end',
    showConfirmButton: false,
    timer: 5000
})

  // console.log("shipmentState",shipmentstate)
  React.useEffect(() => {

    shipmentList(null,0,true)
      dimension_detail()
  }, [])
  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
    const homeSection = document.querySelector('.home-section');
    if (homeSection) {
        homeSection.classList.toggle('sidebar-closed');
    }
};
  

  const shipmentList=(status,index=0,onLoad)=>{
    setisLoading(true)
    console.log("status   ===" , status)
    if(isOnline.online==true){
      let dataToSend = {customer_id : userData.customer_id ,find:searchstate.search_shipment,  status:status , limit:otherStates.rowsPerPage,indexValue:index
  };
  if(date.from_date!="" && date.to_date!=""){
    dataToSend.from_date = date.from_date
    dataToSend.to_date = date.to_date

  }
 if(selectedWarehouses.length>0){
  dataToSend.warehouse = selectedWarehouses
 }
  if (paymentmode.cod && !paymentmode.prepaid) {
    dataToSend.payment_mode = 'cod';
} else if (!paymentmode.cod && paymentmode.prepaid) {
    dataToSend.payment_mode = 'prepaid';
} else if (paymentmode.cod && paymentmode.prepaid) {
    dataToSend.payment_mode = 'both';
}

if(searchfilters.filterStatus.length > 0) {
  dataToSend.status= searchfilters.filterStatus
}
    // console.log("datatoSend",dataToSend)
    let url = config.apiUrl + '/shipment/customer_shipments_ndr';
   
   // console.log("headers =========> ", config.headers);
   
    
    axios.post(url, dataToSend, { headers: config.headers })
    // // console.log("headers ====== " , config.headers)
        .then((res) => {
          // console.log("responseJson => ", res);
          
          if( onLoad) {
            setOtherState({...otherStates,total_count:res.data.count , page:index})         

}
          setState({shipment_list:res.data.output,count_all:res.data.count_all , count_action_required:res.data.count_action_required,count_action_requested:res.data.count_action_requested, count_delivered:res.data.count_delivered , count_rto:res.data.count_rto,isLoading:false})
          setisLoading(false)
       
        })
        .catch((error) => {
          setisLoading(false)
            //Hide Loader
          //   setLoadingstate({...loadingstate,loading:false})
            // console.log(error);
        });    
      }
      else{
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: "Network Connection Error",
          
        })
        setisLoading(false)
      }

  }




  

  const dimension_detail = () =>{
    let full_api = config.apiUrl + `/user/get_company_info_api`;
    let sendData = {};
  
    axios.post(full_api, sendData, { headers: config.headers }).then(res => {
      setdimensionState({...dimensionState,companyDetail:res.data.companyDetail});
      // console.log("rnmmmmm", res);
    }).catch((e) => {
      // toast.configure()
       //toast.error("Some thing went wrong")
      // console.log("----error:   ", e);
    })
  }


 
const handleSelectAll = (event) => {
  if (event.target.checked) {
    const filteredShipments = state.shipment_list
        .filter((shipment) => shipment.ndr_action_required === 0) // Filter shipments with ndr_action_required = 0
        .map((shipment) => shipment.shipment_id); // Map to shipment_id

    setSelectedShipments(filteredShipments);
    setSelectCount({ select_count: filteredShipments.length });
  } else {
    setSelectedShipments([]);
    setSelectCount({select_count : 0})

  }
};

const handleSelectAllmobile = () => {
  const isAllSelected = selectedShipments.length === state.shipment_list.length;
  const selectedIds = isAllSelected ? [] : state.shipment_list.map(order => order.shipment_id);

  setSelectedShipments({
    
    selectedIds,
  });
  setSelectCount({select_count : selectedIds.length})

};
// const handleSelectIndividual = (event, shipmentId) => {
//   if (event.target.checked) {
//     setSelectedShipments([...selectedShipments, shipmentId]);
//     setSelectCount({...selectCount , select_count :selectCount.select_count +1})
//   } else {
//     setSelectedShipments(selectedShipments.filter((id) => id !== shipmentId));
//     setSelectCount({...selectCount , select_count :selectCount.select_count -1})
//   }
// };
const handleSelectIndividual = (event, shipmentId, ndrActionRequired) => {
  if (ndrActionRequired !== 0) return; // Prevent selection if ndr_action_required is not 0

  if (event.target.checked) {
    setSelectedShipments([...selectedShipments, shipmentId]);
    setSelectCount({ ...selectCount, select_count: selectCount.select_count + 1 });
  } else {
    setSelectedShipments(selectedShipments.filter((id) => id !== shipmentId));
    setSelectCount({ ...selectCount, select_count: selectCount.select_count - 1 });
  }
};



 const handlesearchShipment=(e)=>{
  setsearchstate({search_shipment:e.target.value})
 }
 

const dateselect =(e)=>{
setdate({...date, [e.target.name]:moment(e.target.value).format('YYYY-MM-DD')})
}



const openModal=(sub)=>{
setndrmodal(true)
setShipments({shipment:sub})
}
const closemodal =(e)=>{
setndrmodal(false)
setType({...typestate,type:5})
setndrstate({...ndrstate ,ndr_date:"" , ndr_address:"" , ndr_phone:"" , ndr_remarks:"" })
setShipments({shipment:{}})
}

const bulkopenModal=(sub)=>{
  setbulkndrmodal(true)
  setSelectedShipments(sub)
  }
const bulkclosemodal =(e)=>{
  setbulkndrmodal(false)
  setType({...typestate,type:5})
  setndrstate({...ndrstate ,ndr_date:"" , ndr_address:"" , ndr_phone:"" , ndr_remarks:"" })
  setSelectedShipments([])
  }

const handleNDRchange  =(e)=>{
setType({...typestate,type:e.target.value})
}

const handleNDRchnage =(e)=>{
setndrstate({...ndrstate , [e.target.name]:e.target.value})
}

const ndrReAttempt = () =>{
  setndrloader(true)
  if(typestate.type==1 && ndrstate.ndr_date!="" && ndrstate.ndr_phone!="" && ndrstate.remarks!="" || typestate.type==2 && ndrstate.ndr_date!="" && ndrstate.ndr_address!="" && ndrstate.remarks!=""  || typestate.type==0 && ndrstate.ndr_date!="" && ndrstate.remarks!="" || typestate.type==3  && ndrstate.remarks!="" ){
        let full_api = config.apiUrl + `/shipment/ndr_re_attempt`;
        let sendData = {type:typestate.type ,shipment: stateshipment.shipment , ndrstate};
        console.log("sendData",sendData)

        axios.post(full_api, sendData, { headers: config.headers }).then(res => {
        if(res.data.status == true){
          shipmentList(shipmentstate.shipment_status,otherStates.page,true)
        setndrmodal(false)
        setType({...typestate,type:5})
        setndrstate({...ndrstate ,ndr_date:"" , ndr_address:"" , ndr_phone:"" , ndr_remarks:"" })
        setShipments({shipment:{}})
        setndrloader(false)

        const Toast = Swal.mixin({
        toast: true,
        position: 'bottom-end',
        showConfirmButton: false,
        timer: 3000
        })

        Toast.fire({
        background:"#3659cd",
        type: 'success',
        title: res.data.message,
        color:"white"
        });
        }
        else{
        setndrmodal(false)
        setType({...typestate,type:5})
        setndrstate({...ndrstate ,ndr_date:"" , ndr_address:"" , ndr_phone:"" , ndr_remarks:"" })
        setShipments({shipment:{}})
        setndrloader(false)

        const Toast = Swal.mixin({
        toast: true,
        position: 'bottom-end',
        showConfirmButton: false,
        timer: 3000
        })

        Toast.fire({
        background:"#c2200a",
        type: 'unsuccess',
        title: res.data.message,
        color:"white"
        });
        }


        }).catch((e) => {
        const Toast = Swal.mixin({
        toast: true,
        position: 'bottom-end',
        showConfirmButton: false,
        timer: 3000
        })

        Toast.fire({
        background:"#c2200a",
        type: 'unsuccess',
        title: "Something Went Wrong ",
        color:"white"
        });
        setndrloader(false)

        // toast.configure()
        //toast.error("Some thing went wrong")
        // console.log("----error:   ", e);
        })
        }else{
        const Toast = Swal.mixin({
        toast: true,
        position: 'bottom-end',
        showConfirmButton: false,
        timer: 3000
        })

        Toast.fire({
        background:"#c2200a",
        type: 'unsuccess',
        title: "Please fill all the details ",
        color:"white"
        });
        setndrloader(false)

        }
        }


        const bulkndrReAttempt = () =>{
          setbulkndrloader(true)
          if(typestate.type==1 && ndrstate.ndr_date!="" && ndrstate.ndr_phone!="" && ndrstate.remarks!="" || typestate.type==2 && ndrstate.ndr_date!="" && ndrstate.ndr_address!="" && ndrstate.remarks!=""  || typestate.type==0 && ndrstate.ndr_date!="" && ndrstate.remarks!="" || typestate.type==3  && ndrstate.remarks!="" ){
                let full_api = config.apiUrl + `/shipment/bulk_ndr_re_attempt`;
                let sendData = {type:typestate.type ,shipment: selectedShipments , ndrstate};
                console.log("sendData",sendData)
        
                axios.post(full_api, sendData, { headers: config.headers }).then(res => {
                if(res.data.status == true){
                  shipmentList(shipmentstate.shipment_status,otherStates.page,true)
                setbulkndrmodal(false)
                setType({...typestate,type:5})
                setndrstate({...ndrstate ,ndr_date:"" , ndr_address:"" , ndr_phone:"" , ndr_remarks:"" })
               setSelectedShipments([])
                setbulkndrloader(false)
                const allMessages = res.data.message.map((shipmentMessage) => {
                  return `${shipmentMessage.message}`;
                }).join('\n');
                const Toast = Swal.mixin({
                toast: true,
                position: 'bottom-end',
                showConfirmButton: false,
                timer: 10000
                })
        
                Toast.fire({
                background:"#3659cd",
                type: 'success',
                width:'600px',
                title: allMessages,
                color:"white",
                });
                }
                else{
                setbulkndrmodal(false)
                setType({...typestate,type:5})
                setndrstate({...ndrstate ,ndr_date:"" , ndr_address:"" , ndr_phone:"" , ndr_remarks:"" })
                setSelectedShipments([])
                setbulkndrloader(false)
                const allMessages = res.data.message.map((shipmentMessage) => {
                  return `${shipmentMessage.message}`;
                }).join('\n');
                const Toast = Swal.mixin({
                toast: true,
                position: 'bottom-end',
                showConfirmButton: false,
                timer: 10000
                })
        
                Toast.fire({
                background:"#c2200a",
                type: 'unsuccess',
                title: allMessages,
                color:"white",
                width:'600px',
                
                });
                }
        
        
                }).catch((e) => {
                const Toast = Swal.mixin({
                toast: true,
                position: 'bottom-end',
                showConfirmButton: false,
                timer: 3000
                })
        
                Toast.fire({
                background:"#c2200a",
                type: 'unsuccess',
                title: "Something Went Wrong ",
                color:"white"
                });
                setbulkndrloader(false)
        
                // toast.configure()
                //toast.error("Some thing went wrong")
                // console.log("----error:   ", e);
                })
                }else{
                const Toast = Swal.mixin({
                toast: true,
                position: 'bottom-end',
                showConfirmButton: false,
                timer: 3000
                })
        
                Toast.fire({
                background:"#c2200a",
                type: 'unsuccess',
                title: "Please fill all the details ",
                color:"white"
                });
                setbulkndrloader(false)
        
                }
                }

const handlePageChange = (event,newPage) =>{
  setOtherState({...otherStates,page:newPage})
  // console.log("newPage ", newPage);
  // getCountryList(newPage);
  shipmentList(shipmentstate.shipment_status,newPage,true)
}
const handleChangeRowsPerPage = (event , newPage) => {
  // console.log("event ", event.target.value);
  setOtherState({ ...otherStates, rowsPerPage: event.target.value })
   
}
React.useEffect(()=>{
  shipmentList(shipmentstate.shipment_status,otherStates.page,true)
},[otherStates.rowsPerPage])


const shipmentFilter = (status) => {
  // console.log("action ", status);
  setshipStatus({ ...shipmentstate, status: status })
  shipmentList(status,0,true)
}
  return (
    <div>
<Header/>
<div class="page-wrapper pinned">
<div class="main-container">
  <div className='app-container'>
<div class="app-body">


<div class="container-fluid">

<div class="mob_nav">
  <div class=" d-flex justify-content-start ">
    <a href="javascript:window.history.back();" class=""><i class="bi bi-arrow-left"></i></a>
    <h2>NDR</h2>
  </div>
</div>



<div class="row justify-content-between">
    <div class="col-12 col-md-4 desk_nav">
      <div class="d-flex">
        <a href="javascript:window.history.back();" class="back_btn"><i class="bi bi-arrow-90deg-left"></i></a>
        <h2>NDR</h2>
      </div>
    </div>
    <div class="col-12 col-md-4 mb-3">
    </div>
</div>



<Modal show={ndrmodal} onHide={() => closemodal()} size='lg'>
                  <Modal.Header closeButton>
                                     <Modal.Title> <h4 class="modal-title">NDR Submit Form</h4></Modal.Title>
                                  </Modal.Header>
                                  <Modal.Body >
                               
                        <div class="modal-body">
                          <div class="row booking_info_bx">
                           
                            <div className='row'>
                              <div className='col-12'>
                               <select className='form-select' onChange={(e)=>handleNDRchange(e)}>
                                <option>Choose Action</option>
                                <option value="0">Re-Attempt</option>
                                {/* <option value="0">RTO</option> */}
                                {/* <option value="2">Update Address</option>
                                <option value="1">Update Phone</option> */}
                               </select>
                              </div>

                              <div className='row mt-2'>
                                {typestate.type==1?<>
                                 <div className='col-6 '>
                                 <input className='form-control' placeholder='Enter phone' type='date' name="ndr_date" onChange={(e)=>handleNDRchnage(e)}></input>
                               </div>
                                <div className='col-6 '>
                                  <input className='form-control' placeholder='Enter phone' type='number' name="ndr_phone" onChange={(e)=>handleNDRchnage(e)}></input>
                                </div>
                                <div className='col-12 m-2'>
                                 <input className='form-control' placeholder='Enter Remarks' type='text' name="ndr_remarks" onChange={(e)=>handleNDRchnage(e)}></input>
                               </div>
                                </>
                                :typestate.type==2?<>
                                <div className='col-6 '>
                                <input className='form-control' placeholder='Enter phone' type='date' name="ndr_date" onChange={(e)=>handleNDRchnage(e)}></input>
                              </div>
                               <div className='col-6 '>
                                 <input className='form-control' placeholder='Enter address' type='text' name="ndr_address" onChange={(e)=>handleNDRchnage(e)}></input>
                               </div>
                               <div className='col-12 mt-2'>
                                 <input className='form-control' placeholder='Enter Remarks' type='text' name="ndr_remarks" onChange={(e)=>handleNDRchnage(e)}></input>
                               </div>
                               </>

                               :typestate.type==0?<>
                               <div className='col-12 '>
                               <input className='form-control' placeholder='Enter phone' type='date' name="ndr_date" onChange={(e)=>handleNDRchnage(e)}></input>
                             </div>
                              {/* <div className='col-6 '>
                                <input className='form-control' placeholder='Enter address' type='text'></input>
                              </div> */}
                              <div className='col-12 mt-2'>
                                <input className='form-control' placeholder='Enter Remarks' type='text' name="ndr_remarks" onChange={(e)=>handleNDRchnage(e)}></input>
                              </div>
                              </>
                              :  typestate.type==3?<>
                              <div className='col-12 mt-2'>
                                <input className='form-control' placeholder='Enter Remarks' type='text' name="ndr_remarks" onChange={(e)=>handleNDRchnage(e)}></input>
                              </div>
                              </>
                              :
                               ""}
                              </div>



                            </div>
                            
                         </div>
                       </div>
                       <div class="modal-footer text-start">
                        {ndrloader==true?
                        <div style={{ display: 'flex', justifyContent: 'center',  alignItems: 'center' }}>
        <ReactLoading type={"spin"} color={"#0d6efd"} height={40} width={60} /></div>
                        :<>
                         <button type="button" class="btn btn-primary"  onClick={()=>ndrReAttempt()}>Submit</button>
                         <button type="button" class="btn btn-outline-danger"  onClick={()=>closemodal()}>I'll Do this later</button></>}
                       </div>
                     
                   
                                 </Modal.Body>
                                
                             </Modal>



                             <Modal show={bulkndrmodal} onHide={() => bulkclosemodal()} size='lg'>
                  <Modal.Header closeButton>
                                     <Modal.Title> <h4 class="modal-title">Bulk NDR Submit Form</h4></Modal.Title>
                                  </Modal.Header>
                                  <Modal.Body >
                               
                        <div class="modal-body">
                          <div class="row booking_info_bx">
                           
                            <div className='row'>
                              <div className='col-12'>
                               <select className='form-select' onChange={(e)=>handleNDRchange(e)}>
                                <option>Choose Action</option>
                                <option value="0">Re-Attempt</option>
                                {/* <option value="0">RTO</option> */}
                                {/* <option value="2">Update Address</option>
                                <option value="1">Update Phone</option> */}
                               </select>
                              </div>

                              <div className='row mt-2'>
                                {typestate.type==1?<>
                                 <div className='col-6 '>
                                 <input className='form-control' placeholder='Enter phone' type='date' name="ndr_date" onChange={(e)=>handleNDRchnage(e)}></input>
                               </div>
                                <div className='col-6 '>
                                  <input className='form-control' placeholder='Enter phone' type='number' name="ndr_phone" onChange={(e)=>handleNDRchnage(e)}></input>
                                </div>
                                <div className='col-12 m-2'>
                                 <input className='form-control' placeholder='Enter Remarks' type='text' name="ndr_remarks" onChange={(e)=>handleNDRchnage(e)}></input>
                               </div>
                                </>
                                :typestate.type==2?<>
                                <div className='col-6 '>
                                <input className='form-control' placeholder='Enter phone' type='date' name="ndr_date" onChange={(e)=>handleNDRchnage(e)}></input>
                              </div>
                               <div className='col-6 '>
                                 <input className='form-control' placeholder='Enter address' type='text' name="ndr_address" onChange={(e)=>handleNDRchnage(e)}></input>
                               </div>
                               <div className='col-12 mt-2'>
                                 <input className='form-control' placeholder='Enter Remarks' type='text' name="ndr_remarks" onChange={(e)=>handleNDRchnage(e)}></input>
                               </div>
                               </>

                               :typestate.type==0?<>
                               <div className='col-12 '>
                               <input className='form-control' placeholder='Enter phone' type='date' name="ndr_date" onChange={(e)=>handleNDRchnage(e)}></input>
                             </div>
                              {/* <div className='col-6 '>
                                <input className='form-control' placeholder='Enter address' type='text'></input>
                              </div> */}
                              <div className='col-12 mt-2'>
                                <input className='form-control' placeholder='Enter Remarks' type='text' name="ndr_remarks" onChange={(e)=>handleNDRchnage(e)}></input>
                              </div>
                              </>
                              :  typestate.type==3?<>
                              <div className='col-12 mt-2'>
                                <input className='form-control' placeholder='Enter Remarks' type='text' name="ndr_remarks" onChange={(e)=>handleNDRchnage(e)}></input>
                              </div>
                              </>
                              :
                               ""}
                              </div>



                            </div>
                            
                         </div>
                       </div>
                       <div class="modal-footer text-start">
                        {bulkndrloader==true?
                        <div style={{ display: 'flex', justifyContent: 'center',  alignItems: 'center' }}>
        <ReactLoading type={"spin"} color={"#0d6efd"} height={40} width={60} /></div>
                        :<>
                         <button type="button" class="btn btn-primary"  onClick={()=>bulkndrReAttempt()}>Submit</button>
                         <button type="button" class="btn btn-outline-danger"  onClick={()=>bulkclosemodal()}>I'll Do this later</button></>}
                       </div>
                     
                   
                                 </Modal.Body>
                                
                             </Modal>


<div class="card mb-3">
  <div class="card-body">
    <div class="row justify-content-between">
        <div class="col-12 col-md-6 mb-1">
          <div class="row g-1">
              <div class="col-12 col-md-6">
                <div class="input-group">
                  <input type="date" class="form-control" name='from_date' onChange={(e)=>dateselect(e)} value={date.from_date} />
                  <input type="date" class="form-control" name='to_date' onChange={(e)=>dateselect(e)} value={date.to_date}/>

                  <span class="input-group-text">
                  <i class="bi bi-search" onClick={(e)=>shipmentList(null,otherStates.page,true)}></i>
                  </span>
                </div>
              </div>
              {/* <div class="col-12 col-md-6">
                <div class="search-box">
                    <div class="search-icon">
                    <i class="bi bi-search"></i>
                    </div>
                    <input type="text" class="search-input" placeholder="Search packages..."  onClick={(e)=>shipmentList(null,0,true)}/>
                </div>
              </div> */}
          </div>
        </div>
        <div class="col-12 col-md-6 mb-1">
          <div class="row justify-content-between">

              <div class="col-6 col-md-3">
              {selectCount.select_count==0?"":
                <div class="dropdown">
                  <button type="button" class="btn btn-outline-secondary btn-sm dropdown-toggle" data-bs-toggle="dropdown" onClick={(e)=>bulkopenModal(selectedShipments)} >
                    Bulk Take Actions
                  </button>
                  {/* <ul class="dropdown-menu">
                    <li><a class="dropdown-item" href="#">Re-Attempt</a></li>
                    <li><a class="dropdown-item" href="#">Update Address</a></li>
                    <li><a class="dropdown-item" href="#">Update Phone Number</a></li>
                    <li><a class="dropdown-item text-danger" href="#">Retrun</a></li>
                  </ul> */}
                </div>
}
              </div>
              <div class="input-group input-group-sm mb-3">
                                {/* <select class="form-select">
                                    <option>AWB</option>
                                    <option>Order ID</option>
                                    <option>Phone Number</option>
                                </select> */}
                               
                                <input type="text" class="form-control w-50" name="search_shipment" placeholder="Enter Tracking Number" onChange={(e)=>handlesearchShipment(e)} value={searchstate.search_shipment} />
                                <button class="btn btn-outline-primary" onClick={(e)=>shipmentList(null,otherStates.page,true)}>Search</button>
                            </div>
              {/* <div class="col-6 col-md-6 text-end">
                <a href="" class="btn btn-outline-secondary btn-block"  data-bs-toggle="offcanvas" data-bs-target="#NDR_Filter"><i class="bi bi-filter"></i> Filter</a>
              </div> */}
          </div>
        </div>
    </div>
  </div>
</div>




<section class="ndr_bx">
  <ul class="nav nav-pills mb-2">
    <li class="nav-item">
      <a class={(shipmentstate.status == null) ? 'nav-link active' : 'nav-link'}  onClick={() => shipmentFilter(null)} style={{cursor:"pointer"}}>All ({state.count_all})</a>
    </li>
    <li class="nav-item">
      <a class={(shipmentstate.status == 0) ? 'nav-link active' : 'nav-link'}  onClick={() => shipmentFilter(0)} style={{cursor:"pointer"}}>Action Required ({state.count_action_required})</a>
    </li>
    <li class="nav-item">
      <a class={(shipmentstate.status == 1) ? 'nav-link active' : 'nav-link'}  onClick={() => shipmentFilter(1)} style={{cursor:"pointer"}}>Action Requested ({state.count_action_requested})</a>
    </li>
    <li class="nav-item">
      <a class={(shipmentstate.status == 2) ? 'nav-link active' : 'nav-link'}  onClick={() => shipmentFilter(2)} style={{cursor:"pointer"}}>Delivered ({state.count_delivered})</a>
    </li>
    <li class="nav-item">
      <a class={(shipmentstate.status == 3) ? 'nav-link active' : 'nav-link'}  onClick={() => shipmentFilter(3)} style={{cursor:"pointer"}}>RTO ({state.count_rto})</a>
    </li>
  </ul>
  
  <div class="tab-content">
    <div class="tab-pane  active" id="home">
      <div class="card data_table mb-3">
        <div class="card-body">
        {isLoading? <div style={{ display: 'flex', justifyContent: 'center', marginLeft:"60px", alignItems: 'center',marginTop:"-200px", height: '100vh' }}>
        <ReactLoading type={"spin"} color={"#0d6efd"} height={40} width={60} /></div>:
          <div class="table-responsive">
            <div class=" rounded-3">
              <table class="table align-top table-striped table-hover m-0 table-xl">
                <thead>
                  <tr>
                    <th><input class="form-check-input form-check-input-lg" type="checkbox" onChange={handleSelectAll}/></th>
                    <th>Channel</th>
                    {/* <th>NDR Date</th> */}
                    <th>Order</th>
                    <th>Product Details</th>
                    <th>Payment</th>
                    <th>Customer</th>
                    <th>Carrier</th>
                    <th>Status</th>
                    <th>Exception</th>
                    <th>Actions</th>
                  </tr>				
                </thead>
                <tbody>
                {state.shipment_list.map((sub)=>(
                  <tr>
                    <td><input class="form-check-input form-check-input-lg" type="checkbox" onClick={(e) => handleSelectIndividual(e, sub.shipment_id, sub.ndr_action_required)} 
    checked={selectedShipments.includes(sub.shipment_id)} 
    disabled={sub.ndr_action_required !== 0}  /></td>
                    <td>
                    <a href="" data-bs-toggle="tooltip" title="Direct Sales">Direct Sales</a>
                    </td>
                    {/* <td>
                      <div><small>Dec 9, 2:28PM</small></div>
                    </td> */}
                    <td>
                      <a href="#" class="text-primary" data-bs-toggle="offcanvas" data-bs-target="#Order_data">{sub.order_number}</a>
                    </td>
                    <td>{sub.items[0].item_name.substring(0,20) + '...'} {sub.items.length>1?"+1 items":""}
                    </td>
                    <td>
                      <div><span class="badge rounded-pill bg-warning">{sub.payment_mode}</span></div>
                    </td>
                    <td>
                    {sub.receiver.map((item)=>(
                <>
                 {item.name}
                {/* <div><small>{item.address.substring(0,20)}</small></div> */}
                <div><small>{item.city} , {item.state} - {item.pincode}</small></div>

                <div><small>{item.mobile}</small></div></>
               
              ))}
                    </td>
                    <td>
                      <small>{sub.service_provider}</small>
                      <div><a href="#" class="text-primary" data-bs-toggle="offcanvas" data-bs-target="#Order_data">{sub.carrier_tracking_number}</a></div>
                    </td>
                    <td>
                    {sub.status==0?<span class="status under_process"><img src="assets/images/spinner.gif" class="img-fluid" /> Under Processing</span>:sub.status==10? <span class="status rtrn"> <i class="bi bi-arrow-clockwise"></i> RTO</span>:sub.status==1?<span class="status pickup_awit"> <i class="bi bi-clock"></i> Pickup Awaited</span>:sub.status==2?  <span class="status pickedup"> <i class="bi bi-building-fill-up"></i> Pickedup</span>:sub.status==3?  <span class="status in_transit"> <i class="bi bi-truck"></i> In Transit</span>:sub.status==4? <span class="status out_f_deli"> <i class="bi bi-rocket-takeoff"></i> Out for Delivery</span>:sub.status==5? <span class="status "> <i class="bi bi-check2-all"></i> Delivered</span>:sub.status==6? <span class="status cancelled"> <i class="bi bi-x-square"></i> Cancelled</span>:sub.status==7? <span class="status failed"> <i class="bi bi-x-octagon"></i> Failed</span>:sub.status==8?<span class="status failed"> <i class="bi bi-x-octagon"></i> NDR</span>:sub.status==15?<span class="status failed"> <i class="bi bi-x-octagon"></i> Booked</span>:sub.status==16?<span class="status "> <i class="bi bi-check2-all"></i>RTO Delivered</span>:sub.status==19?<span class="status "> <i class="bi bi-check2-all"></i>RTO In Transit</span>
                    :sub.status==17?<span class="status failed"> <i class="bi bi-x-octagon"></i>Pickup Failed</span>:sub.status==9?<>  <span class="status pickup_sechdls"> <i class="bi bi-calendar"></i> Pickup Scheduled</span><br/><br/>
                      {sub.pickup_date!=undefined && sub.pickup_date!=""?  <small>on {sub.pickup_date!=undefined?sub.pickup_date:""} at {sub.pickup_time!=undefined?sub.pickup_time:""}</small>:""} </>:<span class="badge rounded-pill bg-danger fw-400">Not Available</span>}
                    </td>
                    <td>
                    <div class="text-success">{sub.total_ndr_attempt} Attempt(s)</div>
                    <small><a onClick={(e)=>sethistory({ndr_history:sub.ndr_array?sub.ndr_array:[] ,awb:sub.carrier_tracking_number})} style={{cursor:"pointer"}} data-bs-toggle="modal" data-bs-target="#NDR_Modal" data-bs-backdrop="false" class="text-info">(Show History)</a></small>
                    </td>
                    <td>

                      <div class="text-success">{shipmentstate.status == 0?
                        <div class="btn-group">
                            {/* {sub.carrier_id=="1343170006" ||
                            sub.carrier_id == "1343170996"? */}
                        <a type="button" class="btn btn-primary btn-sm" onClick={(e)=>openModal(sub)} >Take Action</a>
                        {/* :""} */}
                        {/* <div class="btn-group">
                          <button type="button" class="btn btn-primary dropdown-toggle" data-bs-toggle="dropdown"></button>
                          <div class="dropdown-menu">
                            <a class="dropdown-item" href="#">Re-Attempt</a>
                            <a class="dropdown-item" href="#">Update Address</a>
                            <a class="dropdown-item" href="#">Update Phone</a>
                            <a class="dropdown-item text-danger" href="#">Return</a>
                          </div>
                        </div> */}
                      </div>:sub.ndr_action_required != 0?sub.ndr_array?sub.ndr_array[0].action:"":""}</div>
                      {/* <small>Dear team Pls call on this no. and arrange delivery tomorrow</small> */}
                    </td>
                  </tr>
                ))}

<div class="modal" id="NDR_Modal" data-bs-backdrop="false">
  <div class="modal-dialog modal-xl">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title">NDR History: AWB #{history.awb}</h4>
        <button type="button" onClick={(e)=>sethistory({ndr_history:[],awb:""})} class="btn-close" data-bs-dismiss="modal"></button>
      </div>
      <div class="modal-body">
        <div class="table-responsive">
          <table class="table table-bordered table-hover table-sm">
            <thead  class="bg-table">
              <tr>
                <th>Date</th>
                <th>Action</th>
                <th>Updated Details (If Any)</th>
                <th>Remarks</th>
                <th>NDR Count #</th>
                <th>By</th>
              </tr>
            </thead>
            <tbody>
              {history.ndr_history.length>0?
              history.ndr_history.map((his)=>(
              <tr>
                <td>{his.date}</td>
                <td>{his.action}</td>
                <td>{his.updated_details}</td>
                <td>{his.remarks}</td>
                <td class="text-center">{his.ndr_count}</td>
                <td>{his.by}</td>
              </tr>
              )):""}
             
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>

                 
                </tbody>
              </table>
            </div>
          </div>
        }
        </div>
      </div>
    </div>
    <TablePagination
                component="div"
                 rowsPerPageOptions={[200,300,400,500,600,700,800,900,1000]}
                 count={otherStates.total_count}
                 page={otherStates.page}
                 onPageChange={handlePageChange}
                 rowsPerPage={otherStates.rowsPerPage}
                 onRowsPerPageChange={handleChangeRowsPerPage}
               
                         />
  </div>
</section>









</div></div>
<Footer/></div></div></div>

    </div>
  )
}

export default Ndr
