import React, { useState, createRef, useEffect } from "react";

import Header from './Header'
import axios from "axios";
import { useLocation, useParams } from 'react-router-dom';
import ReactLoading from 'react-loading';
import config from "../../config"
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import queryString from 'query-string';
import Modal from 'react-bootstrap/Modal';
import { Container, Row, Col, Form, Button, ProgressBar, ModalTitle } from "react-bootstrap";
import { useNetworkState } from 'react-use';
import Footer from "./Footer";

const Ticket_details = () => {
    const isOnline = useNetworkState();
    console.log("isONline  === " ,isOnline )
      const { ticket_id } = useParams();
      let userDetail = localStorage.getItem('ship_rocket_user');
      // console.log("userDetail====shipments====", config.UserId);
      let userData = {};
      if (userDetail != null) {
        userData = JSON.parse(userDetail)
      }
  
      const[state,setState]=React.useState({ticket_detail:[],ticket_shipment:[],shipment_driver:[],shipment_vehicle:[],reply_detail:[],isLoading:true})
  const [reply, setReply] = useState({  reply_content:"",reply_id:"",ticket_id:ticket_id })
     
  const[modals,setModals] = useState({show: false})
  
  const [isSidebarOpen, setIsSidebarOpen] = React.useState(false);
  
  const toggleSidebar = () => {
      setIsSidebarOpen(!isSidebarOpen);
      const homeSection = document.querySelector('.home-section');
      if (homeSection) {
          homeSection.classList.toggle('sidebar-closed');
      }
  };
  
      const axios_get_api = () => {
   
          let full_api = config.apiUrl + `/home/ticket_detail`;
          
          let sendData = {
            dstatus: 1,
            ticket_id:ticket_id,
            
          }
         
          axios.post(full_api, sendData, { headers: config.headers }).then(res => {
            // console.log("-----res-----  ",res);
           setState({...state,ticket_detail:res.data.output,reply_detail:res.data.output2,isLoading:false})
            // console.log("response", res);
          
      
          }).catch((e) => {
      
            // toast.configure()
            // toast.error("Some thing went wrong")
            // console.log("----error:   ", e);
          })
        }
        React.useEffect(() => {
          axios_get_api()
        }, [])
  
        const handleSupportReply = () => {
      
          // console.log("submit1");
         
          let state_detailes = {reply_content:reply.reply_content,reply_id:"", ticket_id:ticket_id}
        
        
          // console.log("report detailes is ",state_detailes);
         
          axios.post(config.apiUrl + `/home/add_reply`,  state_detailes , { headers: config.headers }).then((result)=>{
                  // console.log("result is i ",result);
                 
                  if(result && result.data != undefined){
                     
                      const Toast = Swal.mixin({
                          toast: true,
                          position: 'bottom-end',
                          showConfirmButton: false,
                          timer: 3000
                        })
                        
                        Toast.fire({
                          background:"#3659cd",
                          type: 'success',
                          title:result.data.message,
                          color:"white"
                        });
                          setReply({...reply, reply_content:""}) 
                          axios_get_api()
                          
                      }
       
               }).catch((e) => {
             
       
                  const Toast = Swal.mixin({
                      toast: true,
                      position: 'bottom-end',
                      showConfirmButton: false,
                      timer: 3000
                    })
                    
                    Toast.fire({
                      background:"#a61c09",
                      type: 'unsuccess',
                      title: "Something Went Wrong",
                      color:"white"
                    });
                // console.log("----error:   ", e);
              });}
  
              const closeTicket = () => {
      
                // console.log("submit1");
               
                let state_detailes = {ticket_id:ticket_id}
               
              
                // console.log("report detailes is ",state_detailes);
               
                axios.post(config.apiUrl+ `/home/close_ticket`,  state_detailes , { headers: config.headers }).then((result)=>{
                        // console.log("result is i ",result);
                       
                        if(result && result.data != undefined){
                           
                          const Toast = Swal.mixin({
                              toast: true,
                              position: 'bottom-end',
                              showConfirmButton: false,
                              timer: 3000
                            })
                            
                            Toast.fire({
                              background:"#3659cd",
                              type: 'success',
                              title:result.data.message,
                              color:"white"
                            });
                                setModals({show:false}) 
                                axios_get_api()
                              //   toast.configure();
                                
                                
                            }
             
                     }).catch((e) => {
                      const Toast = Swal.mixin({
                          toast: true,
                          position: 'bottom-end',
                          showConfirmButton: false,
                          timer: 3000
                        })
                        
                        Toast.fire({
                          background:"#a61c09",
                          type: 'unsuccess',
                          title: "Something Went Wrong",
                          color:"white"
                        });
                      // console.log("----error:   ", e);
                    });}
            
      
      
      const handleChange = (event) => {
        // console.log(event);  
        setReply({
            ...reply,
            [event.target.name]: event.target.value,
          });
        
      }
      const nameFormat = (name)=> {
          
        if(name) {
            let nameArray =  name.split(" ");
           // // console.log("nameArray  ",nameArray);
            if(nameArray.length > 1) {
                let n1 = nameArray[0].substring(0,1).toUpperCase();
                let n2 = nameArray[1].substring(0,1).toUpperCase();
                name = n1+n2;
            }else {
                name = nameArray[0].substring(0,2).toUpperCase();
            }
        }else{
            name = "NO";
        }
        return name;
    }
    const handleClick=()=>{
      setModals({show:false})
    }
  return (
    <div>
        <Modal show={modals.show} >
        <Modal.Header closeButton onClick={() => handleClick()}>
          <ModalTitle>
            <h4>Close Ticket</h4>
          </ModalTitle>
        </Modal.Header>
        <Modal.Body>
          <div className='mb-4'>
          <h6>Do you really want to close the ticket ?</h6>
            
          </div>
        
         
        </Modal.Body>
        <Modal.Footer>
         
          <Button variant="primary" onClick={() => closeTicket()} >
            Yes
          </Button>
          <Button variant="primary" onClick={() => handleClick()} >
            No
          </Button>
        </Modal.Footer>
      </Modal>
        <Header/>
 <div class="page-wrapper pinned">
  <div class="main-container">
   <div className='app-container'>
  <div class="app-body">
  <div class="container-fluid"> 


<div class="mob_nav">
  <div class="row">
      <div class="col">
        <div class=" d-flex justify-content-start ">
          <a href="javascript:window.history.back();" class=""><i class="bi bi-arrow-left"></i></a>
          <h2>Ticket {ticket_id}</h2>
        </div>
      </div>
  </div>
</div>


<div class="row justify-content-between desk_nav">
    <div class="col-12 col-md-4 ">
      <div class="d-flex">
        <a href="javascript:window.history.back();" class="back_btn"><i class="bi bi-arrow-90deg-left"></i></a>
        <h2>Ticket {ticket_id}</h2>
      </div>
    </div>
    <div class="col-12 col-md-4 mb-3 text-end">
    
    </div>
</div>


{state.ticket_detail.map((sub)=>(

<div class="col-xxl-12">
    
  <div class="card ">

    <div class="card-header">
      <div class="row">
          <div class="col-12 col-md">
            <h4>{sub.subject}</h4>
          
          </div>
          <div class="col-12 col-md-3 text-end">
          {sub.status==0?   <span class="badge border border-warning text-warning "><i class="bi bi-circle-fill"></i> Awating response</span>:sub.status==1?  <span class="badge border border-success text-success "><i class="bi bi-circle-fill"></i> Answered</span>:   <span class="badge border border-danger text-danger "><i class="bi bi-circle-fill"></i> Closed</span>}
          </div>
      </div>
    </div>

    {state.reply_detail.map((item)=>(
    // <div class="card-body">
    //   <div class="activity-container">
    //     <div class="activity mb-3 pb-3 border-bottom d-flex align-items-start">
    //       <img src="https://avatar.iran.liara.run/username?username=Michel+Wilson" class="rounded-circle me-2 img-4x" alt="Bootstrap Dashboards"/>
    //       <div class="flex-grow-1">
    //         <small class="float-end">7 Mins ago</small>
    //         <strong>Michel Wilson</strong>
    //         <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Porro quos vitae ab rerum doloremque fuga similique! Inventore quaerat, excepturi officiis distinctio eius magni aspernatur reiciendis doloremque, quos iusto, optio odio! Lorem ipsum dolor sit amet consectetur adipisicing elit. Porro quos vitae ab rerum doloremque fuga similique! Inventore quaerat, excepturi officiis distinctio eius magni aspernatur reiciendis doloremque, quos iusto, optio odio! Lorem ipsum dolor sit amet consectetur adipisicing elit. Porro quos vitae ab rerum doloremque fuga similique! Inventore quaerat, excepturi officiis distinctio eius magni aspernatur reiciendis doloremque, quos iusto, optio odio!Lorem ipsum dolor sit amet consectetur adipisicing elit. Porro quos vitae ab rerum doloremque fuga similique! Inventore quaerat, excepturi officiis distinctio eius magni aspernatur reiciendis doloremque, quos iusto, optio odio!</p>
    //         <h6>Attachments:</h6>
    //             <div class="d-flex gap-2 mt-2">
    //               <div class="text-center">
    //                 <a href=""><img src="https://picsum.photos/60" alt="" class="rounded-3 img-3x"/></a>
    //                 <div><small>Picture</small></div>
    //               </div>
    //               <div class="text-center">
    //                 <a href=""><img src="https://picsum.photos/60" alt="" class="rounded-3 img-3x"/></a>
    //                 <div><small>Picture</small></div>
    //               </div>
    //               <div class="text-center">
    //                 <a href=""><img src="https://picsum.photos/60" alt="" class="rounded-3 img-3x"/></a>
    //                 <div><small>Video</small></div>
    //               </div>
    //               <div class="text-center">
    //                 <a href=""><img src="./assets/img/file.png" alt="" class="rounded-3 img-3x border"/></a>
    //                 <div><small>Document</small></div>
    //               </div>
    //             </div>
    //       </div>
    //     </div>
    //     <div class="activity mb-3 pb-3 border-bottom d-flex align-items-start">
    //       <img src="https://avatar.iran.liara.run/username?username=James+Kanday" class="rounded-circle me-2 img-4x" alt="Bootstrap Dashboards"/>
    //       <div class="flex-grow-1">
    //         <small class="float-end">7 Mins ago</small>
    //         <strong>James Kanday</strong>
    //         <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Porro quos vitae ab rerum doloremque fuga similique! Inventore quaerat, excepturi officiis distinctio eius magni aspernatur reiciendis doloremque, quos iusto, optio odio! Lorem ipsum dolor sit amet consectetur adipisicing elit. Porro quos vitae ab rerum doloremque fuga similique! Inventore quaerat, excepturi officiis distinctio eius magni aspernatur reiciendis doloremque, quos iusto, optio odio! Lorem ipsum dolor sit amet consectetur adipisicing elit. Porro quos vitae ab rerum doloremque fuga similique! Inventore quaerat, excepturi officiis distinctio eius magni aspernatur reiciendis doloremque, quos iusto, optio odio!Lorem ipsum dolor sit amet consectetur adipisicing elit. Porro quos vitae ab rerum doloremque fuga similique! Inventore quaerat, excepturi officiis distinctio eius magni aspernatur reiciendis doloremque, quos iusto, optio odio!</p>
    //       </div>
    //     </div>
    //   </div>
    // </div>
    <section>
    {item.reply_id==userData.customer_id? 
     <div class="card-body">
     <div class="activity-container">
       <div class="activity mb-3 pb-3 border-bottom d-flex align-items-start">
         <img src={"https://dummyimage.com/50/1976d2/ffffff&text="+nameFormat(userData.full_name)} class="rounded-circle me-2 img-4x" alt="Bootstrap Dashboards"/>
         <div class="flex-grow-1">
           <small class="float-end">{item.date} {item.time}</small> 
            <strong>{userData.full_name} (Customer)</strong>
           <p>{item.reply_content}</p>
         
               
         </div>
       </div>
       
     </div>
   </div>
       
     :
        item.reply_id!=userData.customer_id ?
       
        // <div class="card shadow-sm mb-3">
        //   <div class="card-body">
        //     <div class="d-flex p-3 ">
        //       <div class="p-2">
        //         <p>{item.reply_content}</p>
        //         <div class="text-end">
        //           <small class="text-muted"><a href="" className="text-decoration-none">{item.full_name} </a> (System Admin) </small><br/>
        //           <small class="text-muted">{item.date} {item.time}</small>
        //         </div>
        //       </div>
        //       <div class="p-2">
        //         <img src={"https://dummyimage.com/50/63a4ff/ffffff&text="+nameFormat(item.full_name)} class="img-fluid rounded-circle" style={{minWidth: "50px"}} />
        //       </div>
        //     </div>
        //   </div>
        // </div>
        
        <div class="card-body">
        <div class="activity-container">
          <div class="activity mb-3 pb-3 border-bottom d-flex align-items-start">
            <img src={"https://dummyimage.com/50/1976d2/ffffff&text="+nameFormat(userData.full_name)} class="rounded-circle me-2 img-4x" alt="Bootstrap Dashboards"/>
            <div class="flex-grow-1">
              <small class="float-end">{item.date} {item.time}</small> 
               <strong>{userData.full_name} (System Admin)</strong>
              <p>{item.reply_content}</p>
            
                  
            </div>
          </div>
          
        </div>
      </div>
       :""}
    </section>
    ))}
  </div>
    

  <div class="py-3 comment_box">
   <div class="card">
      <div class="card-body">
         <div class="row">
            <div class="col-12 col-md">
               <div class="row">
                  <div class="col-12 col-md"></div>
                  <div class="col-12 col-md-4 text-end"><small></small></div>
               </div>
               <textarea class="form-control" rows="5" id="comment" name="reply_content" placeholder="Please enter details" onChange={(e) => handleChange(e)} value={reply.reply_content}></textarea>
               <div class="row mt-2">
                  <div class="col-6 col-md ">
                     <div class=""><a class="btn btn-primary" onClick={()=>handleSupportReply({ticket_id:sub.ticket_id})}>Send</a></div>
                  </div>
                  {sub.status!=2?
                  <div class="col-6 col-md text-end">
                     <div class=""><a class="btn btn-danger btn-sm" onClick={() => setModals({ show: true})}>Mark as Closed</a></div>
                  </div>
                  :""}
               </div>
            </div>
         </div>
      </div>
   </div>
</div>


</div>
))}

    </div></div>
    <Footer/></div></div></div>
    </div>
  )
}

export default Ticket_details
