import React, { useState, createRef, useEffect } from "react";

import Header from './Header'
import axios from "axios";
import { useLocation } from 'react-router-dom';
import ReactLoading from 'react-loading';
import config from "../../config"
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import queryString from 'query-string';
import { useNetworkState } from 'react-use';
import Footer from "./Footer";


const Support_tickets = () => {
    const isOnline = useNetworkState();
    console.log("isONline  === " ,isOnline )
    let userDetail = localStorage.getItem('ship_rocket_user');
    // console.log("userDetail====shipments====", config.UserId);
    let userData = {};
    if (userDetail != null) {
      userData = JSON.parse(userDetail)
    }

    const[state,setState]=React.useState({ticket_detail:[] ,counts:{} , isLoading:true})
    const [shipmentstate, setshipStatus] = useState({ status: null });
    const [isSidebarOpen, setIsSidebarOpen] = React.useState(false);

    const toggleSidebar = () => {
        setIsSidebarOpen(!isSidebarOpen);
        const homeSection = document.querySelector('.home-section');
        if (homeSection) {
            homeSection.classList.toggle('sidebar-closed');
        }
    };


    const axios_get_api = (status) => {
 
        let full_api = config.apiUrl + `/home/ticket_list`;
        let sendData = {
          dstatus: 1,
          ticket_createdById:userData.customer_id,
          status:status
        }
    
          
    
        axios.post(full_api, sendData, { headers: config.headers }).then(res => {
          let tickets = res.data.output
        
          // console.log("response", res);
          const counts = {
            pending: tickets.filter(ticket => ticket.status === 0).length,
            answered: tickets.filter(ticket => ticket.status === 1).length,
            closed: tickets.filter(ticket => ticket.status !== 0 && ticket.status !== 1).length,
            total: tickets.length
          };
          setState({...state,ticket_detail:res.data.output,counts:counts ,isLoading:false})
    
        }).catch((e) => {
    
          // toast.configure()
          // toast.error("Some thing went wrong")
          // console.log("----error:   ", e);
        })
      }
      React.useEffect(() => {
        axios_get_api()
      }, [])
      const shipmentFilter = (status) => {
        // console.log("action ", status);
        setshipStatus({ ...shipmentstate, status: status })
        axios_get_api(status)
      }
  return (
    <div>
      <Header/>
 <div class="page-wrapper pinned">
  <div class="main-container">
   <div className='app-container'>
  <div class="app-body">
  <div class="container-fluid"> 


<div class="mob_nav">
  <div class="row">
      <div class="col-7">
        <div class=" d-flex justify-content-start ">
          <a href="javascript:window.history.back();" class=""><i class="bi bi-arrow-left"></i></a>
          <h2>Support</h2>
        </div>
      </div>
      <div class="col">
        <a href="support_create_ticket.php" class="btn btn-primary">Create Ticket</a>
      </div>
  </div>
</div>


<div class="row justify-content-between desk_nav">
    <div class="col-12 col-md-4 ">
      <div class="d-flex">
        <a href="javascript:window.history.back();" class="back_btn"><i class="bi bi-arrow-90deg-left"></i></a>
        <h2>Support</h2>
      </div>
    </div>
    <div class="col-12 col-md-4 mb-3">
      <div class="row justify-content-end">
          <div class="col-6 col-md-6">
            <div class="d-grid">
              <a href="/create_ticket" class="btn btn-primary btn-block"><i class="bi bi-plus"></i> Create Ticket</a>
            </div>
          </div>
      </div>
    </div>
</div>


<ul class="nav nav-pills mb-3">
  <li class="nav-item">
    <a class="nav-link active" href="/tickets">Tickets</a>
  </li>
  {/* <li class="nav-item">
    <a class="nav-link" href="support-faq.php">FAQ's</a>
  </li> */}
</ul>

<div class="row">
  <div class="col-xl-3 col-sm-6 col-12">
    <div class="card mb-4">
      <div class="card-body">
        <div class="d-flex flex-row">
          <div class="icon-box lg rounded-3 bg-light mb-4">
            <i class="bi bi-envelope-open text-primary fs-2"></i>
          </div>
          <div class="ms-4">
            <h1 class="fw-bold mb-2">{state.counts.total?state.counts.total:0}</h1>
            <h6 class="m-0 fw-normal opacity-50">All Tickets</h6>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="col-xl-3 col-sm-6 col-12">
    <div class="card mb-4">
      <div class="card-body">
        <div class="d-flex flex-row">
          <div class="icon-box lg rounded-3 bg-light mb-4">
            <i class="bi bi-arrow-repeat text-warning fs-2"></i>
          </div>
          <div class="ms-4">
            <h1 class="fw-bold mb-2">{state.counts.pending?state.counts.pending:0}</h1>
            <h6 class="m-0 fw-normal opacity-50">Pending Tickets</h6>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="col-xl-3 col-sm-6 col-12">
    <div class="card mb-4">
      <div class="card-body">
        <div class="d-flex flex-row">
          <div class="icon-box lg rounded-3 bg-light mb-4">
            <i class="bi bi-check2-all text-success fs-2"></i>
          </div>
          <div class="ms-4">
            <h1 class="fw-bold mb-2">{state.counts.answered?state.counts.answered:0}</h1>
            <h6 class="m-0 fw-normal opacity-50">Answered Tickets</h6>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="col-xl-3 col-sm-6 col-12">
    <div class="card mb-4">
      <div class="card-body">
        <div class="d-flex flex-row">
          <div class="icon-box lg rounded-3 bg-light mb-4">
            <i class="bi bi-check-circle text-danger fs-2"></i>
          </div>
          <div class="ms-4">
            <h1 class="fw-bold mb-2">{state.counts.closed?state.counts.closed:0}</h1>
            <h6 class="m-0 fw-normal opacity-50">Closed Tickets</h6>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>


<div class="col-xxl-12">
  <div class="card mb-3">
    <div class="card-body">
      <div class="custom-tabs-container">
        <ul class="nav nav-tabs" id="customTab" role="tablist">
          <li class="nav-item" role="presentation">
            <a class={(shipmentstate.status == null) ? 'nav-link active' : 'nav-link'}  onClick={() => shipmentFilter(null)} style={{cursor:"pointer"}}>All</a>
          </li>
          <li class="nav-item" role="presentation">
            <a class={(shipmentstate.status == 0) ? 'nav-link active' : 'nav-link'} onClick={() => shipmentFilter(0)} style={{cursor:"pointer"}}>Pending</a>
          </li>
          <li class="nav-item" role="presentation">
            <a class={(shipmentstate.status == 1) ? 'nav-link active' : 'nav-link'} onClick={() => shipmentFilter(1)} style={{cursor:"pointer"}}>Answered</a>
          </li>
          <li class="nav-item" role="presentation">
            <a class={(shipmentstate.status == 2) ? 'nav-link active' : 'nav-link'} onClick={() => shipmentFilter(2)} style={{cursor:"pointer"}}>Closed</a>
          </li>
        </ul>
        {state.ticket_detail.length>0?
                state.ticket_detail.map((sub,index)=>(
        <div class="tab-content" id="customTabContent">
          <div class="tab-pane fade active show" id="one" role="tabpanel" aria-labelledby="tab-one">
            <div class="list-group w-auto">
              <a href={`/ticket_details/${sub.ticket_id}`} class="list-group-item list-group-item-action d-flex gap-3 py-3" aria-current="true">
                {/* <img src="https://avatar.iran.liara.run/username?username=Scott+Wilson" alt="" class="rounded-circle flex-shrink-0 img-3x"/> */}
                <div class="d-flex gap-2 w-100 justify-content-between">
                  <div>
                    <h6 class="mb-0">{sub.subject}</h6>
                    <small class="mb-0 opacity-75">Updated on: {sub.time} / {sub.date} </small>
                   
                    {sub.status==0?   <span class="badge border border-warning text-warning desk_display_none"><i class="bi bi-circle-fill"></i> Awating response</span>:sub.status==1?  <span class="badge border border-success text-success desk_display_none"><i class="bi bi-circle-fill"></i> Answered</span>:   <span class="badge border border-danger text-danger desk_display_none"><i class="bi bi-circle-fill"></i> Closed</span>}
                    <p class="mb-0">
                    {sub.details}
                    </p>
                  </div>
                  <small class="text-nowrap mb_display_none">
                  {sub.status==0?   <span class="badge border border-warning text-warning"><i class="bi bi-circle-fill"></i> Awating response</span>:sub.status==1?  <span class="badge border border-success text-success"><i class="bi bi-circle-fill"></i> Answered</span>:   <span class="badge border border-danger text-danger"><i class="bi bi-circle-fill"></i> Closed</span>}
                  </small>
                
                </div>
              </a>
             
            </div>
          </div>
         
         
          
        </div>
         )): <section className="text-center p-5 h-100" style={{marginLeft:"80px"}}>
         <h4 className="text-muted fw-bold">NO TICKETS</h4>
         <p className="pb-5">You do not currently have any tickets in this status. Find what you're looking for in another tab or...</p>
         <a href="/create_ticket"><a className="btn btn-outline-primary btn-lg">Start creating a new ticket</a></a>
       </section>}
      </div>
    </div>
  </div>
</div>


    </div></div>
    <Footer/></div></div></div>
    </div>
  )
}

export default Support_tickets
