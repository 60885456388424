import React from 'react'

import Header from './Header'
import { useNavigate } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';
import axios from "axios";
import Swal from 'sweetalert2'
import ReactLoading from 'react-loading';
import config from "../../config"
import Moment from 'react-moment';
import TablePagination from '@mui/material/TablePagination';
import { useNetworkState } from 'react-use';
import Footer from './Footer';
import moment from 'moment'
import $ from 'jquery'; 
import 'daterangepicker/daterangepicker.css'; // Import daterangepicker CSS
import 'daterangepicker';

const International_orders = () => {
    let navigate = useNavigate()
  
    let userDetail = localStorage.getItem('ship_rocket_user');
    // console.log("userDetail====", config.UserId);
    let userData = {};
    if (userDetail != null) {
      userData = JSON.parse(userDetail)
    }
    const [packagestate, setpackageState] = React.useState([]);
    const[userstate , setUserState] = React.useState({})
    // const[loadingstate,setLoadingstate] = React.useState({loading:false})
    const[errorstate,seterrorstate] = React.useState({message:""})
 
    const[dimensionState,setdimensionState] = React.useState({companyDetail:[]})
    const[state,setState]= React.useState({shipment_list:[],isLoading:true})
    const [shipmentstate, setshipStatus] = React.useState({ shipment_status: null });
  
    const [modalState, setModalState] = React.useState({ show: false });
    const [openOffcanvasfilter, setOpenOffcanvasfilter] = React.useState(false);
   
  
    const[isLoading , setisLoading] =React.useState(true)
    const[syncLoading , setsyncLoading] =React.useState(false)
    const[selected , setSelected] = React.useState({select_count:0 , select_order_id:[]})
    const[searchstate,setsearchstate]= React.useState({search_shipment:""})
    
    const[productselect , setProductselect] = React.useState({product_id:"" , carrier_id:""})
    const [otherStates,setOtherState] = React.useState({dstatus:"",activePage:1,rowsPerPage:20,page:0,total_count:0,onload:true});
    // console.log("otherState ==== " , otherStates)
    // console.log("selected === " , selected)
    const[product , setproduct] = React.useState([]) 
    const[onSubmitButton , setonSubmit] = React.useState(false)

const [openOffcanvas, setOpenOffcanvas] = React.useState(false);
const [openOffdetailcanvas, setOpenOffdetailcanvas] = React.useState(false);

const[warehosuestate , setWarehouseState] = React.useState({warehouse:""})
const[packageloading , setpackageloading] = React.useState({loading:false})
const[bookorder , setbookorder] = React.useState([])
const [alloctedata , setallocatedata ] = React.useState([])
console.log("bookorder" , bookorder)
const [otherState, setOtherStates] = React.useState({ submit_button: false });

const[date, setdate] = React.useState({from_date:moment().subtract(30, 'days').format('YYYY-MM-DD'),to_date:moment().format('YYYY-MM-DD')})

const[paymentmode, setpaymentmode] = React.useState({cod:false,prepaid:false})
const[warehouse , setwarehouse] = React.useState([])
const [selectedWarehouses, setSelectedWarehouses] = React.useState([]);
const [sortOption, setSortOption] = React.useState("Recommended");
const [sub, setSub] = React.useState(null);
const [dateRange, setDateRange] = React.useState({
  startDate: moment().startOf("day"), // Default start date
  endDate: moment().startOf("day"),   // Default end date
});



  React.useEffect(()=>{
    shipment_list(null,0,true)
  },[ paymentmode , selectedWarehouses])

    // console.log("shipmentState",shipmentstate)
    React.useEffect(() => {
  
        shipment_list(null,0,true)
        dimension_detail()
        get_warehouse()
        user()
      }, [otherStates.rowsPerPage])

      React.useEffect(() => {
        // Set the date range dynamically based on fetchedDays
        let dataToSend ={}
          let url = config.apiUrl + '/user/system_preferences_fetch';
       axios.post(url, dataToSend, { headers: config.headers}) .then((response) => {
      
        const data = response.data;
        console.log("response.data ==" , response.data)
        if(response.data!=null){
       
        if (data.orderDateRange === "7 Days") {
          setDateRange({
            startDate: moment().subtract(7, "days").startOf("day"),
            endDate: moment().startOf("day"),
          });
        } else if (data.orderDateRange === "30 Days") {
          setDateRange({
            startDate: moment().subtract(30, "days").startOf("day"),
            endDate: moment().startOf("day"),
          });
        } else if (data.orderDateRange === "Today") {
          setDateRange({
            startDate: moment().startOf("day"),
            endDate: moment().startOf("day"),
          });
        }
       
      }
      else{
        setDateRange({
          startDate: moment().subtract(30, "days").startOf("day"),
          endDate: moment().startOf("day"),
        });
      }
        })
       
      }, []);
      const user=()=>{
        let dataToSend = {
    };
    
      // console.log("datatoSend",dataToSend)
      let url = config.apiUrl + '/user/find_customer_detail';
    //  console.log("headers =========> ", config.headers);
      axios.post(url, dataToSend, { headers: config.headers })
      .then((res) => {
        // console.log("res ===" , res.data.output)
        setUserState(res.data.output)
        let response  = res.data.ouptut
        // console.log("response ===" , response)
        //   const obscuredAadhar = obscureAadharNumber(res.data.ouptut.aadhaar_number);
        // console.log("obscuredAadhar == " , obscuredAadhar)
        // setAadharNumber(obscuredAadhar);
          })
          .catch((error) => {
              // console.log(error);
          });    
    }
    const shipment_list=(status,index=0,onLoad)=>{
        let dataToSend = {customer_id : userData.customer_id ,find:searchstate.search_shipment,  status:status, limit:otherStates.rowsPerPage,indexValue:index , mode:"International"
    };
    if(date.from_date!="" && date.to_date!=""){
      dataToSend.from_date = date.from_date
      dataToSend.to_date = date.to_date

    }
   if(selectedWarehouses.length>0){
    dataToSend.warehouse = selectedWarehouses
   }
    if (paymentmode.cod && !paymentmode.prepaid) {
      dataToSend.payment_mode = 'cod';
  } else if (!paymentmode.cod && paymentmode.prepaid) {
      dataToSend.payment_mode = 'prepaid';
  } else if (paymentmode.cod && paymentmode.prepaid) {
      dataToSend.payment_mode = 'both';
  }
   
      // console.log("datatoSend",dataToSend)
      let url = config.apiUrl + '/shipment/customer_orders';
     
     // console.log("headers => ", config.headers);
     
      
      axios.post(url, dataToSend, { headers: config.headers })
          .then((res) => {
            // console.log("responseJson => ", res);
            if( onLoad) {
              setOtherState({...otherStates,total_count:res.data.count ,  page:index})         

  }
            setState({shipment_list:res.data.output,isLoading:false})
            setisLoading(false)
         
          })
          .catch((error) => {
              //Hide Loader
            //   setLoadingstate({...loadingstate,loading:false})
              // console.log(error);
          });    

    }
    const handlePageChange = (event,newPage) =>{
      setOtherState({...otherStates,page:newPage})
      // console.log("newPage ", newPage);
      // getCountryList(newPage);
      shipment_list(shipmentstate.shipment_status,newPage,true)
  }
  const handleChangeRowsPerPage = (event , newPage) => {
      // console.log("event ", event.target.value);
      setOtherState({ ...otherStates, rowsPerPage: event.target.value })
       
  }

    const dimension_detail = () =>{
      let full_api = config.apiUrl + `/user/get_company_info_api`;
      let sendData = {};
    
      axios.post(full_api, sendData, { headers: config.headers }).then(res => {
        setdimensionState({...dimensionState,companyDetail:res.data.companyDetail});
        // console.log("rnmmmmm", res);
      }).catch((e) => {
        // toast.configure()
         //toast.error("Some thing went wrong")
        // console.log("----error:   ", e);
      })
    }

    const get_warehouse = () =>{
      let full_api = config.apiUrl + `/shipment/getAllWarehouse`;
      let sendData = {};
    
      axios.post(full_api, sendData, { headers: config.headers }).then(res => {
        if(res.data.status==true){
          setwarehouse(res.data.output)
        }
     console.log("rnmmmmm", res);
      }).catch((e) => {
        // toast.configure()
        //  toast.error("Some thing went wrong")
        console.log("----error:   ", e);
      })
    }

   
        // const syncOrders = ()=>{
        //     setsyncLoading(true)
        //     let full_api = config.apiUrl + `/shipment/sync_orders`;
        //     let sendData = {customer_id:userData.customer_id};
        
        //     axios.post(full_api, sendData, { headers: config.headers }).then(res => {
        //     // console.log("rnmmmmm", res);
        //     if(res.data.status==true){
        //         setsyncLoading(false)
        //         shipment_list()

        //     }
        //     else{
        //         const Toast = Swal.mixin({
        //             toast: true,
        //             position: 'bottom-end',
        //             showConfirmButton: false,
        //             timer: 3000
        //         })
        //                  Toast.fire({
        //                         background: "#10b93b",
        //                         type: 'error',
        //                         title: "Something Went Wrong!",
        //                         color: "white"
        //                     });
        //     }
        //     }).catch((e) => {
        //     // console.log("----error:   ", e);
        //     const Toast = Swal.mixin({
        //         toast: true,
        //         position: 'bottom-end',
        //         showConfirmButton: false,
        //         timer: 3000
        //     })
        //              Toast.fire({
        //                     background: "#10b93b",
        //                     type: 'error',
        //                     title: "Something Went Wrong!",
        //                     color: "white"
        //                 });
        //     })
        // }

        const clone_order = (sub) => {
          // console.log("order_id")
        
              let dataToSend = { sender_name: sub.sender[0].name,
                                  sender_mobile: sub.sender[0].mobile,
                                  sender_email: sub.sender[0].email,
                                  sender_address: sub.sender[0].address,
                                  sender_city: sub.sender[0].city,
                                  sender_city_id: sub.sender[0].city_id,
                                  sender_state_name:sub.sender[0].state,
                                  sender_country_name: sub.sender[0].country,
                                  sender_country_code: sub.sender[0].country_code,
                                  sender_postal_code: sub.sender[0].pincode,
                                  receiver_name: sub.receiver[0].name,
                                  receiver_mobile: sub.receiver[0].mobile,
                                  receiver_email: sub.receiver[0].email,
                                  receiver_address: sub.receiver[0].address,
                                  receiver_city: sub.receiver[0].city,
                                  receiver_country_name: sub.receiver[0].country,
                                  receiver_country_code: sub.receiver[0].country_code,
                                  receiver_postal_code: sub.receiver[0].pincode,
                                  receiver_state_name:sub.receiver[0].state,
                                  receiver_state_id:sub.receiver[0].state_id,
                                  receiver_city_id:sub.receiver[0].city_id,
                                  return_name:sub.return[0].name,
                                  return_mobile: sub.return[0].mobile,
                                  return_email: sub.return[0].email,
                                  return_address: sub.return[0].address,
                                  return_city:sub.return[0].city,
                                  return_state_name:sub.return[0].state,
                                  return_country_name: sub.return[0].country,
                                  return_country_code: sub.return[0].country_code,
                                  return_postal_code: sub.return[0].pincode,
                                  payment_mode:sub.payment_mode,
                                  shipment_type:sub.type,
                                  cod_amount : sub.cod_amount,
                                  volumetric_weight:sub.volumetric_weight,
                                  total_weight: sub.total_weight,
                                  dead_weight: sub.dead_weight,
                                  length: sub.length, height: sub.height,
                                  width: sub.width,
                                  insurance_amount: sub.insurance_amount,
                                  type:sub.type,
                                  address_id : sub.address_id,
                                  order_id:"",
                                  order_date: "",
                                  mode: sub.mode,
                                  carrier_type:1,
                                  order_type:sub.order_type?sub.order_type=="Fulfilled"?2:1:1 ,
                                  items:sub.items
                                  }
                    
                                  dataToSend.item_name = sub.items.map((i)=>(
                                  i.item_name
                                  ))   
                                  dataToSend.item_value = sub.items.map((i)=>(
      
                                  i.item_value
                                  ))      
                                  dataToSend.quantity = sub.items.map((i)=>(
      
                                  i.item_quantity
                                  ))                   
                                  
                                  dataToSend.customer_id = userData.customer_id;
                                  // console.log("datatoSend", dataToSend)
      
                                  let url = config.apiUrl + '/shipment/order_create';
      
                                  // setOtherState({ ...otherState, submit_button: false })
                                  axios.post(url, dataToSend, { headers: config.headers })
                                  .then((responseJson) => {
                                  // console.log("responseJson => ", responseJson.data.output);
                                  // setOtherState({ ...otherState, submit_button: true })
                                  if (responseJson.data.status == true) {
                                    shipment_list()
                                    const Toast = Swal.mixin({
                                      toast: true,
                                      position: 'bottom-end',
                                      showConfirmButton: false,
                                      timer: 3000
                                    })       
                                  Toast.fire({
                                  background:"rgb(25, 135, 84)",
                                  type: 'unsuccess',
                                  title: "Order created successfully.",
                                  color:"white"
                                  });
                                  setOpenOffdetailcanvas(false);
                                  }
                                  else{
                                      const Toast = Swal.mixin({
                                          toast: true,
                                          position: 'bottom-end',
                                          showConfirmButton: false,
                                          timer: 3000
                                        })       
                                      Toast.fire({
                                      background:"rgb(25, 135, 84)",
                                      type: 'unsuccess',
                                      title: "Something Went Wrong.",
                                      color:"white"
                                      }); 
                                  }
                                  })
      }
      const handleSelectAllmobile = () => {
        const isAllSelected = selected.select_order_id.length === state.shipment_list.length;
        const selectedIds = isAllSelected ? [] : state.shipment_list.map(order => order.order_number);
      
        setSelected({
          select_count: selectedIds.length,
          select_order_id: selectedIds,
        });
      };
      const convertToYYYYMMDD = (inputDate) => {
        const dateParts = inputDate.split(/[-/]/); // Split by / or -
        // return `${dateParts[0]}-${dateParts[1]}-${dateParts[2]}`;
        const date = new Date(inputDate);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
      };
      const edit_order =(sub)=>{
        const itemsJson = JSON.stringify(sub.items);
  const encodedItems = encodeURIComponent(itemsJson);
  // console.log("encodedItems === " , encodedItems)

 
  const orderDate = sub.order_date // Example order_date
  // console.log("orderDate === " , orderDate)
  const formattedOrderDate = convertToYYYYMMDD(orderDate);
  // const formattedOrderDate = moment(orderDate, 'DD/MM/YYYY').format('YYYY-MM-DD');
window.location.replace(`/customer/create_order_1?value=${sub.mode=="Domestic"?0:1}&receiver_name=${sub.receiver[0].name}&receiver_email=${sub.receiver[0].email}&receiver_mobile=${sub.receiver[0].mobile}&receiver_address=${sub.receiver[0].address}&receiver_country=${sub.receiver[0].country}&receiver_country_code=${sub.receiver[0].country_code}&receiver_postal_code=${sub.receiver[0].pincode}&receiver_city=${sub.receiver[0].city}&receiver_state_name=${sub.receiver[0].state}&sender_name=${sub.sender[0].name}&sender_address=${sub.sender[0].address}&sender_country=${sub.sender[0].country}&sender_country_code=${sub.sender[0].country_code}&sender_postal_code=${sub.sender[0].pincode}&sender_state_name=${sub.sender[0].state}&sender_city=${sub.sender[0].city}&sender_address_id=${sub.address_id}&sender_city_id=${sub.sender[0].city_id}&sender_mobile=${sub.sender[0].mobile}&sender_email=${sub.sender[0].email}&return_name=${sub.return[0].name}&return_email=${sub.return[0].email}&return_mobile=${sub.return[0].mobile}&return_address=${sub.return[0].address}&return_country=${sub.return[0].country}&return_country_code=${sub.return[0].country_code}&return_postal_code=${sub.return[0].pincode}&return_city=${sub.return[0].city}&return_state_name=${sub.return[0].state}&mode=${sub.mode}&items=${encodedItems}&shipment_type=${sub.type}&insurance=${sub.insurance_amount}&order_id=${sub.order_number}&order_date=${formattedOrderDate}&weight=${sub.total_weight}&height=${sub.height}&width=${sub.width}&length=${sub.length}&cod_amount=${sub.cod_amount}&payment_mode=${sub.payment_mode}&store_id=${sub.store_id!=undefined?sub.store_id:""}&channel_name=${sub.channel_name!=undefined?sub.channel_name:""}&volumetric_weight=${sub.volumetric_weight}&dead_weight=${sub.dead_weight}&receiver_state_id=${sub.receiver[0].state_id}&receiver_city_id=${sub.receiver[0].city_id}`)
      //   navigate({pathname:`/customer/create_order_1`,
      //   search: `value=${sub.mode=="Domestic"?0:1}&receiver_name=${sub.receiver[0].name}&receiver_email=${sub.receiver[0].email}&receiver_mobile=${sub.receiver[0].mobile}&receiver_address=${sub.receiver[0].address}&receiver_country=${sub.receiver[0].country}&receiver_country_code=${sub.receiver[0].country_code}&receiver_postal_code=${sub.receiver[0].pincode}&receiver_city=${sub.receiver[0].city}&receiver_state_name=${sub.receiver[0].state}&sender_name=${sub.sender[0].name}&sender_address=${sub.sender[0].address}&sender_country=${sub.sender[0].country}&sender_country_code=${sub.sender[0].country_code}&sender_postal_code=${sub.sender[0].pincode}&sender_state_name=${sub.sender[0].state}&sender_city=${sub.sender[0].city}&sender_address_id=${sub.address_id}&sender_city_id=${sub.sender[0].city_id}&sender_mobile=${sub.sender[0].mobile}&sender_email=${sub.sender[0].email}&return_name=${sub.return[0].name}&return_email=${sub.return[0].email}&return_mobile=${sub.return[0].mobile}&return_address=${sub.return[0].address}&return_country=${sub.return[0].country}&return_country_code=${sub.return[0].country_code}&return_postal_code=${sub.return[0].pincode}&return_city=${sub.return[0].city}&return_state_name=${sub.return[0].state}&mode=${sub.mode}&items=${encodedItems}&shipment_type=${sub.type}&insurance=${sub.insurance_amount}&order_id=${sub.order_number}&order_date=${formattedOrderDate}&weight=${sub.total_weight}&height=${sub.height}&width=${sub.width}&length=${sub.length}&cod_amount=${sub.cod_amount}&payment_mode=${sub.payment_mode}&store_id=${sub.store_id!=undefined?sub.store_id:""}&channel_name=${sub.channel_name!=undefined?sub.channel_name:""}&volumetric_weight=${sub.volumetric_weight}&dead_weight=${sub.dead_weight}&receiver_state_id=${sub.receiver[0].state_id}&receiver_city_id=${sub.receiver[0].city_id}`
      // })
      }

      const handleChangeSelected =(e , sub)=>{
        setSelected(prevSelected => {
          if (prevSelected.select_order_id.includes(sub.order_number)) {
            // Remove the orderId if it's already in the selected list
            return {
              select_count: prevSelected.select_count - 1,
              select_order_id: prevSelected.select_order_id.filter(id => id !== sub.order_number)
            };
          } else {
            // Add the orderId if it's not in the selected list
            return {
              select_count: prevSelected.select_count + 1,
              select_order_id: [...prevSelected.select_order_id, sub.order_number]
            };
          }
        });
      }

      const product_list=()=>{
        let dataToSend = {
            mode:"International"
    };
    // console.log("datatoSend",dataToSend)
      let url = config.apiUrl + '/shipment/all_product_list';
     axios.post(url, dataToSend, { headers: config.headers })
          .then((res) => {
            // console.log("responseJson => ", res);
            setproduct(res.data.output)
         })
          .catch((error) => {
              // console.log(error);
          });    
          }

    const showModal =()=>{
      if(userstate.allocate==0){
        product_list()
        setModalState({show:true})
      }
      else{
        onsubmitbulk()
      }
      

    }

    const onChangeProduct =(e)=>{
      // console.log("e == " , e)
      let values =   Object.assign({},e.target.value.split(',') )
      // console.log("first",values[0])
      let product_id = values[0]
      let carrier_id = values[1]
       setProductselect({...productselect, product_id:product_id , carrier_id:carrier_id})
    }

    const onsubmit =()=>{
      setonSubmit(true)
      let dataToSend = {
        order_id :selected.select_order_id , product_id:productselect.product_id , carrier_id:productselect.carrier_id
      };
      // console.log("datatoSend",dataToSend)
      if(productselect.product_id!="" && productselect.carrier_id !=""){
        let url = config.apiUrl + '/shipment/international_bulk_shipment_booking';
        axios.post(url, dataToSend, { headers: config.headers })
             .then((res) => {
              //  console.log("responseJson => ", res);
               if(res.data.status==true){
                setonSubmit(false)
                window.location.replace(`/customer/bulk_orders_log/${res.data.log_id}`)
               }
               else{
                setonSubmit(false)
               }
              //  setproduct(res.data.output)
            })
             .catch((error) => {
                //  console.log(error);
                setonSubmit(false)

             }); 
      }
      else{
        const Toast = Swal.mixin({
          toast: true,
          position: 'bottom-end',
          showConfirmButton: false,
          timer: 3000
        })       
      Toast.fire({
      background:"rgb(25, 135, 84)",
      type: 'unsuccess',
      title: "Please Select Product Type.",
      color:"white"
      }); 
      }
      
    }

    const onsubmitbulk =()=>{
      setonSubmit(true)
      let dataToSend = {
        order_id :selected.select_order_id 
      };
        let url = config.apiUrl + '/shipment/allocate_bulk_shipment_booking';
        axios.post(url, dataToSend, { headers: config.headers })
             .then((res) => {
              //  console.log("responseJson => ", res);
               if(res.data.status==true){
                setonSubmit(false)
                window.location.replace(`/customer/bulk_orders_log/${res.data.log_id}`)
               }
               else{
                setonSubmit(false)
               }
              //  setproduct(res.data.output)
            })
             .catch((error) => {
                //  console.log(error);
                setonSubmit(false)

             }); 
      
     
      
    }

    const modalClose =()=>{
      setModalState({show:false})
      setProductselect({...productselect , product_id:"" , carrier_id:""})

    }

    const handleSelectAll = (event) => {
      const selectedIds = event.target.checked
        ? state.shipment_list.map(order => order.order_number)
        : [];
  
      setSelected({
        select_count: selectedIds.length,
        select_order_id: selectedIds,
      });
    };

    const cancelOrder=(sub)=>{
      let dataToSend = {
        order_id :sub.order_number
      };
      // console.log("datatoSend",dataToSend)
     
        let url = config.apiUrl + '/shipment/cancel_order';
        axios.post(url, dataToSend, { headers: config.headers })
             .then((res) => {
              //  console.log("responseJson => ", res);
               if(res.data.status==true){
                shipment_list(null,0,true)
                const Toast = Swal.mixin({
                  toast: true,
                  position: 'bottom-end',
                  showConfirmButton: false,
                  timer: 3000
                })       
              Toast.fire({
              background:"rgb(25, 135, 84)",
              type: 'unsuccess',
              title: "Order Cancelled",
              color:"white"
              }); 
              setOpenOffdetailcanvas(false);
               }
              //  setproduct(res.data.output)
            })
             .catch((error) => {
                //  console.log(error);
             }); 
    }
    const cancelBulkOrder=()=>{
      let dataToSend = {
        order_id :selected.select_order_id
      };
      // console.log("datatoSend",dataToSend)
     
        let url = config.apiUrl + '/shipment/cancel_bulk_order';
        axios.post(url, dataToSend, { headers: config.headers })
             .then((res) => {
              //  console.log("responseJson => ", res);
               if(res.data.status==true){
                shipment_list(null,0,true)
                // window.location.reload()
                const Toast = Swal.mixin({
                  toast: true,
                  position: 'bottom-end',
                  showConfirmButton: false,
                  timer: 3000
                })       
              Toast.fire({
              background:"rgb(25, 135, 84)",
              type: 'unsuccess',
              title: "Orders Cancelled",
              color:"white"
              }); 
              window.location.reload()
               }
              //  setproduct(res.data.output)
            })
             .catch((error) => {
                //  console.log(error);
             }); 
    }
    
    const handlesearchShipment=(e)=>{
      setsearchstate({search_shipment:e.target.value})
     }
     const get_list = async (sortValue , sorting) => {
      console.log("sortValue == " , sortValue)
    
      setpackageloading({...packageloading,loading:true})
      
      let sort = "default";
       if(sortValue) {
         sort = sortValue
       }
       setWarehouseState({warehouse:sortValue.warehouse_id?sortValue.warehouse_id:sortValue.warehouse_name})
      let dataToSend = {customer_id:sortValue.customer_id,from_postal_code:sortValue.sender[0].pincode ,to_country:sortValue.receiver[0].country, from_country_code:sortValue.sender[0].country_code , from_city_id:sortValue.sender[0].city_id, to_postal_code:sortValue.receiver[0].pincode,to_country_code:sortValue.receiver[0].country_code , weight:sortValue.total_weight,length:sortValue.length,height:sortValue.height,width:sortValue.width,sort_by:sort , parcel_type:sortValue.type , mode:sortValue.mode , payment_mode:sortValue.payment_mode , order_amount:sortValue.cod_amount,carrier_type:1
      };
      
    
      console.log("datatoSend",dataToSend)
      let url = config.apiUrl + '/shipment/international_shipment_rate_time';
     
     // console.log("headers => ", config.headers);
     
      
      axios.post(url, dataToSend, { headers: config.headers })
          .then((responseJson) => {
            console.log("responseJson => ", responseJson.data);
            
            setpackageloading({...packageloading,loading:false})
            if(responseJson.data.status=="success"){
              let sortedPackages = responseJson.data.output;

              
              // Sort the packages based on total charges
              if (sorting === 'Cheapest') {
                sortedPackages.sort((a, b) => a.total_charges - b.total_charges);
              }
              else if(sorting === 'Highest'){
                sortedPackages.sort((a, b) => b.total_charges - a.total_charges);
              }
      console.log("sortedPackages = " , sortedPackages)
              setpackageState(sortedPackages);
             
              if(responseJson.data.output.length==0){
                seterrorstate({...errorstate,message:"No services available for the selected route and package combination."})
              }
            }
  
            else{
            
            //  seterrorstate({...errorstate,message:"No services available for the selected route and package combination."})
            //  console.log("message",responseJson.data.message);
             Swal.fire({
              icon: 'error',
              // title: 'Oops...',
              text: 'Something Went wrong',
              
            })
            }
             
          })
          .catch((error) => {
              //Hide Loader
              setpackageloading({...packageloading,loading:false})
              // console.log(error);
          });
  };
  const openOffcanvasHandler = (sub) => {
    console.log("sub === " , sub)
    setbookorder(sub)
    setSub(sub)
    setallocatedata(sub)

    if(userstate.allocate==0){
    setOpenOffcanvas(true);
    get_list(sub);
    }
  //  else{
  //   console.log("allocate ==" , userstate.allocate)
  //   setbookorder(sub)
  //   console.log("book --- ---" , bookorder)
  //   setSub(sub)
  //   setTimeout(()=>{
  //     submit_detail(1)
  //   },3000)
   
  //   }
    
  };

  React.useEffect(()=>{
   if(userstate.allocate !=0 ){
    submit_detail(1)
   }
  },[alloctedata])

  const openOffdetailcanvasHandler = (sub) => {
    setOpenOffdetailcanvas(true);
setbookorder(sub)
   
  };
  const closeOffdetailcanvasHandler = () => {
    setOpenOffdetailcanvas(false);
    setbookorder([])
    // Call your get_list function here if needed
    // get_list(sub);
  };  
  const closeOffcanvasHandler = () => {
    setOpenOffcanvas(false);
    setpackageState([])
    setWarehouseState({warehouse:""})
    setbookorder([])
    setallocatedata([])
    setSub(null)
    seterrorstate({...errorstate,message:""})
    // Call your get_list function here if needed
    // get_list(sub);
  };
  

  const submit_detail = async (type,item) => {
    // console.log("type",type)
    // console.log("courier_id",item)
    // console.log("bookorder ===== ", bookorder)
    let bookorder = alloctedata
    const cleanedSenderMobile = bookorder.sender[0].mobile.replace(/\D/g, '');
let formattedSenderMobile ;
if (cleanedSenderMobile.length > 10 && cleanedSenderMobile.startsWith('91')) {
  formattedSenderMobile = cleanedSenderMobile.substring(2);
} else {
  formattedSenderMobile = cleanedSenderMobile;
}
const cleanedReceiverMobile = bookorder.receiver[0].mobile.replace(/\D/g, '');
// const formattedReceiverMobile = cleanedReceiverMobile.startsWith('91')
//   ? cleanedReceiverMobile.substring(2)
//   : cleanedReceiverMobile;
  let formattedReceiverMobile;

if (cleanedReceiverMobile.length > 10 && cleanedReceiverMobile.startsWith('91')) {
    formattedReceiverMobile = cleanedReceiverMobile.substring(2);
} else {
    formattedReceiverMobile = cleanedReceiverMobile;
}



// Remove the '+' and '91' prefixes from the mobile number
const cleanedReturnMobile = bookorder.return[0].mobile.replace(/\D/g, '');
let formattedReturnMobile ;
if (cleanedReturnMobile.length > 10 && cleanedReturnMobile.startsWith('91')) {
  formattedReturnMobile = cleanedReturnMobile.substring(2);
} else {
  formattedReturnMobile = cleanedReturnMobile;
}
    
      let dataToSend = { sender_name: bookorder.sender[0].name,
                        sender_mobile: formattedSenderMobile,
                        sender_email: bookorder.sender[0].email,
                        sender_address: bookorder.sender[0].address,
                        sender_city: bookorder.sender[0].city,
                        sender_city_id: bookorder.sender[0].city_id,
                        sender_country_name: bookorder.sender[0].country,
                        sender_country_code: bookorder.sender[0].country_code,
                        sender_postal_code: bookorder.sender[0].pincode,
                        company_name: item?item.service_name:"",
                        receiver_name: bookorder.receiver[0].name,
                        receiver_mobile: formattedReceiverMobile,
                        receiver_email: bookorder.receiver[0].email,
                        receiver_address: bookorder.receiver[0].address,
                        receiver_city: bookorder.receiver[0].city,
                        receiver_country_name:bookorder.receiver[0].country,
                        receiver_country_code: bookorder.receiver[0].country_code,
                        receiver_postal_code: bookorder.receiver[0].pincode,
                        receiver_state_id:bookorder.receiver[0].state_id,
                        receiver_city_id:bookorder.receiver[0].city_id,

                        sender_state_name:bookorder.sender[0].state,
                        receiver_state_name:bookorder.receiver[0].state,
                        return_name: bookorder.return[0].name,
                        return_mobile: formattedReturnMobile,
                        return_email: bookorder.return[0].email,
                        return_address: bookorder.return[0].address,
                        return_city: bookorder.return[0].city,
                        return_state_name: bookorder.return[0].state,
                        return_country_name: bookorder.return[0].country,
                        return_country_code: bookorder.return[0].country_code,
                        return_postal_code: bookorder.return[0].pincode,
                        courier_id : item && item.courier_id?item.courier_id:"",
                        product_id: item&&item.service_provider_id ? item.service_provider_id:"",
                        discount:item&&item.discount ? item.discount:0,
                        payment_mode:bookorder.payment_mode,
                        shipment_type:bookorder.type,
                        cod_amount : bookorder.cod_amount,
                        weight: bookorder.total_weight, length: bookorder.length, height: bookorder.height,
                        width: bookorder.width, item_name:"",item_value:"",
                        product_type_name: item && item.product_type_name?item.product_type_name:"",
                        total_amount: item ? Number(item.total_charges)+Number(item.commission_charge):0,
                        tax_amount: 0,
                        rate_price :item ? item.total_charges:0,
                        service_name: item && item.service_provider?item.service_provider:"",
                        carrier_id: item && item.carrier_id,
                        insurance_amount: bookorder.insurance_amount?bookorder.insurance_amount:0,
                        type: bookorder.type,
                        carrier_id: item && item.carrier_id ,
                        address_id : bookorder.address_id,
                        order_id:bookorder.order_number,
                        order_date:bookorder.order_date,
                        mode:bookorder.mode,
                        store_id:bookorder.store_id?bookorder.store_id:"",
                        channel_name:bookorder.channel_name?bookorder.channel_name:"",
                        created_by : "Customer",
                        volumetric_weight:bookorder.volumetric_weight,
                        channel_id:bookorder.channel_id?bookorder.channel_id:"",
                         total_weight: bookorder.total_weight ,
                         payment_status : 1,
                         payment_type: type!=undefined ? type :0  , carrier_type:1 , order_type:bookorder.order_type?bookorder.order_type=="Fulfilled"?2:1:1 ,
                         zone_name: item && item.zone_name?item.zone_name:"",
                        items:bookorder.items  
                        }
                          
      dataToSend.item_name = bookorder.items.map((sub)=>(
      sub.item_name
      ))   
      dataToSend.item_value = bookorder.items.map((sub)=>(
        
       sub.item_value
      ))      
      dataToSend.quantity = bookorder.items.map((sub)=>(
        
        sub.item_quantity
       ))                   
                         
                         
      
      dataToSend.cust_id = bookorder.customer_id;
      console.log("datatoSend", dataToSend)

      let url = 
       config.apiUrl + '/shipment/new_shipment_create';
      
      setOtherStates({ ...otherState, submit_button: false })
      axios.post(url, dataToSend, { headers: config.headers })
        .then((responseJson) => {
          // console.log("responseJson => ", responseJson.data.output);
          setOtherStates({ ...otherState, submit_button: true })
          if (responseJson.data.status == "success") {
          setTimeout(()=>{
            setOtherStates({ ...otherState, submit_button: false })
                   window.location.replace(`/customer/shipments`, { replace: true, state: [] })
          },2000)
          const Toast = Swal.mixin({
            toast: true,
            position: 'bottom-end',
            showConfirmButton: false,
            timer: 3000
          })
            Toast.fire({
            background:"rgb(25, 135, 84)",
            type: 'unsuccess',
            title: "Shipment booking process starts.",
            color:"white"
          });

          }
          else {
            setOtherStates({ ...otherState, submit_button: false })
            Swal.fire({
              icon: 'error',
            
              text: responseJson.data.message,

            })
            
          }
        
        }).catch((error) => {
          setOtherStates({ ...otherState, submit_button: false })
          // console.error(error);
        });
      
      
    }

    const dateselect =(start , end)=>{
        setdate({...date, from_date:moment(start).format('YYYY-MM-DD'), to_date:moment(end).format("YYYY-MM-DD")})
     }
     React.useEffect(() => {
      // Initialize daterangepicker after the date range is set
      $(".custom-daterange").daterangepicker({
        opens: "right",
        startDate: dateRange.startDate.format("YYYY-MM-DD"),
        endDate: dateRange.endDate.format("YYYY-MM-DD"),
        autoUpdateInput: true,
        locale: {
          format: "YYYY-MM-DD", // Display format
        },
      }, (start, end) => {
        console.log("Selected range:", start.format("YYYY-MM-DD"), "to", end.format("YYYY-MM-DD"));
        dateselect(start, end); 
      });
    
      // Set initial value in the input
      $(".custom-daterange").val(
        `${dateRange.startDate.format("YYYY-MM-DD")} to ${dateRange.endDate.format("YYYY-MM-DD")}`
      );
    }, [dateRange]);

    const modeChange =(e , value)=>{
    if(e.target.checked){
setpaymentmode({...paymentmode , [e.target.name]:true})
    }
    else{
      setpaymentmode({...paymentmode , [e.target.name]:false})
    }

    }

    const handleWarehouseChange = (e, warehouseId) => {
      const isChecked = e.target.checked;
      if (isChecked) {
          setSelectedWarehouses([...selectedWarehouses, warehouseId]);
      } else {
          setSelectedWarehouses(selectedWarehouses.filter(id => id !== warehouseId));
      }
  };
  const handleSortChange = (value) => {
    setpackageState([])
    setSortOption(value);
    if (sub) {
      get_list(sub, value); // Use the updated 'sub' value along with sorting option
    }
   
  };
  return (
    <div>
    
    <Header/>
    <div class="page-wrapper pinned">
    <div class="main-container">
      <div className='app-container'>
    <div class="app-body">
    
    
    <div class="container-fluid">
    <div class="mob_nav">
      <div class=" d-flex justify-content-start ">
        <a href="javascript:window.history.back();" class=""><i class="bi bi-arrow-left"></i></a>
        <h2>Orders</h2>
      </div>
    </div>
    
    
    
    <div class="row justify-content-between">
        <div class="col-12 col-md-4 desk_nav">
          <div class="d-flex">
            <a href="javascript:window.history.back();" class="back_btn"><i class="bi bi-arrow-90deg-left"></i></a>
            <h2>Orders</h2>
          </div>
        </div>
        <div class="col-12 col-md-4 mb-3">
          <div class="row justify-content-end">
              <div class="col-6 col-md-6">
                <div class="d-grid">
                  <div class="dropdown">
                    <button type="button" class="btn btn-primary btn-block dropdown-toggle" data-bs-toggle="dropdown">
                      <i class="bi bi-bag-plus"></i> Create New Order
                    </button>
                    <ul class="dropdown-menu">
                      <li><a class="dropdown-item"  href='/create_order_1?value=0' style={{cursor:"pointer"}}><i class="bi bi-bag-plus"></i> Create New Order</a></li>
    
                      {/* <li><a class="dropdown-item" href="quick_create_order.php"><i class="bi bi-lightning"></i> Create Quick Order</a></li> */}
                      <li><a class="dropdown-item"  href='/bulk_order_uplaod' style={{cursor:"pointer"}}><i class="bi bi-cloud-upload"></i> Upload Bulk Orders</a></li>
                    </ul>
                  </div>
                </div>
              </div>
              {/* <div class="col-6 col-md-4">
                <div class="d-grid">
                  <div class="dropdown text-end">
                    <button type="button" class="btn btn-outline-primary btn-block dropdown-toggle" data-bs-toggle="dropdown">
                    Actions 
                    </button>
                    <ul class="dropdown-menu">
                    {syncLoading?
                    <ReactLoading type={"spin"} color={"#0d6efd"} height={40} width={20} />:
                    <li><a class="dropdown-item" onClick={()=>syncOrders()} style={{cursor:"pointer"}}><i class="bi bi-arrow-repeat"></i> Sync Orders</a></li>
    }
                    
                    
                    </ul>
                  </div>
                </div>
              </div> */}
          </div>
        </div>
    </div>
    
    
    
    <div class="card mb-3">
      <div class="card-body">
        <div class="row justify-content-between">
            <div class="col-12 col-md-6 mb-1">
              <div class="row g-1">
                  <div class="col-12 col-md-6">
                    <div class="input-group">
                      <input type="text" class="form-control custom-daterange" />
                      <span class="input-group-text">
                      <i class="bi bi-search" onClick={(e)=>shipment_list(null,0,true)}></i>
                      </span>
                    </div>
                  </div>
                  <div class="col-12 col-md-6">
                    <div class="search-box">
                        <div class="search-icon">
                        <i class="bi bi-search" onClick={(e)=>shipment_list(null,0,true)}></i>
                        </div>
                        <input type="text" class="search-input" name="search_shipment" placeholder="Enter Order Number" onChange={(e)=>handlesearchShipment(e)}/>
                    </div>
                  </div>
              </div>
            </div>
            <div class="col-12 col-md-6 mb-1">
              <div class="row justify-content-between">
              {selected.select_count==0?
                   ""
                    :
                  <div class="col-6 col-md-6">
                    <div class="dropdown">
                      <button type="button" class="btn btn-outline-secondary dropdown-toggle" data-bs-toggle="dropdown">
                        Bulk Actions
                      </button>
                      <ul class="dropdown-menu">
                      {userstate.aadhar_kyc!=undefined && userstate.aadhar_kyc==true  ? <li><a class="dropdown-item" style={{cursor:"pointer"}} onClick={()=>showModal()}>Ship Order</a></li>
                      :
                        <li><a class="dropdown-item" href='/customer_kyc' style={{cursor:"pointer"}}>Need KYC First</a></li>}
                        <li><a class="dropdown-item text-danger" onClick={()=>cancelBulkOrder()} style={{cursor:"pointer"}}>Delete Order</a></li>
                      </ul>
                    </div>
                  </div>
    }
    <Modal show={modalState.show} onHide={() => setModalState({ show: false })} size='lg'>
     <Modal.Header closeButton>
                         <Modal.Title>
                         <h6 class="modal-title">Bulk Shipment</h6>
                         </Modal.Title>
                     </Modal.Header>
                     <Modal.Body >
           <div class="modal-body">
             <div class="card border-0 shadow-sm bg-warning mb-3">
               <div class="card-body">
                 <div class="d-flex justify-content-between">
                     <div class="p-3">
                       <i class="bi bi-lightbulb-fill"></i>
                     </div>
                     <div class="ps-2 ">
                       <p>Please select the appropiate product type along with Carrier to process the bulk shipment operation. Please check the log for any error.</p>
                     </div>
                 </div>
               </div>
             </div>
    
            <label for="" class="form-label">Select Product Type:</label>
             <select class="form-select" onChange={(e)=>onChangeProduct(e)}>
               <option>Select Product Type:</option>
               {product.map((sub)=>(
             <option value={[sub.product_id , sub.carrier_id]}>{sub.product_name}  </option>
       
              ))}
           
            </select>
    {onSubmitButton==false?
            <div class="d-grid mt-3">
              <a  class="btn btn-primary btn-block" onClick={()=>onsubmit()}>Submit</a>
            </div>
            :""}
    
            <hr/>
            <div class="d-flex justify-content-between mt-3">
              <div class="">
                
              </div>
              <div class="">
                <button type="button" class="btn btn-danger btn-sm" data-bs-dismiss="modal" onClick={()=>modalClose()}>Close</button>
              </div>
            </div>
    
          </div>
        
                    </Modal.Body>
                   
                </Modal>
                  <div class="col-6 col-md-6 text-end">
                    <a  class="btn btn-outline-secondary btn-block" onClick={()=>setOpenOffcanvasfilter(true)}><i class="bi bi-filter"></i> Filter</a>
                  </div>
                  {openOffcanvasfilter && (
                    <section>
                    
                  <div class="offcanvas offcanvas-end show" id="Order_Filter" aria-modal="true" role="dialog">
      <div class="offcanvas-header">
        <h2 class="offcanvas-title">Order Filters</h2>
        <button type="button" class="btn-close text-reset" onClick={()=>setOpenOffcanvasfilter(false)}></button>
      </div>
      <div class="offcanvas-body">
        <div class="card mb-3">
          <div class="card-body">
            <h6>Payment Mode</h6>
            <hr/>
            <div class="form-check form-switch  mb-2">
               <input class="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckChecked" name="prepaid" onChange={(e)=>modeChange(e)}  checked={paymentmode.prepaid}/>
               <label class="form-check-label" for="flexSwitchCheckChecked">Prepaid</label>
            </div>
            <div class="form-check form-switch  mb-2">
               <input class="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckChecked" name="cod" onChange={(e)=>modeChange(e)} checked={paymentmode.cod}/>
               <label class="form-check-label" for="flexSwitchCheckChecked">COD (Cash On Delivery)</label>
            </div>
          </div>
        </div>
        <div class="card mb-3">
          <div class="card-body">
            <h6>Warehouse Pickup Locations</h6>
            <hr/>
            {warehouse.map((sub) => (
            <div class="form-check form-switch  mb-2">
               <input class="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckChecked"  value={sub.address_id} 
                        onChange={(e) => handleWarehouseChange(e, sub.address_id)} 
                        checked={selectedWarehouses.includes(sub.address_id)}/>
               <label class="form-check-label" for="flexSwitchCheckChecked">{sub.warehouse_id}</label>
            </div>
            ))}
         
          </div>
        </div>
        {/* <div class="d-grid">
          <a href="" class="btn btn-primary btn-block">Update Filter</a>
        </div> */}
      </div>
      
    </div>
    <div className='offcanvas-backdrop fade show'></div>
    </section>
                  )}
              </div>
            </div>
        </div>
      </div>
    </div>
    
    <div class="card data_table mb-3 mb_display_none">
      <div class="card-body">
        <div class="table-responsive">
          <div class=" rounded-3">
            <table class="table align-top table-striped table-hover m-0 table-xl">
              <thead>
                <tr>
                  <th>
                    {/* <input class="form-check-input form-check-input-lg" type="checkbox" onChange={handleSelectAll}/> */}
                    <input class="form-check-input form-check-input-lg" type="checkbox" id="inlineCheckbox1" value="option1" onChange={handleSelectAll}/>
                    </th>
                  <th>Order #</th>
                  <th>Customer Details</th>
                  <th>Value</th>
                  <th>Package Details</th>
                  {/* <th>Channel</th> */}
                  <th>Pickup Address</th>
                  <th>Product Details</th>
                  <th>Actions</th>
                </tr>				
              </thead>
              <tbody>
              {state.shipment_list.map((sub)=>(
                <tr>
                  <td><input class="form-check-input form-check-input-lg" type="checkbox" checked={selected.select_order_id.includes(sub.order_number)} value="option1" onChange={(e)=>handleChangeSelected(e , sub)}/></td>
                  <td>
                    <a onClick={()=>openOffdetailcanvasHandler(sub)}  style={{cursor:"pointer"}} class="text-primary" >{sub.order_number}</a>
                    <div><small>  <Moment format="MMM DD YYYY">{(sub.order_date)}</Moment> - <Moment format="hh:mm:ss a">
                             {new Date(sub.order_created*1000)}
                 </Moment></small></div>
                  </td>
                  <td>
                  {sub.receiver.map((item)=>(
                    <>
                     {item.name}
                    <div><small>{item.address}</small></div>
                    <div><small>{item.city} , {item.state} - {item.pincode}</small></div>
    
                    <div><small>{item.mobile}</small></div></>
                   
                  ))}
                  </td>
                  <td>
                    {/* ₹ 2000.00 */}
                    <div><span class={`badge rounded-pill ${sub.payment_mode=="prepaid"?'bg-warning':'bg-info'}`}>{sub.payment_mode}</span></div>
                  </td>
                  <td>
                    Dead wt.: {sub.dead_weight!=undefined?sub.dead_weight:""} kg
                    <div><small>{sub.height!=undefined?sub.height:""} x {sub.width!=undefined?sub.width:""} x {sub.length!=undefined?sub.length:""} (cm)</small></div>
                    Volumetric wt.:  {sub.volumetric_weight!=undefined?sub.volumetric_weight:""} Kg  
                  </td>
                  {/* <td>
                  <a href="" data-bs-toggle="tooltip" title="Direct Sales">Direct Sales</a>
                  </td> */}
                  <td>
                  <a href="#"  data-bs-toggle="popover" data-bs-trigger="hover" data-bs-content="Mumbai WI, Warli, 400001">{sub.warehouse_id?sub.warehouse_id:sub.warehouse_name}</a>
                  </td>
                  <td>    {sub.items[0].item_name.substring(0,20) + '...'} {sub.items.length>1?"+1 items":""}
                    {/* iPhone 15 Max Pro +1
                    <div><small>SKU: 838383</small></div>
                    Qty: 01 */}
                  </td>
                  <td>
                    <div class="btn-group">
                    <button type="button" class="btn btn-primary"   onClick={() => openOffcanvasHandler(sub)}>Assign Courier</button>
                      <div class="btn-group">
                        <button type="button" class="btn btn-primary dropdown-toggle" data-bs-toggle="dropdown"></button>
                        <div class="dropdown-menu">
                          <a class="dropdown-item" onClick={()=>edit_order(sub)} style={{cursor:"pointer"}}>Edit Order</a>
                          <a class="dropdown-item" onClick={(e)=>clone_order(sub)} style={{cursor:"pointer"}}>Clone Order</a>
                          <a class="dropdown-item text-danger" onClick={(e)=>cancelOrder(sub)} style={{cursor:"pointer"}}>Cancel Order</a>
                        </div>
                      </div>
                    </div>
                  </td>
                </tr>
              ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    
    {/* <!-- Mobile View Order Data --> */}
    <section class="desk_display_none">
    <div>
      <button type="button" class="btn btn-outline-secondary" value="option1" onClick={handleSelectAllmobile}>
        <i class="bi bi-check2-square"></i>  Select All
      </button>
    </div>
    <div class="card  mb-3 shipm_tablew">
    {state.shipment_list.map((sub)=>(
        <div class="card-body">
          <div class="row">
              <div class="col mb-2">
                <div class="row">
                    <div class="col-2">
                      <input class="form-check-input form-check-input-lg" type="checkbox" checked={selected.select_order_id.includes(sub.order_number)} value="option1" onChange={(e)=>handleChangeSelected(e , sub)}/>
                    </div>
                    <div class="col-10">
                      <h4>Order #{sub.order_number}</h4>
                    </div>
                </div>
              </div>
              <div class="col-2 mb-2 text-end">
                <a href="#" class="btn btn-outline-secondary btn-block btn-sm collapsed" data-bs-toggle="collapse" data-bs-target="#shipment_full" aria-expanded="false"><i class="bi bi-caret-down"></i></a>
              </div>
              <div class="col-12">
                <div class="d-grid">
                  <a onClick={() => openOffcanvasHandler(sub)} style={{cursor:"pointer"}}  class="btn btn-primary btn-block" >Assign Courier</a>
                </div>
              </div>
              <div class="row mt-3">
                  <div class="col-6"><Moment format="MMM DD YYYY">{(sub.order_date)}</Moment> - <Moment format="hh:mm:ss a">
                             {new Date(sub.order_created*1000)}
                 </Moment></div>
                  <div class="col-6 mb-2 text-end">
                    <a onClick={()=>openOffdetailcanvasHandler(sub)}  style={{cursor:"pointer"}}  class="btn btn-outline-secondary btn-block">View Order</a>
                  </div>
              </div>
              <div class="row">
                  <div class="col-12 mb-2">
                    <h5>Customer Details:</h5>
                    {sub.receiver.map((item)=>(
                    <>
                    {item.name}, {item.city}, {item.state} - {item.pincode} <br/>Mob: {item.mobile}</>
                   
                  ))}
                  </div>
                
              </div>
    
              <div class="collapse" id="shipment_full">
                <hr/>
                <div class="row">
                  <div class="col-12 mb-2">
                    <h5>Pickup Address</h5>
                    <a href="#" data-bs-toggle="popover" data-bs-trigger="hover" data-bs-content="Mumbai WI, Warli, 400001">{sub.warehouse_id?sub.warehouse_id:sub.warehouse_name}</a>
                  </div>
                  <div class="col-6 mb-2">
                    <h5>Product Details</h5>
                    {sub.items[0].item_name.substring(0,20) + '...'} {sub.items.length>1?"+1 items":""}
                      {/* <div><small>SKU: 838383</small></div>
                      Qty: 01 */}
                  </div>
                  <div class="col-6 mb-2">
                    <h5>Package Details</h5>
                      Dead wt. : {sub.dead_weight!=undefined?sub.dead_weight:""} kg
                      <div><small>{sub.height!=undefined?sub.height:""} x {sub.width!=undefined?sub.width:""} x {sub.length!=undefined?sub.length:""} (cm)</small></div>
                      Volumetric wt.:  {sub.volumetric_weight!=undefined?sub.volumetric_weight:""} Kg  
                  </div>
                  {/* <div class="col-6 mb-2">
                    <h5>Channel</h5>
                    <a href="" data-bs-toggle="tooltip" data-bs-original-title="Direct Sales">Direct Sales</a>
                  </div> */}
                  <div class="col-6 mb-2">
                    {/* <h5>Value</h5>
                    ₹ 2000.00  */}
                    <span class="badge rounded-pill bg-warning">{sub.payment_mode}</span>
                  </div>
              </div>
              </div>
          </div>
        </div>
    ))}
      </div>
    
    
      
    </section>
    
    </div>
    
    </div>
    <TablePagination
                    component="div"
                    rowsPerPageOptions={[500,800,1000]}
                    count={otherStates.total_count}
                    page={otherStates.page}
                    onPageChange={handlePageChange}
                    rowsPerPage={otherStates.rowsPerPage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                   
                            />
    </div>
    
    <Footer/>
    </div>
    
    </div>
    {openOffcanvas && (
      <section class="order_data">
       <div class="offcanvas offcanvas-end show" id="Carrier_lists" >
        <div class="offcanvas-header">
            <h4 class="offcanvas-title">Select Courier Partner</h4>
            <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" onClick={()=>closeOffcanvasHandler()}></button>
        </div>
        <div class="offcanvas-body">
        <div class="row g-0">
        <div class="col-12 col-md-4 bg-light show_carrier_left mb_display_none">
            <div class="row">
                <div class="col-12 mb-3">
                    <small>Pickup From</small>
                    <p>{bookorder.sender[0].pincode?bookorder.sender[0].pincode:""}, {bookorder.sender[0].pincode?bookorder.sender[0].state:""}</p>
                </div>
                <div class="col-12 mb-3">
                    <small>Deliver To</small>
                    <p>{bookorder.receiver[0].pincode?bookorder.receiver[0].pincode:""}, {bookorder.receiver[0].pincode?bookorder.receiver[0].state:""}</p>
                </div>
                {/* <div class="col-12 mb-3">
                    <small>Order Value</small>
                    <p>₹ 100.00</p>
                </div> */}
                <div class="col-12 mb-3">
                    <small>Payment Mode</small>
                    <p>{bookorder.payment_mode =="cod"?"COD":"Prepaid"}</p>
                </div>
                <div class="col-12 mb-3">
                    <small>Applicable Weight (in Kg)</small>
                    <p>{bookorder.total_weight} Kg</p>
                </div>
            </div>
        </div>
        <div class="col-12 col-md show_carrier_right">
            <div class="px-2 mb-1">
                <div class="row justify-content-between">
                    <div class="col-12 col-md-4">
                        <h5>Available Couriers</h5>
                    </div>
                    <div class="col-12 col-md-4">
                        <select class="form-select" onChange={(e) => handleSortChange(e.target.value)}>
                        <option value="Recommended">Sort By: Recommended</option>
                            <option value="Cheapest">Sort By: Low to High</option>
                            <option value="Highest">Sort By: High to Low</option>
                        </select>
                    </div>
                </div>
            </div>
            {packageloading.loading?
              <div style={{width:"100%", display: "flex",
      justifyContent: "center"}}>
    
        
            <ReactLoading type={"spin"} color={"#0d6efd"}  />
            </div>
            :""}
             <h3>
                {errorstate.message}</h3>
                {packagestate.length>0?
            <ul class="list-group">
                  { packagestate.map((item)=>(
                <li class="list-group-item list-group-item-action">
                    <div class="row g-2">
                        <div class="col-2">
                            <figure>
                            {/* {dimensionState.companyDetail[0].hide_carrier?"":   */}
                             <img src={`https://cdn.shiprath.in/images/${item.carrierImage}`} class="img-fluid" />
                             {/* } */}
                               
                            </figure>
                        </div>
                        <div class="col-4">
                            <h4>{item.service_provider}</h4>
                            <small>{item.product_type_name?item.product_type_name:""}</small>
                        </div>
                        <div class="col-2">
                            <h6>Ch. Weight</h6>
                            <small>{bookorder.total_weight} Kg</small>
                        </div>
                        <div class="col-2">
                            <h2>₹{parseFloat(Number(item.total_charges)+Number(item.commission_charge)).toFixed(2)}</h2>
                        </div>
                        <div class="col-12 col-md-2">
                            <div class="d-grid">
                              <a onClick={()=>submit_detail(1,item )} class="btn btn-primary btn-block">Ship Now</a>
                            </div>
                        </div>
                    </div>
                </li>
                 ))}
              
              
            </ul>
             :""}
        </div>
    </div>
        </div>
        </div>
           <div className='offcanvas-backdrop fade show'></div>
         
    </section>
    )}
    
    {openOffdetailcanvas && (
     <section class="order_data">
     <div class="offcanvas offcanvas-end show" id="Order_data">
     <div class="offcanvas-header shadow-sm">
         <h4 class="offcanvas-title">Order # {bookorder.order_number}</h4>
         <div class="dropdown text-end">
             <button type="button" class="btn btn-outline-primary btn-block dropdown-toggle" data-bs-toggle="dropdown">
             Actions 
             </button>
             <ul class="dropdown-menu">
                 <li><a class="dropdown-item" onClick={()=>edit_order(bookorder)} style={{cursor:"pointer"}}> Edit Order</a></li>
                 <li><a class="dropdown-item" onClick={()=>clone_order(bookorder)} style={{cursor:"pointer"}}> Clone Order</a></li>
                 <li><a class="dropdown-item text-danger" onClick={()=>cancelOrder(bookorder)} style={{cursor:"pointer"}}> Cancel Order</a></li>
             </ul>
         </div>
         <button type="button" class="btn-close text-reset" onClick={()=>closeOffdetailcanvasHandler()}></button>
     </div>
     <div class="offcanvas-body">
     <div class="">
        <div class="row mt-3 order_box_cont">
            <div class="col-12 col-md-12 ">
                <div class="card border-0 mb-3">
                    <div class="card-body">
                        <div class="d-flex ">
                            <div class="border-end pe-3">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" data-lucide="shopping-bag" class="lucide lucide-shopping-bag"><path d="M6 2 3 6v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V6l-3-4Z"></path><path d="M3 6h18"></path><path d="M16 10a4 4 0 0 1-8 0"></path></svg>
                            </div>
                            <div class="ps-3 ">
                                <h6>Order Details</h6>
                            </div>
                        </div>
                        <hr/>
                        <div class="row">
                            <div class="col-12 col-md-6 mb-2">
                                <small class="text-muted">Order created on channel</small>
                                <p>{bookorder.order_date}</p>
                            </div>
                            <div class="col-12 col-md-6 mb-2">
                                <small class="text-muted">Order created on system</small>
                                <p>{bookorder.order_date}</p>
                            </div>
                            <div class="col-12 col-md-6 mb-2">
                                <small class="text-muted">Channel</small>
                                <p>{bookorder.channel_name?bookorder.channel_name:""}</p>
                            </div>
                            <div class="col-12 col-md-6 mb-2">
                                <small class="text-muted">Shipment value</small>
                                <p>₹ {bookorder.items.reduce((total, sub) => total + parseFloat(sub.item_value), 0).toFixed(2)} <span class="badge rounded-pill bg-warning">{bookorder.payment_mode}</span></p>
                            </div>
                        </div>
                    </div>
                </div>
    
                <div class="card border-0 mb-3">
                    <div class="card-body">
                    <div class="d-flex ">
                        <div class="border-end pe-3">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" data-lucide="package" class="lucide lucide-package"><path d="m7.5 4.27 9 5.15"></path><path d="M21 8a2 2 0 0 0-1-1.73l-7-4a2 2 0 0 0-2 0l-7 4A2 2 0 0 0 3 8v8a2 2 0 0 0 1 1.73l7 4a2 2 0 0 0 2 0l7-4A2 2 0 0 0 21 16Z"></path><path d="m3.3 7 8.7 5 8.7-5"></path><path d="M12 22V12"></path></svg>
                        </div>
                        <div class="ps-3 ">
                            <h6>Shipment Details</h6>
                        </div>
                    </div>
                    <hr/>
                    <div class="row">
                        <div class="col-12 col-md">
                            <h6>Ship From:</h6>
                                <small class="text-muted">Sender Name:</small>
                                <p>{bookorder.sender[0].name}</p>
                                <small class="text-muted">Sender Address:</small>
                                <p>{bookorder.sender[0].address},{bookorder.sender[0].city}, {bookorder.sender[0].state} {bookorder.sender[0].pincode}</p>
                                <small class="text-muted">Sender Email:</small>
                                <p>{bookorder.sender[0].email}</p>
                                <small class="text-muted">Sender Mobile:</small>
                                <p>{bookorder.sender[0].mobile}</p>
                        </div>
                        <div class="col-12 col-md">
                            <h6>Ship To:</h6>
                                <small class="text-muted">Receiver Name:</small>
                                <p>{bookorder.receiver[0].name}</p>
                                <small class="text-muted">Receiver Address:</small>
                                <p>{bookorder.receiver[0].address},{bookorder.receiver[0].city}, {bookorder.receiver[0].state} {bookorder.receiver[0].pincode}</p>
                                <small class="text-muted">Receiver Email:</small>
                                <p>{bookorder.receiver[0].email}</p>
                                <small class="text-muted">Receiver Mobile:</small>
                                <p>{bookorder.receiver[0].mobile}</p>
                        </div>
                    </div>
                    </div>
                </div>
    
    
    
                <div class="card border-0  mb-3">
                    <div class="card-body">
                    <div class="d-flex ">
                        <div class="border-end pe-3">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" data-lucide="package-open" class="lucide lucide-package-open"><path d="M12 22v-9"></path><path d="M15.17 2.21a1.67 1.67 0 0 1 1.63 0L21 4.57a1.93 1.93 0 0 1 0 3.36L8.82 14.79a1.655 1.655 0 0 1-1.64 0L3 12.43a1.93 1.93 0 0 1 0-3.36z"></path><path d="M20 13v3.87a2.06 2.06 0 0 1-1.11 1.83l-6 3.08a1.93 1.93 0 0 1-1.78 0l-6-3.08A2.06 2.06 0 0 1 4 16.87V13"></path><path d="M21 12.43a1.93 1.93 0 0 0 0-3.36L8.83 2.2a1.64 1.64 0 0 0-1.63 0L3 4.57a1.93 1.93 0 0 0 0 3.36l12.18 6.86a1.636 1.636 0 0 0 1.63 0z"></path></svg>
                        </div>
                        <div class="ps-3 ">
                            <h6>Package Details</h6>
                        </div>
                    </div>
                    <hr/>
                    <div class="row">
                        <div class="col-12 col-md-4 mb-2">
                            <small class="text-muted">Applicable Weight (in Kg)</small>
                            <p>{bookorder.total_weight}KG</p>
                        </div>
                        <div class="col-12 col-md-4 mb-2">
                            <small class="text-muted">Dead Weight (in Kg)</small>
                            <p>{bookorder.dead_weight}KG</p>
                        </div>
                        <div class="col-12 col-md-4 mb-2">
                            <small class="text-muted">Volumetric Weight (in kg)</small>
                            <p>{bookorder.volumetric_weight}KG</p>
                        </div>
                        <div class="col-12 col-md-4 mb-2">
                            <small class="text-muted">Dimensions (in cm)</small>
                            <p>{bookorder.height} x {bookorder.width} x {bookorder.length}</p>
                        </div>
                        {/* <div class="col-12 col-md-6 mb-2">
                            <small class="text-muted">Number of Boxes</small>
                            <p>1</p>
                        </div> */}
                    </div>
                    </div>
                </div>
    
                <div class="card border-0 mb-3">
                    <div class="card-body">
                    <div class="d-flex ">
                        <div class="border-end pe-3">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" data-lucide="shopping-basket" class="lucide lucide-shopping-basket"><path d="m15 11-1 9"></path><path d="m19 11-4-7"></path><path d="M2 11h20"></path><path d="m3.5 11 1.6 7.4a2 2 0 0 0 2 1.6h9.8a2 2 0 0 0 2-1.6l1.7-7.4"></path><path d="M4.5 15.5h15"></path><path d="m5 11 4-7"></path><path d="m9 11 1 9"></path></svg>
                        </div>
                        <div class="ps-3 ">
                            <h6>Product Details</h6>
                        </div>
                    </div>
                    <hr/>
                    <div class="table-responsive ">
                        <table class="table table-light">
                            <thead>
                            <tr>
                                <th>Name</th>
                                {/* <th>Category</th>
                                <th>HSN</th>
                                <th>SKU</th> */}
                                <th>Qty</th>
                                <th>Unit price</th>
                                {/* <th>Discount</th>
                                <th>Tax</th> */}
                                <th>Total</th>
                            </tr>
                            </thead>
                            <tbody>
                            {bookorder.items.map((sub)=>(
                            <tr>
    
                            <td>{sub.item_name}</td>
                                                            {/* <td>1689070274414</td> */}
                                                            {/* <td>samsung-A50</td> */}
                                                            {/* <td>1</td> */}
                                                            <td>{sub.item_quantity}</td>
                                                            <td>{sub.item_value}</td>
                                                            {/* <td>100.00</td> */}
                                                            {/* <td>10.00</td> */}
                                                            <td> {bookorder.items.reduce((total, sub) => total + parseFloat(sub.item_value * sub.item_quantity), 0).toFixed(2)}</td>
                            </tr>
                            ))}
                            </tbody>
                        </table>
                    </div>
                    </div>
                </div>
    
                
                
                
            </div>
        </div>
    </div>
     </div>
     </div>
     <div className='offcanvas-backdrop fade show'></div>
    </section>
    )}
    
        </div>
  )
}

export default International_orders
