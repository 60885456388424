import React, { useState, createRef, useEffect } from "react";


import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import Swal from 'sweetalert2'
import config from "../../config"


const Create_password = () => {
  let location = useLocation();
    let navigate = useNavigate();
    const [userState, setUserState] = useState({ full_name: "" });
    const [state, setState] = React.useState({ email: location.state.email,password:""  })
   
    const Toast = Swal.mixin({
        toast: true,
        position: 'bottom-end',
        showConfirmButton: false,
        timer: 3000
    });
    const newPasswordCreate = (event) => {
        event.preventDefault();
        if (state.email != '' && state.password!="") {
          let full_api = config.apiUrl + `/user/create_new_password`;
         // let fullOtp = state.otp1+""+state.otp2+""+state.otp3+""+state.otp4+""+state.otp5+""+state.otp6
          let sendData = { email: state.email, password: state.password  };
        
          // console.log(sendData);
          axios.post(full_api, sendData, { headers: config.headers }).then((res) => {
          
        if(res.data.status==true){
            localStorage.setItem('ship_rocket_user', JSON.stringify(res.data.output));
            setUserState({full_name:res.data.output.full_name})
            config.UserId = res.data.output.customer_id
            // let sendState = { email:state.email};
           Toast.fire({
               background:"#3659cd",
               type: 'success',
               title: res.data.message ,
               color:"white"
             });
             setTimeout(()=>{
              window.location.replace('/dashboard')
             },2000)
           }
           else{
            Toast.fire({
                background:"#a61c09",
                type: 'unsuccess',
                title: res.data.message ,
                color:"white"
              });
           }
            
          }).catch((e) => {
           
          });
        }
      };
      const handleChangeBroker = (event) => {
        setState({
                 ...state,
                 [event.target.name]: event.target.value,
               });
  
      }
  return (
    <div>
    
<section class="register_box_00">
  <div class="row justify-content-center ">
    <div class="col-xl-4 col-lg-5 col-sm-6 col-12 ">
      <form onSubmit={(e) => {
          e.preventDefault(); // Prevent the default form submission behavior
          newPasswordCreate(); // Call your login function
        }} class="my-5">
        <div class="border rounded-2 p-4 mt-5 login_box">
          <div class="login-form">
            <div class="text-center login-logo">
              <a href="/login" class="mb-4 text-center">
                <img src="assets/images/logo_big.png" class="img-fluid " alt=""/>
                <h3 class="fw-bold">SkyShip</h3>
              </a>
            </div>
            <h5 class="fw-bold">Create New Password</h5>
            <p>Thanks for OTP confirmation. Please create new password</p>
            <div class="mb-3">
              <label class="form-label">Create Password</label>
              <input type="text" class="form-control" placeholder="Enter new password" name="password" onChange={(e)=>handleChangeBroker(e)}/>
            </div>
            {/* <div class="mb-5">
              <label class="form-label">Confirm Password</label>
              <input type="text" class="form-control" placeholder="Enter new confirm password"/>
            </div> */}
            <div class="d-grid py-3 mt-4">
              <button type="submit" class="btn btn-lg btn-primary" style={{cursor:"pointer"}}>
                Create Password
              </button>
            </div>
            <div class="text-center pt-4">
              <a href="/login" class="text-blue text-decoration-underline ms-2">
                Retrun to login</a>
            </div>
          </div>
        </div>
      </form>

      <div class="">
        <ul class="nav">
            <li class="nav-item">
              <a class="nav-link" href="terms" target="_blank">Terms &amp; Condition</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="policy" target="_blank">Privacy Policy</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="help" target="_blank">Help</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="contact" target="_blank">Contact</a>
            </li>
        </ul>
      </div>
    </div>
  </div>

</section>

      
    </div>
  )
}

export default Create_password
