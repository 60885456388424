import React, { useEffect, useState } from 'react'
import axios from 'axios'
import Swal from 'sweetalert2'
import config from "../../config"

import Header from './Header';
import Footer from './Footer';


const Channel_list = () => {
    const[state,setState] = React.useState([])
    const [ statusState,setStatus] = React.useState(null)
   
   
    React.useEffect(()=>{
        getChannelList()
    },[])
    const Toast = Swal.mixin({
        toast: true,
        position: 'bottom-end',
        showConfirmButton: false,
        timer: 3000
      })
    const getChannelList = () => {


        let url = config.apiUrl + '/user/channel_list';
        let sendData = {};
        // console.log("bb", sendData);
        axios.post(url, sendData, { headers: config.headers }).then((res) => {
            // console.log("res ", res);  
            if(res.data.status==true){         
            setState(res.data.output)
            }
            else{
                Toast.fire({
                    background:"#a61c09",
                    type: 'unsuccess',
                    title: "Something Went Wrong",
                    color:"white"
                  });
            }
    
        }).catch((e) => {
            Toast.fire({
                background:"#a61c09",
                type: 'unsuccess',
                title: "Something Went Wrong",
                color:"white"
              });
    
        });
    }
    const updateStatus = (status  , store_id) => {


        let url = config.apiUrl + '/user/update_store_status';
        let sendData = {status:status  , store_id:store_id};
        // console.log("bb", sendData);
        axios.post(url, sendData, { headers: config.headers }).then((res) => {
            if(res.data.status==true){
            Toast.fire({
                background:"#3659cd",
                type: 'unsuccess',
                title: "Updated Successfully",
                color:"white"
              });
            }
    
        }).catch((e) => {
    
            Toast.fire({
                background:"#a61c09",
                type: 'unsuccess',
                title: "Something Went Wrong",
                color:"white"
              });
        });
    }

    const handleChange = (e,  store_id) => {
        // console.log("e ", e.target.name);
        // console.log("e checked", e.target.checked);
        if (e.target.checked == true) {
          setStatus({ ...statusState, status: 1 })
          updateStatus(1  , store_id)
        }
        else {
            setStatus({ ...statusState, status: 0 })
            updateStatus(0  , store_id)
        
        }
      }
  return (
    <div>
     <Header/>
 <div class="page-wrapper pinned">
  <div class="main-container">
   <div className='app-container'>
  <div class="app-body">
  <div class="container-fluid"> 

<div class="mob_nav">
  <div class=" d-flex justify-content-start ">
    <a href="javascript:window.history.back();" class=""><i class="bi bi-arrow-left"></i></a>
    <h2>Channel List</h2>
  </div>
</div>


<div class="row justify-content-between">
    <div class="col-12 col-md-4 desk_nav">
      <div class="d-flex">
        <a href="javascript:window.history.back();" class="back_btn"><i class="bi bi-arrow-90deg-left"></i></a>
        <h2>Channel List</h2>
      </div>
    </div>
  
</div>
<div class="row justify-content-between">
    <div class="col-12 col-md">
    {state.length>0?
                 state.map((item)=>(
        <div class="row channel_listing">
            <div class="col-12 col-md-12 mb-3">
                <div class={`card border  ${item.status==5?"border-warning":"border-success"}`}>
                  <div class="card-body">
                    <div class="row">
                        <div class="col-12 col-md-1">
                            <figure>
                                <img src={`https://cdn.shiprath.in/channel_images/${item.channel_img}`} class="img-fluid"/>
                            </figure>
                        </div>
                        <div class="col-12 col-md">
                            <h4>{item.store_name}  </h4>
                            <p>Store ID: #{item.store_id} </p>
                            <p>Store url: {item.store_url} </p>
                        </div>
                        <div class="col-12 col-md-3 text-end">
                            <span class={`badge rounded-pill ${item.status==5?"warning":"bg-success"}`}><small>{item.status==5?"Pending":"Active"}</small></span>
                        </div>
                    </div>
                    <hr/>
                    <div class="row">
                        {/* <div class="col-12 col-md-11">
                            <a href="#" class="btn btn-sm btn-secondary-o"><i class="bi bi-gear"></i> Connections</a>
                        </div> */}
                        
                        <div class="col-12 col-md-1 text-end">
                            <div class="form-check form-switch  mb-2">
                                {/* <input class="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckChecked" checked=""/> */}
                                {item.channel_id=="1507808901"?
                                        
                                        <a class="nav-link text-danger" href={`/channel_integrate/${item.channel_id}?user_id=${item.unique_user}`} data-bs-toggle="tooltip" title="Unlink Store"><i class="material-icons-outlined">edit</i></a>
                                 
                                    :""}
                            </div>
                            
                              {item.status!=5?
                                    <div class="form-check form-switch  mb-2">
                                        <input class="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckChecked" defaultChecked={item.status} onChange={(e)=>handleChange(e , item.store_id)} name="status"/>
                                        <label class="form-check-label" for="flexSwitchCheckChecked"><small>Active/Inactive</small></label>
                                     </div>
                                     :""}
                        </div>
                    </div>
                  </div>
                </div>
            </div>
            
          </div>)):""}
    </div>
    <div class="col-12 col-md-4">
        <h4>Available Integrations</h4>       
        <div class="row mb-3 integration_bx ">
            <div class="col-12 shopify_bx mb-3">
                <div class="card">
                  <div class="card-body">
                    <div class="row justify-content-between">
                        <div class="col-12 col-md-4">
                            <figure>
                                <img src="assets/img/shopify.png" class="img-fluid" />
                            </figure>
                        </div>
                        <div class="col-12 col-md-8 py-4">
                            <div class="d-grid">
                              <a href="/channel_integrate/1567898605" class="btn btn-shopify btn-block btn-lg">Connect Store <i class="bi bi-arrow-right"></i></a>
                            </div>
                        </div>
                    </div>
                    
                  </div>
                </div>
            </div>
            <div class="col-12 woocommerce_bx">
                <div class="card">
                  <div class="card-body">
                    <div class="row justify-content-between">
                        <div class="col-12 col-md-4">
                            <figure>
                                <img src="assets/img/woo.png" class="img-fluid" />
                            </figure>
                        </div>
                        <div class="col-12 col-md-8 py-4">
                            <div class="d-grid">
                              <a href="/channel_integrate/1507808901" class="btn btn-woocommerce btn-block btn-lg">Connect Store <i class="bi bi-arrow-right"></i></a>
                            </div>
                        </div>
                    </div>
                    
                  </div>
                </div>
            </div>
        </div>
    </div>
</div>

{/* {state.length>0?
                 state.map((item)=>(
    <section>
        <div class="row channel_listing">
            <div class="col-12 col-md-6 mb-3">
                <div class="card">
                  <div class="card-body">
                    <div class="row">
                        <div class="col-12 col-md-2">
                            <figure>
                                <img src={`https://cdn.shiprath.in/channel_images/${item.channel_img}`} class="img-fluid" />
                            </figure>
                        </div>
                        <div class="col-12 col-md">
                            <h4>{item.store_name} </h4>
                            <p>Store ID: #{item.store_id}</p>
                            <p>Store url: {item.store_url}</p>
                        </div>
                        <div class="col-12 col-md-3">
                            <span class="badge rounded-pill bg-danger"><small>{item.status==5?"Pending":""}</small></span>
                        </div>
                    </div>
                    <hr />
                    <div class="row">
                       
                        <div class="col-12 col-md text-end">
                            <div class="d-flex justify-content-end">
                                <div class="">
                                    
                                    {item.channel_id=="1507808901"?
                                        
                                        <a class="nav-link text-danger" href={`/channel_integrate/${item.channel_id}?user_id=${item.unique_user}`} data-bs-toggle="tooltip" title="Unlink Store"><i class="material-icons-outlined">edit</i></a>
                                 
                                    :""}
                                </div>
                                
                                <div class="ps-2 ">
                                {item.status!=5?
                                    <div class="form-check form-switch  mb-2">
                                        <input class="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckChecked" defaultChecked={item.status} onChange={(e)=>handleChange(e , item.store_id)} name="status"/>
                                        <label class="form-check-label" for="flexSwitchCheckChecked"><small>Active/Inactive</small></label>
                                     </div>
                                     :""}
                                  
                                </div>
                            </div>
                        </div>
                    </div>
                  </div>
                </div>
            </div>
           
        </div>
    </section>
                 )):""} */}
                
</div></div>
<Footer/>
</div></div></div>
    </div>
  )
}

export default Channel_list
