import React from 'react'
import Header from './Header'
import Footer from './Footer'
import { useNavigate } from 'react-router-dom'
import config from "../../config"
import axios from "axios";
import Swal from 'sweetalert2'
import Moment from 'react-moment';

const Manifest_list = () => {
    const[state,setState]= React.useState([])
    React.useEffect(()=>{
        ManifestList()
    },[])
    const ManifestList=()=>{
       
          let dataToSend = {};
        let url = config.apiUrl + '/shipment/manifest_list';
          
        axios.post(url, dataToSend, { headers: config.headers })
            .then((res) => {
            if(res.data.status==true){
                setState(res.data.output)
            } 
            else{
                const Toast = Swal.mixin({
                    toast: true,
                    position: 'bottom-end',
                    showConfirmButton: false,
                    timer: 3000
                    })
                    
                    Toast.fire({
                    background:"#a61c09",
                    type: 'unsuccess',
                    title: "Something Went Wrong ",
                    color:"white"
                    });
            }
              })
            .catch((error) => {
                const Toast = Swal.mixin({
                    toast: true,
                    position: 'bottom-end',
                    showConfirmButton: false,
                    timer: 3000
                    })
                    
                    Toast.fire({
                    background:"#a61c09",
                    type: 'unsuccess',
                    title: "Something Went Wrong ",
                    color:"white"
                    });
            });    
  
      }
  return (
    <div>
        <Header/>
 <div class="page-wrapper pinned">
  <div class="main-container">
   <div className='app-container'>
  <div class="app-body">
  <div class="container-fluid"> 


<div class="mob_nav">
  <div class=" d-flex justify-content-start ">
    <a href="javascript:window.history.back();" class=""><i class="bi bi-arrow-left"></i></a>
    <h2>Manifest List</h2>
  </div>
</div>


<div class="row justify-content-between">
    <div class="col-12 col-md-4 desk_nav">
      <div class="d-flex">
        <a href="javascript:window.history.back();" class="back_btn"><i class="bi bi-arrow-90deg-left"></i></a>
        <h2>Manifest List</h2>
      </div>
    </div>
    {/* <div class="col-12 col-md-4 mb-3">
      <div class="row justify-content-end">
          <div class="col-6 col-md-6">
            <div class="d-grid">
              <a href="/manifest_add" class="btn btn-primary btn-block"><i class="bi bi-plus"></i> Create Manifest</a>
            </div>
          </div>
      </div>
    </div> */}
</div>



<div class="card data_table mb-3 mb_display_none">
  <div class="card-body">
    <div class="table-responsive" >
      <div class="shipm_tablew rounded-3">
        <table class="table align-top table-striped table-hover m-0 table-xl ">
          <thead>
            <tr>
              <th width="200px">Date</th>
              <th width="170px">Manifest ID</th>
              <th>Shipments</th>
              <th>Action</th>
            </tr>					
          </thead>
          <tbody>
            {state.map((item)=>(
            <tr class="">
              <td>
              <Moment format="MMM DD YYYY">
                        {new Date(item.date*1000)}
            </Moment> - <Moment format="hh:mm:ss a">
                        {new Date(item.date*1000)}
            </Moment>
              </td>
              <td>
                <h6><a href="#" class="text-primary" data-bs-toggle="modal" data-bs-target="#Manifest_Modal">{item.manifest_id}</a></h6>
              </td>
              <td>
                {item.total_shipments} 
              </td>
              <td>
                <div class="btn-group">
                  <a type="button" class="btn btn-outline-secondary" href={`/manifest_print/${item.manifest_id}`}>Print Manifest</a>
                </div>
              </td>
            </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>

    </div></div>
    <Footer/></div></div></div>
    </div>
  )
}

export default Manifest_list
