import React from 'react'
import Settings_nav from './Settings_nav'

import Header from './Header'

import axios from 'axios'
import queryString from 'query-string';
import { useNavigate,useLocation } from 'react-router-dom';
import { Container, Row, Col, Form, Button, ProgressBar } from "react-bootstrap";
import { useNetworkState } from 'react-use';

// import './customer_style.css';
import Modal from 'react-bootstrap/Modal';
import Select from 'react-select';


import Swal from 'sweetalert2'

import ReactLoading from 'react-loading';
import config from "../../config"
import Footer from './Footer'
import { CornerLeftUp , CornerUpLeft } from 'lucide-react'

const User_kyc = () => {
    const isOnline = useNetworkState();
    console.log("isONline  === " ,isOnline )
      const [state, setState] = React.useState({aadhar_number:""})
      const[refId , setrefId] = React.useState({ref_id:""})
      const[optstate , setOtp] = React.useState({otp:""})
      const[userstate , setUserState] = React.useState({})
      const[companystate , setcompanyState] = React.useState({})
  
      const [aadharNumber, setAadharNumber] = React.useState('');
      const [gststate , setgstState] = React.useState({gst_number:""})
      let userDetail = localStorage.getItem('ship_rocket_user');
      console.log("userDetail====", userDetail);
      let userData = {};
      if (userDetail != null) {
        userData = JSON.parse(userDetail)
      }
      const Toast = Swal.mixin({
          toast: true,
          position: 'bottom-end',
          showConfirmButton: false,
          timer: 5000
      })
      const [formData, setFormData] = React.useState({
        aadharNumber: '',
        panNumber: '',
        gstNumber: '',
        aadharFrontImage: null,
        aadharBackImage: null,
        panFrontImage: null,
        gstImage:null
      });
      console.log("formData == " , formData)
      const [errors, setErrors] = React.useState({});
      const [btnstate, setbtnstate] = React.useState(false);
      const [isSidebarOpen, setIsSidebarOpen] = React.useState(false);
  
      const toggleSidebar = () => {
          setIsSidebarOpen(!isSidebarOpen);
          const homeSection = document.querySelector('.home-section');
          if (homeSection) {
              homeSection.classList.toggle('sidebar-closed');
          }
      };
  
    
      const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
        setErrors({})
      };
    
      const handleFileChange = (e, type) => {
        setFormData({ ...formData, [type]: e.target.files[0] });
        setErrors({})
      };
     
      const handleSubmit = async (e) => {
        setbtnstate(true)
        e.preventDefault();
    let errors = {};
    let isValid = true;
  
    if (!formData.aadharNumber) {
      errors.aadharNumber = 'Aadhar Number is required';
      isValid = false;
    }
    else if (formData.aadharNumber.length !== 12) {
        errors.aadharNumber = 'Aadhar Number must be exactly 12 digits';
        isValid = false;
    }
  
    if (!formData.panNumber) {
      errors.panNumber = 'PAN Number is required';
      isValid = false;
    }
    else if (formData.panNumber.length > 10) {
        errors.panNumber = 'PAN Number must not exceed 10 digits';
        isValid = false;
    }
  
  
    if (formData.gstNumber && formData.gstNumber.length > 15) {
        errors.gstNumber = 'GST Number must not exceed 15 digits';
        isValid = false;
    }
  
    if (!formData.aadharFrontImage) {
      errors.aadharFrontImage = 'Front side of Aadhar card is required';
      isValid = false;
    }
  
    if (!formData.aadharBackImage) {
      errors.aadharBackImage = 'Back side of Aadhar card is required';
      isValid = false;
    }
  
    if (!formData.panFrontImage) {
      errors.panFrontImage = 'Front side of PAN card is required';
      isValid = false;
    }
  
    if (!isValid) {
      setErrors(errors);
      setbtnstate(false)
      return;
    }
        const data = new FormData();
        
        data.append('customer_id', userData.customer_id);
  
        data.append('aadharNumber', formData.aadharNumber);
        data.append('panNumber', formData.panNumber);
        data.append('gstNumber', formData.gstNumber);
        data.append('aadharFrontImage', formData.aadharFrontImage);
        data.append('aadharBackImage', formData.aadharBackImage);
        data.append('panFrontImage', formData.panFrontImage);
        data.append('gstImage', formData.gstImage);
   
        for (let [key, value] of data.entries()) {
            console.log(key, value);
          }
  
        try {
            let full_api = config.apiUrl + `/user/add_customer_proof`;
            axios.post(full_api, data, { headers: config.headers }).then((res) => {
                if(res){
                    Toast.fire({
                        background:"#3659cd",
                        type: 'success',
                        title: res.data.message ,
                        color:"white"
                      });
                      user()
                      setbtnstate(false)
                      // navigate('/customer/bank_details')
                      
                 }
                 else{
                    Toast.fire({
                        background:"#a61c09",
                        type: 'success',
                        title: "Something Went Wrong" ,
                        color:"white"
                      });
                      setbtnstate(false)
                    }
            })
            // Handle success or redirect user
          } catch (error) {
            console.error('Error:', error);
            setbtnstate(false)
  
            // Handle error
          }
      }
   
       React.useEffect(()=>{
          user()
       },[])
      const user=()=>{
          let dataToSend = {
      };
     
        // console.log("datatoSend",dataToSend)
        let url = config.apiUrl + '/user/find_customer_detail';
      //  console.log("headers =========> ", config.headers);
        axios.post(url, dataToSend, { headers: config.headers })
        .then((res) => {
          console.log("res ===" , res.data.output)
          setUserState(res.data.output)
          setcompanyState(res.data.company)
         
          setFormData({...formData ,aadharNumber:res.data.output.aadhaar_number ,panNumber:res.data.output.pan_number , gstNumber:res.data.output.gst_number , aadharFrontImage:res.data.output.aadharFrontImage , aadharBackImage:res.data.output.aadharBackImage , panFrontImage:res.data.output.panFrontImage , gstImage:res.data.output.gstImage })
          // console.log("response ===" , response)
          //   const obscuredAadhar = obscureAadharNumber(res.data.ouptut.aadhaar_number);
          // console.log("obscuredAadhar == " , obscuredAadhar)
          // setAadharNumber(obscuredAadhar);
        
            })
            .catch((error) => {
                console.log(error);
            });    
     }
  return (
    <div>
               <Header/>
 <div class="page-wrapper pinned">
  <div class="main-container">
   <div className='app-container'>
  <div class="app-body">
  <div class="container-fluid"> 


<div class="mob_nav">
  <div class=" d-flex justify-content-start ">
    <a href="javascript:window.history.back();" class=""><i class="bi bi-arrow-left"></i></a>
    <h2>Customer KYC</h2>
  </div>
</div>


<div class="row justify-content-between">
    <div class="col-12 col-md-4 desk_nav">
      <div class="d-flex">
        <a href="javascript:window.history.back();" class="back_btn"><i class="bi bi-arrow-90deg-left"></i></a>
        <h2>Customer KYC</h2>
      </div>
    </div>
</div>



<div class="row">
    <div class="col-12 col-md-3 left_sub_menu_bx mb_display_none">
       <Settings_nav value={3}/>
    </div>
    <div class="col-12 col-md-9">
    <div>
                            {userstate.aadhar_kyc==true?
                                <div class="alert alert-success">
                                    <strong>Verified!</strong> Your KYC has been verified already.
                                </div>: <div class="alert alert-warning">
                                    <strong>Pending!</strong> Your details are under review. Our team will update you soon.
                                </div>}
                               
                            </div>
        <div class="card border-0 shadow-sm">
            <div class="card-body">
            <div class="row">
                    <div class="col-12 col-md-12 mb-3">
                        <label for="" class="form-label">Aadhar Card Number <span class="text-danger">*</span></label>
                        <input type="text" class="form-control" id="text" placeholder="Enter Aadhar Card Number"name="aadharNumber"
                        value={formData.aadharNumber}
                        onChange={handleChange}/>
                          {errors.aadharNumber && <div className="text-danger mt-2">{errors.aadharNumber}</div>}
                    </div>
                    <div class="col-12 col-md-6 mb-3">
                        <label for="" class="form-label">Aadhar Card Front Image upload <span class="text-danger">*</span></label>
                        <img src={formData.aadharFrontImage} class="img-fluid img-thumbnail" />
                        <input className='form-control'
                            type="file"
                            accept=".jpg, .jpeg, .png"
                            onChange={(e) => handleFileChange(e, 'aadharFrontImage')}
                          />
                           {errors.aadharFrontImage && <div className="text-danger mt-2">{errors.aadharFrontImage}</div>}
                    </div>
                    <div class="col-12 col-md-6 mb-3">
                        <label for="" class="form-label">Aadhar Card Back Image upload <span class="text-danger">*</span></label>
                        <img src={formData.aadharBackImage} class="img-fluid img-thumbnail" />
                        <input className='form-control'
                            type="file"
                            accept=".jpg, .jpeg, .png"
                            onChange={(e) => handleFileChange(e, 'aadharBackImage')}
                          />
                           {errors.aadharBackImage && <div className="text-danger mt-2">{errors.aadharBackImage}</div>}
                    </div>
                    <div class="col-12 col-md-12 mb-3 mt-5">
                        <label for="" class="form-label">PAN Card Number <span class="text-danger">*</span></label>
                        <input type="email" class="form-control" id="email" placeholder="Enter PAN Number" name="panNumber"
                        value={formData.panNumber}
                        onChange={handleChange}/>
                          {errors.panNumber && <div className="text-danger mt-2">{errors.panNumber}</div>}
                    </div>
                    <div class="col-12 col-md-12 mb-3">
                        <label for="" class="form-label">PAN Card Image upload <span class="text-danger">*</span></label>
                        <img src={formData.panFrontImage} class="img-fluid img-thumbnail" />
                        <input className='form-control'
                            type="file"
                            accept=".jpg, .jpeg, .png"
                            onChange={(e) => handleFileChange(e, 'panFrontImage')}
                          />
                        {errors.panFrontImage && <div className="text-danger mt-2">{errors.panFrontImage}</div>}
                    </div>


                    <div class="col-12 col-md-12 mb-3 mt-5">
                        <label for="" class="form-label">GST Number <span class="text-danger">*</span></label>
                        <input type="text" class="form-control form-control-lg" placeholder="Enter GST Number"   required
                        name="gstNumber"
                        value={formData.gstNumber}
                        onChange={handleChange}/>
                    </div>
                    <div class="col-12 col-md-12 mb-3">
                        <label for="" class="form-label">GST Certificate Image upload <span class="text-danger">*</span></label>
                        <img src={formData.gstImage} class="img-fluid img-thumbnail" />
                        <input className='form-control'
                            type="file"
                            accept=".jpg, .jpeg, .png"
                            onChange={(e) => handleFileChange(e, 'gstImage')}
                          />
                        {errors.gstImage && <div className="text-danger mt-2">{errors.gstImage}</div>}
                    </div>
            </div>

           
            {userstate.aadhar_kyc==true?"":
                        <div class="">
                           {btnstate==true?<div className='' style={{marginLeft:"60px"}}><ReactLoading type={"spin"} color={"#0d6efd"} height={40} width={30}/></div>: <a onClick={(e)=>handleSubmit(e)} class="btn btn-primary">Update</a>}
                        </div>
}
            </div>
        </div>
    </div>
</div>


    </div></div>
    <Footer/></div></div></div>
      
    </div>
  )
}

export default User_kyc
