import React from 'react'

const Shipment_data = () => {
  return (
    <div>
      <section>
            


            <div class="">
                <div class="row mt-3 order_box_cont">
                    <div class="col-12 col-md-8 ">
                        
        
                       
        
                        <div class="card border-0 mb-3">
                            <div class="card-body">
                                <div class="d-flex ">
                                    <div class="border-end pe-3">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" data-lucide="truck" class="lucide lucide-truck"><path d="M5 18H3c-.6 0-1-.4-1-1V7c0-.6.4-1 1-1h10c.6 0 1 .4 1 1v11"></path><path d="M14 9h4l4 4v4c0 .6-.4 1-1 1h-2"></path><circle cx="7" cy="18" r="2"></circle><path d="M15 18H9"></path><circle cx="17" cy="18" r="2"></circle></svg>
                                    </div>
                                    <div class="ps-3 ">
                                        <h6>Shipping Details</h6>
                                    </div>
                                </div>
                                <hr/>
                                <div class="row">
                                    <div class="col-12 col-md-4 mb-2 shipping_carrier_im">
                                        <img src="assets/images/carriers_logo/fedex.svg" alt="" class="img-fluid" title="Fedex Inc."/>
                                    </div>
                                    <div class="col-6 col-md-4 mb-2">
                                        <small class="text-muted">Shipment No.</small>
                                        <p><strong><a href="">98237498234</a></strong></p>
                                    </div>
                                    <div class="col-6 col-md-4 mb-2">
                                        <small class="text-muted">Order on</small>
                                        <p><a href="">Jan 26 2024:12:00</a></p>
                                    </div>
                                    <div class="col-6 col-md-4 mb-2">
                                        <small class="text-muted">Shipping Courier</small>
                                        <p>Xpressbees Surface</p>
                                    </div>
                                    <div class="col-6 col-md-4 mb-2">
                                        <small class="text-muted">Shipping Courier AWB No.</small>
                                        <p><a href="">141123828201017</a></p>
                                    </div>
                                    <div class="col-6 col-md-4 mb-2">
                                        <small class="text-muted">Shipping Courier Service</small>
                                        <p>Surface</p>
                                    </div>
                                    <div class="col-6 col-md-4 mb-2">
                                        <small class="text-muted">Order Type</small>
                                        <p>Domestic</p>
                                    </div>
                                    <div class="col-6 col-md-4 mb-2">
                                        <small class="text-muted">Shipment Type</small>
                                        <p>Forward</p>
                                    </div>
                                    <div class="col-6 col-md-4 mb-2">
                                        <small class="text-muted">Zone</small>
                                        <p>Local Zone</p>
                                    </div>
                                    <div class="col-6 col-md-4 mb-2">
                                        <small class="text-muted">Courier Rule Applied</small>
                                        <p>N/A</p>
                                    </div>
                                    <div class="col-12 col-md-4 mb-2 desk_display_none">
                                        <div class="d-grid">
                                          <a href="#track_shipment" class="btn btn-outline-secondary btn-block">Track Shipment</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
        
                        <div class="card border-0  mb-3">
                            <div class="card-body">
                            <div class="d-flex ">
                                <div class="border-end pe-3">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" data-lucide="package-open" class="lucide lucide-package-open"><path d="M20.91 8.84 8.56 2.23a1.93 1.93 0 0 0-1.81 0L3.1 4.13a2.12 2.12 0 0 0-.05 3.69l12.22 6.93a2 2 0 0 0 1.94 0L21 12.51a2.12 2.12 0 0 0-.09-3.67Z"></path><path d="m3.09 8.84 12.35-6.61a1.93 1.93 0 0 1 1.81 0l3.65 1.9a2.12 2.12 0 0 1 .1 3.69L8.73 14.75a2 2 0 0 1-1.94 0L3 12.51a2.12 2.12 0 0 1 .09-3.67Z"></path><line x1="12" x2="12" y1="22" y2="13"></line><path d="M20 13.5v3.37a2.06 2.06 0 0 1-1.11 1.83l-6 3.08a1.93 1.93 0 0 1-1.78 0l-6-3.08A2.06 2.06 0 0 1 4 16.87V13.5"></path></svg>
                                </div>
                                <div class="ps-3 ">
                                    <h6>Package Details</h6>
                                </div>
                            </div>
                            <hr/>
                            <div class="row">
                                <div class="col-6 col-md-4 mb-2">
                                    <small class="text-muted">Applicable Weight (in Kg)</small>
                                    <p>1KG</p>
                                </div>
                                <div class="col-6 col-md-4 mb-2">
                                    <small class="text-muted">Dead Weight (in Kg)</small>
                                    <p>1KG</p>
                                </div>
                                <div class="col-6 col-md-4 mb-2">
                                    <small class="text-muted">Volumetric Weight (in kg)</small>
                                    <p>1KG</p>
                                </div>
                                <div class="col-6 col-md-4 mb-2">
                                    <small class="text-muted">Dimensions (in cm)</small>
                                    <p>1.000 x 1.000 x 1.000</p>
                                </div>
                                <div class="col-6 col-md-4 mb-2">
                                    <small class="text-muted">Number of Boxes</small>
                                    <p>1</p>
                                </div>
                                <div class="col-6 col-md-4 mb-2">
                                    <small class="text-muted">Shipment value</small>
                                    <p>₹ 2000 <span class="badge rounded-pill bg-warning">Prepaid</span></p>
                                </div>
                            </div>
                            </div>
                        </div>
        
        
                        <div class="card border-0 mb-3">
                            <div class="card-body">
                                <div class="d-flex ">
                                    <div class="border-end pe-3">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" data-lucide="package" class="lucide lucide-package"><path d="m7.5 4.27 9 5.15"></path><path d="M21 8a2 2 0 0 0-1-1.73l-7-4a2 2 0 0 0-2 0l-7 4A2 2 0 0 0 3 8v8a2 2 0 0 0 1 1.73l7 4a2 2 0 0 0 2 0l7-4A2 2 0 0 0 21 16Z"></path><path d="m3.3 7 8.7 5 8.7-5"></path><path d="M12 22V12"></path></svg>
                                    </div>
                                    <div class="ps-3 ">
                                        <h6>Shipment Details</h6>
                                    </div>
                                </div>
                                <hr/>
                                <div class="row">
                                    <div class="col-12 col-md">
                                        <h6>Ship From:</h6>
                                            <small class="text-muted">Sender Name:</small>
                                            <p>Rahul Gandi</p>
                                            <small class="text-muted">Sender Address:</small>
                                            <p>Lodhiya Street, New Delhi, Delhi 110001</p>
                                            <small class="text-muted">Sender Email:</small>
                                            <p>rahulgandi@gmail.com</p>
                                            <small class="text-muted">Sender Mobile:</small>
                                            <p>123456789</p>
                                    </div>
                                    <div class="col-12 col-md">
                                        <h6>Ship To:</h6>
                                            <small class="text-muted">Receiver Name:</small>
                                            <p>Rahul Gandi</p>
                                            <small class="text-muted">Receiver Address:</small>
                                            <p>Lodhiya Street, New Delhi, Delhi 110001</p>
                                            <small class="text-muted">Receiver Email:</small>
                                            <p>rahulgandi@gmail.com</p>
                                            <small class="text-muted">Receiver Mobile:</small>
                                            <p>123456789</p>
                                    </div>
                                </div>
                            </div>
                        </div>
        
        
                        <div class="card border-0  mb-3">
                            <div class="card-body">
                            <div class="d-flex ">
                                <div class="border-end pe-3">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" data-lucide="dollar-sign" class="lucide lucide-dollar-sign"><line x1="12" x2="12" y1="2" y2="22"></line><path d="M17 5H9.5a3.5 3.5 0 0 0 0 7h5a3.5 3.5 0 0 1 0 7H6"></path></svg>
                                </div>
                                <div class="ps-3 ">
                                    <h6>Payment Details</h6>
                                </div>
                            </div>
                            <hr/>
                            <div class="row">
                                <div class="col-6 col-md-4 mb-2">
                                    <small class="text-muted">Transaction ID</small>
                                    <p><a href="">3456789876</a></p>
                                </div>
                                <div class="col-6 col-md-4 mb-2">
                                    <small class="text-muted">Payment Mode</small>
                                    <p>Wallet</p>
                                </div>
                                <div class="col-6 col-md-4 mb-2">
                                    <small class="text-muted">Total Paid Amount</small>
                                    <p>₹ 2983.00</p>
                                </div>
                                <div class="col-6 col-md-4 mb-2">
                                    <small class="text-muted">Bill To (Customer)</small>
                                    <p><a href="">New Trading India Pvt Ltd</a></p>
                                </div>
                                <div class="col-6 col-md-4 mb-2">
                                    <small class="text-muted">Customer Account No.</small>
                                    <p>9298376466</p>
                                </div>
                            </div>
                            </div>
                        </div>
        
                        <div class="card border-0 mb-3">
                            <div class="card-body">
                            <div class="d-flex ">
                                <div class="border-end pe-3">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" data-lucide="shopping-basket" class="lucide lucide-shopping-basket"><path d="m5 11 4-7"></path><path d="m19 11-4-7"></path><path d="M2 11h20"></path><path d="m3.5 11 1.6 7.4a2 2 0 0 0 2 1.6h9.8c.9 0 1.8-.7 2-1.6l1.7-7.4"></path><path d="m9 11 1 9"></path><path d="M4.5 15.5h15"></path><path d="m15 11-1 9"></path></svg>
                                </div>
                                <div class="ps-3 ">
                                    <h6>Product Details</h6>
                                </div>
                            </div>
                            <hr/>
                            <div class="table-responsive ">
                                <table class="table">
                                    <thead>
                                    <tr>
                                        <th style={{width: "20px"}}>Name</th>
                                        <th>Category</th>
                                        <th>HSN</th>
                                        <th>SKU</th>
                                        <th>Qty</th>
                                        <th>Total</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr>
                                        <td style={{width: "200px"}}>Samsung mobile Transparent Covers - Samsung-A50</td>
                                        <td>1689070274414</td>
                                        <td>samsung-A50</td>
                                        <td>1</td>
                                        <td>1</td>
                                        <td>200.00</td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                            </div>
                        </div>
        
                        
                        
                        
                    </div>
                    <div class="col-12 col-md-4">
                    <section class="bg-white p-3 mb-3">
                        <p>
                            Jaipur <i class="bi bi-arrow-right"></i> Delhi
                            <span class="text-end"><small class="text-muted">Location last updated on 3 May 2022 09:38</small></span>
                        </p>
        
                        
                        <p>Delivery ETA - <span class="text-muted">03-02-2022 @ 23:29</span></p>
                    </section>
                    <div class="card border-0 shadow-sm tracking_xb">
                        <div class="card-body">
                            <div class="d-flex ">
                                <div class="border-end pe-3">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" data-lucide="package-search" class="lucide lucide-package-search"><path d="M21 10V8a2 2 0 0 0-1-1.73l-7-4a2 2 0 0 0-2 0l-7 4A2 2 0 0 0 3 8v8a2 2 0 0 0 1 1.73l7 4a2 2 0 0 0 2 0l2-1.14"></path><path d="m7.5 4.27 9 5.15"></path><polyline points="3.29 7 12 12 20.71 7"></polyline><line x1="12" x2="12" y1="22" y2="12"></line><circle cx="18.5" cy="15.5" r="2.5"></circle><path d="M20.27 17.27 22 19"></path></svg>
                                </div>
                                <div class="ps-3 ">
                                    <h6>Tracking Info</h6>
                                </div>
                            </div>
                            <hr/>
                            <div class="progress-container  position-relative" id="track_shipment">
                                <div class="step-container">
                                <div class="step active">
                                    <i class="bi bi-check-circle-fill"></i>
                                </div>
                                <div class="progress"></div>
                                <div class="step-info">
                                    <h6>In Transit</h6>
                                    <p>Shipment Location: City A</p>
                                    <p>Time: 10:00 AM</p>
                                </div>
                                </div>
                                <div class="step-container">
                                <div class="step ">
                                <i class="bi bi-circle"></i>
                                </div>
                                <div class="progress"></div>
                                <div class="step-info">
                                    <h6>In Transit</h6>
                                    <p>Shipment Location: City B</p>
                                    <p>Time: 11:30 AM</p>
                                </div>
                                </div>
                                <div class="step-container">
                                <div class="step">
                                <i class="bi bi-circle"></i>
                                </div>
                                <div class="progress"></div>
                                <div class="step-info">
                                    <h6>In Transit</h6>
                                    <p>Shipment Location: City C</p>
                                    <p>Time: 1:00 PM</p>
                                </div>
                                </div>
                                <div class="step-container">
                                <div class="step">
                                <i class="bi bi-circle"></i>
                                </div>
                                <div class="progress"></div>
                                <div class="step-info">
                                    <h6>In Transit</h6>
                                    <p>Shipment Location: City D</p>
                                    <p>Time: 3:15 PM</p>
                                </div>
                                </div>
                                <div class="step-container">
                                <div class="step">
                                <i class="bi bi-circle"></i>
                                </div>
                                <div class="progress"></div>
                                <div class="step-info">
                                    <h6>In Transit</h6>
                                    <p>Shipment Location: City E</p>
                                    <p>Time: 5:30 PM</p>
                                </div>
                                </div>
                            </div>
                            No Data Available
                        </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
  )
}

export default Shipment_data
