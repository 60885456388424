import React from 'react'
import Settings_nav from './Settings_nav'

import Header from './Header'

import axios from 'axios'
import { useNavigate,useLocation, useParams } from 'react-router-dom';

import Swal from 'sweetalert2'
import config from "../../config"
import Footer from './Footer'

const Profile_update = () => {
    const customer_id = useParams()
    console.log("customer_id",customer_id)
   
    const [state, setState] = React.useState({ isLoading: true, user_info: {} , country_id:"" , state_id:"" , city_id:"" });
    console.log("state",state)
    const [countrylist , setcountrylist]= React.useState([])
    const [statelist , setstatelist]= React.useState([])
    const [citylist , setcitylist]= React.useState([])
   
     React.useEffect(() => {
        getUsersInfo();
        countryList();
      }, []);

      

      const handleChange =(e)=>{
        console.log("e", e);
   
      let userTemp = {...state.user_info};
      userTemp[e.target.name] =  e.target.value;
       setState({ ...state, user_info:userTemp });
    
      }

  const axios_get_api = () => {
    let sendData = state.user_info
    console.log("sendData == " , sendData)
  if(state.user_info.full_name!="" && state.user_info.email!='' && state.user_info.address!=' ' && state.user_info.mobile!=""  && state.user_info.company_name!="" ){
      
    axios.post(config.apiUrl + `/user/customer_update`,  sendData , { headers: config.headers }).then((result)=>{
              
               console.log("result=== " , result)
                if (result.data.status==true) {
                   
                     localStorage.setItem('ship_rocket_user', JSON.stringify(result.data.output[0]));
                    console.log("---------------------------");
                     
                    const Toast = Swal.mixin({
                        toast: true,
                        position: 'bottom-end',
                        showConfirmButton: false,
                        timer: 2000
                      })
                      
                      Toast.fire({
                        background:"#3659cd",
                        type: 'Success',
                        title: "Successfully Update",
                        color:"white"
                      });
                    
                    window.location.reload();
                   
                }
             
                    // setaddState({web_body_id:"",body_heading:"",pic:"right",body_sub_heading:"",body_img:"",body_content:"",body_link_title:""})
                
                
                }).catch((e) => {
                    const Toast = Swal.mixin({
                        toast: true,
                        position: 'bottom-end',
                        showConfirmButton: false,
                        timer: 5000
                      })
                      
                      Toast.fire({
                        background:"#a61c09",
                        type: 'error',
                        title: "Something Went Wrong",
                        color:"white"
                      });
                 
                  console.log("----error:   ", e);
                })
            
            }
            else{
                const Toast = Swal.mixin({
                    toast: true,
                    position: 'bottom-end',
                    showConfirmButton: false,
                    timer: 2000
                  })
                  
                  Toast.fire({
                    background:"#a61c09",
                    type: 'error',
                    title: "Please Fill the details",
                    color:"white"
                  });
            }
            
                

  };
       
  const getUsersInfo = () => {

    // setIsLoading(true)
    // let full_api = exportValue.host + "/" + exportValue.version + "/" + exportValue.api + `/user_detail`;
    let sendData = {};

   
   // console.log("bb", sendData);
    axios.post(config.apiUrl + `/user/user_detail`,  sendData , { headers: config.headers }).then((res) => {
        // setIsLoading(false) 
        if(res.data.output.length ==1) {       
       
        if (res.data.output[0].country && res.data.output[0].country !="") {
            handleSelectCountry({ target: { name: "country", value: res.data.output[0].country } });
          }
        
          // Preload city list if state is set
          if (res.data.output[0].state && res.data.output[0].state!="") {
            handleSelectState({ target: { name: "state", value: res.data.output[0].state } });
          }
          setState({ ...state, user_info: res.data.output[0], isLoading: false })
        }



    }).catch((e) => {
        // setIsLoading(false)

    });
}
const countryList = () =>{
    
  let full_api = config.apiUrl + `/country/country_list`;
  let sendData= {};
  axios.post(full_api, sendData, { headers: config.headers }).then((res) => {
      setcountrylist(res.data.output)
   }).catch((e) => {
      // setIsLoading(false)

  });
}
const handleSelectCountry = (e)=>{
  let userTemp = {...state.user_info};
  // console.log("userTemp ", userTemp[e.target.name]);
   userTemp[e.target.name] =  e.target.value;
    setState({ ...state, user_info:userTemp });

  let full_api = config.apiUrl + `/country/state_list`;
  let sendData= {t_country_id:e.target.value};
  axios.post(full_api, sendData, { headers: config.headers }).then((res) => {
      setstatelist(res.data.output)
   }).catch((e) => {
      // setIsLoading(false)

  });
}
const handleSelectState = (e)=>{
  let userTemp = {...state.user_info};
  // console.log("userTemp ", userTemp[e.target.name]);
   userTemp[e.target.name] =  e.target.value;
    setState({ ...state, user_info:userTemp });

  let full_api = config.apiUrl + `/country/all_city_list`;
  let sendData= { state_id:e.target.value};
  axios.post(full_api, sendData, { headers: config.headers }).then((res) => {
      setcitylist(res.data.output)
   }).catch((e) => {
      // setIsLoading(false)

  });
}


const handleSelectCity = (e)=>{
  let userTemp = {...state.user_info};
  // console.log("userTemp ", userTemp[e.target.name]);
   userTemp[e.target.name] =  e.target.value;
    setState({ ...state, user_info:userTemp });


}

  return (
    <div>
      <Header/>
 <div class="page-wrapper pinned">
  <div class="main-container">
   <div className='app-container'>
  <div class="app-body">
  <div class="container-fluid"> 

<div class="mob_nav">
  <div class=" d-flex justify-content-start ">
    <a href="javascript:window.history.back();" class=""><i class="bi bi-arrow-left"></i></a>
    <h2>Profile Settings</h2>
  </div>
</div>


<div class="row justify-content-between">
    <div class="col-12 col-md-4 desk_nav">
      <div class="d-flex">
        <a href="javascript:window.history.back();" class="back_btn"><i class="bi bi-arrow-90deg-left"></i></a>
        <h2>Profile Settings</h2>
      </div>
    </div>
</div>



<div class="row">
    <div class="col-12 col-md-3 left_sub_menu_bx mb_display_none">
        {/* <?php include 'settings_nav.php';?> */}
        <Settings_nav value={1}/>
    </div>
    <div class="col-12 col-md-9">
    <div class="card border-0 shadow-sm">
                <div class="card-body">
                <div class="row">
                        <div class="col-12 col-md-6 mb-3">
                            <label for="" class="form-label">Name <span class="text-danger">*</span></label>
                            <input type="text" class="form-control" id="text" placeholder="Enter full name" name="full_name"  onChange={(e)=>handleChange(e)} required="" value={state.user_info.full_name}/>
                        </div>
                        <div class="col-12 col-md-6 mb-3">
                            <label for="" class="form-label">Mobile Number <span class="text-danger">*</span></label>
                            <input type="text" class="form-control" id="text" placeholder="Enter Mobile number"  name= "mobile"   onChange={(e)=>handleChange(e)} required="" value={state.user_info.mobile!=undefined && state.user_info.mobile!="" ? state.user_info.mobile:"" }/>
                        </div>
                        <div class="col-12 col-md-6 mb-3">
                            <label for="" class="form-label">Email Address <span class="text-danger">*</span></label>
                            <input type="text" class="form-control" id="text" placeholder="hello@gmail.com" name="email" onChange={(e)=>handleChange(e)} required="" value={state.user_info.email!=undefined && state.user_info.email!="" ? state.user_info.email:"" } readOnly/>
                        </div>
                        <hr/>
                        <div class="col-12 col-md-6 mb-3">
                            <label for="" class="form-label">Company Name</label>
                            <input type="text" class="form-control" id="text" placeholder="Company Name" name="company_name" onChange={(e)=>handleChange(e)} required="" value={state.user_info.company_name!=undefined && state.user_info.company_name!="" ? state.user_info.company_name:"" }/>
                        </div>
                        <div class="col-12 col-md-6 mb-3">
                            <label for="" class="form-label">User Account</label>
                            <input type="text" class="form-control" id="text" placeholder="User Account" name="user_account" onChange={(e)=>handleChange(e)} required="" value={state.user_info.user_account!=undefined && state.user_info.user_account!="" ? state.user_info.user_account==1?"Personal":"Business":"" } readOnly/>
                        </div>
                        {/* <div class="col-12 col-md-6 mb-3">
                            <label for="" class="form-label">Company Type</label>
                            <select class="form-select">
                                <option>Select</option>
                                <option>Sole Proprietorship</option>
                                <option>Partnership Firm</option>
                                <option>Private Limited Company (Pvt. Ltd.)</option>
                                <option>Public Limited Company</option>
                                <option>Limited Liability Partnership (LLP)</option>
                                <option>One Person Company (OPC)</option>
                                <option>Cooperative Society</option>
                                <option>Section 8 Company (Non-Profit)</option>
                            </select>
                        </div> */}
                        <div class="col-12 col-md-12 mb-3">
                            <label for="" class="form-label">Full Address <span class="text-danger">*</span></label>
                            <textarea class="form-control" rows="5" id="comment"  placeholder="Enter details" name="address"  onChange={(e)=>handleChange(e)}  value={state.user_info.address!=undefined && state.user_info.address!=""? state.user_info.address:""}></textarea>
                        </div>
                        <div class="col-12 col-md-4 mb-3">
                            <label for="" class="form-label">Country <span class="text-danger">*</span></label>
                            <select class="form-select w-100 mb-0" id="state" name="country"  aria-label="State select example" onChange={(e)=>handleSelectCountry(e)}  value={state.user_info.country || ""} >
                                                               <option >Country</option>
                                                                
                                                                {countrylist.map((sub)=>(
                                                                    <option value={sub.country_id}>{sub.country_name}</option> 
                                                                ))}
                                                            </select>
                        </div>
                        <div class="col-12 col-md-4 mb-3">
                            <label for="" class="form-label">State <span class="text-danger">*</span></label>
                            <select class="form-select w-100 mb-0" id="state" name="state" aria-label="State select example" onChange={(e)=>handleSelectState(e)} value={state.user_info.state || ""}>
                                                          <option >State</option>
                                                                {statelist.map((sub)=>(
                                                                    <option value={sub.state_id}>{sub.state_name}</option> 
                                                                ))}
                                                            </select>
                        </div>
                        <div class="col-12 col-md-4 mb-3">
                            <label for="" class="form-label">City <span class="text-danger">*</span></label>
                            <select class="form-select w-100 mb-0" id="state" name="city" aria-label="State select example" onChange={(e)=>handleSelectCity(e)} value={state.user_info.city || ""}>
                                                             <option >City</option>
                                                               
                                                                {citylist.map((sub)=>(
                                                                    <option value={sub.city_id}>{sub.city_name}</option> 
                                                                ))}
                                                            </select>
                        </div>
                </div>

                <a onClick={()=>axios_get_api()} class="btn btn-primary">Update</a>
                </div>
            </div>
    </div>
</div>
</div>
</div>
<Footer/>
</div></div></div>


    </div>
  )
}

export default Profile_update
