import React from 'react'

const Create_order_steps_b2b = (props) => {
    const { value } = props;
  return (
    <div>
      <ul class="nav flex-column order_box_left">
        <li class="nav-item">
          <a class={value==1?"nav-link":"nav-link disabled"} href="#">
            <div class="d-flex ">
                <div class=""><span class={value==1?"active_step":""}>01.</span> </div>
                <div class="ps-3 ">Buyer Details</div>
            </div>
          </a>
        </li>
        <li class="nav-item">
            <a class={value==2?"nav-link":"nav-link disabled"} href="#">
                <div class="d-flex ">
                    <div class=""><span class={value==2?"active_step":""}>02</span> </div>
                    <div class="ps-3 ">Pickup Details</div>
                </div>
            </a>
        </li>
        <li class="nav-item">
          <a class={value==3?"nav-link":"nav-link disabled"} href="#">
            <div class="d-flex ">
                <div class=""><span class={value==3?"active_step":""}>03</span> </div>
                <div class="ps-3 ">Order Details</div>
            </div>
          </a>
        </li>
        {/* <li class="nav-item">
          <a class={value==4?"nav-link":"nav-link disabled"} href="#">
            <div class="d-flex ">
                <div class=""><span class={value==4?"active_step":""}>04</span> </div>
                <div class="ps-3 ">Package Details</div>
            </div>
          </a>
        </li> */}
      </ul>
    </div>
  )
}

export default Create_order_steps_b2b
