import React from "react";
import logo from "./logo.svg";
import "./App.css";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import Login from "./views/Customer_pro/Login";
import Dashboard from "./views/Customer_pro/Dashboard";
import Customer_orders from "./views/Customer_pro/Customer_orders";
import Register from "./views/Customer_pro/Register";
import Forgot_password from "./views/Customer_pro/Forgot_password";
import Create_password from "./views/Customer_pro/Create_password";
import Verify_email from "./views/Customer_pro/Verify_email";
import Bulk_order_log from "./views/Customer_pro/Bulk_order_log";
import Bulk_orders_log_detail from "./views/Customer_pro/Bulk_orders_log_detail";
import Create_order_1 from "./views/Customer_pro/Create_order_1";
import Create_order_2 from "./views/Customer_pro/Create_order_2";
import Create_order_3 from "./views/Customer_pro/Create_order_3";
import Create_order_4 from "./views/Customer_pro/Create_order_4";
import Order_import from "./views/Customer_pro/Order_import";
import Detail_summary from "./views/Customer_pro/Detail_summary";
import Channel_list from "./views/Customer_pro/Channel_list";
import Add_channel from "./views/Customer_pro/Add_channel";
import Channel_integrate from "./views/Customer_pro/Channel_integrate";
import Shipments from "./views/Customer_pro/Shipments";
import Master_label_settings from "./views/Customer_pro/Master_label_settings";
import Label_size_settings from "./views/Customer_pro/Label_size_settings";
import Pickup_request from "./views/Customer_pro/Pickup_request";
import Warehouse from "./views/Customer_pro/Warehouse";
import Weight_discrepancies_list from "./views/Customer_pro/Weight_discrepancies_list";
import Rate_calculator from "./views/Customer_pro/Rate_calculator";
import Order_allocation_engine from "./views/Customer_pro/Order_allocation_engine";
import After_ship from "./views/Customer_pro/After_ship";
import Aftership_tracking_script from "./views/Customer_pro/Aftership_tracking_script";
import Billing_shipping_charges from "./views/Customer_pro/Billing_shipping_charges";
import Deduction from "./views/Customer_pro/Deduction";
import Recharge from "./views/Customer_pro/Recharge";
import Billing_weight_reconciliation from "./views/Customer_pro/Billing_weight_reconciliation";
import Billing_cod_remittance from "./views/Customer_pro/Billing_cod_remittance";
import Billing_wallet from "./views/Customer_pro/Billing_wallet";
import Reports from "./views/Customer_pro/Reports";
import Customer_invoice from "./views/Customer_pro/Customer_invoice";
import Invoice_vendor from "./views/Customer_skyship/Invoice_vendor";
import Profile_update from "./views/Customer_pro/Profile_update";
import Bank_details from "./views/Customer_pro/Bank_details";
import User_kyc from "./views/Customer_pro/User_kyc";
import Billing_address from "./views/Customer_pro/Billing_address";
import Change_password from "./views/Customer_pro/Change_password";
import Email_notifications from "./views/Customer_pro/Email_notifications";
import Whatsapp_notification from "./views/Customer_pro/Whatsapp_notification";
import Wallet_recharge from "./views/Customer_pro/Wallet_recharge";
import Tracking from "./views/Customer_pro/Tracking";
import Print_label from "./views/Customer_pro/Print_label";
import Support_tickets from "./views/Customer_pro/Support_tickets";
import Create_ticket from "./views/Customer_pro/Create_ticket";
import Ticket_details from "./views/Customer_pro/Ticket_details";
import Activity_logs from "./views/Customer_pro/Activity_logs";
import Settings from "./views/Customer_pro/Settings";
import International_orders from "./views/Customer_pro/International_orders";
import International_order_import from "./views/Customer_pro/International_order_import";
import Ndr from "./views/Customer_pro/Ndr";
import Transaction_detail from "./views/Customer_pro/Transaction_detail";
import Manifest_add from "./views/Customer_pro/Manifest_add";
import Manifest_list from "./views/Customer_pro/Manifest_list";
import Manifest_print from "./views/Customer_pro/Manifest_print";
import Bulk_lb_print from "./views/Customer_pro/Bulk_lb_print";
import System_prefrences from "./views/Customer_pro/System_prefrences";
import Customer_b2b_orders from "./views/Customer_pro/Customer_b2b_orders";
import Create_order_b2b_1 from "./views/Customer_pro/Create_order_b2b_1";
import Create_order_b2b_2 from "./views/Customer_pro/Create_order_b2b_2";
import Create_order_b2b_3 from "./views/Customer_pro/Create_order_b2b_3";

function App() {
  const userDetail = localStorage.getItem("ship_rocket_user");
  const isLoggedIn = Boolean(userDetail);

  console.log("userDetail 111===>   ", userDetail);
  return (
    <Router>
      <Routes>
        {isLoggedIn ? (
          <Route path="/" element={<Navigate to="/dashboard" replace />} />
        ) : (
          <Route path="/" element={<Navigate to="/login" replace />} />
        )}
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Register />} />
        <Route path="/forgot_password" element={<Forgot_password />} />
        <Route path="/create_password" element={<Create_password />} />
        <Route path="/verify_email" element={<Verify_email />} />

        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/orders" element={<Customer_orders />} />
        <Route path="/bulk_orders_log/:log_id" element={<Bulk_order_log />} />
        <Route path="/create_order_1" element={<Create_order_1 />} />
        <Route path="/create_order_2" element={<Create_order_2 />} />
        <Route path="/create_order_3" element={<Create_order_3 />} />
        <Route path="/create_order_4" element={<Create_order_4 />} />
        <Route path="/bulk_order_upload" element={<Order_import />} />
        <Route path="/detail_summary/:record_id" element={<Detail_summary />} />
        <Route path="/channel_list" element={<Channel_list />} />
        <Route path="/add_channel" element={<Add_channel />} />
        <Route path="/shipments" element={<Shipments />} />
        <Route
          path="/master_label_settings"
          element={<Master_label_settings />}
        />
        <Route path="/label_size_settings" element={<Label_size_settings />} />
        <Route path="/pickup_request" element={<Pickup_request />} />
        <Route path="/warehouse" element={<Warehouse />} />
        <Route
          path="/weight_discrepancies_list"
          element={<Weight_discrepancies_list />}
        />
        <Route path="/rate_calculator" element={<Rate_calculator />} />
        <Route
          path="/order_allocation_engine"
          element={<Order_allocation_engine />}
        />
        <Route path="/aftership" element={<After_ship />} />
        <Route
          path="/aftership_tracking_script"
          element={<Aftership_tracking_script />}
        />

        <Route
          path="/channel_integrate/:channel_id"
          element={<Channel_integrate />}
        />

        <Route
          path="/bulk_orders_log_detail/:log_id"
          element={<Bulk_orders_log_detail />}
        />
        <Route
          path="/billing_shipping_charges"
          element={<Billing_shipping_charges />}
        />

        <Route path="/billing_deduction" element={<Deduction />} />
        <Route path="/billing_recharges" element={<Recharge />} />
        <Route
          path="/billing_weight_reconciliation"
          element={<Billing_weight_reconciliation />}
        />

        <Route
          path="/billing_cod_remittance"
          element={<Billing_cod_remittance />}
        />
        <Route path="/billing_passbook" element={<Billing_wallet />} />
        <Route path="/shipment_report" element={<Reports />} />
        <Route path="/invoices_list" element={<Customer_invoice />} />
        <Route path="/invoice_vendor" element={<Invoice_vendor />} />
        <Route path="/profile_update" element={<Profile_update />} />
        <Route path="/bank_details" element={<Bank_details />} />
        <Route path="/user_kyc" element={<User_kyc />} />
        <Route path="/billing_address" element={<Billing_address />} />
        <Route path="/change_password" element={<Change_password />} />
        <Route path="/email_notification" element={<Email_notifications />} />
        <Route path="/wallet_recharge" element={<Wallet_recharge />} />
        <Route path="/ndr" element={<Ndr />} />

        <Route exact path="/tracking/:tracking_no" element={<Tracking />} />
        <Route
          exact
          path="/international_order_import"
          element={<International_order_import />}
        />

        <Route
          exact
          path="/print_label/:shipment_id"
          element={<Print_label />}
        />

        <Route
          path="/international_orders"
          element={<International_orders />}
        />
        <Route
          path="/whatsapp_notification"
          element={<Whatsapp_notification />}
        />
        <Route exact path="/tickets" element={<Support_tickets />} />
        <Route exact path="/create_ticket" element={<Create_ticket />} />
        <Route exact path="/activity_logs" element={<Activity_logs />} />
        <Route exact path="/settings" element={<Settings />} />
        <Route exact path="/manifest_add" element={<Manifest_add />} />
        <Route exact path="/manifest_list" element={<Manifest_list />} />
        <Route exact path="/bulk_lb_print" element={<Bulk_lb_print />} />
        <Route exact path="/bulk_orders_logs" element={<Bulk_order_log />} />
        <Route path="/b2b_orders" element={<Customer_b2b_orders />} />
        <Route path="/create_order_b2b_1" element={<Create_order_b2b_1 />} />
        <Route path="/create_order_b2b_2" element={<Create_order_b2b_2 />} />

        <Route path="/create_order_b2b_3" element={<Create_order_b2b_3 />} />

        <Route
          exact
          path="/system_prefrences"
          element={<System_prefrences />}
        />

        <Route
          exact
          path="/manifest_print/:manifest_id"
          element={<Manifest_print />}
        />

        <Route
          exact
          path="/transaction_detail/:payout_id"
          element={<Transaction_detail />}
        />

        <Route
          exact
          path="/ticket_details/:ticket_id"
          element={<Ticket_details />}
        />
      </Routes>
    </Router>
  );
}

export default App;
