import React, { useState, createRef, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import Swal from 'sweetalert2'
import config from "../../config"


const Login = () => {
  let navigate = useNavigate();
  let userDetail = localStorage.getItem('ship_rocket_user');
  // console.log("userDetail ===>   ",userDetail);
  const [state, setState] = useState({ email: "",password:"" , type:config.type });
 
  const [userState, setUserState] = useState({ full_name: "" });
  useEffect(() => {

    if(userDetail!= null) {
      let uDetail = JSON.parse(userDetail)
      setUserState({full_name:uDetail.full_name})
      config.UserId = uDetail.customer_id
    }
  }, [])
  
  const Toast = Swal.mixin({
    toast: true,
    position: 'bottom-end',
    showConfirmButton: false,
    timer: 3000
  })

  const inputHandleChange = (e) => {
    const value = e.target.value;
    const text = e.target.name;
    setState({ ...state, [text]: value })
  }
 

  const loginButton = (z) => {
   
    // console.log("state => ", state);
    if(state.email != "" && state.password != "") {
    let full_api = config.apiUrl + `/user/login_api`;
    let sendData = state;
    // let headers = config.headers
    // console.log("bb", sendData);
    axios.post(full_api, sendData, { headers:config.headers }).then((res) => {
        // console.log("res ", res);
        let email = res.data.email
        if (res.data.isValid) {
          if(res.data.isotp==true){
            localStorage.setItem('ship_rocket_user', JSON.stringify(res.data.output));
            setUserState({full_name:res.data.output.full_name})
            config.UserId = res.data.output.customer_id
         
            // navigate('/dashboard')
            window.location.replace('/dashboard');
          }
          else{
            let full_api = config.apiUrl + `/user/otp_sent_for_staff`;
            let sendData = {customer_email:email , staff_email:state.email};
            // console.log("bb", sendData);
            axios.post(full_api, sendData, { headers: config.headers }).then((res) => {
                // console.log("res ", res);
                if (res.data.isValid) {
                  
                 
                    Toast.fire({
                      background:"#3659cd",
                      type: 'success',
                      title: res.data.message ,
                      color:"white"
                    });
                    let sendState = {email:email , mobile_number:"", staff_email:state.email};
                    // console.log("sendState === " , sendState)
                  
                     navigate('/staff_otp_verification',{state:sendState})
                    // navigate('/customer/dashboard')
                }else {
                  Toast.fire({
                    background:"#a61c09",
                    type: 'unsuccess',
                    title: res.data.message ,
                    color:"white"
                  });
                   
                }
        
            }).catch((e) => {
        
        
            });
           
          }
        }else {
          Toast.fire({
            background:"#a61c09",
            type: 'unsuccess',
            title: res.data.message,
            color:"white"
          });
           
        }

    }).catch((e) => {


    });
  }else {
    Toast.fire({
      background:"#a61c09",
      type: 'unsuccess',
      title: "Please fill both fields",
      color:"white"
    });
  }
}


const naviagte_to_forgot = ()=>{
  if(state.email != "" ) {
    let full_api = config.apiUrl + `/user/forgot_password`;
    let sendData = {email:state.email};
    // console.log("bb", sendData);
    axios.post(full_api, sendData, { headers: config.headers }).then((res) => {
        // console.log("res ", res);
        if (res.data.isValid) {
          
        
            Toast.fire({
              background:"#3659cd",
              type: 'success',
              title: res.data.message ,
              color:"white"
            });
    }
    else{

    }
  })
    let sendState = {email:state.email};
    // console.log("sendState === " , sendState)
  
       navigate('/forgot_password',{state:sendState})
  }
  else{
    Toast.fire({
      background:"#a61c09",
      type: 'unsuccess',
      title: "Please Enter Email",
      color:"white"
    });
  }
}

  return (
    <div>
      <section class="register_box_00">
  <div class="row justify-content-center ">
    <div class="col-xl-4 col-lg-5 col-sm-6 col-12 ">
    <form
        onSubmit={(e) => {
          e.preventDefault(); // Prevent the default form submission behavior
          loginButton(); // Call your login function
        }}
        className="my-5"
      >
        <div class="border rounded-2 p-4 mt-5 login_box">
          <div class="login-form">
            <div class="text-center login-logo">
              <a href="/login" class="mb-4 text-center">
                <img src="assets/images/logo_big.png" class="img-fluid " alt=""/>
                <h3 class="fw-bold">SkyShip</h3>
              </a>
            </div>
            <h5 class="fw-bold mb-5">Sign in to access dashboard.</h5>
            <div class="mb-3">
              <label class="form-label">Your Email</label>
              <input type="text" class="form-control" placeholder="Enter your email address" name="email" onChange={(e) => inputHandleChange(e)} autocomplete="new-username"/>
            </div>
            <div class="mb-3">
              <label class="form-label">Your Password</label>
              <input type="password" class="form-control" id="email" placeholder="Enter your password" name="password" onChange={(e) => inputHandleChange(e)}  autocomplete="new-password" />
            </div>
            <div class="d-flex align-items-center justify-content-between">
              <div class="form-check m-0">
                {/* <input class="form-check-input" type="checkbox" value="" id="rememberPassword"/>
                <label class="form-check-label" for="rememberPassword">Remember</label> */}
              </div>
              <a style={{cursor:"pointer"}} onClick={(e)=>naviagte_to_forgot(e)} class="text-blue text-decoration-underline">Lost password?</a>
            </div>
            <div class="d-grid py-3 mt-4">
              <button type="submit" class="btn btn-lg btn-primary" 
              // onClick={() =>loginButton()}
                >
                LOGIN
              </button>
            </div>
            {/* <!-- <div class="text-center py-3">or Login with</div>
            <div class="btn-group w-100">
              <button type="button" class="btn btn-sm btn-outline-danger">
                Google
              </button>
              <button type="button" class="btn btn-sm btn-outline-primary">
                Facebook
              </button>
              <button type="button" class="btn btn-sm btn-outline-info">
                Twitter
              </button>
            </div> --> */}
               {config.type==5?"":  
            <div class="text-center pt-4">
              <span>Not registered?</span>
              <a href="/register" class="text-blue text-decoration-underline ms-2">
                SignUp</a>
            </div>
            }
          </div>
        </div>
      </form>

      <div class="">
        <ul class="nav">
            <li class="nav-item">
              <a class="nav-link" href="terms" target="_blank">Terms &amp; Condition</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="policy" target="_blank">Privacy Policy</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="help" target="_blank">Help</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="contact" target="_blank">Contact</a>
            </li>
        </ul>
      </div>
    </div>
  </div>

</section>
    </div>
  )
}

export default Login
