import React, { useEffect, useState } from 'react'
import axios from 'axios'
import queryString from 'query-string';
import { useNavigate, useLocation } from 'react-router-dom';
import { Container, Row, Col, Form, Button, ProgressBar } from "react-bootstrap";
import Modal from 'react-bootstrap/Modal';
import Select from 'react-select';
import Moment from 'react-moment';

import Swal from 'sweetalert2'
import TablePagination from '@mui/material/TablePagination';
import ReactLoading from 'react-loading';
import config from "../../config"
import { useNetworkState } from 'react-use';
import Footer from './Footer';
import Header from './Header';
import moment from 'moment'
import $ from 'jquery'; 
import 'daterangepicker/daterangepicker.css'; // Import daterangepicker CSS
import 'daterangepicker';

const Weight_discrepancies_list = () => {
    let navigate = useNavigate()
    const isOnline = useNetworkState();
    console.log("isONline  === " ,isOnline )
 
    let userDetail = localStorage.getItem('ship_rocket_user');
    // console.log("userDetail====shipments====", config.UserId);
    let userData = {};
    if (userDetail != null) {
      userData = JSON.parse(userDetail)
    }

    const [isSidebarOpen, setIsSidebarOpen] = React.useState(false);

    const toggleSidebar = () => {
        setIsSidebarOpen(!isSidebarOpen);
        const homeSection = document.querySelector('.home-section');
        if (homeSection) {
            homeSection.classList.toggle('sidebar-closed');
        }
    };
    const[selected , setSelected] = React.useState({select_count:0 , select_order_id:[]})
   
    const[state,setState]= React.useState({shipment_list:[],isLoading:true})
  
    const[searchstate,setsearchstate]= React.useState({search_shipment:""})

    const [shipmentstate, setshipStatus] = React.useState({ shipment_status: null });
  
    const[isLoading , setisLoading] =React.useState(true)
  
    const [otherStates,setOtherState] = React.useState({dstatus:4,activePage:1,rowsPerPage:20,page:0,total_count:0,onload:true});
   
    const[date, setdate] = React.useState({from_date:moment().subtract(30, 'days').format('YYYY-MM-DD'),to_date:moment().format('YYYY-MM-DD')})
    const[paymentmode, setpaymentmode] = React.useState({cod:false,prepaid:false})

    const[modals,setModals] = React.useState({show: false})
    const [disputeText, setDisputeText] = React.useState('');
const [images, setImages] = React.useState([]);
const [shipments , setshipments] = React.useState({shipment_id:""})
const [videos, setVideos] = useState([]);
const[disablebutton , setdisablebutton] = React.useState(false)
const[viewModal, setviewModal] = React.useState({show:false , sub:{}})


    const shipment_list=(status,index=0,onLoad)=>{
        if(isOnline.online==true){
          let dataToSend = {customer_id : userData.customer_id ,find:searchstate.search_shipment,  status:status , limit:otherStates.rowsPerPage,indexValue:index , discrepencies_status:otherStates.dstatus
      };
      if(date.from_date!="" && date.to_date!=""){
        dataToSend.from_date = date.from_date
        dataToSend.to_date = date.to_date
  
      }
     
      if (paymentmode.cod && !paymentmode.prepaid) {
        dataToSend.payment_mode = 'cod';
    } else if (!paymentmode.cod && paymentmode.prepaid) {
        dataToSend.payment_mode = 'prepaid';
    } else if (paymentmode.cod && paymentmode.prepaid) {
        dataToSend.payment_mode = 'both';
    }
     
        // console.log("datatoSend",dataToSend)
        let url = config.apiUrl + '/shipment/weight_discrepancies_list';
       
       // console.log("headers =========> ", config.headers);
       
        
        axios.post(url, dataToSend, { headers: config.headers })
        // // console.log("headers ====== " , config.headers)
            .then((res) => {
              // console.log("responseJson => ", res);
              if( onLoad) {
                setOtherState({...otherStates,total_count:res.data.count , page:index})         
  
    }
              setState({shipment_list:res.data.output,isLoading:false})
              setisLoading(false)
           
            })
            .catch((error) => {
                //Hide Loader
              //   setLoadingstate({...loadingstate,loading:false})
                // console.log(error);
            });    
          }
          else{
            Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: "Network Connection Error",
              
            })
          }
  
      }
      React.useState(()=>{
        shipment_list(null,0,true)
    },[])

    const handlesearchShipment=(e)=>{
        setsearchstate({search_shipment:e.target.value})
       }
    
       const dateselect =(start , end)=>{
        setdate({...date, from_date:moment(start).format('YYYY-MM-DD'), to_date:moment(end).format("YYYY-MM-DD")})
     }

     React.useEffect(()=>{
        shipment_list(null,0,true)
      },[ otherStates.dstatus ])
      const handlePageChange = (event,newPage) =>{
        setOtherState({...otherStates,page:newPage})
        // console.log("newPage ", newPage);
        // getCountryList(newPage);
        shipment_list(shipmentstate.shipment_status,newPage,true)
    }
    const handleChangeRowsPerPage = (event , newPage) => {
        // console.log("event ", event.target.value);
        setOtherState({ ...otherStates, rowsPerPage: event.target.value })
         
    }
    const handleImageUpload = (e) => {
      const uploadedImages = Array.from(e.target.files);
    
      // Check the number of selected images
      if (uploadedImages.length + images.length > 3) {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: "You can only upload up to 3 images.",
        });
        return;
      }
    
      // Check the total size of selected images
      const totalSize = uploadedImages.reduce((acc, img) => acc + img.size, 0);
      if (
        (uploadedImages.length === 1 && totalSize > 3 * 1024 * 1024) ||
        (uploadedImages.length === 2 && totalSize > 6 * 1024 * 1024) ||
        (uploadedImages.length === 3 && totalSize > 10 * 1024 * 1024)
      ) {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: "Total image size exceeds the allowed limit.",
        });
        return;
      }
    
      setImages(prevImages => [...prevImages, ...uploadedImages]);
    };
    
    
    const handlemodals=(item)=>{
      setModals({show:true})
      setshipments({shipment_id:item.shipment_id})
    }
    const handleclosemodals = ()=>{
      setModals({show:false})
      setDisputeText('')
      setshipments({shipment_id:""})
      setImages([])
      setVideos([])
    }

    const handlemodalsbulk=(item)=>{
      setModals({show:true})
    }
   

    const handleSubmit = () => {
      setdisablebutton(true)
      submitDispute(disputeText, images , videos);
    };
    const submitDispute = async (disputeText, images, videos) => {
      if (disputeText === "") {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: "Please enter dispute issue",
        });
        setdisablebutton(false)
        return;
       }
    
      const formData = new FormData();
      formData.append('disputeText', disputeText);
      formData.append('customerid', userData.customer_id);
      formData.append('shipmentid', shipments.shipment_id!=""?shipments.shipment_id:selected.select_order_id);
    
      images.forEach((image, index) => {
        formData.append(`image${index}`, image);
      });
    
      videos.forEach((video, index) => {
        formData.append(`video${index}`, video);
      });
    
      try {
        const response = await axios.post(config.apiUrl + '/user/submit_dispute', formData, { headers: config.headers });
        if (response.status === 200) {
          Swal.fire({
            icon: 'success',
            title: 'Success...',
            text: "Dispute submitted successfully!",
          });
          handleclosemodals();
          setdisablebutton(false)
          shipment_list(null,0,true)
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: "Failed to submit dispute. Please try again.",
          });
          setdisablebutton(false)
        }
      } catch (error) {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: "An error occurred. Please try again.",
        });
        setdisablebutton(false)
      }
    };

    
    const statusChange = (status) => {
      setOtherState({ ...otherStates, dstatus: status })
  }
  const handleVideoUpload = (e) => {
    const uploadedVideos = Array.from(e.target.files);
  
    // Check the number of selected videos
    if (uploadedVideos.length + videos.length > 3) {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: "You can only upload up to 3 videos.",
      });
      return;
    }
  
    // Check the total size of selected videos
    const totalSize = uploadedVideos.reduce((acc, vid) => acc + vid.size, 0);
    if (
      (uploadedVideos.length === 1 && totalSize > 30 * 1024 * 1024) ||
      (uploadedVideos.length === 2 && totalSize > 60 * 1024 * 1024) ||
      (uploadedVideos.length === 3 && totalSize > 100 * 1024 * 1024)
    ) {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: "Total video size exceeds the allowed limit.",
      });
      return;
    }
  
    setVideos(prevVideos => [...prevVideos, ...uploadedVideos]);
  };
  
  
  const removeImage = (index) => {
    setImages(prevImages => prevImages.filter((_, i) => i !== index));
  };
  
  const removeVideo = (index) => {
    setVideos(prevVideos => prevVideos.filter((_, i) => i !== index));
  };

  const handleupdateSubmit = () => {
    setdisablebutton(true)
    submitupdateDispute(disputeText, images , videos);
  };
  const submitupdateDispute = async (disputeText, images, videos) => {
    if (disputeText === "") {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: "Please enter dispute issue",
      });
      setdisablebutton(false)
      return;
     }
  
    const formData = new FormData();
    formData.append('disputeText', disputeText);
    formData.append('customerid', userData.customer_id);
    formData.append('shipmentid', viewModal.sub.shipment_id);
  
    images.forEach((image, index) => {
      formData.append(`image${index}`, image);
    });
  
    videos.forEach((video, index) => {
      formData.append(`video${index}`, video);
    });
  
    try {
      const response = await axios.post(config.apiUrl + '/user/update_submit_dispute', formData, { headers: config.headers });
      if (response.status === 200) {
        Swal.fire({
          icon: 'success',
          title: 'Success...',
          text: "Dispute updated successfully!",
        });
        handlecloseviewmodals();
        setdisablebutton(false)
        shipment_list(null,0,true)
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: "Failed to update dispute. Please try again.",
        });
        setdisablebutton(false)
      }
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: "An error occurred. Please try again.",
      });
      setdisablebutton(false)
    }
  };
  const handlecloseviewmodals = ()=>{
    setviewModal({show:false,sub:{}})
    setDisputeText('')
    setImages([])
    setVideos([])
  }

  React.useEffect(() => {
    // Initialize date range picker with today's date as the default range
    $('.custom-daterange').daterangepicker({
        opens: 'right',
        startDate: moment().subtract(30, 'days').format('YYYY-MM-DD'),  // Set start date to today's date
        endDate: moment().startOf('day'),    // Set end date to today's date
        autoUpdateInput: true,
        locale: {
            format: 'YYYY-MM-DD'             // Display format
        }
    }, (start, end) => {
      dateselect(start, end); 
     
              // Update selected range when a new range is chosen
    });
  
    // Set initial value in the input to show today's date
    $('.custom-daterange').val(`${moment().subtract(30, 'days').format('YYYY-MM-DD')} to ${moment().format('YYYY-MM-DD')}`);
  }, []);

  const handleSelectAll = (event) => {
    const selectedIds = event.target.checked
      ? state.shipment_list
          .filter(order => (order.discrepencies_dispute_status === 0 )) // Only select orders with status 1 or 9
          .map(order => `${order.shipment_id}`) // Use a unique identifier combining order_number and status
      : [];
  
    setSelected({
      select_count: selectedIds.length,
      select_order_id: selectedIds,
    });
  };
  const handleChangeSelected = (e, sub) => {
    const uniqueOrderIdentifier = `${sub.shipment_id}`; // Combine order_number and status
  
    if (sub.discrepencies_dispute_status === 0 ) {
      setSelected(prevSelected => {
        if (prevSelected.select_order_id.includes(uniqueOrderIdentifier)) {
          // Remove the unique identifier if it's already in the selected list
          return {
            select_count: prevSelected.select_count - 1,
            select_order_id: prevSelected.select_order_id.filter(id => id !== uniqueOrderIdentifier)
          };
        } else {
          // Add the unique identifier if it's not in the selected list
          return {
            select_count: prevSelected.select_count + 1,
            select_order_id: [...prevSelected.select_order_id, uniqueOrderIdentifier]
          };
        }
      });
    }
  };
 
  return (
    <div>
       <Header/>
 <div class="page-wrapper pinned">
  <div class="main-container">
   <div className='app-container'>
  <div class="app-body">
  <div class="container-fluid"> 


<div class="mob_nav">
  <div class="row">
      <div class="col">
        <div class=" d-flex justify-content-start ">
          <a href="javascript:window.history.back();" class=""><i class="bi bi-arrow-left"></i></a>
          <h2>Weight Discrepancies</h2>
        </div>
      </div>
  </div>
</div>


<div class="row justify-content-between desk_nav">
    <div class="col-12 col-md-4 ">
      <div class="d-flex">
        <a href="javascript:window.history.back();" class="back_btn"><i class="bi bi-arrow-90deg-left"></i></a>
        <h2>Weight Discrepancies</h2>
      </div>
    </div>
    <div class="col-12 col-md-4 mb-3">
    </div>
</div>

<Modal show={modals.show} onHide={() => handleclosemodals()}>
  <Modal.Header closeButton>
    <Modal.Title>Raise Dispute</Modal.Title>
  </Modal.Header>
  <Modal.Body>
    <div className="modal-body text-center p-5">
      {/* Text Area */}
      <textarea
        className="form-control mb-4"
        placeholder="Describe your issue..."
        value={disputeText}
        onChange={(e) => setDisputeText(e.target.value)}
        rows="4"
      />

      {/* Image Upload */}
      <div className="mb-4">
        <label className="form-label">Upload Images (up to 3)</label>
        <input
          type="file"
          accept="image/*"
          multiple
          onChange={handleImageUpload}
          className="form-control"
        />
      </div>

      {/* Display Uploaded Images */}
      <div className="d-flex flex-wrap">
        {images.map((image, index) => (
          <div key={index} className="position-relative m-2">
            <img
              src={URL.createObjectURL(image)}
              alt={`uploaded-${index}`}
              style={{ width: '100px', height: '100px', objectFit: 'cover' }}
            />
            {/* Remove Image Button */}
            <button
              type="button"
              className="btn btn-danger btn-sm position-absolute top-0 end-0"
              onClick={() => removeImage(index)}
            >
              X
            </button>
          </div>
        ))}
      </div>

      {/* Video Upload */}
      <div className="mb-4">
        <label className="form-label">Upload Videos (up to 3)</label>
        <input
          type="file"
          accept="video/*"
          multiple
          onChange={handleVideoUpload}
          className="form-control"
        />
      </div>

      {/* Display Uploaded Videos */}
      <div className="d-flex flex-wrap">
        {videos.map((video, index) => (
          <div key={index} className="position-relative m-2">
            <video
              src={URL.createObjectURL(video)}
              style={{ width: '100px', height: '100px', objectFit: 'cover' }}
              controls
            />
            {/* Remove Video Button */}
            <button
              type="button"
              className="btn btn-danger btn-sm position-absolute top-0 end-0"
              onClick={() => removeVideo(index)}
            >
              X
            </button>
          </div>
        ))}
      </div>
    </div>
  </Modal.Body>
  <Modal.Footer>
    <button className="btn btn-secondary" onClick={() => handleclosemodals()}>
      Close
    </button>
    {disablebutton==false?
    <button className="btn btn-primary" onClick={() => handleSubmit()}>
      Submit
    </button>
    :""}
  </Modal.Footer>
</Modal>
       
<Modal show={viewModal.show} onHide={() => setviewModal({ show: false, sub: {} })}>
  <Modal.Header closeButton>
    <Modal.Title>View and Update Dispute</Modal.Title>
  </Modal.Header>
  <Modal.Body>
    <div className="modal-body">
      
      {/* Display Dispute Text */}
      <div className="mb-4">
        <label>Dispute Text:</label>
        {viewModal.sub.discrepencies_dispute_issue && viewModal.sub.discrepencies_dispute_issue.length>0?
        viewModal.sub.discrepencies_dispute_issue.map((item)=>(
          <textarea 
          className="form-control" 
          rows="3" 
          value={item} 
          disabled

          />
        )):""}
       </div>

      {/* Display Uploaded Images */}
      {viewModal.sub.discrepencies_dispute_images && viewModal.sub.discrepencies_dispute_images.length > 0 && (
        <div className="mb-4">
          <label>Attached Images and Videos:</label>
          <div className="d-flex flex-wrap">
            {viewModal.sub.discrepencies_dispute_images.map((file, index) => (
              <div key={index} className="m-2">
                {file.endsWith('.jpg') || file.endsWith('.jpeg') || file.endsWith('.png') ? (
                  <img 
                    src={file} 
                    alt={`attachment-${index}`} 
                    style={{ width: '100px', height: '100px', objectFit: 'cover' }}
                  />
                ) : (
                  <video width="100" height="100" controls>
                    <source src={file}  />
                    Your browser does not support the video tag.
                  </video>
                )}
              </div>
            ))}
          </div>
        </div>
      )}


{/* Display Admin Dispute Text */}
         <div className="mb-4">
      {viewModal.sub.admin_discrepencies_dispute_issue?  <label>Admin Dispute Text:</label>:""}
        {viewModal.sub.admin_discrepencies_dispute_issue && viewModal.sub.admin_discrepencies_dispute_issue.length>0?
        viewModal.sub.admin_discrepencies_dispute_issue.map((item)=>(
          <textarea 
          className="form-control" 
          rows="3" 
          value={item} 
          disabled

          />
        )):""}
       </div>

      {/* Display Uploaded Images */}
      {viewModal.sub.admin_discrepencies_dispute_images && viewModal.sub.admin_discrepencies_dispute_images.length > 0 && (
        <div className="mb-4">
          <label>Admin Attached Images and Videos:</label>
          <div className="d-flex flex-wrap">
            {viewModal.sub.admin_discrepencies_dispute_images.map((file, index) => (
              <div key={index} className="m-2">
                {file.endsWith('.jpg') || file.endsWith('.jpeg') || file.endsWith('.png') ? (
                  <img 
                    src={file} 
                    alt={`attachment-${index}`} 
                    style={{ width: '100px', height: '100px', objectFit: 'cover' }}
                  />
                ) : (
                  <video width="100" height="100" controls>
                    <source src={file} />
                    Your browser does not support the video tag.
                  </video>
                )}
              </div>
            ))}
          </div>
        </div>
      )}
      <textarea
        className="form-control mb-4"
        placeholder="Describe your issue..."
        value={disputeText}
        onChange={(e) => setDisputeText(e.target.value)}
        rows="4"
      />
      {/* File Upload Section */}
      <div className="mb-4">
        <label className="form-label">Upload Images (up to 3)</label>
        <input
          type="file"
          accept="image/*"
          multiple
          onChange={handleImageUpload}
          className="form-control"
        />
      </div>

      {/* Display Uploaded Images */}
      <div className="d-flex flex-wrap">
        {images.map((image, index) => (
          <div key={index} className="position-relative m-2">
            <img
              src={URL.createObjectURL(image)}
              alt={`uploaded-${index}`}
              style={{ width: '100px', height: '100px', objectFit: 'cover' }}
            />
            {/* Remove Image Button */}
            <button
              type="button"
              className="btn btn-danger btn-sm position-absolute top-0 end-0"
              onClick={() => removeImage(index)}
            >
              X
            </button>
          </div>
        ))}
      </div>

      {/* Video Upload */}
      <div className="mb-4">
        <label className="form-label">Upload Videos (up to 3)</label>
        <input
          type="file"
          accept="video/*"
          multiple
          onChange={handleVideoUpload}
          className="form-control"
        />
      </div>

      {/* Display Uploaded Videos */}
      <div className="d-flex flex-wrap">
        {videos.map((video, index) => (
          <div key={index} className="position-relative m-2">
            <video
              src={URL.createObjectURL(video)}
              style={{ width: '100px', height: '100px', objectFit: 'cover' }}
              controls
            />
            {/* Remove Video Button */}
            <button
              type="button"
              className="btn btn-danger btn-sm position-absolute top-0 end-0"
              onClick={() => removeVideo(index)}
            >
              X
            </button>
          </div>
        ))}
      </div>
      
    </div>
  </Modal.Body>
  <Modal.Footer>
    <button className="btn btn-secondary" onClick={() => setviewModal({ show: false, sub: {} })}>
      Close
    </button>
    <button className="btn btn-primary" onClick={() => handleupdateSubmit()}>
      Update Dispute
    </button>
  </Modal.Footer>
</Modal>
<div class="card mb-3">
  <div class="card-body">
    <div class="row justify-content-between">
        <div class="col-12 col-md-6 mb-1">
          <div class="row g-1">
              <div class="col-12 col-md-6">
                <div class="input-group">
                  <input type="text" class="form-control custom-daterange"/>
                  <span class="input-group-text">
                    <i class="bi bi-search" onClick={(e)=>shipment_list(null,0,true)}></i>
                  </span>
                </div>
              </div>
              <div class="col-12 col-md-6">
                <div class="search-box">
                    <div class="search-icon">
                    <i class="bi bi-search" onClick={(e)=>shipment_list(null,0,true)}></i>
                    </div>
                    <input type="text" class="search-input" name="search_shipment" placeholder="Enter AWB Number" onChange={(e)=>handlesearchShipment(e)} value={searchstate.search_shipment}/>
                </div>
              </div>
            
          </div>
        </div>
        <div class="col-12 col-md-6 mb-1">
          <div class="row justify-content-between">
        
              <div class="col-6 col-md-6">
                <div class="dropdown">
                  <button type="button" class="btn btn-outline-secondary dropdown-toggle" data-bs-toggle="dropdown">
                    Bulk Actions
                  </button>
                  <ul class="dropdown-menu">
                  {selected.select_count==0?
               ""
                :
                    <li><a class="dropdown-item" onClick={()=>handlemodalsbulk()} style={{cursor:"pointer"}}>Raise Dispute</a></li>
                  }
                  </ul>
                </div>
              </div>

          </div>
        </div>
    </div>
  </div>
</div>


<div class="card mb-3 weight_discepancies_list">
  <div class="card-body">
    <div class="custom-tabs-container">
      <ul class="nav nav-tabs" id="customTab" role="tablist">
        <li class="nav-item" role="presentation">
          <a class={"nav-link" + (otherStates.dstatus == 4 ? " active" : "")} style={{ cursor: 'pointer' }} onClick={() => statusChange(4)}>All</a>
        </li>
        <li class="nav-item" role="presentation">
        <a class={"nav-link" + (otherStates.dstatus == 0 ? " active" : "")} style={{ cursor: 'pointer' }} onClick={() => statusChange(0)}>Action Required</a>
        </li>
        <li class="nav-item" role="presentation">
        <a class={"nav-link" + (otherStates.dstatus == 1 ? " active" : "")} style={{ cursor: 'pointer' }} onClick={() => statusChange(1)}>Dispute Raised</a>
        </li>
        <li class="nav-item" role="presentation">
        <a class={"nav-link" + (otherStates.dstatus == 2 ? " active" : "")} style={{ cursor: 'pointer' }} onClick={() => statusChange(2)}>Accepted</a>
        </li>
        <li class="nav-item" role="presentation">
        <a class={"nav-link" + (otherStates.dstatus == 3 ? " active" : "")} style={{ cursor: 'pointer' }} onClick={() => statusChange(3)}>Closed</a>
        </li>
      </ul>
      <div class="tab-content" id="customTabContent">
        <div class="tab-pane fade show active" id="one" role="tabpanel" aria-labelledby="tab-one">
          <div class="card data_table mb-3 mb_display_none">
            <div class="card-body">
              <div class="table-responsive" >
                <div class="shipm_tablew rounded-3">
                  <table class="table align-top table-striped table-hover m-0 table-xl ">
                    <thead>
                      <tr>
                      <th>
                <input class="form-check-input form-check-input-lg" type="checkbox" id="inlineCheckbox1" value="option1" onChange={handleSelectAll} />
                </th>
                        <th width="">Updated Date</th>
                        <th width="">Order Number</th>
                        <th width="">AWB Number</th>
                        <th>Booking Weight</th>
                        <th>Charged Amount</th>
                        <th>Updated Weight</th>
                        <th>Final Charged Amount</th>
                        <th>Difference</th>
                        <th>Action</th>
                        <th>Status</th>

                      </tr>					
                    </thead>
                    <tbody>
                    {state.shipment_list.map((sub)=>(
                      <tr class="">
                         <td><input class="form-check-input form-check-input-lg" type="checkbox"  checked={selected.select_order_id.includes(`${sub.shipment_id}`)} 
                            value={`${sub.shipment_id}`} // Use unique value
                            onChange={(e) => handleChangeSelected(e, sub)} /></td>
                         
                        <td>
                          <small><Moment format="MMM DD YYYY">
                        {new Date(sub.discrepencies_date*1000)}
             </Moment> - <Moment format="hh:mm:ss a">
                         {new Date(sub.discrepencies_date*1000)}
             </Moment></small>
                        </td>
                        <td>
                          <h6><a href="#" class="text-primary">{sub.order_number}</a></h6>
                        </td>
                        <td>
                          <h6><a href={`/tracking/${sub.carrier_tracking_number}`} class="text-primary">{sub.carrier_tracking_number}</a></h6>
                        </td>
                        <td>
                        {sub.total_weight}KG 
                        </td>
                        <td>
                        <span class="">₹{sub.total_amount.toFixed(2)}</span>
                        </td>
                        <td>
                        {sub.discrepencies_weight?sub.discrepencies_weight:sub.total_weight} KG</td>
                      {/* <td>{sub.discrepencies_amount?sub.discrepencies_amount.toFixed(2):sub.total_amount.toFixed(2)}KG <small>{sub.discrepencies_length?sub.discrepencies_length:sub.length} X {sub.discrepencies_width?sub.discrepencies_width:sub.width} X {sub.discrepencies_height?sub.discrepencies_height:sub.height} cm</small>
                        </td> */}
                        <td>
                        <span class="">₹{sub.discrepencies_amount?sub.discrepencies_amount.toFixed(2):sub.total_amount.toFixed(2)}</span>
                        </td>
                        <td>
                        <span className={sub.discrepencies_amount ? 
  ((sub.total_amount - sub.discrepencies_amount) > 0 ? 'text-danger' : 'text-success') : 
  'text-danger'}>
  {sub.discrepencies_amount ? 
    ((sub.total_amount - sub.discrepencies_amount) > 0 ? 
      `+${(sub.total_amount - sub.discrepencies_amount).toFixed(2)}` : 
      (sub.total_amount - sub.discrepencies_amount).toFixed(2)) : 
    sub.total_amount.toFixed(2)}</span>
                        </td>
                        <td>
                        {sub.discrepencies_dispute_status==0?
                          <a onClick={()=>handlemodals(sub)} style={{cursor:"pointer"}}  class="btn btn-outline-secondary btn-sm" >Raise Dispute</a>
                          :
                          <a onClick={()=>setviewModal({show:true , sub:sub})} style={{cursor:"pointer"}} class="btn btn-outline-sprimary btn-sm">
                          View
                         </a>}
                        </td>
                        <td>
                        {sub.discrepencies_dispute_status==0? <span class="badge bg-primary">Action Required</span>:sub.discrepencies_dispute_status==1?
                          <span class="badge bg-warning">Dispute Raised</span>:sub.discrepencies_dispute_status==2?     <span class="badge bg-success">Accepted</span>:sub.discrepencies_dispute_status==3? <span class="badge bg-danger">Closed</span>:""}
                        </td>
                      </tr>
                    ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          {/* <!-- Mobile View --> */}
          <section class="desk_display_none">
            <div class="card  mb-3">
            {state.shipment_list.map((sub)=>(
              <div class="card-body">
                <div class="row">
                    <div class="col-6 mb-2">
                      <h5><a href={`/tracking/${sub.carrier_tracking_number}`}>{sub.carrier_tracking_number}</a></h5>
                      <small>AWB Number</small>
                    </div>
                    <div class="col-6 mb-2">
                    {sub.discrepencies_dispute_status==0? <span class="badge bg-primary">Action Required</span>:sub.discrepencies_dispute_status==1?
                          <span class="badge bg-warning">Dispute Raised</span>:sub.discrepencies_dispute_status==2?     <span class="badge bg-success">Accepted</span>:sub.discrepencies_dispute_status==3? <span class="badge bg-danger">Closed</span>:""}
                     
                    </div>
                    <div class="col-12 mb-2">
                      <h5>Order Number</h5>
                      <a href="#">{sub.order_number}</a>
                    </div>
                    <div class="col-6 mb-2">
                      <h5>Updated Date</h5>
                      <Moment format="MMM DD YYYY">
                        {new Date(sub.discrepencies_date*1000)}
             </Moment> - <Moment format="hh:mm:ss a">
                         {new Date(sub.discrepencies_date*1000)}
             </Moment>  
                    </div>
                    <div class="col-6 mb-2">
                      <h5>Booking Weight</h5>
                      {sub.total_weight}KG 
                    </div>
                    <div class="col-6 mb-2">
                      <h5>Charged Amount</h5>
                      ₹{sub.total_amount.toFixed(2)}
                    </div>
                    <div class="col-6 mb-2">
                      <h5>Updated Weight</h5>
                      {sub.discrepencies_weight?sub.discrepencies_weight:sub.total_weight} KG {sub.discrepencies_length?sub.discrepencies_length:sub.length} X {sub.discrepencies_width?sub.discrepencies_width:sub.width} X {sub.discrepencies_height?sub.discrepencies_height:sub.height} cm
                    </div>
                    <div class="col-6 mb-2">
                      <h5>Final Charged Amount</h5>
                      ₹{sub.discrepencies_amount?sub.discrepencies_amount.toFixed(2):sub.total_amount.toFixed(2)}
                    </div>
                    <div class="col-6 mb-2">
                      <h5>Difference</h5>
                      <span className={sub.discrepencies_amount ? 
  ((sub.total_amount - sub.discrepencies_amount) > 0 ? 'text-danger' : 'text-success') : 
  'text-danger'}>
  {sub.discrepencies_amount ? 
    ((sub.total_amount - sub.discrepencies_amount) > 0 ? 
      `+${(sub.total_amount - sub.discrepencies_amount).toFixed(2)}` : 
      (sub.total_amount - sub.discrepencies_amount).toFixed(2)) : 
    sub.total_amount.toFixed(2)}</span>
                    </div>
                    <div class="col-6">
                      <div class="d-grid">
                      {sub.discrepencies_dispute_status==0?
                          <a onClick={()=>handlemodals(sub)} style={{cursor:"pointer"}}  class="btn btn-danger btn-block" >Raise Dispute</a>
                          :
                          <a onClick={()=>setviewModal({show:true , sub:sub})} style={{cursor:"pointer"}} class="btn btn-primary btn-block">
                          View
                         </a>}
                     
                      </div>
                    </div>
                </div>
              </div>
            ))}
            </div>
            
          </section>
        </div>
     
      </div>
    </div>
  </div>
</div>


    </div>
    <TablePagination
                component="div"
                 rowsPerPageOptions={[20,25,30,35,40,45,50]}
                 count={otherStates.total_count}
                 page={otherStates.page}
                 onPageChange={handlePageChange}
                 rowsPerPage={otherStates.rowsPerPage}
                 onRowsPerPageChange={handleChangeRowsPerPage}
               
                         />
    </div>
    <Footer/>
    </div></div></div>
    </div>
  )
}

export default Weight_discrepancies_list
