import React from 'react'
import Header from './Header'
import Footer from './Footer'
import {  useParams } from 'react-router-dom';
import axios from "axios";
import config from "../../config"
import { useNetworkState } from 'react-use';
import Moment from 'react-moment';
const Activity_logs = () => {
    const isOnline = useNetworkState();
    console.log("isONline  === " ,isOnline )
    const[state,setState]= React.useState([])
    const [statesummary, setsummaryState] = React.useState([])
    let userDetail = localStorage.getItem('ship_rocket_user');
   
    let userData = {};
    if (userDetail != null) {
      userData = JSON.parse(userDetail)
    }

        const shipment_list=(status)=>{
            let dataToSend = {
        };
       
          // console.log("datatoSend",dataToSend)
          let url = config.apiUrl + '/shipment/bulk_shipment_log_view';
         
         // console.log("headers => ", config.headers);
         
          
          axios.post(url, dataToSend, { headers: config.headers })
              .then((res) => {
                // console.log("responseJson => ", res);
                setState(res.data.output)
                // setisLoading(false)
             
              })
              .catch((error) => {
                  //Hide Loader
                //   setLoadingstate({...loadingstate,loading:false})
                  // console.log(error);
              });    
    
        }
    
        React.useEffect(()=>{
            shipment_list()
            shipment_log_list()
        },[])

        const shipment_log_list = () => {
            let dataToSend = {
              customer_id: userData.customer_id
            };
        
            // console.log("datatoSend",dataToSend)
            let url = config.apiUrl + '/shipment/view_order_file_summary';
        
            // // console.log("headers => ", config.headers);
        
        
            axios.post(url, dataToSend, { headers: config.headers })
              .then((res) => {
                // console.log("responseJson => ", res);
                // setSummarystate(res.data.output)
                setsummaryState(res.data.output)
        
              })
              .catch((error) => {
                //Hide Loader
                //   setLoadingstate({...loadingstate,loading:false})
                // console.log(error);
              });
        
          }
  return (
    <div>
    <Header/>
<div class="page-wrapper pinned">
<div class="main-container">
  <div className='app-container'>
<div class="app-body">


<div class="container-fluid">

<div class="mob_nav">
  <div class=" d-flex justify-content-start ">
    <a href="javascript:window.history.back();" class=""><i class="bi bi-arrow-left"></i></a>
    <h2>Activity Logs</h2>
  </div>
</div>


<div class="row justify-content-between">
    <div class="col-12 col-md-4 desk_nav">
      <div class="d-flex">
        <a href="javascript:window.history.back();" class="back_btn"><i class="bi bi-arrow-90deg-left"></i></a>
        <h2>Activity Logs</h2>
      </div>
    </div>
    <div class="col-md-8">
    </div>
</div>


<ul class="nav nav-tabs">
  <li class="nav-item">
    <a class="nav-link active" data-bs-toggle="tab" href="#home">Bulk Shipment Log</a>
  </li>
  <li class="nav-item">
    <a class="nav-link" data-bs-toggle="tab" href="#menu1">Import Order Log</a>
  </li>
</ul>


<div class="tab-content">

  <div class="tab-pane active" id="home">
        <div class="card border-0">
            <div class="card-body">
                <div class="table-data table-responsive">
                    <table class="table table-hover ">
                        <thead>
                        <tr class="table-light">
                            <th>Log ID</th>
                            <th>Date</th>
                         
                            <th>Total Orders</th>
                            <th>Uploaded Orders</th>
                            <th>Status</th>
                            <th>Log</th>
                        </tr>
                        </thead>
                        <tbody>
                        {state.map((sub)=>(
                        <tr class="ship-now-container">
                            <td>
                            <a href={`/bulk_orders_log_detail/${sub.log_id}`} class="text-primary">{sub.log_id}</a>
                            </td>
                            <td>
                            {sub.date} | {sub.time}
                            </td>
                           
                            <td>
                            {sub.total_records}
                            </td>
                            <td>
                            {sub.uploaded_records}
                            </td>
                            <td>
                            {sub.status==0?
                              <span class="inactive_st">Running</span>:
                            <span class="active_st">Completed</span>
}
                            </td>
                            <td>
                                <a href={`/bulk_orders_log_detail/${sub.log_id}`} class="text-primary">Import log <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" data-lucide="move-right" class="lucide lucide-move-right"><path d="M18 8L22 12L18 16"></path><path d="M2 12H22"></path></svg></a>
                            </td>
                        </tr>
                    ))}
                       
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
  </div>

  <div class="tab-pane fade" id="menu1">
  
  <div class="card border-0">
            <div class="card-body">
                <div class="table-data table-responsive">
                    <table class="table table-hover ">
                        <thead>
                        <tr class="table-light">
                            <th>Log ID</th>
                            <th>Date</th>
                            <th>File Name</th>
                            <th>Total Orders</th>
                            <th>Uploaded Orders</th>
                            {/* <th>Status</th> */}
                            <th>Log</th>
                        </tr>
                        </thead>
                        <tbody>
                        {statesummary.map((sub) => (
                        <tr class="ship-now-container">
                            <td>
                                <a href={`/detail_summary/${ sub.record_id}`} class="text-primary">{ sub.record_id}</a>
                            </td>
                            <td>
                            <Moment format="MMM DD YYYY">
                                        {new Date(sub.start_time * 1000)}
                                      </Moment> - <Moment format="hh:mm:ss a">
                                          {new Date(sub.start_time * 1000)}
                                        </Moment> 
                            </td>
                            <td>{sub.file_name}</td>
                            <td>
                            {sub.total_record}
                            </td>
                            <td>
                            {sub.uploaded_record}
                            </td>
                            {/* <td>
                            <span class="active_st">Completed</span>
                            </td> */}
                            <td>
                                <a href={`/detail_summary/${ sub.record_id}`} class="text-primary">Import log <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" data-lucide="move-right" class="lucide lucide-move-right"><path d="M18 8L22 12L18 16"></path><path d="M2 12H22"></path></svg></a>
                            </td>
                        </tr>
                        ))}
                       
                        </tbody>
                    </table>
                </div>
            </div>
        </div>

  </div>
</div>

</div></div>
<Footer/></div></div></div>
    </div>
  )
}

export default Activity_logs
