import React, { useState, createRef, useEffect } from "react";

import Header from './Header'
import axios from "axios";
import { useLocation } from 'react-router-dom';

import config from "../../config"
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";

import { useNetworkState } from 'react-use';
import Footer from "./Footer";
const Create_ticket = () => {
    let navigate = useNavigate()
    const isOnline = useNetworkState();
    console.log("isONline  === " ,isOnline )
    let userDetail = localStorage.getItem('ship_rocket_user');
    let userData = {};
    if (userDetail != null) {
      userData = JSON.parse(userDetail)
    }
   const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const[shipment,shipmentState]=React.useState({shipment_detail:[]})
    const[category,setcategory]=React.useState([])
    const[dimensionState,setdimensionState] = React.useState({companyDetail:[]})
const [state, setState] = React.useState({  subject:"" , ticket_createdById:"" , details:"",shipment_id:""  , category_id:"" , subcategory_id:""  })
const [urlorder , seturlorder ] = React.useState({shipment_id:""})
const [selectedSubcategories, setSelectedSubcategories] = useState([]);
console.log("selectedSubcategories == " , selectedSubcategories)
  const [selectedCategory, setSelectedCategory] = useState({category_id:""});
  const [filteredShipments, setFilteredShipments] = useState([]);
    const [selectedShipments, setSelectedShipments] = useState([]);
    const [query, setQuery] = useState('');

useEffect(()=>{
  const order_number = queryParams.get('order_number');
  if(order_number){
    setState({...state, shipment_id:order_number})
    seturlorder({...urlorder , shipment_id:order_number})
    setSelectedShipments([order_number])
  }
},[location])

    const axios_get_api = () => {

        let full_api = config.apiUrl + `/home/support_shipments`;
        let sendData = {
          dstatus: 1,
          };
      axios.post(full_api, sendData, { headers: config.headers }).then(res => {
          shipmentState({ ...shipment, shipment_detail:res.data.output })
        }).catch((e) => {
      })
      }

      const dimension_detail = () =>{
        let full_api = config.apiUrl + `/user/get_company_info_api`;
        let sendData = {};
      
        axios.post(full_api, sendData, { headers: config.headers }).then(res => {
          setdimensionState({...dimensionState,companyDetail:res.data.companyDetail});
          // console.log("rnmmmmm", res);
        }).catch((e) => {
          // toast.configure()
           //toast.error("Some thing went wrong")
          // console.log("----error:   ", e);
        })
      }
     

      const handleShipmentInputChange = (e) => {
        const input = e.target.value;
        setQuery(input);
    
        if (input.length >= 4) {
            const matches = shipment.shipment_detail.filter((item) => {
                const orderNumber = item.order_number || ""; // Fallback to empty string if undefined
                const shipmentId = item.shipment_id || "";   // Fallback to empty string if undefined
                const trackingNumber = item.carrier_tracking_number || ""; // Fallback to empty string if undefined
    
                return (
                    orderNumber.toLowerCase().includes(input.toLowerCase()) ||
                    shipmentId.toLowerCase().includes(input.toLowerCase()) ||
                    trackingNumber.toLowerCase().includes(input.toLowerCase())
                );
            });
            setFilteredShipments(matches);
        } else {
            setFilteredShipments([]);
        }
    };
    const handleSuggestionClick = (orderNumber) => {
        if (!selectedShipments.includes(orderNumber)) {
            setSelectedShipments([...selectedShipments, orderNumber]);
        }
        setQuery('');
        setFilteredShipments([]);
    };

    const removeShipment = (orderNumber) => {
        setSelectedShipments(selectedShipments.filter((shipment) => shipment !== orderNumber));
    };
      const ticketcategory = () => {

        let full_api = config.apiUrl + `/home/category_ticket`;
        let sendData = {
          dstatus: 1,
          };
    
        axios.post(full_api, sendData, { headers: config.headers }).then(res => {
          setcategory(res.data.output)
        }).catch((e) => {
      })
      }

      React.useEffect(() => {
        axios_get_api()
        ticketcategory()
      }, [])
  
      const handleSupport = () => {
        let state_detailes = { subject:state.subject , details:state.details,shipment_id:selectedShipments , category_id:state.category_id , subcategory_id:state.subcategory_id }
        if(state.subject!="" && state.details!=""){
        axios.post(config.apiUrl + `/home/add_support`,  state_detailes , { headers: config.headers }).then((result)=>{
                  if(result && result.data != undefined){
                    const Toast = Swal.mixin({
                        toast: true,
                        position: 'bottom-end',
                        showConfirmButton: false,
                        timer: 3000
                      })
                      Toast.fire({
                        background:"#3659cd",
                        type: 'success',
                        title: result.data.message,
                        color:"white"
                      });
                      window.location.replace("/tickets")
                    }
         }).catch((e) => {
                const Toast = Swal.mixin({
                    toast: true,
                    position: 'bottom-end',
                    showConfirmButton: false,
                    timer: 3000
                  })
                  
                  Toast.fire({
                    background:"#a61c09",
                    type: 'unsuccess',
                    title: 'Something went wrong',
                    color:"white"
                  });
        });}
            else{
                const Toast = Swal.mixin({
                    toast: true,
                    position: 'bottom-end',
                    showConfirmButton: false,
                    timer: 3000
                  })
                  Toast.fire({
                    background:"#a61c09",
                    type: 'unsuccess',
                    title: 'Please Fill the Mandatory Fields ',
                    color:"white"
                  });
            }
    }
    
    const handleChange = (event) => {
      setState({
          ...state,
          [event.target.name]: event.target.value,
        });
     }
    
    const handleChange1 =(event)=>{
      const currentUrl = new URL(window.location.href);
      currentUrl.searchParams.delete('order_number'); // Remove the 'customer_id' parameter
      currentUrl.searchParams.set('order_number', event.target.value); // Set the new 'customer_id' parameter
      
      // Update the URL
      window.history.replaceState({}, '', currentUrl.toString());
      setState({
        ...state,
        [event.target.name]: event.target.value,
      });

    }

    const handleCategoryChange = (e) => {
      const selectedCategoryId = e.target.value;
      setState({...state , category_id:selectedCategoryId})
     const categoryData = category.find((item) => item.category_id === selectedCategoryId);
     if (categoryData) {
        const subcategories = categoryData;
        setSelectedSubcategories(subcategories);
        setSelectedCategory({category_id:e.target.value});
      } else {
        setSelectedSubcategories([]);
        setSelectedCategory({category_id:""});
      }
    };

    const handleSelectsubcategory =(e)=>{
    setState({...state , subcategory_id:e.target.value})
    }
  
  return (
    <div>
       <Header/>
 <div class="page-wrapper pinned">
  <div class="main-container">
   <div className='app-container'>
  <div class="app-body">
  <div class="container-fluid"> 

<div class="mob_nav">
  <div class="row">
      <div class="col">
        <div class=" d-flex justify-content-start ">
          <a href="javascript:window.history.back();" class=""><i class="bi bi-arrow-left"></i></a>
          <h2>Create Ticket</h2>
        </div>
      </div>
  </div>
</div>


<div class="row justify-content-between desk_nav">
    <div class="col-12 col-md-4 ">
      <div class="d-flex">
        <a href="javascript:window.history.back();" class="back_btn"><i class="bi bi-arrow-90deg-left"></i></a>
        <h2>Create Ticket</h2>
      </div>
    </div>
    <div class="col-12 col-md-4 mb-3 text-end">
    
    </div>
</div>



<div class="row ">
    <div class="col-12 col-md-6">
      <div class="card">
        <div class="card-body">
          <div class="row">
              <div class="col-12 col-md-6 mb-3">
                <label for="email" class="form-label">Category:</label>
                <select class="form-select" onChange={(e)=>handleCategoryChange(e)}>
                <option>Select</option>
                {category.map((item) => (
              <option key={item.category_id} value={item.category_id}>
                {item.category_name}
              </option>
            ))}
                
                </select>
              </div>
              <div class="col-12 col-md-6 mb-3">
                <label for="email" class="form-label">Sub Category:</label>
                <select className="form-select" onChange={(e)=>handleSelectsubcategory(e)}>
            <option>Select</option>
            {selectedSubcategories.subcategory
              ? selectedSubcategories.subcategory.map((sub, index) => (
                  <option key={index} value={sub.sub_id}>
                    {sub.name}
                  </option>
                ))
              : <option>No Subcategories Available</option>}
          </select>
              </div>
              <div class="col-12 col-md-12 mb-3">
                <label for="email" class="form-label">Subject:</label>
                <input type="text" class="form-control" id="text" placeholder="Enter Subject" name="subject" value={state.subject} onChange={(e) => handleChange(e)}/>
              </div>
              <div class="col-12 col-md-12 mb-3">
               
                <div class="mb-3">
                  <input type="text" class="form-control" id="text" placeholder="Enter shipment number" onChange={handleShipmentInputChange}/>
                </div>
                {filteredShipments.length > 0 && (
                <ul className="list-group mb-3">
                    {filteredShipments.map((item) => (
                        <li
                            key={item.shipment_id}
                            onClick={() => handleSuggestionClick(item.order_number)}
                            className="list-group-item d-flex justify-content-between align-items-center"
                            style={{ cursor: 'pointer' }}
                        >
                            <span>{item.order_number}</span>
                            <span>{item.shipment_id}</span>
                            <span>{item.carrier_tracking_number}</span>
                        </li>
                    ))}
                </ul>
            )}
                <div className="mb-3">
                            <label htmlFor="shipmentTextarea" className="form-label">Selected Shipments:</label>
                            <textarea
                                className="form-control"
                                id="shipmentTextarea"
                                rows="5"
                                readOnly
                                value={selectedShipments.join("\n")}
                            ></textarea>
                            {selectedShipments.map((shipment, index) => (
                                <span key={index} className="badge bg-secondary me-2">
                                    {shipment}
                                    <button
                                        type="button"
                                        className="btn-close btn-close-white ms-2"
                                        onClick={() => removeShipment(shipment)}
                                    ></button>
                                </span>
                            ))}
                        </div>
              </div>
              <div class="col-12 col-md-12 mb-3">
                <label for="email" class="form-label">Details:</label>
                <textarea class="form-control" rows="5" id="comment" name="details" placeholder="Enter details" value={state.details} onChange={(e) => handleChange(e)}></textarea>
              </div>
              <div class="col-12 col-md-12 mb-3">
                <a onClick={()=>handleSupport()} class="btn btn-primary">Create Ticket</a>
              </div>
          </div>
        </div>
      </div>
    </div>
     <div class="col-12 col-md-4 px-5">
      {/* <!-- <img src="assets/images/support.png" class="img-fluid" style="max-height: 200px;" /> --> */}
      <h4>Need Help? We're Here for You!</h4>
      <p>We understand that sometimes you might need a little assistance, and that's what our support team is here for. If you're facing any issues, have questions, or need any form of help regarding our services, don't hesitate to reach out to us through this support ticket form.</p>
        <p>
          Once you submit your ticket, our dedicated support team will get to work and strive to resolve your issue as quickly as possible. You can track the status of your ticket and communicate directly with our team.
        </p>
        <div class="text-center rounded-3 p-3 m-auto mb-3">
          <p class="fw-300 m-0">Reach Us</p>
            <h3 class="text-black">
              <div>
                <a href={`callto:${dimensionState.companyDetail.map((sub)=>(<>{sub.phone}</>))}`} data-bs-toggle="tooltip" data-bs-original-title="Call to Support"><i class="bi bi-headset"></i>{dimensionState.companyDetail.map((sub)=>(<>{sub.phone}</>))} </a>
              </div>
              <div class="mt-2">
                <a href={`${dimensionState.companyDetail.map((sub)=>(<>{sub.email}</>))}`} data-bs-toggle="tooltip" data-bs-original-title="Mail Us"><i class="bi bi-envelope"></i> {dimensionState.companyDetail.map((sub)=>(<>{sub.email}</>))}</a>
              </div>
            </h3>
          </div>
    </div>
</div>

    </div></div>
    <Footer/></div></div></div>
    </div>
  )
}

export default Create_ticket
