import React from 'react'

import Header from './Header'
import {  useParams } from 'react-router-dom';

import axios from "axios";

import config from "../../config"

import Footer from './Footer';


const Bulk_orders_log_detail = () => {
    const{log_id} = useParams() 
 
   
const[state,setState]= React.useState([])
const [statuscount, setstatuscount] = React.useState({success_count:0 , failed_count:0});

    const shipment_list=(status)=>{
        let dataToSend = {log_id : log_id
    };
   
      // console.log("datatoSend",dataToSend)
      let url = config.apiUrl + '/shipment/bulk_shipment_log_view';
     
     // console.log("headers => ", config.headers);
     
      
      axios.post(url, dataToSend, { headers: config.headers })
          .then((res) => {
            // console.log("responseJson => ", res);
            setState(res.data.output)
            setstatuscount({ success_count:res.data.success_count , failed_count:res.data.failed_count})
            // setisLoading(false)
         
          })
          .catch((error) => {
              //Hide Loader
            //   setLoadingstate({...loadingstate,loading:false})
              // console.log(error);
          });    

    }
    React.useEffect(()=>{
        shipment_list()
    },[])
  return (
    <div>
      <Header/>
      <div class="page-wrapper pinned">
 <div class="main-container">
  <div className='app-container'>
 <div class="app-body">
 <div class="container-fluid">


<div class="mob_nav">
  <div class=" d-flex justify-content-start ">
    <a href="javascript:window.history.back();" class=""><i class="bi bi-arrow-left"></i></a>
    <h2>Bulk Order Log Detail</h2>
  </div>
</div>


<div class="row justify-content-between">
    <div class="col-12 col-md-4 desk_nav">
      <div class="d-flex">
        <a href="javascript:window.history.back();" class="back_btn"><i class="bi bi-arrow-90deg-left"></i></a>
        <h2>Bulk Order Log Detail</h2>
      </div>
    </div>
    <div class="col-md-8">
    <div class="col-12 col-md m-3">
           <div className='row'>
            <div className='col-6'>{statuscount.failed_count} <span class="badge rounded-pill bg-danger fw-400">Failed</span></div>
            <div className='col-6'> {statuscount.success_count} <span class="badge rounded-pill bg-success fw-400">Success</span></div>

            </div> 
             
              </div>
    </div>
</div>


<div class="tab-content">
  <div class="tab-pane active" id="home">
        <div class="card border-0">
            <div class="card-body">
                <div class="table-data table-responsive">
                    <table class="table table-hover ">
                        <thead>
                        <tr class="table-light">
                        <th>Order Number</th>
                        <th>Status</th>
                        <th>Error Log</th>
                        </tr>
                        </thead>
                        <tbody>
                        {state.map((sub)=>(
                            sub.log.length>0?
                            sub.log.map((item)=>(
                        <tr class="ship-now-container">
                            <td>
                                <a href="#" class="text-primary">{item.order_number}</a>
                            </td>
                            <td>
                            {item.status==1?  <span class="active_st">Completed</span>:item.status==0?  <span class="active_st">Running</span>: <span class="inactive_st">Error</span>}
                            </td>
                           
                            <td>
                            {item.reason}
                            </td>
                          
                        </tr>
                       )):""
                    ))}
                     
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
  </div>
 
</div>




 </div>
 </div>
 </div>
 <Footer/>
 </div>
 </div>

    </div>
  )
}

export default Bulk_orders_log_detail
