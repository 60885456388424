import React from 'react'

import Header from './Header'
import { useNavigate } from 'react-router-dom';

import axios from "axios";

import config from "../../config"

import Footer from './Footer';
import * as XLSX from 'xlsx'
const Customer_invoice = () => {
   
    const[state , setState ] = React.useState([])
    const[isLoading , setisLoading] = React.useState(true)
    const[stateshipments , setShipments] = React.useState([])
    React.useEffect(()=>{
        invoice_list()
    },[])
    const invoice_list=(status,index=0,onLoad)=>{
        let dataToSend = {
    };
   
      // console.log("datatoSend",dataToSend)
      let url = config.apiUrl + '/shipment/customer_invoices';
     
     // console.log("headers => ", config.headers);
     
      
      axios.post(url, dataToSend, { headers: config.headers })
          .then((res) => {
            // console.log("responseJson => ", res);
           
            setState(res.data.output)
            setisLoading(false)
         
          })
          .catch((error) => {
              //Hide Loader
            //   setLoadingstate({...loadingstate,loading:false})
              // console.log(error);
          });    

    }
   
      const next_step = (sub) => {
        const url = `/invoice_vendor?invoice_id=${sub.invoice_id}&invoice_date=${sub.invoice_date}&amount=${sub.amount}&invoice_period=${sub.invoice_period}`;
        
        // Open the URL in a new tab
        window.open(url, '_blank');
      };

      const get_shipments = (invoice_id)=>{
        let dataToSend = {
invoice_id:invoice_id
        };
          let url = config.apiUrl + '/shipment/get_invoice_shipments';
           axios.post(url, dataToSend, { headers: config.headers })
              .then((res) => {
              
                setShipments(res.data.output)
                downloadExcel(res.data.output)
              })
              .catch((error) => {
                 
              });    
     } 
     const transformDataForExcel = (data) => {
        return data.map(item => {
          console.log("created == ", item.created)
          const formattedDate = item.created
            ? new Date(item.created * 1000).toLocaleDateString('en-US', {
              year: 'numeric',
              month: 'short',
              day: 'numeric',
            })
            : '';
    
          const formattedTime = item.created
            ? new Date(item.created * 1000).toLocaleTimeString('en-US', {
              hour: 'numeric',
              minute: 'numeric',
              second: 'numeric',
              hour12: true,
            })
            : '';
          return {
            'Tracking Id': item?.carrier_tracking_number || '',
            'Reference Number': item?.order_number || '',
            'Carrier': item?.carrier_name || '',
            'Mode':  item?.product_type_name || '',
            'Carrier Product': item?.service_provider || '',
            'Current Status': getStatusText(item?.status) || '',
           
          }
        });
      };
    
      const getStatusText = (status) => {
        // Define your status mappings here
        const statusMappings = {
          0: 'Pending',
          10: 'RTO',
          1: 'Pick Up Awaited',
          2: 'Picked Up',
          3: 'In Transit',
          4: 'Out For Delivery',
          5: 'Delivered',
          6: 'Cancelled',
          7: 'Failed',
          8: 'NDR',
          9: 'Pickup Scheduled',
          15:'Booked'
    
        };
    
    
        return statusMappings[status] || '';
      }

      const downloadExcel = (items) => {
        const data = transformDataForExcel(items);
        const ws = XLSX.utils.json_to_sheet(data);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Invoice AWb');
        XLSX.writeFile(wb, 'Invoice AWb.xlsx');
      };
  return (
    <div>
         <Header/>
 <div class="page-wrapper pinned">
  <div class="main-container">
   <div className='app-container'>
  <div class="app-body">
  <div class="container-fluid"> 
<div class="mob_nav">
  <div class="row">
      <div class="col-7">
        <div class=" d-flex justify-content-start ">
          <a href="javascript:window.history.back();" class=""><i class="bi bi-arrow-left"></i></a>
          <h2>Invoices</h2>
        </div>
      </div>
      <div class="col">
      </div>
  </div>
</div>


<div class="row justify-content-between desk_nav">
    <div class="col-12 col-md-4 ">
      <div class="d-flex">
        <a href="javascript:window.history.back();" class="back_btn"><i class="bi bi-arrow-90deg-left"></i></a>
        <h2>Invoices</h2>
      </div>
    </div>
    <div class="col-12 col-md-4 mb-3">
      <div class="row justify-content-end">
          <div class="col-6 col-md-6">
          </div>
      </div>
    </div>
</div>



<div class="card data_table mb-3 mb_display_none">
  <div class="card-body">
    <div class="table-responsive" >
      <div class="shipm_tablew rounded-3">
        <table class="table align-top table-striped table-hover m-0 table-xl ">
          <thead>
            <tr>
              <th width="170px">Invoice No. #</th>
              <th width="200px">Customer</th>
              <th>Invoice Date</th>
              <th>Invoice Period</th>
              <th>Invoice Amount</th>
              <th>Actions</th>
            </tr>					
          </thead>
          <tbody>
          {state.map((sub)=>(
            <tr class="">
              <td>
                <h6><a href="#" class="text-primary">{sub.invoice_id}</a></h6>
              </td>
              <td>
              {sub.company_name?sub.company_name:sub.full_name?sub.full_name:""}
              </td>
              <td>
              {sub.invoice_date}
              </td>
              <td>
              {sub.invoice_period}
              </td>
              <td>
              ₹{sub.amount?sub.amount:0}
              </td>
              <td>
                <a type="button" class="btn btn-outline-secondary me-2" onClick={()=>next_step(sub)} target="_blank" >Print Invoice</a>
                <a type="button" class="btn btn-outline-secondary" onClick={(e)=>get_shipments(sub.invoice_id)} >Download Shipments Report</a>
              </td>
            </tr>
          ))}
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>

{/* <!-- Mobile View Order Data --> */}
<section class="desk_display_none">
  <div class="card  mb-3">
    <div class="card-body">
      <div class="row">
          <div class="col-6 mb-2">
            <h5>INV51</h5>
            <small>Invoice No. #</small>
          </div>
          <div class="col-6 mb-2">
            <h5>Invoice Amount</h5>
            ₹130
          </div>
          <div class="col-12 mb-2">
            <h5>Customer</h5>
            TESTING COMPANY
          </div>
          <div class="col-6 mb-2">
            <h5>Invoice Date</h5>
            2024-03-01  
          </div>
          <div class="col-6 mb-2">
            <h5>Invoice Period</h5>
            February,2024
          </div>
          <div class="col-6">
            <div class="d-grid">
              <a href="invoice_vendor.php" class="btn btn-primary btn-block" target="_blank">Print Invoice</a>
            </div>
          </div>
          <div class="col-6">
            <div class="d-grid">
              <a href="invoice_vendor.php" class="btn btn-danger btn-block">Download Shipments</a>
            </div>
          </div>
      </div>
    </div>
  </div>
  
</section>
</div></div>
<Footer/></div></div></div>

    </div>
  )
}

export default Customer_invoice
