import React, { useState, createRef, useEffect } from "react";

import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import Swal from 'sweetalert2'
import config from "../../config"

import { useLocation } from 'react-router-dom';
import { useNetworkState } from 'react-use';


const Verify_email = () => {
    const isOnline = useNetworkState();
    console.log("isONline  === " ,isOnline )
    let navigate = useNavigate()
    const location = useLocation();
 
    const [userState, setUserState] = useState({ full_name: "" });
    const [state, setState] = React.useState({ email: location.state.email,otp:"" , mobile_number:location.state.mobile_number })
    const initialTimeInSeconds = 120; // 2 minutes
    const [timeLeft, setTimeLeft] = useState(initialTimeInSeconds);
    const [timerActive, setTimerActive] = useState(true); // State to track whether the timer is active
    const Toast = Swal.mixin({
        toast: true,
        position: 'bottom-end',
        showConfirmButton: false,
        timer: 3000
    });

    useEffect(() => {
        let timerInterval;

        if (timerActive) {
            timerInterval = setInterval(() => {
                if (timeLeft > 0) {
                    setTimeLeft((prevTime) => prevTime - 1);
                } else {
                    setTimerActive(false); // Timer has reached 0, so deactivate it
                }
            }, 1000);
        }

        return () => {
            clearInterval(timerInterval);
        };
    }, [timeLeft, timerActive]);

    const formatTime = (seconds) => {
        const minutes = Math.floor(seconds / 60);
        const remainingSeconds = seconds % 60;
        return `${minutes}:${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}`;
    };
    const handleResendClick = () => {
        // Reset the timer and make it active again
        setTimeLeft(initialTimeInSeconds);
        setTimerActive(true);
        let full_api = config.apiUrl + `/user/otp_resend`;
        // let fullOtp = state.otp1+""+state.otp2+""+state.otp3+""+state.otp4+""+state.otp5+""+state.otp6
         let sendData = { email: state.email, otp: state.otp };
         // console.log(sendData);
         axios.post(full_api, sendData, { headers: config.headers }).then((res) => {
           // console.log(res);
           if (res.data.status == true) {
            Toast.fire({
                background:"#3659cd",
                type: 'success',
                title: res.data.message ,
                color:"white"
              });
              let sendState = { mobile_number:sendData.mobile_number};
              // console.log("sendState === " , sendState)
            
            //    navigate('/customer/verify_sms',{state:sendState})
           }else {
            Toast.fire({
                background:"#a61c09",
                type: 'success',
                title: res.data.message ,
                color:"white"
              });
           }
           
         }).catch((e) => {
           //setState({...state,isLoading: false});
 
           // toast.configure();
           // toast.error("Some thing went wrong");
           // // console.log("----error:   ", e);
         });
    };
    
    const handleChangeBroker = (event) => {
        setState({
                 ...state,
                 [event.target.name]: event.target.value,
               });
  
      }
  
      const axios_registration_api = (event) => {
        event.preventDefault();
        if (state.email != '') {
          let full_api = config.apiUrl + `/user/otp_verification`;
         // let fullOtp = state.otp1+""+state.otp2+""+state.otp3+""+state.otp4+""+state.otp5+""+state.otp6
          let sendData = { email: state.email, otp: state.otp , mobile_number:state.mobile_number };
        
          // console.log(sendData);
          axios.post(full_api, sendData, { headers: config.headers }).then((res) => {
          
        if(res.data.status==true){
            localStorage.setItem('ship_rocket_user', JSON.stringify(res.data.output));
            setUserState({full_name:res.data.output.full_name})
            config.UserId = res.data.output.customer_id
           Toast.fire({
               background:"#3659cd",
               type: 'success',
               title: res.data.message ,
               color:"white"
             });
             setTimeout(()=>{
              window.location.replace('/dashboard')
             },2000)
           }
           else{
            Toast.fire({
                background:"#a61c09",
                type: 'unsuccess',
                title: res.data.message ,
                color:"white"
              });
           }
            
          }).catch((e) => {
           
          });
        }
      };
  return (
    <div>
   
<section class="register_box_00">
  <div class="row justify-content-center ">
    <div class="col-xl-4 col-lg-5 col-sm-6 col-12 ">
      <form onSubmit={(e) => {
          e.preventDefault(); // Prevent the default form submission behavior
          axios_registration_api(); // Call your login function
        }} class="my-5">
        <div class="border rounded-2 p-4 mt-5 login_box">
          <div class="login-form">
            <div class="text-center login-logo">
              <a href="/login" class="mb-4 text-center">
                <img src="assets/images/logo_big.png" class="img-fluid " alt=""/>
                <h3 class="fw-bold">SkyShip</h3>
              </a>
            </div>
            <h5 class="fw-bold">Verify Email address</h5>
            <p >We have sent you a six-digit OTP on your email <strong>{state.email}</strong>. Please check your inbox or spam folder and enter it below.</p>
            <small> Didn't receive OTP? {timerActive ? (
                                         <span id="resendTimer">Resend OTP in {formatTime(timeLeft)} </span>
                                     ) : (
                                         <button onClick={handleResendClick} className="btn btn-primary">Resend OTP</button>
                                     )}</small>
            {/* <p>Didn't get OTP on your email. <a onClick={handleResendClick} class="text-primary">Resend again</a></p> */}
            <div class="mb-5">
              <label class="form-label">Verify Email</label>
              <input type="text" class="form-control" placeholder="Enter OTP sent your email address" name="otp" onChange={(e)=>handleChangeBroker(e)}/>
            </div>
            <div class="d-grid py-3 mt-4">
              <button type="submit" class="btn btn-lg btn-primary">
                Verify Email
              </button>
            </div>
            <div class="text-center pt-4">
              <a href="/login" class="text-blue text-decoration-underline ms-2">
                Retrun to login</a>
            </div>
          </div>
        </div>
      </form>

      <div class="">
        <ul class="nav">
            <li class="nav-item">
              <a class="nav-link" href="terms" target="_blank">Terms &amp; Condition</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="policy" target="_blank">Privacy Policy</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="help" target="_blank">Help</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="contact" target="_blank">Contact</a>
            </li>
        </ul>
      </div>
    </div>
  </div>

</section>

      
    </div>
  )
}

export default Verify_email
