import React from 'react'
import Settings_nav from './Settings_nav'

import Header from './Header'

import axios from 'axios'



import Swal from 'sweetalert2'


import config from "../../config"
import Footer from './Footer'


const Change_password = () => {
    const[state,setstate]=React.useState({current_password:"" , new_password:"" , confirm_password:""})
   
    
    const handleChange =(e)=>{
      setstate({...state,[e.target.name]: e.target.value})
    }

    const update =()=>{
        let full_api = config.apiUrl + `/user/update_password`;
        let sendData = { state };

        if (state.current_password!="" && state.new_password!="" && state.confirm_password!="") {
         console.log("bb", sendData);
        axios.post(full_api, sendData, { headers: config.headers }).then((res) => {
            if(res.data.status==true){
                setstate({...state , current_password:"" , new_password:"" , confirm_password:""})
                const Toast = Swal.mixin({
                    toast: true,
                    position: 'bottom-end',
                    showConfirmButton: false,
                    timer: 2000
                  })
                Toast.fire({
                    background: "#3659cd",
                    type: 'success',
                    title: res.data.message,
                    color: "white"
                });
            }
            else{
                const Toast = Swal.mixin({
                    toast: true,
                    position: 'bottom-end',
                    showConfirmButton: false,
                    timer: 2000
                  })
                Toast.fire({
                    background: "#a61c09",
                    type: 'error',
                    title: res.data.message,
                    color: "white"
                });
            }
           
        }).catch((e) => {
            const Toast = Swal.mixin({
                toast: true,
                position: 'bottom-end',
                showConfirmButton: false,
                timer: 2000
              })
            Toast.fire({
                background: "#a61c09",
                type: 'error',
                title: "Something Went Wrong",
                color: "white"
            });

        });
    }else{
        const Toast = Swal.mixin({
            toast: true,
            position: 'bottom-end',
            showConfirmButton: false,
            timer: 2000
          })
        Toast.fire({
            background: "#a61c09",
            type: 'error',
            title: "Please Fill all details",
            color: "white"
        });

    }
    }
  return (
    <div>
       <Header/>
 <div class="page-wrapper pinned">
  <div class="main-container">
   <div className='app-container'>
  <div class="app-body">
  <div class="container-fluid">

<div class="mob_nav">
  <div class=" d-flex justify-content-start ">
    <a href="javascript:window.history.back();" class=""><i class="bi bi-arrow-left"></i></a>
    <h2>Customer KYC</h2>
  </div>
</div>


<div class="row justify-content-between">
    <div class="col-12 col-md-4 desk_nav">
      <div class="d-flex">
        <a href="javascript:window.history.back();" class="back_btn"><i class="bi bi-arrow-90deg-left"></i></a>
        <h2>Customer KYC</h2>
      </div>
    </div>
</div>



<div class="row">
    <div class="col-12 col-md-3 left_sub_menu_bx mb_display_none">
      <Settings_nav value={5}/>
    </div>
    <div class="col-12 col-md-9">
        <div class="card border-0 shadow-sm">
            <div class="card-body">
            <div class="row">
                    <div class="col-12 col-md-6 mb-3">
                        <label for="" class="form-label">Current Password <span class="text-danger">*</span></label>
                        <input type="password" class="form-control" id="text"  name="current_password" placeholder="Enter Current Password" onChange={(e)=>handleChange(e)}/>
                    </div>
                    <div class="col-12 col-md-6 mb-3">
                        
                    </div>
                    <div class="col-12 col-md-6 mb-3">
                        <label for="" class="form-label">New Password <span class="text-danger">*</span></label>
                        <input type="password" class="form-control" id="text" name="new_password" placeholder="Enter New Password" onChange={(e)=>handleChange(e)}/>
                    </div>
                    <div class="col-12 col-md-6 mb-3">
                        <label for="" class="form-label">Confirm New Password <span class="text-danger">*</span></label>
                        <input type="password" class="form-control" id="text" name="confirm_password" placeholder="Confirm New Password" onChange={(e)=>handleChange(e)} />
                    </div>
            </div>

            <a honClick={(e)=>update(e)} class="btn btn-primary">Update</a>
            </div>
        </div>
    </div>
</div>



  </div>
  </div>
  <Footer/></div></div></div>
    </div>
  )
}

export default Change_password
