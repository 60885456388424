import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

const Create_order_nav_b2b = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState(0);  // Initialize with default tab (0 for Domestic Order)
 

  // Function to handle tab changes
  const handleChangeTab = (tabValue) => {
    console.log("tabValue == " , tabValue)
      if (tabValue === 2) {
        window.location.replace('/bulk_order_upload');
      }
      else if(tabValue === 3){
        window.location.replace('/international_order_import');
      } else {
        window.location.replace(`/create_order_1?value=${tabValue}`);
          setActiveTab(tabValue);
        
      }
  };

  // Use effect to set active tab based on the URL query parameter
  useEffect(() => {
      const searchParams = new URLSearchParams(location.search);
      const valueParam = searchParams.get('value');

      if (valueParam !== null) {
          setActiveTab(parseInt(valueParam, 10));
      }
      else if (location.pathname === '/international_order_import') {
        setActiveTab(3);  // Bulk Order Tab
    }
       else if (location.pathname === '/bulk_order_upload') {
          setActiveTab(2);  // Bulk Order Tab
      } else {
          setActiveTab(0);  // Default to Domestic Order Tab
      }
  }, [location]);
  return (
    <div>
      <ul class="nav nav-pills mt-3">
      <li className="nav-item">
                                <a 
                                    className={activeTab === 0 ? 'nav-link active' : 'nav-link'} 
                                    style={{ cursor: 'pointer' }} 
                                    onClick={() => handleChangeTab(0)}>
                                    Domestic Order
                                </a>
                            </li>
                            {/* <li className="nav-item">
                                <a 
                                    className={activeTab === 1 ? 'nav-link active' : 'nav-link'} 
                                    style={{ cursor: 'pointer' }} 
                                    onClick={() => handleChangeTab(1)}>
                                    International Order
                                </a>
                            </li>
                            <li className="nav-item">
                                <a 
                                    className={activeTab === 2 ? 'nav-link active' : 'nav-link'} 
                                    style={{ cursor: 'pointer' }} 
                                    onClick={() => handleChangeTab(2)}>
                                    Bulk Domestic Order
                                </a>
                            </li> */}

                            {/* <li className="nav-item">
                                <a 
                                    className={activeTab === 3 ? 'nav-link active' : 'nav-link'} 
                                    style={{ cursor: 'pointer' }} 
                                    onClick={() => handleChangeTab(3)}>
                                    Bulk International Order
                                </a>
                            </li> */}
</ul>
    </div>
  )
}

export default Create_order_nav_b2b
