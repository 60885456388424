import React from 'react'

import axios from 'axios'

import config from "../../config"


const Settings_nav = (props) => {
    let value = props.value
    console.log("value == " , value)
    let userDetail = localStorage.getItem('ship_rocket_user');
    // console.log("userDetail====shipments====", config.UserId);
    let userData = {};
    if (userDetail != null) {
      userData = JSON.parse(userDetail)
    }
   
    const[userstate , setUserState] = React.useState({})
  

    React.useEffect(()=>{
        user()
       
    },[])
    const user=()=>{
        let dataToSend = {
    };
     let url = config.apiUrl + '/user/find_customer_detail';
       axios.post(url, dataToSend, { headers: config.headers })
      .then((res) => {
        console.log("res ===" , res.data.output)
        setUserState(res.data.output)
          })
          .catch((error) => {
              console.log(error);
          });    
   }
  return (
    <div>
      
<h3><i class="bi bi-gear"></i> Settings</h3>
<ul class="nav  flex-column ps-4">
    <li class="nav-item">
        <a class={value==1?"nav-link active text-decoration-none":"nav-link text-decoration-none"} href="/profile_update">Profile</a>
    </li>
    <li class="nav-item">
        <a class={value==2?"nav-link active text-decoration-none":"nav-link text-decoration-none"} href="/bank_details">Bank Detail 
        {userstate.aadhar_kyc==true?<span class="text-success"><i class="bi bi-patch-check"></i></span>: <span class="text-danger"><i class="bi bi-exclamation-circle"></i></span>}</a>
    </li>
    <li class="nav-item">
        <a class={value==3?"nav-link active text-decoration-none":"nav-link text-decoration-none"} href="/user_kyc">KYC Detail {userstate.aadhar_kyc==true?<span class="text-success"><i class="bi bi-patch-check"></i></span>: <span class="text-danger"><i class="bi bi-exclamation-circle"></i></span>}</a>
    </li>
    <li class="nav-item">
        <a class={value==4?"nav-link active text-decoration-none":"nav-link text-decoration-none"} href="/billing_address">Billing Detail</a>
    </li>
    {userData.user_type==2?"":
    <li class="nav-item">
        <a class={value==5?"nav-link active text-decoration-none":"nav-link text-decoration-none"} href="/change_password">Change Password</a>
    </li>
}
</ul>
<h3><i class="bi bi-gear"></i> Other Settings</h3>
<ul class="nav  flex-column ps-4">
    <li class="nav-item">
        <a class={value==6?"nav-link active text-decoration-none":"nav-link text-decoration-none"} href="/email_notification">Email Notification</a>
    </li>
    {/* <li class="nav-item">
        <a class="nav-link" href="sms_notification.php">SMS Notification</a>
    </li> */}
    <li class="nav-item">
        <a class={value==7?"nav-link active text-decoration-none":"nav-link text-decoration-none"} href="/whatsapp_notification">Whatsapp Notification</a>
    </li>
    <li class="nav-item">
        <a class={value==8?"nav-link active text-decoration-none":"nav-link text-decoration-none"} href="/system_prefrences">System Prefrences</a>
    </li>
</ul> 
    </div>
  )
}

export default Settings_nav
