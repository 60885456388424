import React from 'react'
import { useLocation, useParams } from 'react-router-dom';

import config from "../../config"
import axios from 'axios';
import ReactLoading from 'react-loading';
import Swal from 'sweetalert2';
import Moment from 'react-moment';

const Tracking = () => {
  let tracking_number = useParams()
  // console.log("tracking_number === " , tracking_number)
  let userDetail = localStorage.getItem('ship_rocket_user');
  // console.log("userDetail====", config.UserId);
  let userData = {};
  if (userDetail != null) {
    userData = JSON.parse(userDetail)
  }
 const [trackingstate,setTrackingState] = React.useState({track:{},isLoading:true})
 const [state, setState] = React.useState({ shipmentDetail: {sender:{},receiver:{},items:[] }, isLoading: true,detailFound:false,parcel_count:0  })
 const[dimensionState,setdimensionState] = React.useState({companyDetail:[]})



const dimension_detail = () =>{
  let full_api = config.apiUrl + `/user/get_company_info_api`;
  let sendData = {};

  axios.post(full_api, sendData, { headers: config.headers }).then(res => {
    setdimensionState({...dimensionState,companyDetail:res.data.companyDetail});
    // console.log("rnmmmmm", res);
  }).catch((e) => {
    // toast.configure()
     //toast.error("Some thing went wrong")
    // console.log("----error:   ", e);
  })
}

  React.useEffect(()=>{
    shipment_tracking()
  
    dimension_detail()
  },[])
    const shipment_tracking = () =>{
      
       let full_api = config.apiUrl + `/shipment/shipment_tracking`;
      let sendData = {customer_id:userData.customer_id , trackingNumber:tracking_number.tracking_no};
      // console.log("sendData",sendData)
       
      axios.post(full_api, sendData, { headers: config.headers }).then(res => {
       if(res.data.status==200){
          setTrackingState({...trackingstate,track:res.data.output,isLoading:false})
          // console.log("res",trackingstate.track)
          setState({ ...state, shipmentDetail: res.data.ship_detail, isLoading:false})
        
       }
       else{
        setTrackingState({...trackingstate,track:res.data.output,isLoading:false})
        // console.log("res",trackingstate.track)
        setState({ ...state, shipmentDetail: res.data.ship_detail, isLoading:false})
       }
      
       
      }).catch((e) => {
        // toast.configure()
         //toast.error("Some thing went wrong")
        // console.log("----error:   ", e);
      })
    }
    
   
  
  return (
    <div>
     
    
 
  {trackingstate.track!='' && state.shipmentDetail!='' ?
<div class="">


{trackingstate.isLoading==false?



state.shipmentDetail.map((sub)=>(




<section class="tracking_boxs">
   <div class="container">
      <div class="row justify-content-center">
         <div class="col-12 col-md-6 text-center">
            
            <div class="d-flex justify-content-between">
              <div class="text-start mb-3">
                <a href="javascript:window.history.back();" class=""><i class="bi bi-arrow-90deg-left"></i> Back</a>
              </div>
              <div>
                <small>Crated: {<Moment format="MMM DD YYYY hh:mm:ss a">
           { new Date(sub.created*1000)}
     </Moment>}</small>
              </div>
            </div>
            <div class="status_xo">
               <h1>{trackingstate.track.shipment_status}</h1>
            </div>
            <div class="pb-5">
               <small>Tracking ID</small>
               <h3>{sub.carrier_tracking_number}</h3>
            </div>
            
            <div class="d-flex justify-content-between">
              {/* <!-- EDD with first proiority. if there is no EDD value show Order place box. there will be one box among these two --> */}
               <div class="">
                  <h4><span class="text-danger title_edd" title="Estimated delivery date is subject to change based on external factors."></span></h4>
                  <small class="text-uppercase"></small>
               </div>
               {/* <!-- <div class="">
                  <h4>Nov 12, 2024 00:00</h4>
                  <small class="text-uppercase">Order placed at</small>
               </div> --> */}
               <div class=""><h4>{sub.service_provider}</h4><small>ORDER ID : {sub.order_number}</small></div>
            </div>
            <div class="card">
               <div class="card-body">
                  <div class="box-header">Tracking History</div>
                  <div class="container">
                     <div class="row">
                        <div class="col-md-12 col-lg-12">
                           <div id="tracking-pre"></div>
                           <div id="tracking">
                           {trackingstate.track.history.length>0?
                              <div class="tracking-list">
  {trackingstate.track.history.map((sub)=>(
                                 <div class="tracking-item">
                                    <div class="tracking-icon status-current ">
                                       <svg class="svg-inline--fa fa-circle fa-w-16" aria-hidden="true" data-prefix="fas" data-icon="circle" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" data-fa-i2svg="">
                                          <path fill="currentColor" d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8z"></path>
                                       </svg>
                                    </div>
                                    <div class="tracking-date"><i class="bi bi-check-circle-fill"></i></div>
                                    <div class="tracking-content">{sub.message}<span><Moment format="MMM DD YYYY hh:mm:ss a">
                 {(sub.time)}
     </Moment> | {sub.location}</span></div>
                                 </div>
  ))}
                               
                                 
                              </div>
                            :  <div className='' style={{marginLeft:"50px"}}><b>Tracking History Not Found</b></div>}  
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </div>
</section>



))
:<ReactLoading type="bubbles" color="#0d6efd" style={{ position: 'absolute', right: '45%', height: '20px', width: '10%' }} />}    
</div>
:
<div className='row' style={{marginTop:"200px"}}>
<div className='col-4'></div>
<div className='col-4'>
 <h2  style={{marginLeft:"50px"}}>Tracking Number Not Found </h2>
 
 <a   style={{marginLeft:"150px"}}href='/' className='btn btn-danger text-white'>Go Back to Home </a>
</div>
<div className='col-4'></div>

</div>}

 
    </div>
   

    


  )
}

export default Tracking



