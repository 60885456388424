import React from 'react'
import Header from './Header'
import {  useNavigate } from 'react-router-dom'
import axios from "axios";
import Swal from 'sweetalert2';
import Moment from 'react-moment';
import config from "../../config"
import { useNetworkState } from 'react-use';
import Footer from './Footer'

const Billing_cod_remittance = () => {
  let userDetail = localStorage.getItem('ship_rocket_user');
    // console.log("userDetail====", config.UserId);
    let userData = {};
    if (userDetail != null) {
      userData = JSON.parse(userDetail)
    }
   
    const isOnline = useNetworkState();
    console.log("isONline  === " ,isOnline )
    const[balancestate, setbalanceState ]= React.useState({add_balance:[], total_balance:[]})
    const[dimensionState,setdimensionState] = React.useState({companyDetail:[]})
   
    const[transaction  , settransaction] = React.useState([])
    const[codremitted , setcodremitted] = React.useState({last_cod_remitted:0 , next_cod_remitted:0 , remittance_due:0})

   React.useEffect(()=>{
    get_cod_wallet_balance()
    dimension_detail()
    cod_wallet_transactions()
   },[])

    const get_cod_wallet_balance=()=>{
     let dataToSend = {customer_id : userData.customer_id};
    
      // console.log("datatoSend",dataToSend)
      let url = config.apiUrl + '/user/get_cod_wallet_balance';
      axios.post(url, dataToSend, { headers: config.headers })
          .then((res) => {
            // console.log("responseJson => ", res);
            if(res.data.status==true){
        setbalanceState({add_balance:res.data.added_wallet_transactions, total_balance:res.data.find_customer_wallet})
     }
    else{
        const Toast = Swal.mixin({
            toast: true,
            position: 'bottom-end',
            showConfirmButton: false,
            timer: 3000
          })
          Toast.fire({
            background:"#a61c09",
            type: 'Unsuccess',
            title: "Something Went Wrong !",
            color:"white"
          });
    } })
        
          .catch((error) => {
            const Toast = Swal.mixin({
                toast: true,
                position: 'bottom-end',
                showConfirmButton: false,
                timer: 3000
              })
              Toast.fire({
                background:"#a61c09",
                type: 'Unsuccess',
                title: "Something Went Wrong !",
                color:"white"
              });
              // console.log(error);
          });    
    
    }

   
      
    const dimension_detail = () =>{
        let full_api = config.apiUrl + `/user/get_company_info_api`;
        let sendData = {};
      
        axios.post(full_api, sendData, { headers: config.headers }).then(res => {
          setdimensionState({...dimensionState,companyDetail:res.data.companyDetail});
          // console.log("rnmmmmm", res);
        }).catch((e) => {
          // toast.configure()
           //toast.error("Some thing went wrong")
          // console.log("----error:   ", e);
        })
      }

      const cod_wallet_transactions=()=>{
        let dataToSend = {customer_id : userData.customer_id};
       
         // console.log("datatoSend",dataToSend)
         let url = config.apiUrl + '/shipment/get_cod_wallet_transactions';
         axios.post(url, dataToSend, { headers: config.headers })
             .then((res) => {
               // console.log("responseJson => ", res);
               if(res.data.status==true){
                settransaction(res.data.output)
                setcodremitted({last_cod_remitted: res.data.last_cod_remitted, next_cod_remitted:res.data.next_cod_remitted , remittance_due:res.data.due_remittance})
        }
       else{
           const Toast = Swal.mixin({
               toast: true,
               position: 'bottom-end',
               showConfirmButton: false,
               timer: 3000
             })
             Toast.fire({
               background:"#a61c09",
               type: 'Unsuccess',
               title: "Something Went Wrong !",
               color:"white"
             });
       } })
           
             .catch((error) => {
               const Toast = Swal.mixin({
                   toast: true,
                   position: 'bottom-end',
                   showConfirmButton: false,
                   timer: 3000
                 })
                 Toast.fire({
                   background:"#a61c09",
                   type: 'Unsuccess',
                   title: "Something Went Wrong !",
                   color:"white"
                 });
                 // console.log(error);
             });    
       
       }
     
     
  return (
    <div>
     <Header/>
 <div class="page-wrapper pinned">
  <div class="main-container">
   <div className='app-container'>
  <div class="app-body">
  <div class="container-fluid"> 
 

<div class="mob_nav">
  <div class="row">
      <div class="col-7">
        <div class=" d-flex justify-content-start ">
          <a href="javascript:window.history.back();" class=""><i class="bi bi-arrow-left"></i></a>
          <h2>COD Remittance</h2>
        </div>
      </div>
      <div class="col">
      </div>
  </div>
</div>


<div class="row justify-content-between desk_nav">
    <div class="col-12 col-md-4 ">
      <div class="d-flex">
        <a href="javascript:window.history.back();" class="back_btn"><i class="bi bi-arrow-90deg-left"></i></a>
        <h2>COD Remittance</h2>
      </div>
    </div>
    <div class="col-12 col-md-4 mb-3">
    </div>
</div>    
<section class="mb-3">
          <div class="">
            <div class="card border-0">
              <div class="card-body">
                <div class="row states stboxbil py-3">
                    <div class="col-12 col-md-2 mb-3">
                        <div class="stbox text-center py-4 border rounded  ">
                        {balancestate.total_balance.map((sub)=>( <h5>&#8377;{parseFloat(sub.cod_wallet_balance).toFixed(2)}</h5>))}
                            <small>Total COD Amount <a href="#" data-bs-toggle="popover" data-bs-placement="top" data-bs-trigger="hover" data-bs-content="Amount to be remitted in next cycle" class="text-muted"><i class="fa fa-question-circle-o" aria-hidden="true"></i></a></small>
                        </div>
                    </div>
                    <div class="col-12 col-md-2 mb-3">
                        <div class="stbox text-center py-4 border rounded ">
                            <h5>&#8377;0.00</h5>
                            <small>Remittance Till Date</small>
                        </div>
                    </div>
                    <div class="col-12 col-md-2 mb-3">
                        <div class="stbox text-center py-4 border rounded ">
                            <h5>&#8377;{codremitted.remittance_due.toFixed(2)}</h5>
                            <small>Remittance Due</small>
                        </div>
                    </div>
                    <div class="col-12 col-md-2 mb-3">
                        <div class="stbox text-center py-4 border rounded ">
                       { codremitted.last_cod_remitted!=0 ?  <h5>&#8377;{codremitted.last_cod_remitted.toFixed(2)}</h5>:<h5>----</h5>}
                            <small>Last Remittance</small>
                        </div>
                    </div>
                    <div class="col-12 col-md-2 mb-3">
                        <div class="stbox text-center py-4 border rounded ">
                        {codremitted.next_cod_remitted!=0?  <h5>&#8377;{codremitted.next_cod_remitted.toFixed(2)}</h5>:<h5>----</h5>}
                            <small>Next Remittance Due</small>
                        </div>
                    </div>
                   
                  
                </div>
              </div>
            </div>     
          </div>
        </section>

        <div class="card data_table mb-3 ">
  <div class="card-body">
    <div class="table-responsive" >
      <div class="shipm_tablew rounded-3">
        <table class="table align-top table-striped table-hover m-0 table-xl ">
          <thead>
                      <tr>
                        <th>Date/Time</th>
                        <th>Particulars</th>
                      
                        <th>Shipment</th>
                        <th>Payout Id</th>
                        <th>Transaction</th>
                         <th>Amount</th>
                        <th>Status</th>
                         </tr>
                    </thead>
                    <tbody>
                      {transaction.map((sub)=>(
                      <tr>
                      <td>{sub.date?sub.date:<Moment format='DD-MM-YYYY'>{new Date(sub.created*1000)}</Moment>}</td>
                      <td>{sub.payment_method}</td>
                    
                      <td>{sub.shipment_id}</td>
                      <td> <a className='text-decoration-none' href={`/transaction_detail/${sub.payout_id}`} style={{cursor:"pointer"}}>{sub.payout_id?sub.payout_id:""}</a> </td>
                      <td>{sub.transaction_id}</td>
                     

                      <td>{sub.amount}</td>
                      <td>{sub.status==2?<span class="badge rounded-pill bg-success fw-400">Success</span>:<span class="badge rounded-pill bg-success fw-400">Success</span>}</td>

                      </tr>
                      ))}
                     
                      {/* <tr>
                        <td colspan="12" class="text-center">
                          <p>Your remittance is on its way</p>
                          <small>We release COD remittance 3 times in a week and on the 8th day from the date of delivery.</small>
                        </td>
                      </tr> */}
                    </tbody>
                </table>  
               
      </div>
    </div>
  </div>
</div>
</div></div>
<Footer/></div></div></div>
    </div>
        
    

 
   
    
  )
}

export default Billing_cod_remittance
