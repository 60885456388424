import React, { useState, useEffect, useRef }  from 'react'
import Header from './Header'
import Footer from './Footer'


import axios from "axios";

import config from "../../config"

import { useNetworkState } from 'react-use';


import Chart from 'chart.js/auto';

import moment from 'moment';
import Moment from 'react-moment';

import ReactLoading from 'react-loading';
import $ from 'jquery'; 
import 'daterangepicker/daterangepicker.css'; // Import daterangepicker CSS
import 'daterangepicker';
const Dashboard = () => {
   
  const isOnline = useNetworkState();
  console.log("isONline  === " ,isOnline )
  const chartRef = useRef(null);
  const chartRefone = useRef(null);
  const chartRefndr = useRef(null);
  const chartRefrto = useRef(null);
  const chartRefdelivered = useRef(null);
  const [shippingstate,setshippingState] = React.useState([])
  const[announcementstate,setannouncementstate]= React.useState([])
 
  const[balancestate,setbalanceState]= React.useState({total_balance:[]})
  const [selectedRange, setSelectedRange] = React.useState([new Date(), new Date()]);
  console.log("selectedRange == " , selectedRange)
  const [financedate, setfinancedate] = React.useState({
    finance_start_date: formatDate(new Date()),
    finance_end_date: formatDate(new Date())
  });
  const[ndrstate , setndrstate] = React.useState({ndr_count:0 , action_required_ndr:0 , action_requested_ndr:0})
  const [statusBookingData, setStatusBookingData] = useState([]);
  const [graphData, setGraphData] = useState(null);

  const[financeloader , setfinanceloader] = React.useState(false)
  const [sevendayrecharges,setSevenDayRecharges] = React.useState({last7DaysRecharges:[] ,totalRechargesLast7Days:0 , totalRechargesToday:0 });
  const [topProducts, setTopProducts] = useState([]);
  const [ndrZoneData, setNdrZoneData] = useState([]);
  const [rtoZoneData, setRtoZoneData] = useState([]);
  const [deliveredZoneData, setDeliveredZoneData] = useState([]);
  function formatDate(date) {
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear();
    return `${year}-${month}-${day}`;
  }
  console.log("financedate == " , financedate)

 
  const handleDateChange = (start, end) => {
    // Update the state with the new date range
    setSelectedRange([new Date(start), new Date(end)]);
};

const handleFinanceDate =(start, end)=>{
    setfinancedate({...financedate , finance_start_date:moment(start).format('YYYY-MM-DD'),
        finance_end_date: moment(end).format('YYYY-MM-DD')})
  }
useEffect(() => {
    // Initialize date range picker with today's date as the default range
    $('.custom-daterange').daterangepicker({
        opens: 'right',
        startDate: moment().startOf('day'),  // Set start date to today's date
        endDate: moment().startOf('day'),    // Set end date to today's date
        autoUpdateInput: true,
        locale: {
            format: 'YYYY-MM-DD'             // Display format
        }
    }, (start, end) => {
        handleDateChange(start, end); 
        handleFinanceDate(start, end) 
              // Update selected range when a new range is chosen
    });

    // Set initial value in the input to show today's date
    $('.custom-daterange').val(`${moment().format('YYYY-MM-DD')} to ${moment().format('YYYY-MM-DD')}`);
}, []);
  

const [shipstate, setshipstate] = React.useState({
  today_shipments: 0,
  today_failed: 0,
  pickup_pending: 0,
  today_in_transit:0,
  delivered_shipments:0,
  ndr_shipments:0,
  rto_shipments:0,
  rto_shipments_delivered:0,
  month_shipments: 0,
  total_shipments: 0,
  today_channel_orders: 0,
  order_summary: [],
  courier_overview: [],
  zone_overview: [],
  zone_total_overview:{},

  total_overview:{},
  performanceOverview:[],
  today_shipments_stats:0,
  today_failed_stats:0,
  today_in_transit_stats:0,
  today_delivered_shipments_stats:0,
  today_ndr_shipments_stats:0,
  today_rto_shipments_stats:0,
  today_rto_intransit_shipments_stats:0,
  today_rto_delivered_shipments_stats:0,
  today_pickup_pending_stats:0,
  today_total_shipments_stats:0,
  today_channel_orders_stats:0,
  today_picked_up:0
});
const[sevendaystate , setsevendaystate]= React.useState({last7DaysShipments: [] , totalShipmentsCount:0})
console.log("sevendaystate == " , sevendaystate)
const [topCities, setTopCities] = useState([]);
const [topStates, setTopStates] = useState([]);
const [filterType, setFilterType] = React.useState('all');

const startDate = new Date();
startDate.setDate(startDate.getDate() - 7);


let userDetail = localStorage.getItem('ship_rocket_user');
  // console.log("userDetail====", config.UserId);
  let userData = {};
  if (userDetail != null) {
    userData = JSON.parse(userDetail)
  }
React.useEffect(()=>{
 
  get_wallet_balance()
  get_full_dashboard_data()
  list()
  // get_dashboard_data()
  fetchDashboardData()

},[])
// React.useEffect(()=>{
//   get_dashboard_data()
// },[date])

  const get_wallet_balance=()=>{
      let dataToSend = {customer_id : userData.customer_id};
     
       // console.log("datatoSend",dataToSend)
       let url = config.apiUrl + '/user/get_customer_wallet';
       axios.post(url, dataToSend, { headers: config.headers })
           .then((res) => {
             // console.log("responseJson => ", res);
             if(res.data.status==true){
         setbalanceState({total_balance:res.data.find_customer_wallet})
      }
     else{
        //  const Toast = Swal.mixin({
        //      toast: true,
        //      position: 'bottom-end',
        //      showConfirmButton: false,
        //      timer: 3000
        //    })
        //    Toast.fire({
        //      background:"rgb(231, 91, 75)",
        //      type: 'Unsuccess',
        //      title: "Something Went Wrong !",
        //      color:"white"
        //    });
     } })
         
           .catch((error) => {
            //  const Toast = Swal.mixin({
            //      toast: true,
            //      position: 'bottom-end',
            //      showConfirmButton: false,
            //      timer: 3000
            //    })
            //    Toast.fire({
            //      background:"rgb(231, 91, 75)",
            //      type: 'Unsuccess',
            //      title: "Something Went Wrong !",
            //      color:"white"
            //    });
               // console.log(error);
           });    
     
     }

  
   
     const get_ndr_shipment=()=>{
      let dataToSend = {customer_id : userData.customer_id};
     
       // console.log("datatoSend",dataToSend)
       let url = config.apiUrl + '/shipment/get_ndr_shipments';
       axios.post(url, dataToSend, { headers: config.headers })
           .then((res) => {
             // console.log("responseJson => ", res);
             setndrstate({ndr_count:res.data.ndr_count , action_required_ndr:res.data.action_required_ndr , action_requested_ndr:res.data.action_requested_ndr})
             })
         
           .catch((error) => {
           
           });    
     
     }

    
   
    const fetchDashboardData = async () => {
      const today = moment().format('YYYY-MM-DD');
      console.log("today date === ", today);
      const sevenDaysAgo = moment().subtract(7, 'days').format('YYYY-MM-DD');
      console.log("seven days ago date === ", sevenDaysAgo);
 try {
        const url = `${config.apiUrl}/home/graph_dashboard_data`;
        const dataToSend = {
          customer_id: userData.customer_id,
          date: [sevenDaysAgo, today],
        };

        const response = await axios.post(url, dataToSend, { headers: config.headers });
        console.log("repone === " , response)

        if (response.data.status === true) {
          const shipments = response.data.output;
          const transactions = response.data.transactions

          // Calculate shipments count for each day in the last 7 days
          const last7DaysShipments = [];
          let totalShipmentsCount = 0
          for (let i = 6; i >= 0; i--) {
            const date = moment(today).subtract(i, 'days').format('YYYY-MM-DD');
            const count = shipments.filter(shipment => shipment.shipment_created_date === date && shipment.status !== 0 ).length;
            last7DaysShipments.push({
              date: date,
              count: count,
            });
            totalShipmentsCount += count;
          }
          setsevendaystate({last7DaysShipments:last7DaysShipments ,totalShipmentsCount:totalShipmentsCount })
          const last7DaysShipmentsStatus = [];

          for (let i = 6; i >= 0; i--) {
            const date = moment(today).subtract(i, 'days').format('YYYY-MM-DD');
            const shipmentsForDate = shipments.filter(shipment => (shipment.shipment_created_date) === date);
            last7DaysShipmentsStatus.push({
              date: date,
              shipments: shipmentsForDate,
            });
          }
      
          const statusCounts = {
            "Pick Up Awaited": 0,
            "Picked Up": 0,
            "In Transit": 0,
            "Out For Delivery": 0,
            "Delivered": 0,
            "Cancelled": 0,
            "Failed": 0,
            "NDR": 0,
            "Pickup Scheduled": 0,
            "RTO In Transit": 0,
            "RTO Delivered": 0,
            "Booked":0
          };
      
          last7DaysShipmentsStatus.forEach(day => {
            day.shipments.forEach(shipment => {
              switch (shipment.status) {
                case 15:
                  statusCounts["Booked"]++;
                  break;
                case 1:
                  statusCounts["Pick Up Awaited"]++;
                  break;
                case 2:
                  statusCounts["Picked Up"]++;
                  break;
                case 3:
                  statusCounts["In Transit"]++;
                  break;
                case 4:
                  statusCounts["Out For Delivery"]++;
                  break;
                case 5:
                  statusCounts["Delivered"]++;
                  break;
                case 6:
                  statusCounts["Cancelled"]++;
                  break;
                case 7:
                  statusCounts["Failed"]++;
                  break;
                case 8:
                  statusCounts["NDR"]++;
                  break;
                case 9:
                  statusCounts["Pickup Scheduled"]++;
                  break;
                case 19:
                  statusCounts["RTO In Transit"]++;
                  break;
                  case 16:
                  statusCounts["RTO Delivered"]++;
                  break;
                default:
                  break;
              }
            });
          });
      
          const data = Object.keys(statusCounts).map(status => ({
            status: status,
            totalCount: statusCounts[status]
          }));
      
          setStatusBookingData(data);
          const last7DaysRecharges = [];
          let totalRechargesLast7Days = 0;
          let totalRechargesToday = 0;
          for (let i = 6; i >= 0; i--) {
            const date = moment(today).subtract(i, 'days').format('YYYY-MM-DD');
            const rechargesForDate = transactions.filter(transaction => moment.unix(transaction.created).format('YYYY-MM-DD') === date);
            const totalAmount = rechargesForDate.reduce((sum, transaction) => sum + transaction.amount, 0);
            last7DaysRecharges.push({
              date: date,
              amount: totalAmount,
            });
            totalRechargesLast7Days += totalAmount;
            if (date === today) {
              totalRechargesToday = totalAmount;
            }
          }
          setSevenDayRecharges({last7DaysRecharges:last7DaysRecharges ,totalRechargesLast7Days:totalRechargesLast7Days , totalRechargesToday:totalRechargesToday });
         
   
        } else {
          console.error('Error fetching dashboard data:', response.data.message);
        }
      } catch (error) {
        console.error('Error fetching dashboard data:', error);
        // Handle error
      }
    };
   
            const get_full_dashboard_data=()=>{
              let dataToSend = {customer_id : userData.customer_id , date:selectedRange};
             
               // console.log("datatoSend",dataToSend)
               let url = config.apiUrl + '/home/all_dashboard_data';
               axios.post(url, dataToSend, { headers: config.headers })
                   .then((res) => {
                   console.log("responseJson => ", res);
                   if(res.data.status==true){
                      const shipments = res.data.output;
                      const today = new Date().toISOString().split('T')[0];
                      const startOfMonth = new Date(new Date().getFullYear(), new Date().getMonth(), 1).toISOString().split('T')[0];
            
                      const today_shipments = shipments.filter(shipment => shipment.status !== 0 && shipment.status !== 7).length;
                      const today_failed = shipments.filter(shipment => shipment.shipment_created_date === today && shipment.status === 1).length;
                      const today_in_transit = shipments.filter(shipment =>  shipment.status === 3).length;
                      const delivered_shipments = shipments.filter(shipment =>  shipment.status === 5).length;
                      const ndr_shipments = shipments.filter(shipment =>  shipment.status === 8).length;
                      const rto_shipments = shipments.filter(shipment =>  shipment.status === 19).length;
                      const rto_shipments_delivered = shipments.filter(shipment =>  shipment.status === 16).length;



                      const pickup_pending = shipments.filter(shipment => shipment.status === 1).length;
                      const month_shipments = shipments.filter(shipment => shipment.shipment_created_date >= startOfMonth && shipment.status !== 0 && shipment.status !== 7).length;
                      const total_shipments = shipments.filter(shipment => shipment.status !== 0 && shipment.status !== 7).length;
                      const today_channel_orders = shipments.filter(shipment => shipment.shipment_created_date === today && shipment.store_id !== "" && shipment.status !== 0 && shipment.status !== 7).length;

                      const today_shipments_stats = shipments.filter(shipment => shipment.shipment_created_date === today &&shipment.status !== 0 && shipment.status !== 7).length;
                      const today_failed_stats = shipments.filter(shipment => shipment.shipment_created_date === today && shipment.status === 1).length;
                      const today_in_transit_stats = shipments.filter(shipment => shipment.shipment_created_date === today && shipment.status === 3).length;
                      const today_delivered_shipments_stats = shipments.filter(shipment => shipment.shipment_created_date === today && shipment.status === 5).length;
                      const today_ndr_shipments_stats = shipments.filter(shipment => shipment.shipment_created_date === today && shipment.status === 8).length;
                      const today_rto_shipments_stats = shipments.filter(shipment => shipment.shipment_created_date === today && shipment.status === 10).length;
                      const today_rto_intransit_shipments_stats = shipments.filter(shipment => shipment.shipment_created_date === today && shipment.status === 19).length;
                      const today_rto_delivered_shipments_stats = shipments.filter(shipment => shipment.shipment_created_date === today && shipment.status === 19).length;


                      const today_pickup_pending_stats = shipments.filter(shipment => shipment.shipment_created_date === today && shipment.status === 1).length;
                     
                      const today_total_shipments_stats = shipments.filter(shipment => shipment.status !== 0 && shipment.status !== 7).length;
                      const today_channel_orders_stats = shipments.filter(shipment => shipment.shipment_created_date === today && shipment.store_id !== "" && shipment.status !== 0 && shipment.status !== 7).length;
                      const today_picked_up  = shipments.filter(shipment => shipment.shipment_created_date === today && shipment.store_id !== "" && shipment.status == 2 ).length;

                      const orderSummary = shipments.reduce((acc, shipment) => {
                          console.log("acc ==" , acc)
                          const carrierName = shipment.carrier_name;
                          if (shipment.status === 0 ||shipment.status === 7 ) {
                            return acc;
                          }
                          if (!acc[carrierName]) {
                            acc[carrierName] = { 
                              cod: 0, 
                              prepaid: 0, 
                              total: 0, 
                              codDelivered: 0, 
                              prepaidDelivered: 0 
                            };
                          }
                          acc[carrierName].total++;
                          if (shipment.payment_mode === "cod") {
                            acc[carrierName].cod++;
                            if (shipment.status === 5 ||shipment.status === 16 ) {
                              acc[carrierName].codDelivered++;
                            }
                          } else if (shipment.payment_mode === "prepaid") {
                            acc[carrierName].prepaid++;
                            if (shipment.status === 5 || shipment.status === 16) {
                              acc[carrierName].prepaidDelivered++;
                            }
                          }
                        
                          return acc;
                        }, {});
                        
              
                        
                      const orderSummaryArray = Object.keys(orderSummary).map((key, index) => ({
                        sno: index + 1,
                        carrier: key,
                        cod: orderSummary[key].cod,
                        prepaid: orderSummary[key].prepaid,
                        total: orderSummary[key].total,
                        codDelivered: orderSummary[key].codDelivered,
                        prepaidDelivered: orderSummary[key].prepaidDelivered
                      }));

                        const courierOverview = shipments.reduce((acc, shipment) => {
                          const carrierName = shipment.carrier_name;
                          if (shipment.status === 0 ||shipment.status === 7 ) {
                            return acc;
                          }
                          if (!acc[carrierName]) {
                            acc[carrierName] = { booked: 0, delivered: 0, pending: 0, in_transit:0, ndr:0, rto_intransit: 0 , rto_delivered:0 };
                          }
                          acc[carrierName].booked++;
                          if (shipment.status === 5) {
                            acc[carrierName].delivered++;
                          } else if (shipment.status === 19) {
                            acc[carrierName].rto_intransit++;
                          }else if (shipment.status === 16) {
                            acc[carrierName].rto_delivered++;
                          }
                          else if (shipment.status === 8) {
                            acc[carrierName].ndr++;
                          }
                          else if (shipment.status === 3) {
                            acc[carrierName].in_transit++;
                          } else {
                            acc[carrierName].pending++;
                          }
                          return acc;
                        }, {});
              
                        const courierOverviewArray = Object.keys(courierOverview).map((key, index) => ({
                          carrier: key,
                          booked: courierOverview[key].booked,
                          delivered: courierOverview[key].delivered,
                          deliveredPercentage: ((courierOverview[key].delivered / courierOverview[key].booked) * 100).toFixed(2),
                          pending: courierOverview[key].pending,
                          pendingPercentage: ((courierOverview[key].pending / courierOverview[key].booked) * 100).toFixed(2),
                          rto_intransit: courierOverview[key].rto_intransit,
                          rto_intransitPercentage: ((courierOverview[key].rto_intransit / courierOverview[key].booked) * 100).toFixed(2),
                          rto_delivered: courierOverview[key].rto_delivered,
                          rto_deliveredPercentage: ((courierOverview[key].rto_delivered / courierOverview[key].booked) * 100).toFixed(2),
                          in_transit:courierOverview[key].in_transit,
                          in_transitPercentage: ((courierOverview[key].in_transit / courierOverview[key].booked) * 100).toFixed(2),
                          ndr:courierOverview[key].ndr,
                          ndrPercentage: ((courierOverview[key].ndr / courierOverview[key].booked) * 100).toFixed(2),
                        }));
              
                        const totalOverview = courierOverviewArray.reduce((acc, curr) => {
                          acc.booked += curr.booked;
                          acc.delivered += curr.delivered;
                          acc.pending += curr.pending;
                          acc.in_transit += curr.in_transit;
                          acc.ndr += curr.ndr;
                          acc.rto_intransit += curr.rto_intransit;
                          acc.rto_delivered+=curr.rto_delivered
                          return acc;
                          }, { booked: 0, delivered: 0, pending: 0, in_transit:0, ndr:0, rto_intransit: 0 , rto_delivered:0 });
                        totalOverview.deliveredPercentage = ((totalOverview.delivered / totalOverview.booked) * 100).toFixed(2);
                        totalOverview.pendingPercentage = ((totalOverview.pending / totalOverview.booked) * 100).toFixed(2);
                        totalOverview.rto_intransitPercentage = ((totalOverview.rto_intransit / totalOverview.booked) * 100).toFixed(2);
                        totalOverview.rto_deliveredPercentage = ((totalOverview.rto_delivered / totalOverview.booked) * 100).toFixed(2);
                        totalOverview.in_transitPercentage = ((totalOverview.in_transit / totalOverview.booked) * 100).toFixed(2);
                        totalOverview.ndrPercentage = ((totalOverview.ndr / totalOverview.booked) * 100).toFixed(2);

                        const zoneOverview = shipments.reduce((acc, shipment) => {
                          const carrierName = shipment.zone_name;
                          if (shipment.status === 0 ||shipment.status === 7 ) {
                            return acc;
                          }
                          if (!acc[carrierName]) {
                            acc[carrierName] = { booked: 0, delivered: 0, pending: 0,in_transit:0,ndr:0, rto: 0 , rto_delivered:0 };
                          }
                          acc[carrierName].booked++;
                          if (shipment.status === 5) {
                            acc[carrierName].delivered++;
                          } else if (shipment.status === 19) {
                            acc[carrierName].rto++;
                          }else if (shipment.status === 16) {
                            acc[carrierName].rto_delivered++;
                          } else if (shipment.status === 8) {
                            acc[carrierName].ndr++;
                          }else if (shipment.status === 3) {
                            acc[carrierName].in_transit++;
                          } else {
                            acc[carrierName].pending++;
                          }
                          
                          return acc;
                        }, {});
              
                        const zoneOverviewArray = Object.keys(zoneOverview).map((key, index) => ({
                          zone_name: key,
                          booked: zoneOverview[key].booked,
                          delivered: zoneOverview[key].delivered,
                          deliveredPercentage: ((zoneOverview[key].delivered / zoneOverview[key].booked) * 100).toFixed(2),
                          pending: zoneOverview[key].pending,
                          pendingPercentage: ((zoneOverview[key].pending / zoneOverview[key].booked) * 100).toFixed(2),
                          rto: zoneOverview[key].rto,
                          rtoPercentage: ((zoneOverview[key].rto / zoneOverview[key].booked) * 100).toFixed(2),
                          rto_delivered:zoneOverview[key].rto_delivered,
                          rto_deliveredPercentage: ((zoneOverview[key].rto_delivered / zoneOverview[key].booked) * 100).toFixed(2),

                          in_transit: zoneOverview[key].in_transit,
                            in_transitPercentage: ((zoneOverview[key].in_transit / zoneOverview[key].booked) * 100).toFixed(2),
                            ndr: zoneOverview[key].ndr,
                            ndrPercentage: ((zoneOverview[key].ndr / zoneOverview[key].booked) * 100).toFixed(2),
                        }));
              
                        const zone_totalOverview = zoneOverviewArray.reduce((acc, curr) => {
                          acc.booked += curr.booked;
                          acc.delivered += curr.delivered;
                          acc.pending += curr.pending;
                          acc.in_transit += curr.in_transit;
                          acc.ndr += curr.ndr;
                          acc.rto += curr.rto;
                          acc.rto_delivered += curr.rto_delivered;

                          return acc;
                        }, { booked: 0, delivered: 0, pending: 0,in_transit:0 , ndr:0, rto: 0, rto_delivered:0 });
              
                        zone_totalOverview.deliveredPercentage = ((zone_totalOverview.delivered / zone_totalOverview.booked) * 100).toFixed(2);
                        zone_totalOverview.pendingPercentage = ((zone_totalOverview.pending / zone_totalOverview.booked) * 100).toFixed(2);
                        zone_totalOverview.rtoPercentage = ((zone_totalOverview.rto / zone_totalOverview.booked) * 100).toFixed(2);
                        zone_totalOverview.rto_deliveredPercentage = ((zone_totalOverview.rto_delivered / zone_totalOverview.booked) * 100).toFixed(2);

                        zone_totalOverview.ndrPercentage = ((zone_totalOverview.ndr / zone_totalOverview.booked) * 100).toFixed(2);
                        zone_totalOverview.in_transitPercentage = ((zone_totalOverview.in_transit / zone_totalOverview.booked) * 100).toFixed(2);
                       
                        const performanceOverviewData = calculatePerformanceOverview(shipments , selectedRange);
                       
                        const { cityCounts, stateCounts } = countShipmentsByLocation(shipments);
                        const totalShipments = shipments.length;
                        setTopCities(getTopLocations(cityCounts , totalShipments));
                        setTopStates(getTopLocations(stateCounts , totalShipments));
                      setshipstate({
                        today_shipments,
                        today_failed,
                        pickup_pending,
                        today_in_transit,
                        delivered_shipments,
                        ndr_shipments,
                        rto_shipments,
                        rto_shipments_delivered,
                        month_shipments,
                        total_shipments,
                        today_channel_orders,
                        order_summary: orderSummaryArray,
                        courier_overview: courierOverviewArray,
          total_overview: totalOverview,
          zone_overview: zoneOverviewArray,
          zone_total_overview: zone_totalOverview,
          performanceOverview:performanceOverviewData,
          today_shipments_stats,
          today_failed_stats,
          today_in_transit_stats,
          today_delivered_shipments_stats,
          today_ndr_shipments_stats,
          today_rto_shipments_stats,
          today_rto_intransit_shipments_stats,
          today_rto_delivered_shipments_stats,
          today_pickup_pending_stats,
          today_total_shipments_stats,
          today_channel_orders_stats,
          today_picked_up

                      });

                      generateTopRegionsGraphData(shipments)
                      const productMap = {};
                      const ndrData = {};
                      const rtoData = {};
                      const deliveredData = {};
      shipments.forEach((shipment) => {
      shipment.items.forEach((item) => {
        const { item_name, item_value } = item;

        if (!productMap[item_name]) {
          productMap[item_name] = { count: 0, totalValue: 0 };
        }

        productMap[item_name].count += 1;
        productMap[item_name].totalValue += item_value;
      });
      const zone = shipment.zone_name;
      if (shipment.status === 8) {
        ndrData[zone] = (ndrData[zone] || 0) + 1;
      } else if ([16, 19].includes(shipment.status)) {
        rtoData[zone] = (rtoData[zone] || 0) + 1;
      } else if (shipment.status === 5) {
        deliveredData[zone] = (deliveredData[zone] || 0) + 1;
      }

    });
    setNdrZoneData(
      Object.entries(ndrData).map(([zone, count]) => ({ zone, count }))
    );
    setRtoZoneData(
      Object.entries(rtoData).map(([zone, count]) => ({ zone, count }))
    );
    setDeliveredZoneData(
      Object.entries(deliveredData).map(([zone, count]) => ({ zone, count }))
    );


    // Convert the productMap to an array and sort by count
    const sortedProducts = Object.entries(productMap)
      .map(([item_name, { count, totalValue }]) => ({
        item_name,
        count,
        totalValue,
      }))
      .sort((a, b) => b.count - a.count)
      .slice(0, 10);

    setTopProducts(sortedProducts);

   
                   }
                    
           
              })
                 
                   .catch((error) => {
                    //  const Toast = Swal.mixin({
                    //      toast: true,
                    //      position: 'bottom-end',
                    //      showConfirmButton: false,
                    //      timer: 3000
                    //    })
                    //    Toast.fire({
                    //      background:"rgb(231, 91, 75)",
                    //      type: 'Unsuccess',
                    //      title: "Something Went Wrong !",
                    //      color:"white"
                    //    });
                       // console.log(error);
                   });    
             
             }

             const handleFilterChange = (e) => {
              setFilterType(e.target.value);
            };
          
            const filteredCourierOverview = shipstate.courier_overview.filter(shipment => {
              if (filterType === 'cod') {
                return shipment.payment_mode === 'cod';
              } else if (filterType === 'prepaid') {
                return shipment.payment_mode === 'prepaid';
              }
              return true; // 'all' or any other case
            });
          
            const calculatePerformanceOverview = (shipments, dateRange) => {
              const datesInRange = getDatesInRange(dateRange);
              const performanceData = datesInRange.map(date => {
                const shipmentsOnDate = shipments.filter(shipment => shipment.shipment_created_date === date && shipment.status != 0 && shipment.status != 7);
                const booked = shipmentsOnDate.length;
                const delivered = shipmentsOnDate.filter(shipment => shipment.status === 5).length;
                const pending = shipmentsOnDate.filter(shipment => shipment.status !== 5 && shipment.status !== 8).length;
                const rto = shipmentsOnDate.filter(shipment => shipment.status === 19).length;
                const rto_delivered = shipmentsOnDate.filter(shipment => shipment.status === 16).length;

                const ndr = shipmentsOnDate.filter(shipment => shipment.status === 8).length;
                  const in_transit = shipmentsOnDate.filter(shipment => shipment.status === 3).length;
                return {
                  date,
                  booked,
                  delivered,
                  deliveredPercentage: delivered!=0? ((delivered / booked) * 100).toFixed(2):"0.00",
                  pending,
                  pendingPercentage: pending!=0?((pending / booked) * 100).toFixed(2):'0.00',
                  rto,
                  rtoPercentage: rto!=0?((rto / booked) * 100).toFixed(2):'0.00',
                  ndr,
                  ndrPercentage: ndr!=0?((ndr / booked) * 100).toFixed(2):'0.00',
                  in_transit,
                  in_transitPercentage: in_transit!=0?((in_transit / booked) * 100).toFixed(2):'0.00',
                  rto_delivered,
                  rto_deliveredPercentage: rto_delivered!=0?((rto_delivered / booked) * 100).toFixed(2):'0.00',
                };
              });
            
              return performanceData;
            };
          
            const getDatesInRange = (dateRange) => {
              const [startDate, endDate] = dateRange;
              const start = new Date(Date.UTC(startDate.getFullYear(), startDate.getMonth(), startDate.getDate()));
              const end = new Date(Date.UTC(endDate.getFullYear(), endDate.getMonth(), endDate.getDate()));
            
              const dates = [];
              let currentDate = start;
            
              while (currentDate <= end) {
                dates.push(new Date(currentDate).toISOString().split('T')[0]);
                currentDate.setUTCDate(currentDate.getUTCDate() + 1);
              }
            
              return dates;
            };
          
            // const countTotalDays = (dateRange) => {
            //   const [startDate, endDate] = dateRange;
            //   const start = new Date(startDate);
            //   const end = new Date(endDate);
            //   start.setHours(0, 0, 0, 0); // Set time to midnight
            //   end.setHours(0, 0, 0, 0); // Set time to midnight
            //   const diffTime = Math.abs(end - start);
            //   const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)) + 1;
            //   return diffDays;
            // };

            const countShipmentsByLocation = (shipments) => {
              const cityCounts = {};
              const stateCounts = {};
          
              shipments.forEach(shipment => {
                if (shipment.status === 0 ||shipment.status === 7 ) {
                  return; // Skip this iteration
                }
                // Count sender cities and states
                const senderCity = shipment.receiver[0].city;
                const senderState = shipment.receiver[0].state;
                if (senderCity) {
                  if (!cityCounts[senderCity]) cityCounts[senderCity] = 0;
                  cityCounts[senderCity]++;
                }
                if (senderState) {
                  if (!stateCounts[senderState]) stateCounts[senderState] = 0;
                  stateCounts[senderState]++;
                }
          
                // Count receiver cities and states
              //   const receiverCity = shipment.receiver[0].city;
              //   const receiverState = shipment.receiver[0].state;
              //   if (receiverCity) {
              //     if (!cityCounts[receiverCity]) cityCounts[receiverCity] = 0;
              //     cityCounts[receiverCity]++;
              //   }
              //   if (receiverState) {
              //     if (!stateCounts[receiverState]) stateCounts[receiverState] = 0;
              //     stateCounts[receiverState]++;
              //   }
              });
          
              return { cityCounts, stateCounts };
            };
          
            const getTopLocations = (locationCounts, totalShipments, topN = 10) => {
              return Object.entries(locationCounts)
                .sort(([, a], [, b]) => b - a)
                .slice(0, topN)
                .map(([location, count]) => ({
                  location,
                  count,
                  percentage: ((count / totalShipments) * 100).toFixed(2)
                }));
            };

          //   const options = {
          //     title: {
          //       text: "Shipment Status"
          //     },
          //     data: [{
          //       type: "pie",
          //       startAngle: 75,
          //       toolTipContent: "{name}: {y}%",
          //       showInLegend: "true",
          //       legendText: "{name}",
          //       indexLabelFontSize: 16,
          //     //   indexLabel: "{name} - {y}%",
          //       dataPoints: [
          //         { y: shipstate.total_overview.delivered, name: "Delivered", color: "#28a745" },
          //         { y: shipstate.total_overview.pending, name: "Pending", color: "#dc3545" },
          //         { y: shipstate.total_overview.rto, name: "RTO", color: "#007bff" }
          //       ]
          //     }]
          //   };
        

          React.useEffect(() => {
              const ctx = chartRef.current.getContext('2d');
              const data = {
                labels: ['Delivered', 'Pending', 'RTO IN TRANSIT','RTO DELIVERED'],
                datasets: [
                  {
                    data: [shipstate.total_overview.delivered, shipstate.total_overview.pending, shipstate.total_overview.rto_intransit , shipstate.total_overview.rto_delivered],
                    backgroundColor: ['green', 'red', 'blue','purple'],
                    hoverBackgroundColor: ['darkgreen', 'darkred', 'darkblue' , 'darkpurple']
                  }
                ]
              };
          
              const options = {
                responsive: true,
                maintainAspectRatio: false,
                plugins: {
                  legend: {
                    display: true,
                    position: 'bottom'
                  }
                }
              };
          
              const carrierBookingChart = new Chart(ctx, {
                type: 'pie',
                data: data,
                options: options
              });
          
              return () => {
                carrierBookingChart.destroy();
              };
            }, [shipstate]);
           

            const list = ()=>{
              setfinanceloader(true)
              let dataToSend = {customer_id : userData.customer_id  , start_date:financedate.finance_start_date , end_date:financedate.finance_end_date};
          
              // console.log("datatoSend",dataToSend)
              let url = config.apiUrl + '/user/dashboard_shipping_charges_transaction';
              axios.post(url, dataToSend, { headers: config.headers })
                  .then((res) => {
                    // console.log("responseJson => ", res);
                    if(res.data.status==true){
                      setshippingState(res.data.output)
                      setfinanceloader(false)
             }
            else{
                // const Toast = Swal.mixin({
                //     toast: true,
                //     position: 'bottom-end',
                //     showConfirmButton: false,
                //     timer: 3000
                //   })
                //   Toast.fire({
                //     background:"rgb(231, 91, 75)",
                //     type: 'Unsuccess',
                //     title: "Something Went Wrong !",
                //     color:"white"
                //   });
                  setfinanceloader(false)
            } })
                
                  .catch((error) => {
                    // const Toast = Swal.mixin({
                    //     toast: true,
                    //     position: 'bottom-end',
                    //     showConfirmButton: false,
                    //     timer: 3000
                    //   })
                    //   Toast.fire({
                    //     background:"rgb(231, 91, 75)",
                    //     type: 'Unsuccess',
                    //     title: "Something Went Wrong !",
                    //     color:"white"
                    //   });
                      setfinanceloader(false)
                      // console.log(error);
                  });    
          }
    
          

          const generateTopRegionsGraphData = (shipments) => {
            // Step 1: Filter data for NDR and RTO
            const ndrShipments = shipments.filter((shipment) => shipment.status === 8);
            const rtoShipments = shipments.filter((shipment) => shipment.status === 19 || shipment.status === 16);
        
            // Step 2: Group by state and aggregate counts
            const regionCounts = {};
        
            const processShipments = (shipmentList, type) => {
              shipmentList.forEach((shipment) => {
                const state = shipment.receiver[0]?.state || "Unknown";
                if (!regionCounts[state]) {
                  regionCounts[state] = { ndr: 0, rto: 0 };
                }
                regionCounts[state][type]++;
              });
            };
        
            processShipments(ndrShipments, "ndr");
            processShipments(rtoShipments, "rto");
        
            // Step 3: Convert to array and sort by total counts
            const sortedRegions = Object.entries(regionCounts)
              .map(([state, counts]) => ({
                state,
                ...counts,
                total: counts.ndr + counts.rto,
              }))
              .sort((a, b) => b.total - a.total)
              .slice(0, 10); // Top 10 states
        
            // Step 4: Prepare data for the graph
            const newGraphData = {
              labels: sortedRegions.map((region) => region.state),
              datasets: [
                {
                  label: "NDR",
                  data: sortedRegions.map((region) => region.ndr),
                  backgroundColor: "rgba(255, 99, 132, 0.8)", // NDR color
                },
                {
                  label: "RTO",
                  data: sortedRegions.map((region) => region.rto),
                  backgroundColor: "rgba(54, 162, 235, 0.8)", // RTO color
                },
              ],
            };
        
            setGraphData(newGraphData);
          };

          React.useEffect(() => {
            const ctx = document.getElementById('walletchart').getContext('2d');
          
            // Use actual shipment counts received from the API
            const data = {
              labels: sevendayrecharges.last7DaysRecharges.map(item => item.date),
              datasets: [{
                data: sevendayrecharges.last7DaysRecharges.map(item => item.amount),
                borderColor: '#3D348B', // Line color
                borderWidth: 2,
                fill: true ,// Fill the area under the line
                backgroundColor: '#cbc7ea'
              }],
            };
          
            const options = {
              scales: {
                x: {
                  display: false,
                },
                y: {
                  beginAtZero: true,
                  display: false,
                },
              },
              elements: {
                line: {
                  tension: 0.4,
                },
                point: {
                  radius: 1,
                },
              },
              plugins: {
                legend: {
                  display: false,
                },
              },
            };
          
            const walletchart = new Chart(ctx, {
              type: 'line',
              data: data,
              options: options,
            });
          
            return () => {
              walletchart.destroy();
            };
          }, [sevendayrecharges]);

          React.useEffect(() => {
            const ctx = document.getElementById('ordersChart').getContext('2d');
          
            // Use actual shipment counts received from the API
            const data = {
              labels: sevendaystate.last7DaysShipments.map(item => item.date),
              datasets: [{
                data: sevendaystate.last7DaysShipments.map(item => item.count),
                borderColor: '#3D348B', // Line color
                borderWidth: 2,
                fill: true ,// Fill the area under the line
                backgroundColor: '#cbc7ea'
              }],
            };
          
            const options = {
              scales: {
                x: {
                  display: false,
                },
                y: {
                  beginAtZero: true,
                  display: false,
                },
              },
              elements: {
                line: {
                  tension: 0.4,
                },
                point: {
                  radius: 1,
                },
              },
              plugins: {
                legend: {
                  display: false,
                },
              },
            };
          
            const ordersChart = new Chart(ctx, {
              type: 'line',
              data: data,
              options: options,
            });
          
            return () => {
              ordersChart.destroy();
            };
          }, [sevendaystate]);

          useEffect(() => {
            const ctx = document.getElementById('shipmentChart').getContext('2d');
        
            // Data for the bar chart
            const data = {
              labels: statusBookingData.map(entry => entry.status),
              datasets: [{
                label: 'Shipment Status',
                data: statusBookingData.map(entry => entry.totalCount),
                backgroundColor: [
                  '#c19efa', '#3D348B', '#F7B801', '#7678ED', '#F35B04', 
                  '#F18701', '#8338ec', 'rgba(153, 102, 255, 0.2)', 
                  'rgba(153, 102, 255, 0.2)', 'rgba(153, 102, 255, 0.2)'
                ],
                borderColor: [
                  '#6610f2', '#302970', '#cb9801', '#1d21e2', '#c94c03', 
                  '#cb7301', '#6e16e9', '#6610f2', '#6610f2', '#6610f2'
                ],
                borderWidth: 1
              }]
            };
        
            // Configuration for the chart
            const options = {
              scales: {
                y: {
                  beginAtZero: true // Start Y axis at 0
                }
              }
            };
        
            // Create the bar chart
            const shipmentChart = new Chart(ctx, {
              type: 'bar',
              data: data,
              options: options
            });
        
            // Cleanup: Destroy the chart when the component unmounts
            return () => {
              shipmentChart.destroy();
            };
          }, [statusBookingData]);

          useEffect(() => {
         
            const ctx = chartRefone.current.getContext('2d');
            const ndrChart = new Chart(ctx, {
              type: "bar",
              data: graphData,
              options: {
                responsive: true,
                plugins: {
                  legend: {
                    position: "top",
                  },
                  tooltip: {
                    callbacks: {
                      label: (context) => `${context.dataset.label}: ${context.raw}`,
                    },
                  },
                },
                scales: {
                  x: {
                    stacked: true,
                  },
                  y: {
                    stacked: true,
                    beginAtZero: true,
                    title: {
                      display: true,
                      text: "Number of Shipments",
                    },
                  },
                },
              },
            });
        
            // Cleanup the chart on component unmount
            return () => {
              ndrChart.destroy();
            };
          }, [graphData]);




          useEffect(() => {
         
            const ctx = chartRefndr.current.getContext('2d');
            const ndrChartPie = new Chart(ctx, {
              type: "doughnut",
              data: {
                labels: ndrZoneData.map((item) => item.zone),
                datasets: [
                  {
                    data: ndrZoneData.map((item) => item.count),
                    backgroundColor: [
                      "#fc5e03",
                      "#36A2EB",
                      "#FFCE56",
                      "#4BC0C0",
                      "#9966FF",
                      "#FF9F40",
                    ],
                    hoverOffset: 4,
                  },
                ],
              },
              options: {
                responsive: true,
                plugins: {
                  legend: {
                    position: "top",
                  },
                  title: {
                    display: true,
                    text: "Top NDR Zones",
                  },
                },
              },
            });
        
            // Cleanup the chart on component unmount
            return () => {
              ndrChartPie.destroy();
            };
          }, [ndrZoneData]);

          useEffect(() => {
         
            const ctx = chartRefrto.current.getContext('2d');
            const rtoChartPie = new Chart(ctx, {
              type: "doughnut",
              data: {
                labels: rtoZoneData.map((item) => item.zone),
                datasets: [
                  {
                    data: rtoZoneData.map((item) => item.count),
                    backgroundColor: [
                      "#fc5e03",
                      "#36A2EB",
                      "#FFCE56",
                      "#4BC0C0",
                      "#9966FF",
                      "#FF9F40",
                    ],
                    hoverOffset: 4,
                  },
                ],
              },
              options: {
                responsive: true,
                plugins: {
                  legend: {
                    position: "top",
                  },
                  title: {
                    display: true,
                    text: "Top RTO Zones",
                  },
                },
              },
            });
        
            // Cleanup the chart on component unmount
            return () => {
              rtoChartPie.destroy();
            };
          }, [rtoZoneData]);
           

          useEffect(() => {
         
            const ctx = chartRefdelivered.current.getContext('2d');
            const deliveredChartPie = new Chart(ctx, {
              type: "doughnut",
              data: {
                labels: deliveredZoneData.map((item) => item.zone),
                datasets: [
                  {
                    data: deliveredZoneData.map((item) => item.count),
                    backgroundColor: [
                      "#fc5e03",
                      "#36A2EB",
                      "#FFCE56",
                      "#4BC0C0",
                      "#9966FF",
                      "#FF9F40",
                    ],
                    hoverOffset: 4,
                  },
                ],
              },
              options: {
                responsive: true,
                plugins: {
                  legend: {
                    position: "top",
                  },
                  title: {
                    display: true,
                    text: "Top Delivered Zones",
                  },
                },
              },
            });
        
            // Cleanup the chart on component unmount
            return () => {
              deliveredChartPie.destroy();
            };
          }, [deliveredZoneData]);


          React.useEffect(()=>{
            announcement()
          },[])
          const announcement=()=>{
            let dataToSend = {};
           
             // console.log("datatoSend",dataToSend)
             let url = config.apiUrl + '/user/get_announcement';
             axios.post(url, dataToSend, { headers: config.headers })
                 .then((res) => {
                   if(res.data.status==true){
                    setannouncementstate(res.data.output)
            }
           else{
              
           } })
               
                 .catch((error) => {
                  
                 });    
           
           }
  return (
    <div>
   
<Header/>

{announcementstate.length>0?
<div class="position-relative marquee-container d-none d-sm-block">
  <div class="marquee d-flex ">
    {announcementstate.map((sub)=>(
    <span>{sub.announcement}</span>
  ))}
   
  </div>
</div>
:""}

<div class="page-wrapper pinned">
<div class="main-container">
<div className='app-container'>
<div class="app-body">


<div class="container-fluid">
<section class="mb-3 dashboard_box_ne">
  <div class="dash_navs">
      <ul class="nav nav-tabs justify-content-center" role="tablist">
          <li class="nav-item" role="presentation">
              <a class="nav-link active" data-bs-toggle="tab" href="#home" aria-selected="true" role="tab">Overview</a>
          </li>
          <li class="nav-item" role="presentation">
              <a class="nav-link" data-bs-toggle="tab" href="#menu1" aria-selected="false" role="tab" tabindex="-1">Analytics</a>
          </li>
          <li class="nav-item" role="presentation">
              <a class="nav-link" data-bs-toggle="tab" href="#menu2" aria-selected="false" role="tab" tabindex="-1">Finance</a>
          </li>
      </ul>
  </div>

  <div class="tab-content mt-3 pb-5">
  <div class="tab-pane container active" id="home">
                        <div class="card border-0 shadow-sm mb-3">
                          <div class="card-body">
                            <h4>Today Stats</h4>
                            <div class="row">
                                <div class="col-12 col-md-2">
                                    <div class="d-flex justify-content-center dash_icon_bx dash_order shadow-sm">
                                        <div class="icon_dash">
                                            <i class="bi bi-bag"></i>
                                        </div>
                                        <div class="icon_dash_text">
                                            <h5>Shipments</h5>
                                           
    
                                            <h2>{shipstate.today_shipments_stats}</h2>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-12 col-md-2">
                                    <div class="d-flex justify-content-center dash_icon_bx dash_order_rea_pickup shadow-sm">
                                        <div class="icon_dash">
                                            <i class="bi bi-box-arrow-up"></i>
                                        </div>
                                        <div class="icon_dash_text">
                                            <h5>Pending Pickup</h5>
                                            <h2>{shipstate.today_pickup_pending_stats}</h2>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-12 col-md-2">
                                    <div class="d-flex justify-content-center dash_icon_bx dash_order_pickdup shadow-sm">
                                        <div class="icon_dash">
                                            <i class="bi bi-box-seam"></i>
                                        </div>
                                        <div class="icon_dash_text">
                                            <h5>Picked Up</h5>
                                            <h2>{shipstate.today_picked_up}</h2>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-12 col-md-2">
                                    <div class="d-flex justify-content-center dash_icon_bx dash_order_intransit shadow-sm">
                                        <div class="icon_dash">
                                            <i class="bi bi-rocket"></i>
                                        </div>
                                        <div class="icon_dash_text">
                                            <h5>In Transit</h5>
                                            <h2>{shipstate.today_in_transit_stats}</h2>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-12 col-md-2">
                                    <div class="d-flex justify-content-center dash_icon_bx dash_order_delivered shadow-sm">
                                        <div class="icon_dash">
                                            <i class="bi bi-check2-square"></i>
                                        </div>
                                        <div class="icon_dash_text">
                                            <h5>Delivered</h5>
                                            <h2>{shipstate.today_delivered_shipments_stats}</h2>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-12 col-md-2">
                                    <div class="d-flex justify-content-center dash_icon_bx dash_order_ndr shadow-sm">
                                        <div class="icon_dash">
                                            <i class="bi bi-house-exclamation"></i>
                                        </div>
                                        <div class="icon_dash_text">
                                            <h5>NDR</h5>
                                            <h2>{shipstate.today_ndr_shipments_stats}</h2>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-12 col-md-2 mt-2">
                                    <div class="d-flex justify-content-center dash_icon_bx dash_order_ndr shadow-sm">
                                        <div class="icon_dash">
                                            <i class="bi bi-rocket"></i>
                                        </div>
                                        <div class="icon_dash_text">
                                            <h5>RTO In Transit</h5>
                                            <h2>{shipstate.today_rto_intransit_shipments_stats}</h2>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-12 col-md-2 mt-2">
                                    <div class="d-flex justify-content-center dash_icon_bx dash_order_ndr shadow-sm">
                                        <div class="icon_dash">
                                            <i class="bi bi-check2-square"></i>
                                        </div>
                                        <div class="icon_dash_text">
                                            <h5>RTO Delivered</h5>
                                            <h2>{shipstate.today_rto_delivered_shipments_stats}</h2>
                                        </div>
                                    </div>
                                </div>
                            </div>
                          </div>
                        </div>

                        <section class="mb-3 ">
                            <div class="row">
                                <div class="col-12 col-md-6 mb-3">
                                    <div class="card border-0 shadow-sm">
                                      <div class="card-body">
                                        <h4>COD Stats</h4>
                                        <div class="row">
                                            <div class="col-12 col-md-4 text-center ">
                                                <div class="card cod_dash_box cod_dash_box_1">
                                                  <div class="card-body">
                                                    <div class="cod_dash_boxing">
                                                        <div class="cod_dash">
                                                            <i class="bi bi-wallet"></i>
                                                        </div>
                                                    </div>
                                                    <h5>Next COD</h5>
                                                    <h2>----</h2>
                                                  </div>
                                                </div>
                                            </div>
                                            <div class="col-12 col-md-4 text-center ">
                                                <div class="card cod_dash_box cod_dash_box_2">
                                                  <div class="card-body">
                                                    <div class="cod_dash_boxing">
                                                        <div class="cod_dash">
                                                            <i class="bi bi-bank"></i>
                                                        </div>
                                                    </div>
                                                    <h5>Upcoming COD</h5>
                                                    <h2>----</h2>
                                                  </div>
                                                </div>
                                            </div>
                                            <div class="col-12 col-md-4 text-center ">
                                                <div class="card cod_dash_box cod_dash_box_3">
                                                  <div class="card-body">
                                                    <div class="cod_dash_boxing">
                                                        <div class="cod_dash">
                                                            <i class="bi bi-cash"></i>
                                                        </div>
                                                    </div>
                                                    <h5>Last COD</h5>
                                                    <h2>----</h2>
                                                  </div>
                                                </div>
                                            </div>
                                        </div>
                                      </div>
                                    </div>
                                </div>
                                <div class="col-12 col-md-6 mb-3">
                                    <div class="card border-0 shadow-sm">
                                      <div class="card-body">
                                        <h4>NDR Stats</h4>
                                        <div class="row">
                                            <div class="col-12 col-md-4 text-center ">
                                                <div class="card cod_dash_box cod_dash_box_1">
                                                  <div class="card-body">
                                                    <div class="cod_dash_boxing">
                                                        <div class="cod_dash">
                                                            <i class="bi bi-check-all"></i>
                                                        </div>
                                                    </div>
                                                    <h5>Open NDR(s)</h5>
                                                    <h2>{ndrstate.ndr_count}</h2>
                                                  </div>
                                                </div>
                                            </div>
                                            <div class="col-12 col-md-4 text-center ">
                                                <div class="card cod_dash_box cod_dash_box_2">
                                                  <div class="card-body">
                                                    <div class="cod_dash_boxing">
                                                        <div class="cod_dash">
                                                            <i class="bi bi-check-circle"></i>
                                                        </div>
                                                    </div>
                                                    <h5>Action Required </h5>
                                                    <h2>{ndrstate.action_required_ndr}</h2>
                                                  </div>
                                                </div>
                                            </div>
                                            <div class="col-12 col-md-4 text-center ">
                                                <div class="card cod_dash_box cod_dash_box_3">
                                                  <div class="card-body">
                                                    <div class="cod_dash_boxing">
                                                        <div class="cod_dash">
                                                            <i class="bi bi-x-circle"></i>
                                                        </div>
                                                    </div>
                                                    <h5>Action Requested </h5>
                                                    <h2>{ndrstate.action_requested_ndr}</h2>
                                                  </div>
                                                </div>
                                            </div>
                                        </div>
                                      </div>
                                    </div>
                                </div>
                            </div>
                        </section>

                        <section class="mb-3 mt-3">
                            <div class="">
                              <div class="row">
                                  <div class="col-12 col-md-6 ">
                                          <div class="wallet_card shadow-sm">
                                              <div class="wallet_card_box">
                                                  <div class="row">
                                                      <div class="col-12 col-md">
                                                          <h4>Today's Wallet Recharge</h4>
                                                          <h1>{sevendayrecharges.totalRechargesToday}</h1>
                                                          <h6>{sevendayrecharges.totalRechargesLast7Days} spent in last 7 days</h6>
                                                      </div>
                                                      <div class="col-12 col-md-4 text-end">
                                                          <a href="">Wallet Recharge</a>
                                                      </div>
                                                  </div>
                                              </div>
                                              <canvas id="walletchart" width="632" height="158" style={{display: "block", boxSizing: "border-box", height: "158px", width: "632px"}}></canvas>
                                          </div>
                                  </div>
                                  <div class="col-12 col-md-6 ">
                                          <div class="shipment_card shadow-sm">
                                              <div class="shipment_card_box">
                                                  <div class="row">
                                                      <div class="col-12 col-md">
                                                          <h4>Today's Shipments</h4>
                                                          <h1>{shipstate.today_shipments_stats}</h1>
                                                          <h6>{sevendaystate.totalShipmentsCount} shipments in last 7 days</h6>
                                                      </div>
                                                      <div class="col-12 col-md-4 text-end">
                                                          <a href="#">Shipment List</a>
                                                      </div>
                                                  </div>
                                              </div>
                                              <canvas id="ordersChart" width="632" height="158" style={{display: "block", boxSizing: "border-box", height: "158px", width: "632px"}}></canvas>
                                          </div>
                                  </div>
                              </div>      
                            </div>
                        </section>
                           
                        <div class="card border-0 mb-3 shadow-sm">
                            <div class="card-body">
                                <div class="d-flex justify-content-between">
                                    <div class="">
                                        <h4>Shipments</h4>
                                    </div>
                                    <div class="">
                                        <small>Last 7 days</small>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-12 col-md shipment_state">
                                        <h1>Shipment Status</h1>
                                        <div class="row">
                                            <div class="col-12 col-md">
                                         
                                                <h2>{shipstate.today_shipments_stats}</h2>
                                                <p>Today</p>
                                            </div>
                                            <div class="col-12 col-md">
                                                <h2>{sevendaystate.totalShipmentsCount}</h2>
                                                <p>Last 7 days</p>
                                            </div>
                                          
                                        </div>
                                    </div>
                                    <div class="col-12 col-md-8">
                                        <canvas id="shipmentChart" width="832" height="208" style={{display: "block", boxSizing: "border-box", height: "208px", width: "832px"}}></canvas>
                                    </div>
                                </div>
                            </div>
                        </div>

                       

                    </div>
      <div class="tab-pane g-0  fade" id="menu1" role="tabpanel">

          {/* <div class="row justify-content-end">
              <div class="col-12 col-md-3 text-end ">
                <div class="input-group">
                  <input type="text" class="form-control custom-daterange"/>
                  <span class="input-group-text">
                    <i class="bi bi-calendar2-range"></i>
                  </span>
                </div>
              </div>
          </div> */}
          <div className="row justify-content-end mb-1">
            <div className="col-12 col-md-3 text-end ">
                <div className="input-group">
                    <input
                        type="text"
                        className="form-control custom-daterange"
                    />
                    <span className="input-group-text">
                         <button class="btn btn-primary" onClick={(e)=>get_full_dashboard_data(e)}><i class="bi bi-search"></i></button>
                    </span>
                </div>
            </div>
        </div>
      
        <div class="card border-0 shadow-sm mb-3">
                            <div class="card-body">
                              <div class="row">
                                  <div class="col-12 col-md-2">
                                      <div class="d-flex justify-content-center dash_icon_bx dash_order shadow-sm">
                                          <div class="icon_dash">
                                              <i class="bi bi-bag"></i>
                                          </div>
                                          <div class="icon_dash_text">
                                              <h5>Total Shipments</h5>
                                              <h2>{shipstate.today_shipments}</h2>
                                          </div>
                                      </div>
                                  </div>
                                  <div class="col-12 col-md-2">
                                      <div class="d-flex justify-content-center dash_icon_bx dash_order_rea_pickup shadow-sm">
                                          <div class="icon_dash">
                                              <i class="bi bi-box-arrow-up"></i>
                                          </div>
                                          <div class="icon_dash_text">
                                              <h5>Pending Pickup</h5>
                                              <h2>{shipstate.pickup_pending}</h2>
                                          </div>
                                      </div>
                                  </div>
                                  <div class="col-12 col-md-2">
                                      <div class="d-flex justify-content-center dash_icon_bx dash_order_intransit shadow-sm">
                                          <div class="icon_dash">
                                              <i class="bi bi-rocket"></i>
                                          </div>
                                          <div class="icon_dash_text">
                                              <h5>In Transit</h5>
                                              <h2>{shipstate.today_in_transit}</h2>
                                          </div>
                                      </div>
                                  </div>
                                  <div class="col-12 col-md-2">
                                      <div class="d-flex justify-content-center dash_icon_bx dash_order_delivered shadow-sm">
                                          <div class="icon_dash">
                                              <i class="bi bi-check2-square"></i>
                                          </div>
                                          <div class="icon_dash_text">
                                              <h5>Delivered</h5>
                                              <h2>{shipstate.delivered_shipments}</h2>
                                          </div>
                                      </div>
                                  </div>
                                  <div class="col-12 col-md-2">
                                      <div class="d-flex justify-content-center dash_icon_bx dash_order_ndr shadow-sm">
                                          <div class="icon_dash">
                                              <i class="bi bi-house-exclamation"></i>
                                          </div>
                                          <div class="icon_dash_text">
                                              <h5>Total NDR</h5>
                                              <h2>{shipstate.ndr_shipments}</h2>
                                          </div>
                                      </div>
                                  </div>
                                  <div class="col-12 col-md-2">
                                      <div class="d-flex justify-content-center dash_icon_bx dash_order_rto shadow-sm">
                                          <div class="icon_dash">
                                              <i class="bi bi-rocket"></i>
                                          </div>
                                          <div class="icon_dash_text">
                                              <h5>RTO In Transit</h5>
                                              <h2>{shipstate.rto_shipments}</h2>
                                          </div>
                                      </div>
                                  </div>
                                  <div class="col-12 col-md-2 mt-1">
                                      <div class="d-flex justify-content-center dash_icon_bx dash_order_rto shadow-sm">
                                          <div class="icon_dash">
                                              <i class="bi bi-check2-square"></i>
                                          </div>
                                          <div class="icon_dash_text">
                                              <h5>RTO Delivered</h5>
                                              <h2>{shipstate.rto_shipments_delivered}</h2>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                            </div>
                          </div>
  
                          <div class="card border-0 shadow-sm mb-3 order_summary">
                            <div class="card-body">
                              <h4>Shipment Summary</h4>
                              <div class="row">
                                  <div class="col-12 col-md-12">
                                      <div class="table-responsive">
                                          <table class="table">
                                              <thead class="table-primary">
                                                  <tr>
                                                      <th scope="col">SNO.</th>
                                                      <th scope="col">CARRIERS</th>
                                                      <th scope="col">COD DELIVERED / TOTAL COD</th>
                                                      <th scope="col">PREPAID DELIVERED / TOTAL PREPAID</th>
                                                      <th scope="col">TOTAL</th>
                                                  </tr>
                                              </thead>
                                              <tbody>
                                              {shipstate.order_summary.map((order, index) => (
                                                  <tr>
                                                      <td data-label="SNO.">{index+1}</td>
                                                      <td data-label="CARRIERS">{order.carrier}</td>
                                                      <td data-label="COD">{order.codDelivered}/{order.cod}</td>
                                                      <td data-label="PREPAID">{order.prepaidDelivered}/{order.prepaid}</td>
                                                      <td data-label="TOTAL">{order.cod}</td>
                                                  </tr>
                                              ))}
                                              </tbody>
                                          </table>
                                      </div>
                                  </div>
                              </div>
                            </div>
                          </div>
  
                          <div class="card border-0 shadow-sm mb-3 carrier_summary">
                            <div class="card-body">
                              <div class="row">
                                  <div class="col-12 col-md">
                                      <h4>Carrier Overview</h4>
                                  </div>
                                  {/* <div class="col-12 col-md-2 text-end">
                                      <select class="form-select">
                                        <option>All</option>
                                        <option>Prepaid</option>
                                        <option>COD</option>
                                      </select>
                                  </div> */}
                              </div>
                              <div class="row">
                                  <div class="col-12 col-md-12">
                                      <div class="table-responsive">
                                          <table class="table">
                                              <thead class="table-primary">
                                                  <tr>
                                                  <th scope="col">SNO.</th>
                                                      <th scope="col">CARRIER NAME</th>
                                                      <th scope="col">SHIPMENTS BOOKED</th>
                                                      <th scope="col">DELIVERED</th>
                                                      <th scope="col">IN TRANSIT</th>
                                                      <th scope="col">NDR</th>
                                                      <th scope="col">RTO IN TRANSIT</th>
                                                      <th scope="col">RTO DELIVERED</th>

                                                  </tr>
                                              </thead>
                                              <tbody>
                                              {filteredCourierOverview.map((overview, index) => (
                  <tr key={index}>
                      <td data-label="SNO.">{index+1}</td>
                    <td data-label="Carrier NAME">{overview.carrier}</td>
                    <td data-label="SHIPMENTS BOOKED">{overview.booked} </td>
                    <td data-label="DELIVERED">{overview.delivered} ({overview.deliveredPercentage}%)</td>
                    {/* <td data-label="RUNNING">{overview.pending} ({overview.pendingPercentage}%)</td> */}
                    <td data-label="INTRANSIT">{overview.in_transit} ({overview.in_transitPercentage}%)</td>
                    <td data-label="NDR">{overview.ndr} ({overview.ndrPercentage}%)</td>

                    <td data-label="RETURN TO ORIGIN">{overview.rto_intransit} ({overview.rto_intransitPercentage}%)</td>
                    <td data-label="RETURN TO ORIGIN">{overview.rto_delivered} ({overview.rto_deliveredPercentage}%)</td>

                  </tr>
                ))}
                                                
                                                  <tr class="total table-info">
                                                      <td data-label="" colspan="2">Total</td>
                                                      <td data-label="TOTAL SHIPMENTS BOOKED">{shipstate.total_overview.booked}</td>
                                                      <td data-label="TOTAL DELIVERED">{shipstate.total_overview.delivered}<span class="text-success"> ({(shipstate.total_overview.delivered ? (shipstate.total_overview.delivered / shipstate.total_overview.booked * 100).toFixed(2) : '0.00')}%)</span></td>
                                                      {/* <td data-label="TOTAL RUNNING">{shipstate.total_overview.pending}<span class="text-warning">({(shipstate.total_overview.pending ? (shipstate.total_overview.pending / shipstate.total_overview.booked * 100).toFixed(2) : '0.00')}%)</span></td> */}
                                                      <td data-label="TOTAL INTRANSIT">{shipstate.total_overview.in_transit}<span class="text-warning">({(shipstate.total_overview.in_transit ? (shipstate.total_overview.in_transit / shipstate.total_overview.booked * 100).toFixed(2) : '0.00')}%)</span></td>
                                                      <td data-label="TOTAL NDR">{shipstate.total_overview.ndr}<span class="text-warning">({(shipstate.total_overview.ndr ? (shipstate.total_overview.ndr / shipstate.total_overview.booked * 100).toFixed(2) : '0.00')}%)</span></td>
                                                      <td data-label="TOTAL RETURNING TO ORIGIN">{shipstate.total_overview.rto_intransit}<span class="text-danger">({(shipstate.total_overview.rto_intransit ? (shipstate.total_overview.rto_intransit / shipstate.total_overview.booked * 100).toFixed(2) : '0.00')}%)</span></td>
                                                      <td data-label="TOTAL RETURNED TO ORIGIN">{shipstate.total_overview.rto_delivered}<span class="text-danger">({(shipstate.total_overview.rto_delivered ? (shipstate.total_overview.rto_delivered / shipstate.total_overview.booked * 100).toFixed(2) : '0.00')}%)</span></td>
                                                  </tr>
                                              </tbody>
                                          </table>
                                      </div>
                                  </div>
                              </div>
                            </div>
                          </div>
  
                          <div class="card border-0 shadow-sm mb-3 perfrom_summary">
                            <div class="card-body">
                              <div class="row">
                                  <div class="col-12 col-md">
                                      <h4>Performance Overview</h4>
                                  </div>
                                  <div class="col-12 col-md-6 text-end">
                                      <div class="row">
                                          {/* <div class="col-12 col-md-4">
                                              <select class="form-select">
                                                  <option>Carriers</option>
                                                  <option>Ecom (Surface - 0.5KG)</option>
                                                  <option>Delhivery (Surface - 0.5KG)</option>
                                              </select>
                                          </div> */}
                                          {/* <div class="col-12 col-md-4">
                                              <select class="form-select">
                                                  <option>Warehouse</option>
                                                  <option>Mumbai Warehouse</option>
                                                  <option>Jaipur Warehouse</option>
                                              </select>
                                          </div> */}
                                          {/* <div class="col-12 col-md-4">
                                              <select class="form-select">
                                                  <option>All</option>
                                                  <option>Prepaid</option>
                                                  <option>COD</option>
                                              </select>
                                          </div> */}
                                      </div>
                                  </div>
                              </div>
                              <div class="row">
                                  <div class="col-12 col-md-12">
                                      <div class="table-responsive">
                                          <table class="table">
                                              <thead class="table-primary">
                                                  <tr>
                                                  <th scope="col">DATE</th>
                                                      <th scope="col">SHIPMENTS BOOKED</th>
                                                      <th scope="col">DELIVERED</th>
                                                      <th scope="col">IN TRANSIT</th>
                                                      <th scope="col">NDR</th>
                                                      <th scope="col">RTO IN TRANSIT</th>
                                                      <th scope="col">RTO DELIVERED</th>

                                                  </tr>
                                              </thead>
                                              <tbody>
                                              {shipstate.performanceOverview.map((overview, index) => (
                <tr key={index}>
                  <td data-label="DATE">{overview.date}</td>
                  <td data-label="SHIPMENTS BOOKED">{overview.booked}</td>
                  <td data-label="DELIVERED">{overview.delivered} <span class="text-success">({overview.deliveredPercentage}%)</span></td>
                  {/* <td data-label="RUNNING">{overview.pending} <span class="text-warning">({overview.pendingPercentage}%)</span></td> */}
                  <td data-label="INTRANSIT">{overview.in_transit} <span class="text-warning">({overview.in_transitPercentage}%)</span></td>
                  <td data-label="NDR">{overview.ndr} <span class="text-warning">({overview.ndrPercentage}%)</span></td>


                  <td data-label="RETURN TO ORIGIN">{overview.rto}<span class="text-danger">({overview.rtoPercentage}%)</span></td>
                  <td data-label="RETURNED TO ORIGIN">{overview.rto_delivered}<span class="text-danger">({overview.rto_deliveredPercentage}%)</span></td>

                  
                </tr>
              ))}

<tr class="total table-info">
<td data-label="TOTAL">Total</td>
<td data-label="SHIPMENTS BOOKED">{shipstate.performanceOverview.reduce((acc, curr) => acc + curr.booked, 0)}</td>
                <td data-label="DELIVERED">{shipstate.performanceOverview.reduce((acc, curr) => acc + curr.delivered, 0)}<span class="text-success">({(shipstate.performanceOverview.reduce((acc, curr) => acc + curr.delivered, 0) ? (shipstate.performanceOverview.reduce((acc, curr) => acc + curr.delivered, 0) / shipstate.performanceOverview.reduce((acc, curr) => acc + curr.booked, 0) * 100).toFixed(2) : '0.00')}%)</span> </td>

                {/* <td data-label="RUNNING">{shipstate.performanceOverview.reduce((acc, curr) => acc + curr.pending, 0)}<span class="text-warning">({(shipstate.performanceOverview.reduce((acc, curr) => acc + curr.pending, 0) ? (shipstate.performanceOverview.reduce((acc, curr) => acc + curr.pending, 0) / shipstate.performanceOverview.reduce((acc, curr) => acc + curr.booked, 0) * 100).toFixed(2) : '0.00')}%)</span>
                 </td> */}
                   <td data-label="INTRANSIT">{shipstate.performanceOverview.reduce((acc, curr) => acc + curr.in_transit, 0)}<span class="text-warning">({(shipstate.performanceOverview.reduce((acc, curr) => acc + curr.in_transit, 0) ? (shipstate.performanceOverview.reduce((acc, curr) => acc + curr.in_transit, 0) / shipstate.performanceOverview.reduce((acc, curr) => acc + curr.booked, 0) * 100).toFixed(2) : '0.00')}%)</span>
                 </td>
                 <td data-label="NDR">{shipstate.performanceOverview.reduce((acc, curr) => acc + curr.ndr, 0)}<span class="text-warning">({(shipstate.performanceOverview.reduce((acc, curr) => acc + curr.ndr, 0) ? (shipstate.performanceOverview.reduce((acc, curr) => acc + curr.ndr, 0) / shipstate.performanceOverview.reduce((acc, curr) => acc + curr.booked, 0) * 100).toFixed(2) : '0.00')}%)</span>
                 </td>

                <td data-label="RETURN TO ORIGIN">{shipstate.performanceOverview.reduce((acc, curr) => acc + curr.rto, 0)}<span class="text-danger">({(shipstate.performanceOverview.reduce((acc, curr) => acc + curr.rto, 0) ? (shipstate.performanceOverview.reduce((acc, curr) => acc + curr.rto, 0) / shipstate.performanceOverview.reduce((acc, curr) => acc + curr.booked, 0) * 100).toFixed(2) : '0.00')}%)</span>
                 </td>
                 <td data-label="RETURN TO ORIGIN">{shipstate.performanceOverview.reduce((acc, curr) => acc + curr.rto_delivered, 0)}<span class="text-danger">({(shipstate.performanceOverview.reduce((acc, curr) => acc + curr.rto_delivered, 0) ? (shipstate.performanceOverview.reduce((acc, curr) => acc + curr.rto_delivered, 0) / shipstate.performanceOverview.reduce((acc, curr) => acc + curr.booked, 0) * 100).toFixed(2) : '0.00')}%)</span>
                 </td>
              </tr>                      
                                                
                                              </tbody>
                                          </table>
                                      </div>
                                  </div>
                              </div>
                            </div>
                          </div>

                          {/* <div class="card border-0 shadow-sm mb-3 state_summary">
                            <div class="card-body">
                              <div class="row">
                                  <div class="col-12 col-md">
                                      <h4>State Wise Overview</h4>
                                  </div>
                                  <div class="col-12 col-md-2 text-end">
                                        <select class="form-select">
                                            <option>All</option>
                                            <option>Prepaid</option>
                                            <option>COD</option>
                                        </select>
                                  </div>
                              </div>
                              <div class="row">
                                  <div class="col-12 col-md-12">
                                      <div class="table-responsive">
                                          <table class="table">
                                              <thead class="table-primary">
                                                  <tr>
                                                      <th scope="col">STATE NAME</th>
                                                      <th scope="col">SHIPMENTS BOOKED</th>
                                                      <th scope="col">DELIVERED</th>
                                                      <th scope="col">RUNNING</th>
                                                      <th scope="col">RETURN TO ORIGIN</th>
                                                  </tr>
                                              </thead>
                                              <tbody>
                                                  <tr>
                                                      <td data-label="STATE NAME">Rajasthan</td>
                                                      <td data-label="SHIPMENTS BOOKED">100</td>
                                                      <td data-label="DELIVERED">10 <span class="text-success">(10%)</span> </td>
                                                      <td data-label="RUNNING">50 <span class="text-warning">(50%)</span></td>
                                                      <td data-label="RETURN TO ORIGIN">40<span class="text-danger">(40%)</span></td>
                                                  </tr>
                                                  <tr>
                                                      <td data-label="STATE NAME">Punjab</td>
                                                      <td data-label="SHIPMENTS BOOKED">10</td>
                                                      <td data-label="DELIVERED">10<span class="text-success">(100%)</span></td>
                                                      <td data-label="RUNNING">0<span class="text-warning">(0%)</span></td>
                                                      <td data-label="RETURN TO ORIGIN">0<span class="text-danger">(0%)</span></td>
                                                  </tr>
                                                  <tr class="total table-info">
                                                      <td data-label="TOTAL">Total</td>
                                                      <td data-label="SHIPMENTS BOOKED">110</td>
                                                      <td data-label="DELIVERED">20<span class="text-success">(12%)</span></td>
                                                      <td data-label="RUNNING">50<span class="text-warning">(49%)</span></td>
                                                      <td data-label="RETURN TO ORIGIN">40<span class="text-danger">(39%)</span></td>
                                                  </tr>
                                              </tbody>
                                          </table>
                                      </div>
                                  </div>
                              </div>
                            </div>
                          </div>

                          <div class="card border-0 shadow-sm mb-3 city_summary">
                            <div class="card-body">
                              <div class="row">
                                  <div class="col-12 col-md">
                                      <h4>City Wise Overview</h4>
                                  </div>
                                  <div class="col-12 col-md-2 text-end">
                                        <select class="form-select">
                                            <option>All</option>
                                            <option>Prepaid</option>
                                            <option>COD</option>
                                        </select>
                                  </div>
                              </div>
                              <div class="row">
                                  <div class="col-12 col-md-12">
                                      <div class="table-responsive">
                                          <table class="table">
                                              <thead class="table-primary">
                                                  <tr>
                                                      <th scope="col">CITY NAME</th>
                                                      <th scope="col">SHIPMENTS BOOKED</th>
                                                      <th scope="col">DELIVERED</th>
                                                      <th scope="col">RUNNING</th>
                                                      <th scope="col">RETURN TO ORIGIN</th>
                                                  </tr>
                                              </thead>
                                              <tbody>
                                                  <tr>
                                                      <td data-label="CITY NAME">Jaipur</td>
                                                      <td data-label="SHIPMENTS BOOKED">100</td>
                                                      <td data-label="DELIVERED">10 <span class="text-success">(10%)</span> </td>
                                                      <td data-label="RUNNING">50 <span class="text-warning">(50%)</span></td>
                                                      <td data-label="RETURN TO ORIGIN">40<span class="text-danger">(40%)</span></td>
                                                  </tr>
                                                  <tr>
                                                      <td data-label="CITY NAME">Delhi</td>
                                                      <td data-label="SHIPMENTS BOOKED">10</td>
                                                      <td data-label="DELIVERED">10<span class="text-success">(100%)</span></td>
                                                      <td data-label="RUNNING">0<span class="text-warning">(0%)</span></td>
                                                      <td data-label="RETURN TO ORIGIN">0<span class="text-danger">(0%)</span></td>
                                                  </tr>
                                                  <tr class="total table-info">
                                                      <td data-label="TOTAL">Total</td>
                                                      <td data-label="SHIPMENTS BOOKED">110</td>
                                                      <td data-label="DELIVERED">20<span class="text-success">(12%)</span></td>
                                                      <td data-label="RUNNING">50<span class="text-warning">(49%)</span></td>
                                                      <td data-label="RETURN TO ORIGIN">40<span class="text-danger">(39%)</span></td>
                                                  </tr>
                                              </tbody>
                                          </table>
                                      </div>
                                  </div>
                              </div>
                            </div>
                          </div> */}

                          <div class="card border-0 shadow-sm mb-3 zone_summary">
                            <div class="card-body">
                              <div class="row">
                                  <div class="col-12 col-md">
                                      <h4>Zone Wise Overview</h4>
                                  </div>
                                  {/* <div class="col-12 col-md-2 text-end">
                                        <select class="form-select">
                                            <option>All</option>
                                            <option>Prepaid</option>
                                            <option>COD</option>
                                        </select>
                                  </div> */}
                              </div>
                              <div class="row">
                                  <div class="col-12 col-md-12">
                                      <div class="table-responsive">
                                          <table class="table">
                                              <thead class="table-primary">
                                                  <tr>
                                                  <th scope="col">ZONE NAME</th>
                                                      <th scope="col">SHIPMENTS BOOKED</th>
                                                      <th scope="col">DELIVERED</th>
                                                      <th scope="col">IN TRANSIT</th>
                                                      <th scope="col">NDR</th>
                                                      <th scope="col">RTO IN TRANSIT</th>
                                                      <th scope="col">RTO DELIVERED</th>

                                                  </tr>
                                              </thead>
                                              <tbody>
                                              {shipstate.zone_overview.map((overview, index) => (
                  <tr key={index}>
                    
                    <td data-label="ZONE NAME">{overview.zone_name}</td>
                    <td data-label="SHIPMENTS BOOKED">{overview.booked} </td>
                    <td data-label="DELIVERED">{overview.delivered} <span class="text-success">({overview.deliveredPercentage}%)</span> </td>
                    {/* <td data-label="RUNNING">{overview.pending} <span class="text-warning">({overview.pendingPercentage}%)</span></td> */}
                    <td data-label="INTRANSIT">{overview.in_transit} <span class="text-warning">({overview.in_transitPercentage}%)</span></td>
                    <td data-label="NDR">{overview.ndr} <span class="text-warning">({overview.ndrPercentage}%)</span></td>

                    <td data-label="RETURN TO ORIGIN">{overview.rto} <span class="text-danger">({overview.rtoPercentage}%)</span></td>
                    <td data-label="RETURN TO ORIGIN">{overview.rto_delivered} <span class="text-danger">({overview.rto_deliveredPercentage}%)</span></td>

                  </tr>
                ))}
                
                                                 <tr class="total table-info">
                                                      <td data-label="TOTAL">Total</td>
                                                      <td data-label="SHIPMENTS BOOKED">{shipstate.zone_total_overview.booked}</td>
                                                      <td data-label="DELIVERED">{shipstate.zone_total_overview.delivered} <span class="text-success"> ({(shipstate.zone_total_overview.delivered ? (shipstate.zone_total_overview.delivered / shipstate.zone_total_overview.booked * 100).toFixed(2) : '0.00')}%)</span></td>
                                                      {/* <td data-label="RUNNING">{shipstate.zone_total_overview.pending}<span class="text-warning">({(shipstate.zone_total_overview.pending ? (shipstate.zone_total_overview.pending / shipstate.zone_total_overview.booked * 100).toFixed(2) : '0.00')}%)</span></td> */}
                                                      <td data-label="INTRANSIT">{shipstate.zone_total_overview.in_transit}<span class="text-warning">({(shipstate.zone_total_overview.in_transit ? (shipstate.zone_total_overview.in_transit / shipstate.zone_total_overview.booked * 100).toFixed(2) : '0.00')}%)</span></td>

                                                      <td data-label="NDR">{shipstate.zone_total_overview.ndr}<span class="text-warning">({(shipstate.zone_total_overview.ndr ? (shipstate.zone_total_overview.ndr / shipstate.zone_total_overview.booked * 100).toFixed(2) : '0.00')}%)</span></td>

                                                      <td data-label="RETURN TO ORIGIN">{shipstate.zone_total_overview.rto}<span class="text-danger">({(shipstate.zone_total_overview.rto ? (shipstate.zone_total_overview.rto / shipstate.zone_total_overview.booked * 100).toFixed(2) : '0.00')}%)</span></td>
                                                      <td data-label="RETURN TO ORIGIN">{shipstate.zone_total_overview.rto_delivered}<span class="text-danger">({(shipstate.zone_total_overview.rto_delivered ? (shipstate.zone_total_overview.rto_delivered / shipstate.zone_total_overview.booked * 100).toFixed(2) : '0.00')}%)</span></td>
                                                  </tr>
                                              </tbody>
                                          </table>
                                      </div>
                                  </div>
                              </div>
                            </div>
                          </div>
  
  
                          <div class="row">
                              <div class="col-12 col-md-6">
                                  <div class="card border-0 shadow-sm mb-3">
                                      <div class="card-body">
                                        <div class="row">
                                            <div class="col-12 col-md">
                                                <h4>Shipments Status</h4>
                                            </div>
                                            <div class="col-12 col-md-6 text-end">
                                                <div class="row">
                                                    {/* <div class="col-6 col-md">
                                                        <select class="form-select">
                                                            <option>Carriers</option>
                                                            <option>Ecom (Surface - 0.5KG)</option>
                                                            <option>Delhivery (Surface - 0.5KG)</option>
                                                        </select>
                                                    </div> */}
                                                    {/* <div class="col-6 col-md">
                                                        <select class="form-select">
                                                            <option>All</option>
                                                            <option>Prepaid</option>
                                                            <option>COD</option>
                                                        </select>
                                                    </div> */}
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-12 col-md-12">
                                            <canvas ref={chartRef}></canvas>
                                            </div>
                                        </div>
                                      </div>
                                  </div>
                              </div>
                              <div class="col-12 col-md-6">
                                  <div class="card border-0 shadow-sm mb-3">
                                      <div class="card-body">
                                        <div class="row">
                                            <div class="col-12 col-md">
                                                <h4>Top 10</h4>
                                            </div>
                                            <div class="col-12 col-md-6 text-end">
                                                <div class="row">
                                                    {/* <div class="col-6">
                                                        <select class="form-select">
                                                            <option>Carriers</option>
                                                            <option>Ecom (Surface - 0.5KG)</option>
                                                            <option>Delhivery (Surface - 0.5KG)</option>
                                                        </select>
                                                    </div> */}
                                                    {/* <div class="col-6">
                                                        <select class="form-select">
                                                            <option>All</option>
                                                            <option>Prepaid</option>
                                                            <option>COD</option>
                                                        </select>
                                                    </div> */}
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row">
                                          <div class="col-12 col-md-6">
                                            <h6>Top City</h6>
                                            <table class="table">
                                                <tbody>
                                                {topCities.map((city, index) => (
              <tr key={index}>
                <td>{city.location}</td>
                <td>{city.count} <span class="text-primary">({city.percentage}%)</span> </td>
             
              </tr>
            ))}
                                                
                                                </tbody>
                                              </table>
                                          </div>
                                          <div class="col-12 col-md-6">
                                              <h6>Top State</h6>
                                              <table class="table">
                                                  <tbody>
                                                  {topStates.map((state, index) => (
              <tr key={index}>
                <td>{state.location} </td>
                <td>{state.count} <span class="text-primary">({state.percentage}%)</span></td>
             
              </tr>
            ))}
                                                   
                                                  </tbody>
                                                </table>
                                            </div>
                                        </div>
                                        
                                      
                                      </div>
                                  </div>
                              </div>
                          </div>
                                        
                                        <div className='row'>
                                        <div class="col-12 col-md-6">
                                  <div class="card border-0 shadow-sm mb-3">
                                      <div class="card-body">
                                        <div class="row">
                                            <div class="col-12 col-md">
                                                <h4>Top 10 NDR Regions</h4>
                                            </div>
                                            <div class="col-12 col-md-6 text-end">
                                                <div class="row">
                                                   
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-12 col-md-12">
                                            <canvas ref={chartRefone}></canvas>
                                            </div>
                                            
                                        </div>
                                        
                                      </div>
                                  </div>
                              </div>

                              <div class="col-12 col-md-6">
                                  <div class="card border-0 shadow-sm mb-3">
                                      <div class="card-body">
                                        <div class="row">
                                            <div class="col-12 col-md">
                                                <h4>Top 10 Selling Products</h4>
                                            </div>
                                            <div class="col-12 col-md-6 text-end">
                                                <div class="row">
                                                </div>
                                            </div>
                                        </div>
                                     <div className='row'>
                                      <table className='table table-responsive'>
                                        <thead>
                                          <tr>
                                            <th>Product Name</th>
                                            <th>Count</th>
                                            <th>Total Amount</th>
                                           </tr>
                                        </thead>
                                        <tbody>
                                        {topProducts.map((product, index) => (
            <tr key={index}>
              <td style={{
  wordWrap: "break-word",
  whiteSpace: "pre-wrap",
  maxWidth: "50ch", 
}}>{product.item_name}</td>
              <td>{product.count}</td>
              <td>{product.totalValue}</td>
            </tr>
          ))}
                                        </tbody>
                                       
                                      </table>
                                     </div>
                                        
                                      
                                      </div>
                                  </div>
                              </div>
                              
                                        </div>


                                      


          <div className='row'>
                                        <div class="col-12 col-md-4">
                                  <div class="card border-0 shadow-sm mb-3">
                                      <div class="card-body">
                                        <div class="row">
                                            <div class="col-12 col-md">
                                                {/* <h4>Top 10 NDR Zones</h4> */}
                                            </div>
                                            <div class="col-12 col-md-4 text-end">
                                                <div class="row">
                                                   
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-12 col-md-12">
                                            <canvas ref={chartRefndr}></canvas>
                                            </div>
                                            
                                        </div>
                                        
                                      </div>
                                  </div>
                              </div>

                              <div class="col-12 col-md-4">
                                  <div class="card border-0 shadow-sm mb-3">
                                      <div class="card-body">
                                        <div class="row">
                                            <div class="col-12 col-md">
                                                {/* <h4>Top 10 RTO Zones</h4> */}
                                            </div>
                                            <div class="col-12 col-md-4 text-end">
                                                <div class="row">
                                                   
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-12 col-md-12">
                                            <canvas ref={chartRefrto}></canvas>
                                            </div>
                                            
                                        </div>
                                        
                                      </div>
                                  </div>
                              </div>

                              <div class="col-12 col-md-4">
                                  <div class="card border-0 shadow-sm mb-3">
                                      <div class="card-body">
                                        <div class="row">
                                            <div class="col-12 col-md">
                                                {/* <h4>Top 10 Delivered Zones</h4> */}
                                            </div>
                                            <div class="col-12 col-md-4 text-end">
                                                <div class="row">
                                                   
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-12 col-md-12">
                                            <canvas ref={chartRefdelivered}></canvas>
                                            </div>
                                            
                                        </div>
                                        
                                      </div>
                                  </div>
                              </div>
                              
                                        </div> 
      </div>
      <div class="tab-pane g-0  fade" id="menu2" role="tabpanel">
          
          <div class="card border-0 shadow-sm mb-3">
              <div class="card-body">
                  <div class="row">
                      <div class="col-12 col-md">
                          <h4>Billing Summary</h4>
                      </div>
                      <div class="col-12 col-md-3 text-end ">
                        <div class="input-group">
                          <input type="text" class="form-control custom-daterange" />
                          <span class="input-group-text">
                          <button class="btn btn-primary" onClick={()=>list()}><i class="bi bi-search"></i></button>
                          </span>
                        </div>
                      </div>
                  </div>
                  <div class="row">
                      <div class="col-12 col-md-12">
                          <div class="table-responsive">
                          {financeloader? <div style={{ display: 'flex', justifyContent: 'center', marginLeft:"50px", alignItems: 'center',marginTop:"-200px", height: '100vh' }}>
      <ReactLoading type={"spin"} color={"#0d6efd"} height={40} width={60} />
    </div>:
                              <table class="table">
                                  <thead class="table-primary">
                                      <tr>
                                          <th scope="col">Transaction ID</th>
                                          <th scope="col">AWB Number</th>
                                          <th scope="col">Weight &amp; Zone</th>
                                          <th scope="col">Carrier</th>
                                          <th scope="col">Status</th>
                                          <th scope="col">Credit</th>
                                          <th scope="col">Debit</th>
                                      </tr>
                                  </thead>
                                  <tbody>
                                                   
                                                   {shippingstate.length>0?
                       shippingstate.map((sub)=>(
                    <tr>
                       <td>{sub.transaction_id}<br/><Moment format="MMM DD YYYY">
                       {new Date(sub.created*1000)}
           </Moment> - <Moment format="hh:mm:ss a">
                       {new Date(sub.created*1000)}
           </Moment></td>
                       <td>{sub.carrier_tracking_number}</td>
                       <td>{sub.total_weight} KG<br/>
                       {sub.zone_name?sub.zone_name:""}</td>

                       <td>{sub.service_provider}</td>
                       <td>{sub.status==6 || sub.status==7? "Refunded":"Shipping Charges"}</td>
                       <td className='text-success'>{sub.status==6 || sub.status==7?<>₹ {sub.total_amount}</> :""}</td>
                       <td className='text-danger'> {sub.status!=6 && sub.status!=7 && sub.status!=0?<>- ₹ {sub.total_amount}</> :""}</td>
                       </tr>
                    )) : 
                    <tr>
                    <td colspan="12" class="text-center">
                        Sorry! There is no data available at the moment.
                    </td>
                  </tr>}
                                               </tbody>
                                           </table>
}
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </div>
  </div>
</section>
</div>
</div>
</div>
<Footer/>
</div>
</div>




    </div>
  )
}

export default Dashboard
